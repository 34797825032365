/**
 *
 * Created by neo on 18.01.17.
 */

import { action, computed, observable, ObservableMap, toJS } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from './LocalizedValue';
import { LocalizedNamedEntity, LocalizedNamedEntityJson } from './LocalizedNamedEntity';

export const languagePriority = ['de', 'en', 'fr', 'it'];

export type LocalizedArrayEntityJson = LocalizedNamedEntityJson & {
  description: LocalizedValueJson[];
};

export class LocalizedArrayEntity extends LocalizedNamedEntity {
  @observable
  description: Array<LocalizedValue> = [];

  constructor(json?: Partial<LocalizedArrayEntityJson>) {
    super(json);
    if (json) {
      this.description = (json.description || []).map((l) => new LocalizedValue(l));
    }
  }

  toJS(newId: boolean = false): LocalizedArrayEntityJson {
    return Object.assign(super.toJS(newId), {
      description: this.description.map((v) => v.toJS()),
    });
  }

  @action
  changeDescription(lang: string, value: string) {
    const existing = this.description.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.description.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getDescription(lang: string) {
    return this.description.find((n) => n.lang === lang.toLowerCase())?.value ?? '';
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }
}
