/**
 * Created by katarinababic on 27.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InfoSvg from '../../../Assets/SVG/info_white.svg';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Checkbox } from '../../Checkbox';
import { Button } from '../../Button';
import { AlertModal, AlertModalProps } from './AlertModal';

const StyledRow = styled(Row)`
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const Title = styled.p`
  font-size: 1rem;
  color: #ffffff;
  padding-left: 1rem;
  padding-top: 1.2rem;
`;

export type NoReceivedEmailAlertProps = Pick<AlertModalProps, 'toggle' | 'isOpen'> & {
  onClickContactUs?: () => void;
};

export const NoReceivedEmailAlert: React.FC<NoReceivedEmailAlertProps> = observer(
  ({ onClickContactUs, toggle, isOpen = false }) => {
    const { t } = useTrans();

    const [checkBoxOneChecked, setCheckBoxOneChecked] = useState(false);
    const [checkBoxTwoChecked, setCheckBoxTwoChecked] = useState(false);

    const toggleCheckboxOne = React.useCallback(() => {
      setCheckBoxOneChecked((prev) => !prev);
    }, []);

    const toggleCheckboxTwo = React.useCallback(() => {
      setCheckBoxTwoChecked((prev) => !prev);
    }, []);

    return (
      <AlertModal
        backdrop={true}
        toggle={toggle}
        isOpen={isOpen}
        centered={true}
        AlertHeader={
          <StyledRow>
            <Col xs={1}>
              <img src={InfoSvg} alt="info" height={24} width={24} />
            </Col>
            <Col xs={11}>
              <Title>
                {t('registrationScreen.noReceivedEmailAlert.title', {
                  defaultValue: "I didn't receive the email...",
                })}
              </Title>
            </Col>
          </StyledRow>
        }
        AlertBody={
          <React.Fragment>
            <Row>
              <Col>
                <Checkbox
                  text={t('registrationScreen.noReceivedEmailAlert.checkboxOne', {
                    defaultValue: 'I never registered for KINASTIC or created an account before',
                  })}
                  checked={checkBoxOneChecked}
                  onToggle={toggleCheckboxOne}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  text={t('registrationScreen.noReceivedEmailAlert.checkboxTwo', {
                    defaultValue:
                      'I also checked other folders of my inbox such as "updates", "promotions", "spam" or similar.',
                  })}
                  checked={checkBoxTwoChecked}
                  onToggle={toggleCheckboxTwo}
                />
              </Col>
            </Row>
          </React.Fragment>
        }
        AlertFooter={
          <Button
            block={true}
            onClick={onClickContactUs}
            color="success"
            disabled={!checkBoxOneChecked || !checkBoxTwoChecked}
          >
            {t('registrationScreen.noReceivedEmailAlert.contactUs', { defaultValue: 'Contact us' }).toUpperCase()}
          </Button>
        }
      />
    );
  },
);
