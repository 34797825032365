export enum HealthDataPointQuantityType {
  activeEnergyBurned = 'activeEnergyBurned',
  basalBodyTemperature = 'basalBodyTemperature',
  basalEnergyBurned = 'basalEnergyBurned',
  bloodAlcoholContent = 'bloodAlcoholContent',
  bloodGlucose = 'bloodGlucose',
  bloodPressureDiastolic = 'bloodPressureDiastolic',
  bloodPressureSystolic = 'bloodPressureSystolic',
  bodyFatPercentage = 'bodyFatPercentage',
  bodyFatPercentageArmLeft = 'bodyFatPercentageArmLeft',
  bodyFatPercentageArmRight = 'bodyFatPercentageArmRight',
  bodyFatPercentageLegLeft = 'bodyFatPercentageLegLeft',
  bodyFatPercentageLegRight = 'bodyFatPercentageLegRight',
  bodyFatPercentageHull = 'bodyFatPercentageHull',
  bodyMass = 'bodyMass',
  bodyMassIndex = 'bodyMassIndex',
  bodyTemperature = 'bodyTemperature',
  electrodermalActivity = 'electrodermalActivity',
  environmentalAudioExposure = 'environmentalAudioExposure',
  forcedExpiratoryVolume1 = 'forcedExpiratoryVolume1',
  forcedVitalCapacity = 'forcedVitalCapacity',
  heartRate = 'heartRate',
  heartRateMax = 'heartRateMax',
  heartRateVariabilitySDNN = 'heartRateVariabilitySDNN',
  height = 'height',
  leanBodyMass = 'leanBodyMass',
  oxygenSaturation = 'oxygenSaturation',
  peakExpiratoryFlowRate = 'peakExpiratoryFlowRate',
  peripheralPerfusionIndex = 'peripheralPerfusionIndex',
  respiratoryRate = 'respiratoryRate',
  restingHeartRate = 'restingHeartRate',
  vo2Max = 'vo2Max',
  waistCircumference = 'waistCircumference',
  upperArmCircumference = 'upperArmCircumference',
  lowerArmCircumference = 'lowerArmCircumference',
  neckCircumference = 'neckCircumference',
  chestCircumference = 'chestCircumference',
  hipCircumference = 'hipCircumference',
  thighCircumference = 'thighCircumference',
  calfCircumference = 'calfCircumference',
  walkingHeartRateAverage = 'walkingHeartRateAverage',
  bmr = 'bmr',
  musclePercentage = 'musclePercentage',
  musclePercentageArmLeft = 'musclePercentageArmLeft',
  musclePercentageArmRight = 'musclePercentageArmRight',
  musclePercentageLegLeft = 'musclePercentageLegLeft',
  musclePercentageLegRight = 'musclePercentageLegRight',
  musclePercentageHull = 'musclePercentageHull',

  distanceCycling = 'distanceCycling',
  distanceDownhillSnowSports = 'distanceDownhillSnowSports',
  distanceSwimming = 'distanceSwimming',
  distanceWalkingRunning = 'distanceWalkingRunning',
  distanceWheelchair = 'distanceWheelchair',

  dietaryWater = 'dietaryWater',

  sitMobility = 'sitMobility',
  reachMobility = 'reachMobility',

  stepCount = 'stepCount',

  /**
   * Perceived Stress Scale
   */
  pssScore = 'pssScore',
}

export const HealthDataPointQuantityTypes: string[] = Object.keys(HealthDataPointQuantityType);
