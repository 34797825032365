import { sleep } from './Sleep';

export class Retry {
  static async tryTimes<T>(request: () => Promise<T>, times: number = 5): Promise<T> {
    let count = 0;
    let lastError: Error | undefined;
    while (times < 0 || count < times) {
      try {
        lastError = undefined;
        count += 1;
        return await request();
      } catch (e: any) {
        if (e.status < 400 || e.status > 499) {
          await sleep(count * 250);
          lastError = e;
        } else {
          throw e;
        }
      }
    }
    throw lastError;
  }

  /**
   * Like tryTimes but does not accept undefined or null as results. Waits count * 250ms
   * @param request
   * @param times how often
   */
  static async tryNonNull<T>(request: () => Promise<T>, times: number = 5): Promise<T | undefined> {
    let count = 0;
    let lastError: Error | undefined;
    while (times < 0 || count < times) {
      try {
        count += 1;
        const result = await request();
        if (typeof result !== 'undefined' && result !== null) {
          return result;
        }
        await sleep(count * 250);
      } catch (e: any) {
        await sleep(count * 250);
        lastError = e;
      }
    }
    if (lastError) {
      throw lastError;
    }
    return undefined;
  }
}
