import { computed, observable, runInAction } from 'mobx';
import { FeedActivityActor } from './FeedActivityActor';
import { FeedCollectionItem } from './FeedCollectionItem';
import { GetStreamFeed } from './GetStreamFeed';
import { AnyObject } from '../../AnyObject';
import { FeedActivityReaction } from './FeedActivityReaction';
import { FeedActivityReactionCounts } from './FeedActivityReactionCounts';
import { FeedActivityReactions } from './FeedActivityReactions';
import dayjs from '../../../Utils/dayjs';
import { HttpBackend } from '../../../Services/Http/HttpBackend';

export class FeedActivity implements AnyObject {
  id: string = '';
  actor: FeedActivityActor = new FeedActivityActor();
  foreign_id?: string;
  object: FeedCollectionItem = new FeedCollectionItem();
  origin?: string;
  target?: string;
  time?: Date;
  verb?: string;
  version: number = 0;
  tags: string[] = [];
  @observable.shallow
  reaction_counts: FeedActivityReactionCounts = new FeedActivityReactionCounts();
  latest_reactions: FeedActivityReactions = new FeedActivityReactions();
  @observable.shallow
  own_reactions: FeedActivityReactions = new FeedActivityReactions();
  @observable
  visible = false;

  constructor(readonly feed?: GetStreamFeed, json?: any) {
    if (json) {
      Object.assign(this, json);
      this.id = json.id || '';
      this.origin = json.origin;
      this.target = json.target;
      this.time = json.time ? new Date(json.time) : undefined;
      this.foreign_id = json.foreign_id;
      this.verb = json.verb;
      this.version = json.version || 0;
      this.tags = json.tags ?? [];
      this.actor = new FeedActivityActor(json.actor);
      this.object = new FeedCollectionItem(json.object);
      this.reaction_counts = new FeedActivityReactionCounts(json.reaction_counts);
      this.latest_reactions = new FeedActivityReactions(json.latest_reactions);
      this.own_reactions = new FeedActivityReactions(json.own_reactions);
    }
  }

  async like(): Promise<FeedActivityReaction> {
    return (
      this.feed?.client?.reactions.add('like', this.id, {}).then((response) => {
        const reaction = new FeedActivityReaction(response);
        runInAction(() => {
          this.own_reactions.like.push(reaction);
          this.reaction_counts.like += 1;
        });
        return reaction;
      }) ?? Promise.reject()
    );
  }

  unlike() {
    this.own_reactions.like.forEach((like: FeedActivityReaction) => this.feed?.client?.reactions.delete(like.id));
    this.reaction_counts.like = Math.max(0, this.reaction_counts.like - this.own_reactions.like.length);
    this.own_reactions.like = [];
  }

  @computed
  get liked(): boolean {
    return this.own_reactions.like.length > 0;
  }

  @computed
  get likes(): number {
    return this.reaction_counts.like;
  }

  @computed
  get dateMoment(): dayjs.Dayjs {
    return dayjs(this.time);
  }

  static async get(feedId: string): Promise<FeedActivity | undefined> {
    return HttpBackend.get(`/messaging/feed/v3/activities/${feedId}`).then((res) =>
      res ? new FeedActivity(undefined, res) : undefined,
    );
  }
}
