import styled from '@emotion/styled';
import React from 'react';
import { observer } from 'mobx-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const Subtitle = styled.p`
  font-weight: 400;
  color: #f6f6f7;
  max-width: 400px;
  font-size: 16px;

  @media (min-width: 600px) {
    font-size: calc(1rem + 0.4vw);
  }
`;

const Logo = styled.img`
  width: 180px;
`;

export type AuthOverlayLeftProps = {
  text: string;
};

export const AuthOverlayLeft: React.FC<AuthOverlayLeftProps> = observer(({ text }) => {
  return (
    <Container>
      <Logo src={require('../../../Assets/Images/kinastic-logo.png')} alt="logo" />
      <Subtitle className="shadow-text" dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, '<br />') }} />
    </Container>
  );
});
