/**
 * Created by neo on 18.01.17.
 */
import { observable, computed, action, ObservableMap } from 'mobx';
import { ExerciseVariation } from './ExerciseVariation';
import { v4 as UUID } from 'uuid';
import { logger } from '../../Utils/logger';
import { BaseExerciseBlockJson, ExerciseBlock, ExerciseBlockJson } from './ExerciseBlock';
import { Workout } from './Workout';

export const PHASES: any[] = [
  { key: 'MOBILIZATION', name: 'Mobilization', type: 'mobilization' },
  { key: 'WARMUP', name: 'Aufwärmen', type: 'warmup' },
  { key: 'BALANCE', name: 'Koordination / Balance', type: 'balance' },
  { key: 'STRENGTH', name: 'Krafttraining', type: 'strength' },
  { key: 'CARDIO', name: 'Cardio', type: 'cardio' },
  { key: 'STRETCHING', name: 'Stretching', type: 'stretching' },
  { key: 'RELAX', name: 'Entspannung', type: 'relax' },
  { key: 'INTERVAL', name: 'Interval', type: 'interval' },
  { key: 'FASCIA', name: 'Fascia training', type: 'fascia' },
];

export type BasePhaseJson = {
  phaseId: string;
  type: string;
  exerciseBlocks: BaseExerciseBlockJson[];
  options: {
    [key: string]: any;
  };
};

export type PhaseJson = Omit<BasePhaseJson, 'exerciseBlocks'> & {
  name: string;
  description?: string;
  exerciseBlocks: ExerciseBlockJson[];
};

export class Phase {
  @observable phaseId: string = UUID();
  @observable type: string = 'strength';
  @observable name: string = '';
  @observable description?: string;
  @observable.shallow exerciseBlocks: ExerciseBlock[] = [];
  @observable options: ObservableMap<string> = observable.map({});
  @observable workout?: Workout;

  constructor(workout?: Workout, json?: Partial<PhaseJson>) {
    this.workout = workout;
    if (json) {
      this.setData(json);
    }
  }

  @action
  setBasicData(json: Partial<PhaseJson>) {
    this.phaseId = json.phaseId || UUID();
    this.type = json.type || 'strength';
    this.name = json.name || '';
    this.description = json.description;
    this.options = observable.map(json.options || {});
  }

  @action
  updateData(json: any) {
    this.setBasicData(json);
    this.exerciseBlocks.forEach((e: ExerciseBlock, index: number) => {
      if (!json.exerciseBlocks.find((w0: any) => w0.exerciseBlockId === e.exerciseBlockId)) {
        logger(`Removing block ${e.exercise.name}`);
        this.exerciseBlocks.splice(index, 1);
      }
    });
    json.exerciseBlocks.forEach((e: any, i: number) => {
      const existing = this.exerciseBlocks.find((w0: ExerciseBlock) => e.exerciseBlockId === w0.exerciseBlockId);
      const block = existing || new ExerciseBlock(this, e);
      if (existing) {
        logger(`Updating ${existing.exercise.name}`);
        existing.updateData(e);
      }

      const element = this.exerciseBlocks[i];
      if (!element || element.exerciseBlockId !== block.exerciseBlockId) {
        if (i < this.exerciseBlocks.length) {
          logger(`Inserting ${block.exercise.name} at ${i}`);
          this.exerciseBlocks.splice(i, 0, block);
        } else {
          logger(`Pushing block ${block.exercise.name}`);
          this.exerciseBlocks.push(block);
        }
      } else {
        logger(`Block ${block.exercise.name} is the same`);
      }
    });
  }

  @action
  setData(json: any) {
    this.setBasicData(json);
    this.exerciseBlocks = (json.exerciseBlocks || [])
      .filter((b: any) => b && b.exercise && b.exercise.id)
      .map((b: any) => new ExerciseBlock(this, b));
  }

  toJS(newId?: boolean): PhaseJson {
    return {
      phaseId: newId ? UUID() : this.phaseId,
      type: this.type,
      name: this.name,
      description: this.description,
      exerciseBlocks: this.exerciseBlocks.map((b) => b.toJS(newId)),
      options: this.options.toJSON(),
    };
  }

  @action
  addExerciseBlock(block: ExerciseBlock, afterBlock?: ExerciseBlock) {
    block.phase = this;
    const index = afterBlock
      ? this.exerciseBlocks.findIndex((b: ExerciseBlock) => b.exerciseBlockId === afterBlock.exerciseBlockId)
      : -1;
    if (index === -1 || index === this.exerciseBlocks.length - 1) {
      this.exerciseBlocks.push(block);
    } else {
      this.exerciseBlocks.splice(index + 1, 0, block);
    }
  }

  @action
  moveBlock(oldIndex: number, newIndex: number): ExerciseBlock | undefined {
    const removed = this.exerciseBlocks.splice(oldIndex, 1)[0];
    if (removed) {
      if (newIndex < this.exerciseBlocks.length) {
        this.exerciseBlocks.splice(newIndex, 0, removed);
      } else {
        this.exerciseBlocks.push(removed);
      }
    }
    return removed;
  }

  @action
  removeExerciseBlock(exerciseBlock: ExerciseBlock) {
    const index = this.exerciseBlocks.findIndex((b) => b.exerciseBlockId === exerciseBlock.exerciseBlockId);
    if (index !== -1) {
      this.exerciseBlocks.splice(index, 1);
    }
  }

  @computed
  get valid(): boolean {
    if (this.name && this.name.trim().length > 0) {
      if (this.exerciseBlocks.length > 0) {
        for (const block of this.exerciseBlocks) {
          if (!block.valid) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  @computed
  get totalExercises(): number {
    return this.exerciseBlocks.length;
  }

  @computed
  get duration(): number {
    return this.exerciseBlocks.reduce((total: number, block: ExerciseBlock) => total + block.duration, 0);
  }

  @computed
  get breakTime(): number {
    return this.exerciseBlocks.reduce((total: number, block: ExerciseBlock) => total + block.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.exerciseBlocks.reduce((total: number, block: ExerciseBlock) => total + block.totalTimeExercising, 0);
  }

  @computed
  get totalSets(): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.sets.length, 0);
  }

  @computed
  get tons(): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.tons, 0);
  }

  @computed
  get lastBlock(): ExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[length - 1];
    }
    return undefined;
  }

  @computed
  get firstBlock(): ExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[0];
    }
    return undefined;
  }

  @computed
  get prevPhase(): Phase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      if (phaseIndex > 0) {
        return this.workout.phases[phaseIndex - 1];
      }
    }
    return undefined;
  }

  @computed
  get nextPhase(): Phase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      const nextIndex = phaseIndex + 1;
      return nextIndex < this.workout.phases.length ? this.workout.phases[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get phaseIndex(): number {
    return this.workout ? this.workout.phases.findIndex((p) => p.phaseId === this.phaseId) : -1;
  }

  @computed
  get prevBlock(): ExerciseBlock | undefined {
    return this.lastBlock || (this.prevPhase ? this.prevPhase.prevBlock : undefined);
  }

  @computed
  get nextBlock(): ExerciseBlock | undefined {
    return this.firstBlock || (this.nextPhase ? this.nextPhase.nextBlock : undefined);
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.exerciseBlocks.reduce(
      (total: number, block: ExerciseBlock) => total + block.estimatedTotalExerciseTime,
      0,
    );
  }

  calculateCalories(bmr24: number): number {
    return this.exerciseBlocks.reduce((total: number, b: ExerciseBlock) => total + b.calculateCalories(bmr24), 0);
  }

  hasExercise(exercise: ExerciseVariation, exerciseBlockId?: string): boolean {
    if (exerciseBlockId) {
      return !!this.exerciseBlocks.find((b) => b.exerciseBlockId === exerciseBlockId);
    }
    const index = this.exerciseBlocks.findIndex((b) => this.getUniqueId(b.exercise) === this.getUniqueId(exercise));
    return index !== -1;
  }

  getUniqueId(exercise: ExerciseVariation): string {
    return exercise.id;
  }
}
