import { HealthDataPointRequest } from './HealthDataPointRequest';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { HealthDataPointObjectType } from './HealthDataPointObjectType';
import { HealthDataPoint } from './HealthDataPoint';
import { HealthDataPointType } from './HealthDataPointType';
import { HealthDataPointCategory } from './HealthDataPointCategory';
import { HealthDataPointQuantity } from './HealthDataPointQuantity';
import { HealthDataPointCorrelation } from './HealthDataPointCorrelation';

export class HealthDataPointQuery {
  static find(request?: HealthDataPointRequest): Promise<HealthDataPoint[]> {
    return HttpBackend.get(`/athlete/health-data`, request).then((res) =>
      res.map((d) => HealthDataPointQuery.build(d)),
    );
  }

  static findQuantity(request?: HealthDataPointRequest): Promise<HealthDataPointQuantity[]> {
    return HttpBackend.get(
      `/athlete/health-data`,
      Object.assign(request ?? {}, { pointType: HealthDataPointType.quantity }),
    ).then((res) => res.map((d) => new HealthDataPointQuantity(d)));
  }

  static last(types: HealthDataPointObjectType[]): Promise<HealthDataPoint[]> {
    return HttpBackend.get(`/athlete/health-data/last/${types.join(',')}`).then((res) =>
      res.map((d) => HealthDataPointQuery.build(d)),
    );
  }

  static recent(): Promise<HealthDataPoint[]> {
    return HttpBackend.get(`/athlete/health-data/recent`).then((res) => res.map((d) => HealthDataPointQuery.build(d)));
  }

  static async lastQuantity(types: HealthDataPointObjectType[]): Promise<HealthDataPointQuantity[]> {
    const res = await HttpBackend.get(`/athlete/health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.quantity,
    });
    return (res || []).map((d) => new HealthDataPointQuantity(d));
  }

  static async lastCorrelation(types: HealthDataPointObjectType[]): Promise<HealthDataPointCorrelation[]> {
    const res = await HttpBackend.get(`/athlete/health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.correlation,
    });
    return (res || []).map((d) => new HealthDataPointCorrelation(d));
  }

  static async lastCategory(types: HealthDataPointObjectType[]): Promise<HealthDataPointCategory[]> {
    const res = await HttpBackend.get(`/athlete/health-data/last/${types.join(',')}`, {
      pointType: HealthDataPointType.category,
    });
    return (res || []).map((d) => new HealthDataPointCategory(d));
  }

  static build(json: any): HealthDataPoint {
    switch (json.pointType) {
      case HealthDataPointType.category:
        return new HealthDataPointCategory(json);
      case HealthDataPointType.quantity:
        return new HealthDataPointQuantity(json);
      case HealthDataPointType.correlation:
        return new HealthDataPointCorrelation(json);
      default:
        return new HealthDataPoint(json);
    }
  }
}
