/**
 * Created by katarinababic on 27.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { FormLabel } from '../../../../Components/Form/FormLabel';
import { Input } from '../../../../Components/Input';
import { Container, Form, FormGroup } from 'reactstrap';
import { useTrans } from '../../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { useRootStore } from '../../../../Store/useRootStore';
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from '../../../../Utils/Constants';
import { useState, useEffect } from 'react';
import { Checkbox } from '../../../../Components/Checkbox';
import { Button } from '../../../../Components/Button';
import { TextWithMiddleLine } from '../../../../Components/TextWithMiddleLine';
import { Translation } from '../../../../Components/Translation';
import { ContinueWithAppleButton } from '../../../../Components/Auth/Buttons/ContinueWithAppleButton';
import { Dropdown, Option } from '../../../../Components/Dropdown';
import { CoachRegistrationVerificationRequest } from '../../../../Store/SignupStore/CoachRegistrationVerificationRequest';
import { runInAction } from 'mobx';
import { useLocation, useNavigate } from 'react-router-dom';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { CoachRegistrationRequest } from '../../../../Model/CoachSubscription/CoachRegistrationRequest';
import { LoginBottomButton } from '../../../../Components/Auth/Buttons/LoginBottomButton';
import { CustomerGroup } from '../../../../Model/CustomerGroup/CustomerGroup';

const FormContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const CheckboxContainer = styled.div`
  margin-top: 1rem;
  background-color: black;
  padding: 1rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
`;

export type RegistrationVerificationFormProps = {
  request: CoachRegistrationVerificationRequest;
  codeRequest: CoachRegistrationRequest;
  onError?: (err?: string) => void;
};

export const RegistrationVerificationForm: React.FC<RegistrationVerificationFormProps> = observer(
  ({ request, codeRequest, onError }) => {
    const { t } = useTrans();
    const { coachSubscription, analytics, authentication } = useRootStore();

    const location = useLocation();
    const navigate = useNavigate();

    useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [emailDirty, setEmailDirty] = useState(false);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);

    const privacyAndTosInvalid = submitted && !request.tosAndPrivacyAccepted;
    const submitDisabled =
      !request.emailValid ||
      !request.tosAndPrivacyAccepted ||
      request.validatingEmail ||
      submitting ||
      (customerGroups.length > 0 && !request.customerGroupId);

    useEffect(() => {
      setCustomerGroups([]);
      codeRequest.findGroups().then((res) => {
        setCustomerGroups(res);
        const groupOptions = res.map((g) => ({ label: g.name, value: g.id } as Option));
        setDropdownOptions(groupOptions);
      });
    }, [codeRequest]);

    const handlePrivateEmailChanged = React.useCallback(
      (e) => {
        setEmail(e.target.value?.trim()?.toLowerCase());
        runInAction(() => (request.email = e.target.value?.trim()?.toLowerCase()));
      },
      [request],
    );

    const handleChangeDepartment = React.useCallback(
      (value?: string) => {
        runInAction(() => (request.customerGroupId = value));
      },
      [request],
    );

    const handleSubmit = React.useCallback(
      (e: any) => {
        e.preventDefault();
        setSubmitted(true);
        if (!submitDisabled && request) {
          setSubmitting(true);
          request
            .signup()
            .then((res) => authentication.otpLogin(res.authenticationCode))
            .then(() => coachSubscription.fetchSubscription())
            .then(() => analytics.logSignup('email'))
            .then(() => navigate(`/`, { replace: true }))
            .catch((err) => {
              analytics.logError('registration_verification', err.response?.data?.errorCode);
              onError && onError(err.response?.data?.errorCode);
            })
            .finally(() => setSubmitting(false));
        }
      },
      [analytics, authentication, coachSubscription, navigate, request, submitDisabled, onError],
    );

    const toggleTerms = React.useCallback(
      () => runInAction(() => (request.tosAndPrivacyAccepted = !request.tosAndPrivacyAccepted)),
      [request],
    );

    const toggleUpdates = React.useCallback(
      () => runInAction(() => (request.acceptsMarketing = !request.acceptsMarketing)),
      [request],
    );

    const handleAppleSignup = React.useCallback(() => {
      appleAuthHelpers
        .signIn({
          authOptions: {
            clientId: APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: APPLE_REDIRECT_URI,
            usePopup: true,
          },
          onError: (error) => console.log('Error performing apple signin', error),
        })
        .then((res) => {
          console.log('apple signin res is', res);
          return {
            identityToken: res.authorization?.id_token,
            email: res.user?.email,
            fullName: { givenName: res.user?.name?.firstName, familyName: res.user?.name?.lastName },
          };
        })
        .then((appleResponse) => {
          setSubmitting(true);
          request
            .signup(appleResponse)
            .then((res) => authentication.otpLogin(res.authenticationCode))
            .then(() => coachSubscription.fetchSubscription())
            .then(() => {
              analytics.logSignup('apple');
            })
            .then(() => navigate(`/`, { replace: true }))
            .catch((err) => {
              const errorCode = err.response?.data?.errorCode;
              analytics.logError('apple_signup', errorCode);
              onError && onError(errorCode === 'athlete_exists' ? 'apple_email_already_associated' : errorCode);
            })
            .finally(() => setSubmitting(false));
        })
        .catch((e) => console.log(e));
    }, [analytics, authentication, coachSubscription, location, navigate, onError]);

    return (
      <Form onSubmit={handleSubmit}>
        <FormContainer>
          <FormGroup>
            <FormLabel>
              {t('registrationScreen.stepTwo.emailLabel', { defaultValue: 'Preferred e-mail address' })}
            </FormLabel>
            <Input
              type="email"
              placeholder={t('registrationScreen.stepTwo.emailPlaceholder', { defaultValue: 'Your e-mail address' })}
              value={email}
              disabled={submitting}
              invalid={emailDirty && !request.emailValid}
              onChange={handlePrivateEmailChanged}
              onBlur={() => setEmailDirty(true)}
              data-lpignore="true"
              autocomplete={false}
              disabledColor={'#3c3c3c'}
            />
          </FormGroup>
          {customerGroups.length > 0 && <Dropdown options={dropdownOptions} onChangeOption={handleChangeDepartment} />}
          <CheckboxContainer>
            <Checkbox
              text={
                <Translation
                  i18nKey="registrationScreen.stepTwo.acceptTosAndPrivacy"
                  defaultValue={
                    'By signing up to KINASTIC, you agree to our <a href="https://www.kinastic.com/agb-app-en/" target="_blank">terms</a> and <a href="https://www.kinastic.com/privacy-policy-blank" target="_blank">privacy policy</a>'
                  }
                />
              }
              checked={request.tosAndPrivacyAccepted}
              onToggle={toggleTerms}
              invalid={privacyAndTosInvalid}
            />
            <Checkbox
              text={
                <Translation
                  i18nKey="registrationScreen.stepTwo.acceptMarketing"
                  defaultValue="Yes, please keep me updated on KINASTIC news, events and offers"
                />
              }
              checked={request.acceptsMarketing}
              onToggle={toggleUpdates}
            />
          </CheckboxContainer>
        </FormContainer>
        <ButtonContainer>
          <Button
            type="submit"
            color="success"
            block={true}
            className={submitDisabled ? 'disabled' : ''}
            onClick={handleSubmit}
          >
            {t('registrationScreen.stepTwo.createKinasticAccount', {
              defaultValue: 'Create KINASTIC Account',
            }).toUpperCase()}
          </Button>
        </ButtonContainer>
        <TextWithMiddleLine text={t('globals.or', { defaultValue: 'Or' })} />
        <ContinueWithAppleButton
          onClick={handleAppleSignup}
          disabled={submitDisabled}
          text={t('authentication.signUpWithApple', { defaultValue: 'Sign up with Apple' })}
        />
        <LoginBottomButton />
      </Form>
    );
  },
);
