/**
 * Created by neo on 29.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { useRootStore } from '../../Store/useRootStore';
import styled from '@emotion/styled';

const Title = styled.p`
  font-weight: 600;
  color: black;
`;

export type ExploreCardTitleProps = {
  entry: ExploreEntry;
};

export const ExploreCardTitle: React.FC<ExploreCardTitleProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const entryTitle = entry.getName(language.language) ?? '';

  return <Title>{entryTitle.toUpperCase()}</Title>;
});
