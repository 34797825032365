/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import { useRootStore } from '../../../Store/useRootStore';
import styled from '@emotion/styled';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { ElementRenderer } from './Components/ElementRenderer';

const Title = styled.h1`
  margin-top: 32px;
`;

export type ExploreContentViewArticleContentProps = {
  entry: ArticleEntry;
};

export const ExploreContentViewArticleContent: React.FC<ExploreContentViewArticleContentProps> = observer(
  ({ entry }) => {
    const { language } = useRootStore();

    const title = entry.getName(language.language);
    const content = entry.getDescription(language.language);

    return (
      <React.Fragment>
        <Title>{title}</Title>
        {entry.content.map((element) => (
          <ElementRenderer key={element.key} element={element} />
        ))}
      </React.Fragment>
    );
  },
);
