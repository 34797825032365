import * as React from 'react';
import styled from '@emotion/styled';
import { Collapse, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import { useState } from 'react';
import { HeaderProfileMenu } from './HeaderProfileMenu';
import { useTrans } from '../../Store/System/LocalizationStore';
import { observer } from 'mobx-react';
import { useRootStore } from '../../Store/useRootStore';

const Logo = styled.img``;

const HeaderContainer = styled(Navbar)`
  background-color: #f6f6f7;
`;

const LogoContainer = styled.div`
  display: flex;
  height: 50px;
  align-self: flex-start;
`;

const NavbarLink = styled(NavLink)`
  color: #3c3c3c;
  font-family: Montserrat;
  font-weight: 600;
  text-decoration: none;

  &.active {
    color: #24dca4 !important;
  }
`;

const NavbarDropdownToggle = styled(DropdownToggle)`
  color: #3c3c3c;
  font-family: Montserrat;
  font-weight: 600;
  text-decoration: none;
`;

const CollapseContainer = styled(Collapse)`
  justify-content: center;
`;

const HeaderNav = styled(Nav)`
  flex: 1;
  justify-content: center;
`;

export type HeaderProps = {};

export const Header: React.FC<HeaderProps> = observer(() => {
  const { coachSubscription } = useRootStore();

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTrans();

  const handleToggle = React.useCallback(() => setIsOpen((p) => !p), []);

  return (
    <HeaderContainer expand="md" container="md">
      <NavbarBrand tag={Link} to="/">
        <LogoContainer>
          <Logo src={require('../../Assets/Images/header_logo.png')} />
        </LogoContainer>
      </NavbarBrand>
      <NavbarToggler onClick={handleToggle} />
      <CollapseContainer isOpen={isOpen} navbar>
        <HeaderNav className="mr-auto" navbar>
          {coachSubscription.canShowMove && (
            <NavItem>
              <NavbarLink tag={RRNavLink} to="/move" activeClassName="active">
                {t('header.moveLink', { defaultValue: 'MOVE' })}
              </NavbarLink>
            </NavItem>
          )}
          {coachSubscription.canShowAllContent && (
            <NavItem>
              <NavbarLink tag={RRNavLink} to="/mental-health" activeClassName="active">
                {t('header.mentalHealth', { defaultValue: 'MENTAL HEALTH' })}
              </NavbarLink>
            </NavItem>
          )}
          {coachSubscription.canShowAllContent && (
            <NavItem>
              <NavbarLink tag={RRNavLink} to="/nutrition" activeClassName="active">
                {t('header.nutrition', { defaultValue: 'NUTRITION' })}
              </NavbarLink>
            </NavItem>
          )}
          {coachSubscription.canShowAcademy && (
            <NavItem>
              <NavbarLink tag={RRNavLink} to="/academy" activeClassName="active">
                {t('header.academy', { defaultValue: 'ACADEMY' })}
              </NavbarLink>
            </NavItem>
          )}
        </HeaderNav>
        <HeaderProfileMenu />
      </CollapseContainer>
    </HeaderContainer>
  );
});
