export type FontProps = {
  light: Record<string, any>;
  normal: Record<string, any>;
  medium: Record<string, any>;
  bold: Record<string, any>;
  heavy: Record<string, any>;
  heavyItalic: Record<string, any>;
  italic: Record<string, any>;
  boldItalic: Record<string, any>;
};

export type ITypography = {
  font: FontProps;
};

export const Typography: ITypography = {
  font: {
    light: {
      fontWeight: 200,
    },
    normal: {},
    medium: {
      fontWeight: 400,
    },
    bold: {
      fontWeight: 600,
    },
    heavy: {
      fontWeight: 800,
    },
    heavyItalic: {
      fontStyle: 'italic',
      fontWeight: 800,
    },
    italic: {
      fontStyle: 'italic',
    },
    boldItalic: {
      fontStyle: 'italic',
      fontWeight: 600,
    },
  },
};
