/**
 * Created by katarinababic on 13.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Col, Row } from 'reactstrap';
import { Button, ButtonProps } from '../Button';

const Container = styled.div`
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  display: flex;
  background-color: #edeef0;
  margin: 48px 0;
  padding: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    border-width: 0;
    border-radius: 0 20px 20px 0;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

const LeftCol = styled(Col)`
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${(p) => `url('${p.image}')`};
  background-position: center center !important;
  background-size: cover !important;

  @media (min-width: 600px) {
    flex-direction: row;
    border-width: 0;
    border-radius: 0;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

const RightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 100%;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BottomContent = styled(Row)`
  @media (min-width: 1024px) {
    max-width: 60%;
  }
`;

const Title = styled.div`
  color: #3c3c3c;
  font-weight: 700;
  margin-top: 16px;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  color: #3c3c3c;
  font-weight: 500;
  margin-top: 16px;
  font-size: 1.1rem;

  @media (min-width: 600px) {
  }
`;

const Description = styled.p`
  color: #3c3c3c;
  font-weight: 400;
  margin: 16px 0 48px 0;
  text-align: left;
  font-size: 1.2rem;

  @media (min-width: 600px) {
  }
`;

export type EmptyScheduleCardProps = {
  image: string;
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
} & Pick<ButtonProps, 'onClick'>;

export const EmptyScheduleCard: React.FC<EmptyScheduleCardProps> = observer(
  ({ image, title, subtitle, description, buttonText, onClick }) => {
    return (
      <Container>
        <LeftCol image={image} xs={12} md={6} />
        <RightCol xs={12} md={6}>
          <TopContent>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Description>{description}</Description>
          </TopContent>
          <BottomContent>
            <Button color="primary" size="sm" onClick={onClick} style={{ marginBottom: 16 }}>
              {buttonText}
            </Button>
          </BottomContent>
        </RightCol>
      </Container>
    );
  },
);
