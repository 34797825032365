/**
 * Created by neo on 28.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Container } from 'reactstrap';

export type FeedbackScreenProps = {};

export const FeedbackScreen: React.FC<FeedbackScreenProps> = observer((props) => {
  return (
    <Container>
      <h1 style={{ marginBottom: 16 }}>Feedback</h1>
      <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
      <style type="text/css" media="screen, projection">
        @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
      </style>
      <iframe
        title="Feedback Form"
        className="freshwidget-embedded-form"
        id="freshwidget-embedded-form"
        src="https://kinastic.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback&searchArea=no"
        scrolling="no"
        height="500px"
        width="100%"
        frameBorder="0"
      ></iframe>
    </Container>
  );
});
