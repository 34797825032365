/**
 * Created by katarinababic on 19.8.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import { useRootStore } from '../../../../Store/useRootStore';
import styled from '@emotion/styled';
import { LatoText } from '../../../Text/LatoText';

const View = styled.div`
  display: flex;
  flex-direction: column;
`;

export type BulletAndNumberElementProps = {
  element: NativeFeedComponent;
};

export const BulletAndNumberElement: React.FC<BulletAndNumberElementProps> = observer(({ element }) => {
  const { language } = useRootStore();

  const bulletText = element.type === 'BulletPoint' ? '\u2022' + ' ' : undefined;
  const bulletStyle = element.type === 'BulletPoint' ? styles.bullet : styles.number;
  const textStyle = element.type === 'BulletPoint' ? styles.bulletText : styles.numberText;

  return (
    <View style={styles.container as any}>
      {element.children.map((c, index) => (
        <View style={styles.row as any} key={c.key}>
          <View style={bulletStyle}>
            <LatoText text={bulletText ?? `${index + 1}.`} variant="body1" />
          </View>
          <View style={textStyle}>
            <LatoText text={c.getText(language.language)} />
          </View>
        </View>
      ))}
    </View>
  );
});

const styles = {
  container: {
    paddingVertical: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  bullet: {
    width: 8,
  },
  number: {
    width: 24,
  },
  bulletText: {
    flex: 1,
    paddingLeft: 16,
    paddingBottom: 8,
  },
  numberText: {
    flex: 1,
    paddingBottom: 8,
  },
};
