export class FeedActivityReaction {
  id: string = '';
  user_id: string = '';
  user?: any;
  kind: string = '';
  activity_id: string = '';
  data: any = {};
  parent: string = '';
  latest_children: any[] = [];
  children_counts: any;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || '';
      this.user_id = json.user_id || '';
      this.user = json.user;
      this.kind = json.kind || '';
      this.activity_id = json.activity_id || '';
      this.data = json.data || {};
      this.parent = json.parent || '';
      this.latest_children = json.latest_children || [];
      this.children_counts = json.children_counts || {};
    }
  }
}
