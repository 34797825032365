/**
 * Created by neo on 01.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { ExploreCardEntryRenderer } from '../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { HorizontalSlider } from '../../Components/HorizontalSlider';
import styled from '@emotion/styled';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Col, Row } from 'reactstrap';
import { Button } from '../../Components/Button';
import { Link } from 'react-router-dom';
import { Translation } from '../../Components/Translation';
import { ExploreEntryBuilder } from '../../Model/Explore/ExploreEntryBuilder';
import { WebinarEntry } from '../../Model/Explore/WebinarEntry';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const IntroContainer = styled(Row)`
  margin-top: 32px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
`;

export type UpcomingAcademyWebinarSectionProps = {};

export const UpcomingAcademyWebinarSection: React.FC<UpcomingAcademyWebinarSectionProps> = observer((props) => {
  const [upcoming, setUpcoming] = useState<SubscribableEntry[]>([]);

  const { t } = useTrans();

  useEffect(() => {
    ExploreEntryBuilder.upcoming({ categories: ['academy_live'] }).then((res) => setUpcoming(res));
  }, []);

  return (
    <Container>
      <IntroContainer>
        <Col sm={12} md={6}>
          <Header>
            <Translation
              i18nKey="upcomingAcademyWebinars.title"
              defaultValue={`<span style="color: #24dca4">Upcoming</span> live webinars`}
            />
          </Header>
          <Description>
            <Translation
              i18nKey="upcomingAcademyWebinars.intro.text"
              defaultValue={
                '<b>Learn more about health!</b> In our monthly webinars, KINASTIC experts share their knowledge with you. You can ask them your questions and get practical health tips for your everyday life.'
              }
            />
          </Description>
        </Col>
        <Col sm={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button tag={Link} to="schedule" shadowed={true}>
            <i className="bi bi-calendar-event" style={{ marginRight: 8 }} />
            {t('upcomingAcademyWebinars.intro.seeAllScheduleButton', { defaultValue: 'SEE ALL SCHEDULE' })}
          </Button>
        </Col>
      </IntroContainer>
      <HorizontalSlider>
        {upcoming.map((entry) => (
          <ExploreCardEntryRenderer key={entry.id} entry={entry} />
        ))}
      </HorizontalSlider>
    </Container>
  );
});
