/**
 * Created by neo on 28.12.22.
 */
import * as React from 'react';
import {observer} from 'mobx-react';
import {useEffect} from 'react';

export type ContactScreenProps = {}

export const ContactScreen: React.FC<ContactScreenProps> = observer((props) => {
  return (
    null
  );
});
