/**
 * Created by neo on 02.01.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import { Label, LabelProps } from 'reactstrap';
import styled from '@emotion/styled';

const LabelComponent = styled(Label)`
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
`;

export type FormLabelProps = LabelProps & {};

export const FormLabel: React.FC<PropsWithChildren<FormLabelProps>> = observer(({ children, ...moreProps }) => {
  return <LabelComponent {...moreProps}>{children}</LabelComponent>;
});
