/**
 * Created by andreaskarantzas on 09.04.21.
 */
import { observable } from 'mobx';

export type BreathingTechniqueStepTypes = 'inhale' | 'hold' | 'exhale';

export type BreathingTechniqueStepJson = {
  type: BreathingTechniqueStepTypes;
  duration: number;
};

export class BreathingTechniqueStep {
  @observable
  type: BreathingTechniqueStepTypes = 'inhale';
  /** the duration is in seconds **/
  @observable
  duration: number = 2;

  constructor(json?: Partial<BreathingTechniqueStepJson>) {
    if (json) {
      this.type = json.type ?? 'inhale';
      this.duration = json.duration || 2;
    }
  }

  toJS(): BreathingTechniqueStepJson {
    return {
      type: this.type,
      duration: this.duration,
    };
  }
}
