/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { useRootStore } from '../../Store/useRootStore';
import { ExploreCardWrapper } from '../../View/Academy/ExploreCardWrapper';
import { ExploreCardTitle } from './ExploreCardTitle';
import { ExploreCardShortDescription } from './ExploreCardShortDescription';

const Container = styled.div`
  height: 100%;
  border-radius: 8px;
  border: 8px solid white;
  background: white;
  margin-bottom: 8px;
  box-shadow: -2px 2.73214px 9.10714px 1.82143px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (min-width: 600px) {
    border: 8px solid white;
  }
`;

const InnerContainer = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: column;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.75) 25%, rgba(0, 0, 0, 0) 98.58%), url('${({ image }) => image}');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  border-radius: 8px;
  align-self: stretch;
  padding: 16px;
  color: white;
  height: 250px;
`;

const LinkWrapper = styled(Link)`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0 8px;
  justify-content: flex-end;
`;

export type ExploreEntryContainerProps = {
  entry: ExploreEntry;
  linkPrefix?: string;
};

export const ExploreEntryContainer: React.FC<PropsWithChildren<ExploreEntryContainerProps>> = observer(
  ({ children, entry, linkPrefix = '/academy/' }) => {
    const { language } = useRootStore();

    const backgroundImage = entry.getImage(language.language)?.medium;

    return (
      <ExploreCardWrapper>
        <Container>
          <LinkWrapper to={`${linkPrefix}${entry.id}`}>
            <InnerContainer image={backgroundImage}>{children}</InnerContainer>
          </LinkWrapper>
          <ContentContainer>
            <ExploreCardTitle entry={entry} />
            <ExploreCardShortDescription entry={entry} />
          </ContentContainer>
        </Container>
      </ExploreCardWrapper>
    );
  },
);
