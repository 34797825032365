/**
 * Created by neo on 20.01.17.
 */

import { observable, toJS } from 'mobx';
import { HttpBackend } from '../Services/Http/HttpBackend';
import { Audited, AuditedJson } from './Audited';
import __DEV__, { __BETA__ } from '../Utils/Dev';

export type UserJson = AuditedJson & {
  id: string;
  email: string;
  roles: string[];
};

export class User extends Audited {
  @observable
  email: string = '';
  @observable
  roles: string[] = [];

  constructor(json?: Partial<UserJson>) {
    super(json);
    if (json) {
      this.email = json.email || '';
      this.roles = json.roles ?? [];
    }
  }

  toJS(): UserJson {
    return Object.assign(super.toJS(), {
      email: this.email,
      roles: toJS(this.roles),
    });
  }
  private callbackUrl(): string {
    if (__DEV__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Faccount%2Fchange-email%2Fconfirm&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Faccount%2Fchange-email%2Fconfirm&afl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Faccount%2Fchange-email%2Fconfirm&efr=1&isi=1465156373';
    } else if (__BETA__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fbeta.coach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fbeta.coach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&afl=https%3A%2F%2Fbeta.coach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&efr=1&isi=1465156373';
    }

    return 'https://open.kinastic.com/?link=https%3A%2F%2Fcoach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fcoach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&afl=https%3A%2F%2Fcoach.kinastic.com%2Faccount%2Fchange-email%2Fconfirm&efr=1&isi=1465156373';
  }

  changeEmail(email: string): Promise<any> {
    return HttpBackend.post(`/uaa/change-email`, {
      email,
      callbackUrl: this.callbackUrl(),
    });
  }

  verifyEmailChange(verificationCode: string): Promise<any> {
    return HttpBackend.post(`/uaa/change-email/verify`, { verificationCode });
  }
}
