import * as React from 'react';
import { observer } from 'mobx-react';
import { UpcomingWebinarCarousel } from '../../Components/Carousel/UpcomingWebinarCarousel';
import styled from '@emotion/styled';
import { Container } from 'reactstrap';
import { useEffect, useState } from 'react';
import { PageResult } from '../../Model/PageResult';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../Model/Explore/ExploreContentBuilder';
import { ExploreContentSwitch } from './ExploreContentSwitch';
import { UpcomingAcademyWebinarSection } from './UpcomingAcademyWebinarSection';
import { PreviousWebinarsSection } from './PreviousWebinarsSection';
import { Translation } from '../../Components/Translation';
import { ExploreCategoryContainer } from './ExploreCategoryRenderer';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';
import { ExploreEntryBuilder } from '../../Model/Explore/ExploreEntryBuilder';

const ExploreContentContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 600px) {
    margin-bottom: 32px;
  }
`;

const AcademyScreenContainer = styled.div``;

const TitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.2rem;
`;

export type AcademyScreenProps = {};

export const AcademyScreen: React.FC<AcademyScreenProps> = observer(() => {
  const [content, setContent] = useState<PageResult<ExploreContent>>(new PageResult());
  const [page, setPage] = useState(0);
  const [upcoming, setUpcoming] = useState<SubscribableEntry[]>([]);

  useEffect(() => {
    ExploreEntryBuilder.upcoming({ size: 2, categories: ['academy_live'] }).then((res) => setUpcoming(res));
  }, []);

  useEffect(() => {
    PageResult.execute(
      ExploreContentBuilder.find({ categories: ['academy'] }),
      ExploreContentBuilder.count({ categories: ['academy'] }),
      page,
      20,
    ).then((res) => setContent(res));
  }, [page]);

  return (
    <AcademyScreenContainer>
      <Container>
        <UpcomingWebinarCarousel upcoming={upcoming} linkPrefix="/academy/" />
        <UpcomingAcademyWebinarSection />
      </Container>
      <TitleContainer>
        <Container>
          <Title>
            <Translation
              i18nKey="previousWebinarSection.title"
              defaultValue={`<span style="color: #24dca4">Previous</span> webinars`}
            />
          </Title>
          <Text>
            <Translation
              i18nKey="previousWebinarSection.description"
              defaultValue={`<b>Missed a webinar or looking to watch one again?</b>\nNo worries, we have everything you need! Explore our archive of past webinars, learn about new health topics, and expand your understanding.`}
            />
          </Text>
        </Container>
      </TitleContainer>
      <ExploreContentContainer>
        {content.content.map((entry, index) => (
          <ExploreContentSwitch key={entry.id} content={entry} />
        ))}
      </ExploreContentContainer>
      <ExploreCategoryContainer isLast={true}>
        <PreviousWebinarsSection />
      </ExploreCategoryContainer>
    </AcademyScreenContainer>
  );
});
