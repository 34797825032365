/**
 * Created by neo on 10.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { observable, ObservableMap, onBecomeObserved, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { Activity } from '../Activity/Activity';
import { ActivityLog } from '../Activity/ActivityLog';
import { Instructor, InstructorJson } from './Instructor';

export type ActivityWorkoutEntryJson = ExploreEntryJson & {
  activityIdentifier: string;
  data: Record<string, any>;
  instructor?: InstructorJson;
  recommendedMusicKey?: string;
  durationInMinutes?: number;
};

export class ActivityWorkoutEntry extends ExploreEntry {
  @observable
  activityIdentifier: string = '';
  @observable
  data: ObservableMap<string> = observable.map({});
  @observable.ref
  instructor?: Instructor;
  @observable
  recommendedMusicKey?: string;
  @observable
  durationInMinutes?: number;
  @observable
  activity?: Activity;

  constructor(json?: Partial<ActivityWorkoutEntryJson>) {
    super(Object.assign({ type: 'activityWorkout' }, json ?? {}));
    if (json) {
      this.activityIdentifier = json.activityIdentifier ?? '';
      this.data = observable.map(json.data || {});
      this.instructor = json.instructor ? new Instructor(json.instructor) : undefined;
      this.recommendedMusicKey = json.recommendedMusicKey;
      this.durationInMinutes = json.durationInMinutes;
    }
    onBecomeObserved(this, 'activity', this.fetchActivity);
  }

  fetchActivity = () => {
    if (!this.activity) {
      return Activity.get(this.activityIdentifier).then((result) => {
        runInAction(() => (this.activity = result));
        return result;
      });
    }
    return Promise.resolve(this.activity);
  };

  toJS(): ActivityWorkoutEntryJson {
    return Object.assign(super.toJS(), {
      activityIdentifier: this.activityIdentifier,
      data: this.data,
      instructor: this.instructor?.toJS(),
      durationInMinutes: this.durationInMinutes,
    });
  }

  createActivityLog(startDate: Date, endDate: Date, data: Record<string, number> = {}): ActivityLog {
    if (!data.DURATION) {
      data.DURATION = Math.round((endDate.valueOf() - startDate.valueOf()) / 1000);
    }

    return new ActivityLog({
      type: this.activityIdentifier,
      source: {
        source: 'kinastic',
        sourceId: this.id,
        sourceType: 'explore-entry',
      },
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      data,
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<ActivityWorkoutEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'activityWorkout' })).then((res) =>
      (res ?? []).map((r) => new ActivityWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<ActivityWorkoutEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new ActivityWorkoutEntry(res));
  }
}
