import { computed, observable } from 'mobx';
import { TagCondition, TagConditionJson } from './TagCondition';
import { Media, MediaJson } from '../../Media/Media';

export type ConditionalMediaJson = MediaJson & {
  tagConditions: TagConditionJson[];
};

export class ConditionalMedia extends Media {
  @observable
  tagConditions: TagCondition[] = [];

  constructor(json?: Partial<ConditionalMediaJson>) {
    super(json);
    if (json) {
      this.tagConditions = (json.tagConditions || []).map((t) => new TagCondition(t));
    }
  }

  toJS(): ConditionalMediaJson {
    return Object.assign(super.toJS(), {
      tagConditions: this.tagConditions.map((t) => t.toJS()),
    });
  }

  matches(tags: string[] = []): boolean {
    if (this.tagConditions.length > 0) {
      return this.tagConditions.some((t) => t.matches(tags));
    }
    return true;
  }

  matchCount(tags: string[] = []): number {
    return this.tagConditions.reduce((agg, t) => Math.max(agg, t.matchCount(tags)), 0);
  }

  @computed
  get tagString(): string {
    return this.tagConditions.map((t) => t.tagString).join(' | ');
  }
}
