import { HttpBackend } from '../../Services/Http/HttpBackend';

export class FeedToken {
  feedId: string = '';
  token: string = '';
  readOnlyToken: string = '';
  analyticsToken?: string;

  constructor(json?: any) {
    if (json) {
      this.feedId = json.feedId;
      this.token = json.token;
      this.readOnlyToken = json.readOnlyToken;
      this.analyticsToken = json.analyticsToken;
    }
  }

  static async get(feedType?: string, feedId?: string): Promise<FeedToken> {
    const data = await HttpBackend.get(`/messaging/feed/v3/token`, { feedId, feedType });
    return new FeedToken(data);
  }
}
