import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Input as ReactstrapInput, InputProps as ReactstrapInputProps } from 'reactstrap';
import React, { useState } from 'react';

const StyledInput = styled(ReactstrapInput)`
  width: 100%;
  padding: 9px 12px;
  gap: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid white;

  ${({ error }) => (error ? 'border-color: red;' : '')}

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    ${({ error }) => (error ? 'color: red;' : '')}
  }

  &:disabled {
    ${({ disabledColor }) =>
      disabledColor
        ? `border-color: ${disabledColor}; background-color: ${disabledColor};`
        : 'border-color: #d9d9d9; background-color: #d9d9d9;'}
  }
`;

export type InputProps = Omit<ReactstrapInputProps, 'placeholder' | 'title'> & {
  placeholder?: string | null;
  auth?: boolean;
};

export const Input: React.FC<InputProps> = observer(({ placeholder = '', invalid, disabledColor, ...moreProps }) => {
  return <StyledInput error={invalid} placeholder={placeholder ?? ''} disabledColor={disabledColor} {...moreProps} />;
});
