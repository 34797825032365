/**
 * Created by neo on 29.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { useRootStore } from '../../Store/useRootStore';
import styled from '@emotion/styled';

const Description = styled.p`
  color: black;
  overflow: hidden;
`;

export type ExploreCardShortDescriptionProps = {
  entry: ExploreEntry;
};

export const ExploreCardShortDescription: React.FC<ExploreCardShortDescriptionProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const longDescription = entry.getDescription(language.language).substring(0, 36);
  const shortDescription = entry.getShortDescription(language.language);
  const description = shortDescription || `${longDescription}...`;

  if (shortDescription || longDescription) {
    return <Description>{description}</Description>;
  }

  return null;
});
