/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { Container } from 'reactstrap';
import { ExploreContentViewWebinarVideo } from './ExploreContentViewWebinarVideo';

export type ExploreContentViewWebinarMediaContainerProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinarMediaContainer: React.FC<ExploreContentViewWebinarMediaContainerProps> = observer(
  ({ entry }) => {
    return (
      <Container>
        <ExploreContentViewWebinarVideo entry={entry} />
      </Container>
    );
  },
);
