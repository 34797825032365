/**
 * Created by andreaskarantzas on 15.05.18.
 */
import { observable, action, reaction, runInAction, toJS } from 'mobx';
import { AuthenticationStore } from '../../Store/AuthenticationStore';
import { FirebaseUser } from '../../Model/Firebase/FirebaseUser';
import { logger } from '../../Utils/logger';
import SentryService from '../SentryService';
import { FirebaseAuth } from '../../Store/Firebase/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

export class FirebaseAuthStore {
  @observable
  user?: FirebaseUser;
  readonly auth = FirebaseAuth;

  constructor(readonly authentication: AuthenticationStore) {
    onAuthStateChanged(this.auth, (user: any) => {
      runInAction(() => (this.user = new FirebaseUser(user)));
      console.log('FirebaseAuthStore:: onAuthStateChanged', this.user);
    });

    reaction(
      () => this.authentication.athleteId,
      (athleteId?: string) => {
        if (athleteId && this.authentication.userId) {
          this.signInWithEmailAndPassword(athleteId, this.authentication.userId);
        }
      },
      { name: 'Firebase create or signIn user', fireImmediately: true },
    );
  }

  /**
   * It performs two operations; first creating the user if they
   * do not already exist, and then signing them in. If the user
   * already exists => sign them in normally with email/pw
   * @param athleteId
   * @param userId
   */
  createAndSignInWithEmailAndPassword(athleteId: string, userId: string): Promise<FirebaseUser> {
    return createUserWithEmailAndPassword(this.auth, `${athleteId}@kinastic.com`, `${userId}`)
      .then((res) => {
        SentryService.addBreadcrumb('FirebaseAuthStore:: User account created & signed in');
        if (res?.user) {
          const user = new FirebaseUser(res.user);
          runInAction(() => (this.user = user));
          logger('FirebaseAuthStore:: User account created & signed in');
          return user;
        }
        throw new Error('no user returned from firebase');
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          logger('FirebaseAuthStore:: That email address is already in use!');
        } else if (error.code === 'auth/invalid-email') {
          logger('FirebaseAuthStore:: That email address is invalid!');
        }
        console.error(error);
        throw error;
      });
  }

  /**
   * If the user account exists => sign them in with email/pw
   * Otherwise => create the account and sign them in directly
   * @param athleteId
   * @param userId
   */
  signInWithEmailAndPassword(athleteId: string, userId: string): Promise<FirebaseUser> {
    return signInWithEmailAndPassword(this.auth, `${athleteId}@kinastic.com`, `${userId}`)
      .then((res) => {
        SentryService.addBreadcrumb('FirebaseAuthStore:: User signed in');
        if (res?.user) {
          const user = new FirebaseUser(res.user);
          runInAction(() => (this.user = user));
          logger('FirebaseAuthStore:: User account signed in', this.user);
          return user;
        }
        throw new Error('no user returned');
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          logger('FirebaseAuthStore:: That email address does not exist! Proceed to create one');
          return this.createAndSignInWithEmailAndPassword(athleteId, userId);
        } else if (error.code === 'auth/wrong-password') {
          logger('FirebaseAuthStore:: The credentials do not match');
        }
        console.error('firebase error', error);
        throw error;
      });
  }

  @action
  signOut(): Promise<void> {
    this.user = undefined;
    return (
      signOut(this.auth)
        .then(() => {
          SentryService.addBreadcrumb('FirebaseAuthStore:: User signed out');
          logger('FirebaseAuthStore:: User signed out');
        })
        // catch errors it's a sign out
        .catch(() => logger('error'))
    );
  }
}
