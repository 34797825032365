/**
 * Created by neo on 18.01.17.
 */

import { action, computed, observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { LocalizedValue, LocalizedValueJson } from '../../../LocalizedValue';
import { ExerciseVariation } from '../../../ProgramPortfolio/ExerciseVariation';
import { RoundInfo } from '../../../ProgramPortfolio/RoundInfo';
import { CoachWorkoutPhase } from './CoachWorkoutPhase';
import { Media } from '../../../Media/Media';
import { languagePriority } from '../../../LocalizedEntity';
import { CoachExerciseBlockSet, CoachExerciseBlockSetJson } from './CoachExerciseBlockSet';
import { TrackingKeysList, BaseTrackingKey } from '../../../ProgramPortfolio/TrackingKeys';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { PipelineContext } from '../../PipelineContext';
import { logger } from '../../../../Utils/logger';
import { BaseExerciseBlockJson, ExerciseBlockJson } from '../../../ProgramPortfolio/ExerciseBlock';

export type CoachExerciseBlockJson = Omit<BaseExerciseBlockJson, 'sets'> & {
  description: LocalizedValueJson[];
  sets: CoachExerciseBlockSetJson[];
};

export class CoachExerciseBlock {
  @observable exerciseBlockId: string = UUID();
  @observable
  description: LocalizedValue[] = [];
  @observable exercise: ExerciseVariation = new ExerciseVariation();
  @observable.shallow sets: CoachExerciseBlockSet[] = [];
  // @deprecated
  @observable roundInfo?: RoundInfo;
  @observable phase: CoachWorkoutPhase;

  constructor(phase: CoachWorkoutPhase, json?: Partial<CoachExerciseBlockJson>) {
    this.phase = phase;
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): CoachExerciseBlockJson {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.description.map((l) => l.toJS()),
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toJS()),
      roundInfo: this.roundInfo ? this.roundInfo.toJS() : undefined,
    };
  }

  toWorkoutJS(newId?: boolean): ExerciseBlockJson {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.defaultDescription,
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toWorkoutJS()),
      roundInfo: this.roundInfo ? this.roundInfo.toJS() : undefined,
    };
  }

  fetchSuggestions(): Promise<CoachExerciseBlock[]> {
    return HttpBackend.post(
      `/coach/workout/${this.phase.workout.id}/${this.phase.phaseId}/suggest`,
      new PipelineContext({ paramValues: { limit: 10 } }).toJS(),
    ).then((result) => result.map((r) => new CoachExerciseBlock(this.phase, r)));
  }

  @action
  setBasicData(json: Partial<CoachExerciseBlockJson> | CoachExerciseBlock) {
    this.exerciseBlockId = json.exerciseBlockId || UUID();
    this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
    this.exercise = json instanceof CoachExerciseBlock ? json.exercise : new ExerciseVariation(json.exercise);
  }

  @action
  setData(json: Partial<CoachExerciseBlockJson> | CoachExerciseBlock) {
    this.setBasicData(json);
    this.sets =
      json instanceof CoachExerciseBlock ? json.sets : (json.sets || []).map((s) => new CoachExerciseBlockSet(this, s));
  }

  calculateCalories(bmr24: number): number {
    return this.sets.reduce((total: number, set: CoachExerciseBlockSet) => total + set.calculateCalories(bmr24), 0);
  }

  suggestNew(params?: PipelineContext): Promise<CoachExerciseBlock[]> {
    const context = params ?? new PipelineContext();
    context.excludeExerciseIds = this.phase.exerciseBlocks.map((b) => b.exercise.id);
    return HttpBackend.post(
      `/coach/workout/${this.phase.workout.id}/${this.phase.phaseId}/suggest`,
      context.toJS(),
    ).then((result) => result.map((r) => new CoachExerciseBlock(this.phase, r)));
  }

  /**
   * Replaces THIS block with a new block
   * @param newBlock
   */
  replace(newBlock: CoachExerciseBlock): Promise<CoachExerciseBlock> {
    const promise = this.replaceRemote(newBlock);
    this.setData(newBlock);
    return promise;
  }

  /**
   * Only Replaces in the backend
   * @param newBlock
   */
  replaceRemote(newBlock: CoachExerciseBlock): Promise<CoachExerciseBlock> {
    return HttpBackend.post(`/coach/workout/${this.phase.workout.id}/replaceExerciseBlock`, {
      exerciseBlockId: this.exerciseBlockId,
      exerciseBlock: newBlock.toJS(),
    }).then(() => this);
  }

  @action
  createSet(setValues?: any): CoachExerciseBlockSet {
    const { lastSet } = this;
    const lastOrDefault = lastSet ? toJS(lastSet.values) : {};
    const values = setValues || lastOrDefault;
    logger('valuesPassed::', values);
    const set = new CoachExerciseBlockSet(this, { values: this.cleanValues(values) });
    logger('set::', set);
    this.sets.push(set);
    return set;
  }

  cleanValues(values: any): any {
    return TrackingKeysList.filter(
      (k: string) => undefined !== values[k] || undefined !== values[`MIN_${k}`] || undefined !== values[`MAX_${k}`],
    )
      .map((k: string) => [
        k,
        values[`MIN_${k}`] || values[`MAX_${k}`] || values[k],
        undefined !== values[k]
          ? values[k]
          : values[`MAX_${k}`] === 0
          ? values[`MAX_${k}`]
          : values[`MAX_${k}`] || values[`MIN_${k}`],
      ])
      .reduce((obj: any, curr: any[]) => {
        obj[`MIN_${curr[0]}`] = curr[2] === 0 ? curr[1] : Math.min(curr[1], curr[2]);
        obj[`MAX_${curr[0]}`] = curr[2] === 0 ? curr[2] : Math.max(curr[1], curr[2]);
        return obj;
      }, {});
  }

  @action
  removeSet(index: number) {
    this.sets.splice(index, 1);
  }

  @action
  async save() {
    return true;
  }

  @computed
  get valid(): boolean {
    return true;
  }

  @computed
  get duration(): number {
    return this.sets.reduce((total, set) => total + set.totalDuration, 0);
  }

  @computed
  get breakTime(): number {
    return this.sets.reduce((total, set) => total + set.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((total, set) => total + set.plannedDurationMs, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((total, set) => total + set.tons, 0);
  }

  @computed
  get lastSet(): CoachExerciseBlockSet | undefined {
    const { length } = this.sets;
    if (length > 0) {
      return this.sets[length - 1];
    }
    return undefined;
  }

  @computed
  get firstSet(): CoachExerciseBlockSet | undefined {
    const { sets } = this;
    if (sets.length > 0) {
      return sets[0];
    }
    return undefined;
  }

  @computed
  get prevSet(): CoachExerciseBlockSet | undefined {
    if (!this.lastSet) {
      return this.prevBlock && this.prevBlock.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): CoachExerciseBlockSet | undefined {
    return this.firstSet || (this.nextBlock ? this.nextBlock.followingSet : undefined);
  }

  @computed
  get exerciseBlockIndex(): number {
    return this.phase ? this.phase.exerciseBlocks.findIndex((b) => b.exerciseBlockId === this.exerciseBlockId) : -1;
  }

  @computed
  get isLastBlock(): boolean {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      return exerciseBlockIndex + 1 === this.phase.exerciseBlocks.length;
    }
    return true;
  }

  @computed
  get prevBlock(): CoachExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      if (exerciseBlockIndex === 0) {
        return this.phase.prevPhase?.prevBlock;
      }
      return this.phase.exerciseBlocks[exerciseBlockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlock(): CoachExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex, isLastBlock } = this;
      if (isLastBlock) {
        return this.phase.nextPhase?.nextBlock;
      }
      const nextIndex = exerciseBlockIndex + 1;
      return nextIndex < this.phase.exerciseBlocks.length ? this.phase.exerciseBlocks[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get defaultTrackingKeys(): BaseTrackingKey[] {
    return this.exercise.trackingParameters;
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    return this.defaultTrackingKeys;
  }

  @computed
  get sortedTrackingParameters(): BaseTrackingKey[] {
    return this.trackingParameters.slice().sort((a, b) => {
      return TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b);
    });
  }

  @computed({ name: 'ExerciseBlock::spotClassMedia' })
  get spotClassMedia(): Media | undefined {
    const { exercise } = this;
    return exercise.imageMedia;
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.sets.reduce((total, set) => total + set.totalDuration, 0);
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }

  @computed({ name: 'CoachExerciseBlock::videoMedia' })
  get videoMedia(): Media | undefined {
    return this.exercise.videoMedia;
  }

  @computed
  get hasReplaceSuggestions(): boolean | undefined {
    return this.phase.hasReplaceSuggestions;
  }
}
