/**
 * Created by neo on 30.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export type MarkdownRendererProps = {
  text?: string | null;
  variant?: string;
};

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = observer(({ text }) => {
  return <ReactMarkdown>{text ?? ''}</ReactMarkdown>;
});
