/**
 * Created by neo on 24.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { ExploreContentViewOnlineMeetingMainButtons } from './ExploreContentViewOnlineMeetingMainButtons';
import dayjs from 'dayjs';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import { ExploreContentViewOnlineMeetingSubscribeButtons } from './ExploreContentViewOnlineMeetingSubscribeButtons';
import { useRootStore } from '../../../Store/useRootStore';
import { reaction } from 'mobx';

const Header = styled.p`
  font-weight: 600;
`;

export type ExploreContentViewOnlineMeetingReminderProps = {
  entry: OnlineMeetingEntry;
};

export const ExploreContentViewOnlineMeetingReminder: React.FC<ExploreContentViewOnlineMeetingReminderProps> = observer(
  ({ entry }) => {
    const { notification } = useRootStore();
    const { t } = useTrans();

    const [fetching, setFetching] = useState(false);
    const [existingSubscription, setExistingSubscription] = useState<SubscribedEventEntry | undefined>();

    const canJoinMeeting = entry.canJoinMeeting;
    const isVimeoLink = entry.meetingLink.startsWith('https://vimeo.com/');

    useEffect(() => {
      setFetching(true);
      SubscribedEventEntry.findExisting({ objectId: entry.id })
        .then((res) => setExistingSubscription(res))
        .finally(() => setFetching(false));
    }, [entry]);

    useEffect(
      reaction(
        () => existingSubscription?.earliestInterval,
        (interval) => {
          if (interval?.durationBefore) {
            notification.requestPermission();
          }
        },
        { fireImmediately: true },
      ),
      [existingSubscription],
    );

    const handleSubscribed = React.useCallback((event) => setExistingSubscription(event), []);

    const handleUnregistered = React.useCallback(() => setExistingSubscription(undefined), []);

    if (!isVimeoLink || !canJoinMeeting) {
      return (
        <React.Fragment>
          {!canJoinMeeting && (
            <Header>{t('exploreEntryViewScreen.remindMeTitle', { defaultValue: 'Remind me' })}</Header>
          )}
          {existingSubscription ? (
            <ExploreContentViewOnlineMeetingMainButtons
              entry={entry}
              subscription={existingSubscription}
              onUnregistered={handleUnregistered}
              canJoinMeeting={canJoinMeeting}
            />
          ) : (
            <React.Fragment>
              {!fetching && (
                <ExploreContentViewOnlineMeetingSubscribeButtons
                  entry={entry}
                  onSubscribed={handleSubscribed}
                  canJoinMeeting={canJoinMeeting}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    return null;
  },
);
