/**
 * Created by katarinababic on 26.4.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { LinkComponent } from '../../LinkComponent';
import styled from '@emotion/styled';
import { useTrans } from '../../../Store/System/LocalizationStore';

const LoginTextContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
`;

const loginSpanStyle: React.CSSProperties = {
  color: '#ffffff',
};

const loginLinkStyle: React.CSSProperties = {
  color: '#E5842B',
};

export type LoginBottomButtonProps = {};

export const LoginBottomButton: React.FC<LoginBottomButtonProps> = observer((props) => {
  const { t } = useTrans();

  return (
    <LoginTextContainer>
      <span style={loginSpanStyle}>
        {t('loginBottomButton.alreadyHaveAnAccount', { defaultValue: 'Already have an account?' })}&nbsp;
      </span>
      <LinkComponent to="/auth" style={loginLinkStyle}>
        {t('loginBottomButton.login', { defaultValue: 'Log in' })}
      </LinkComponent>
    </LoginTextContainer>
  );
});
