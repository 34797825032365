/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import dayjs from 'dayjs';
import { reaction } from 'mobx';
import styled from '@emotion/styled';
import { ExploreContentViewWebinarMainButtons } from './ExploreContentViewWebinarMainButtons';
import { ExploreContentViewWebinarSubscribeButtons } from './ExploreContentViewWebinarSubscribeButtons';

const Header = styled.p`
  font-weight: 600;
`;

export type ExploreContentViewWebinarReminderProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinarReminder: React.FC<ExploreContentViewWebinarReminderProps> = observer(
  ({ entry }) => {
    const { notification } = useRootStore();
    const { t } = useTrans();

    const [fetching, setFetching] = useState(false);
    const [existingSubscription, setExistingSubscription] = useState<SubscribedEventEntry | undefined>();

    const canJoinMeeting = !dayjs().isBefore(entry.nextDate);

    useEffect(() => {
      setFetching(true);
      SubscribedEventEntry.findExisting({ objectId: entry.id })
        .then((res) => setExistingSubscription(res))
        .finally(() => setFetching(false));
    }, [entry]);

    useEffect(
      reaction(
        () => existingSubscription?.earliestInterval,
        (interval) => {
          if (interval?.durationBefore) {
            notification.requestPermission();
          }
        },
        { fireImmediately: true },
      ),
      [existingSubscription],
    );

    const handleSubscribed = React.useCallback((event) => setExistingSubscription(event), []);

    const handleUnregistered = React.useCallback(() => setExistingSubscription(undefined), []);

    if (!canJoinMeeting) {
      return (
        <React.Fragment>
          <Header>{t('exploreEntryViewScreen.remindMeTitle', { defaultValue: 'Remind me' })}</Header>

          {existingSubscription ? (
            <ExploreContentViewWebinarMainButtons
              entry={entry}
              subscription={existingSubscription}
              onUnregistered={handleUnregistered}
              canJoinMeeting={canJoinMeeting}
            />
          ) : (
            <React.Fragment>
              {!fetching && (
                <ExploreContentViewWebinarSubscribeButtons
                  entry={entry}
                  onSubscribed={handleSubscribed}
                  canJoinMeeting={canJoinMeeting}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    return null;
  },
);
