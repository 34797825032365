import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type ActivityLogSourceJson = {
  source: string;
  sourceType?: string;
  sourceId: string;
};

export class ActivityLogSource {
  /**
   * If coming from us it's `flinq`
   */
  @observable source = 'kinastic';
  /**
   * if external and nothing else provided it's the "workoutType" from the other system
   * if internal and workoutLog it's usually `workout`. If an activity it's `activity`
   */
  @observable sourceType?: string = 'activity';
  /**
   * If internal and in case of sourceType = `workoutLog`. sourceId is the workoutLogId
   * If internal and it's an activity then sourceId = activityLogId
   * If external and nothing provided = random
   */
  @observable sourceId = UUID();

  constructor(json?: Partial<ActivityLogSourceJson>) {
    if (json) {
      this.source = json.source ?? 'kinastic';
      this.sourceType = json.sourceType ?? 'activity';
      this.sourceId = json.sourceId || UUID();
    }
  }

  toJS(): ActivityLogSourceJson {
    return {
      source: this.source,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    };
  }
}
