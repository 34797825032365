/**
 * Created by neo on 03.04.23.
 */
import { observable } from 'mobx';
import { Pageable } from '../Pageable';
import { Audited, AuditedJson } from '../Audited';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type CustomerGroupQueryRequest = Pageable & {
  gymId: string;
};

export type CustomerGroupJson = AuditedJson & {
  gymId: string;
  name: string;
  description: string;
};

export class CustomerGroup extends Audited {
  @observable
  gymId = '';

  @observable
  name = '';

  @observable
  description = '';

  constructor(json?: Partial<CustomerGroupJson>) {
    super(json);
    if (json) {
      this.gymId = json.gymId ?? '';
      this.name = json.name ?? '';
      this.description = json.description ?? '';
    }
  }

  toJS(newId: boolean = false): CustomerGroupJson {
    return Object.assign(super.toJS(newId), {
      gymId: this.gymId,
      name: this.name,
      description: this.description,
    });
  }

  save(): Promise<CustomerGroup> {
    return HttpBackend.post(`/gym/customer/group/admin?gymId=${this.gymId}`, this.toJS()).then(() => this);
  }

  delete(): Promise<CustomerGroup> {
    return HttpBackend.delete(`/gym/customer/group/admin?gymId=${this.gymId}`, this.toJS()).then(() => this);
  }

  static findOne(groupId: string, gymId: string): Promise<CustomerGroup | undefined> {
    return HttpBackend.get(`/gym/customer/group/${groupId}?gymId=${gymId}`, groupId).then((res) =>
      res ? new CustomerGroup(res) : undefined,
    );
  }

  static find(request?: Partial<CustomerGroupQueryRequest>): Promise<CustomerGroup[]> {
    return HttpBackend.get(`/gym/customer/group/admin`, request).then(
      (res) => res?.map((r) => new CustomerGroup(r)) ?? [],
    );
  }

  static count(request?: Partial<CustomerGroupQueryRequest>): Promise<number> {
    return HttpBackend.get(`/gym/customer/group/admin/count`, request).then((res) => res ?? 0);
  }
}
