/**
 * Created by neo on 09.11.21.
 */
import { observable } from 'mobx';

export type MediaVisibleRectJson = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

export class MediaVisibleRect {
  @observable
  top = 0;
  @observable
  left = 0;
  @observable
  bottom = 0;
  @observable
  right = 0;

  constructor(json?: Partial<MediaVisibleRectJson>) {
    if (json) {
      this.top = json.top ?? 0;
      this.left = json.left ?? 0;
      this.bottom = json.bottom ?? 0;
      this.right = json.right ?? 0;
    }
  }

  toJS(): MediaVisibleRectJson {
    return {
      top: this.top,
      left: this.left,
      bottom: this.bottom,
      right: this.right,
    };
  }
}
