import { computed, observable } from 'mobx';

export type ExerciseIdJson = {
  variationId: string;
  sourceType?: string;
  sourceId?: string;
};

export class ExerciseId {
  @observable
  variationId: string = '';
  @observable
  sourceType?: string;
  @observable
  sourceId?: string;

  constructor(json?: Partial<ExerciseIdJson>) {
    if (json) {
      this.variationId = json.variationId ?? '';
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
    }
  }

  toJS(): ExerciseIdJson {
    return {
      variationId: this.variationId,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    };
  }

  @computed
  get uniqueId(): string {
    return this.variationId;
  }

  equals(other?: any) {
    if (other === this) {
      return true;
    }
    return other.variationId === this.variationId;
  }
}
