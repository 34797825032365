import { action, observable } from 'mobx';
import { RoundInfo } from './RoundInfo';
import { ExerciseBlock } from './ExerciseBlock';

export class IntervalRound {
  @observable index: number = 0;
  @observable blocks: ExerciseBlock[] = [];
  @observable roundInfo: RoundInfo;
  @observable prevRound?: IntervalRound;
  @observable nextRound?: IntervalRound;

  constructor(index: number = 0, blocks?: ExerciseBlock[], roundInfo?: RoundInfo, prevRound?: IntervalRound) {
    this.index = index || 0;
    this.blocks = blocks || [];
    this.roundInfo = roundInfo || new RoundInfo();
    this.setPrevRound(prevRound);
  }

  @action
  setPrevRound(prevRound?: IntervalRound) {
    if (prevRound) {
      this.prevRound = prevRound;
      prevRound.nextRound = this;
    }
  }

  copy(): ExerciseBlock[] {
    const newBlocks = this.blocks.map((b) => b.copy());
    if (newBlocks.length > 0) {
      const firstBlock = newBlocks[0];
      if (!firstBlock.roundInfo) {
        firstBlock.roundInfo = new RoundInfo();
      }
    }
    return newBlocks;
  }
}
