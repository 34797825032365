/**
 * Created by neo on 16.08.23
 */
import {
  OnlineMeetingEntryEmbedDataEntry,
  OnlineMeetingEntryEmbedDataEntryJson,
} from './OnlineMeetingEntryEmbedDataEntry';
import { observable } from 'mobx';

export type OnlineMeetingEntryEmbedDataJson = {
  web?: OnlineMeetingEntryEmbedDataEntryJson;
  app?: OnlineMeetingEntryEmbedDataEntryJson;
};

export class OnlineMeetingEntryEmbedData {
  @observable
  web?: OnlineMeetingEntryEmbedDataEntry;
  @observable
  app?: OnlineMeetingEntryEmbedDataEntry;

  constructor(json?: Partial<OnlineMeetingEntryEmbedDataJson>) {
    if (json) {
      this.web = json.web ? new OnlineMeetingEntryEmbedDataEntry(json.web) : undefined;
      this.app = json.app ? new OnlineMeetingEntryEmbedDataEntry(json.app) : undefined;
    }
  }

  toJS(): OnlineMeetingEntryEmbedDataJson {
    return {
      web: this.web?.toJS(),
      app: this.app?.toJS(),
    };
  }
}
