export const Config = {
  backend: {
    url: 'https://api.kinastic.com/v1',
    authUrl: '/api',
  },
  firebase: {
    apiKey: 'AIzaSyAA_I8rAl7gTUYpLHZhg353HTa9zgtaLTc',
    authDomain: "flinq-6796d.firebaseapp.com",
    databaseURL: "https://flinq-6796d.firebaseio.com",
    projectId: "flinq-6796d",
    storageBucket: "flinq-6796d.appspot.com",
    messagingSenderId: "4346093781",
    appId: "1:4346093781:web:1208e11e1149e8ca",
    measurementId: 'G-G8G19N6RSH',
    messaging: {
      publicKey: 'BHmOkuweqxZA-XFTKNbNvtug8mLL1pyN18q2O9FC73CRI0yEA6G85iMZpZzcuZpfHOjfHVo7IYs0i1y4DcZCRas',
    },
  },
  getstream: {
    apiKey: '4yffp23xz7c6',
    appId: '98553',
  },
  googleAnalytics: {
    analyticsId: 'G-G8G19N6RSH',
  },
  sentry: {
    dsn: 'https://4a435fee62d04dbd8494356480482b8c@o560994.ingest.sentry.io/4504366496546816',
  },
};
