import { Colors } from './Colors';
import { Typography } from './Typography';
import { ConfigTheme } from './ConfigTheme';
import { Text } from './Text';

export const Theme = {
  Colors,
  Typography,
  ...ConfigTheme,
  Text,
};
