/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { MarkdownRenderer } from '../../Markdown/MarkdownRenderer';
import styled from '@emotion/styled';

const Title = styled.h1``;

export type ExploreContentViewWebinarContentProps = {
  entry: WebinarEntry;
};

export const ExploreContentViewWebinarContent: React.FC<ExploreContentViewWebinarContentProps> = observer(
  ({ entry }) => {
    const { language } = useRootStore();

    const title = entry.getName(language.language);
    const content = entry.getDescription(language.language);

    return (
      <React.Fragment>
        <Title>{title}</Title>
        <MarkdownRenderer text={content} />
      </React.Fragment>
    );
  },
);
