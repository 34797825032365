/**
 * Created by neo on 01.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect, useId, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'reactstrap';

const HorizontalSliderComponent = styled(Row)`
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  flex-wrap: nowrap !important;
  margin-top: 16px;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ChangeButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
`;

const ChangeButton = styled.button`
  background-color: #f6f6f7;
  border-width: 0;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  margin: 0 8px;

  i {
    width: 32px;
    height: 32px;
  }

  &:hover {
    background-color: #c0c0c0;
  }
`;

export type HorizontalSliderProps = {};

export const HorizontalSlider: React.FC<PropsWithChildren<HorizontalSliderProps>> = observer(({ children }) => {
  const [scrollingState, setScrollingState] = useState<string | undefined>();
  const slider = useRef<any>(null);

  const sliderId = useId();

  useEffect(() => {
    const timeout = scrollingState
      ? setInterval(
          () =>
            scrollingState === 'scrollBack'
              ? document.getElementById(sliderId)?.scrollBy({ left: -15 })
              : document.getElementById(sliderId)?.scrollBy({ left: 15 }),
          16,
        )
      : undefined;

    return () => {
      timeout && clearInterval(timeout);
    };
  }, [scrollingState, sliderId]);

  const handleStartScrollBack = React.useCallback(() => {
    setScrollingState('scrollBack');
  }, []);

  const handleScrollForward = React.useCallback(() => {
    setScrollingState('scrollForward');
  }, []);

  const handleStopScroll = React.useCallback(() => setScrollingState(undefined), []);

  return (
    <React.Fragment>
      <HorizontalSliderComponent id={sliderId} innerRef={slider}>
        {children}
      </HorizontalSliderComponent>
      <ButtonsRow>
        <Col sm="auto" className="d-none d-sm-block">
          <ChangeButtonContainer>
            <ChangeButton
              onMouseDown={handleStartScrollBack}
              onMouseUp={handleStopScroll}
              onMouseEnter={handleStartScrollBack}
              onMouseLeave={handleStopScroll}
            >
              <i className="bi bi-chevron-left"></i>
            </ChangeButton>
            <ChangeButton
              onMouseDown={handleScrollForward}
              onMouseUp={handleStopScroll}
              onMouseEnter={handleScrollForward}
              onMouseLeave={handleStopScroll}
            >
              <i className="bi bi-chevron-right"></i>
            </ChangeButton>
          </ChangeButtonContainer>
        </Col>
      </ButtonsRow>
    </React.Fragment>
  );
});
