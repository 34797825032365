/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { ExploreCategory } from '../../Model/Explore/ExploreCategory';
import { ExploreCategoryRenderer, ExploreCategoryRendererProps } from './ExploreCategoryRenderer';
import {ExploreCategorySwitch} from "./ExploreCategorySwitch";

export type ExploreContentSwitchProps = Pick<ExploreCategoryRendererProps, 'linkPrefix' | 'isLast'> & {
  content: ExploreContent;
};

export const ExploreContentSwitch: React.FC<ExploreContentSwitchProps> = observer(({ content, isLast, linkPrefix }) => {
  if (content instanceof ExploreCategory) {
    return <ExploreCategorySwitch category={content} isLast={isLast} linkPrefix={linkPrefix} />;
  }

  return null;
});
