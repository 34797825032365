/**
 * Created by neo on 13.10.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotFoundScreen } from '../NotFoundScreen';
import { ScanQrCodeScreen } from './ScanQrCodeScreen';
import { ExploreEntry } from '../Model/Explore/ExploreEntry';
import { useEffect } from 'react';
import { ExploreEntryBuilder } from '../Model/Explore/ExploreEntryBuilder';
import { ExploreContentViewRenderer } from '../Components/EntryView/ExploreContentViewRenderer';

const appLinkPrefixes = [
  '/explore/',
  '/article/',
  '/activityLog/',
  '/activityLogEdit/',
  '/activityLogEditByActivity/',
  '/challenges/',
  '/mindfulness-programs',
  '/health-data-point',
  '/mindfulness/unit/',
  '/workouts/',
  '/profile/settings/tracker',
  '/engagement/earn/pointsCollected',
  '/rewards',
  '/tracker/terra',
  '/feed',
  '/workouts',
  '/explore-tab/',
  '/profile/setting',
  '/route-challenges',
];

const supportedTypes = ['video', 'article', 'webinar', 'activityWorkout', 'onlineMeeting', 'yoga'];

export type CatchAllRouteProps = {};

export const CatchAllRoute: React.FC<CatchAllRouteProps> = observer((props) => {
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);
  const [entry, setEntry] = React.useState<ExploreEntry | undefined>(undefined);

  // Split the pathname by '/'
  const pathParts = location.pathname.split('/');
  const uuid = pathParts[3];

  useEffect(() => {
    if (uuid) {
      ExploreEntryBuilder.findOne(uuid)
        .then((res) => setEntry(res))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  if (!loading) {
    if (entry && supportedTypes.includes(entry.type)) {
      return <ExploreContentViewRenderer entry={entry} />;
    } else if (appLinkPrefixes.some((prefix) => location.pathname.startsWith(prefix))) {
      return <ScanQrCodeScreen />;
    }

    return <NotFoundScreen />;
  }

  return null;
});
