import { ActivityWorkoutEntry, ActivityWorkoutEntryJson } from './ActivityWorkoutEntry';
import { ArticleEntry, ArticleEntryJson } from './ArticleEntry';
import { WorkoutTemplateEntry, WorkoutTemplateEntryJson } from './WorkoutTemplateEntry';
import { DefaultExploreEntry, DefaultExploreEntryJson } from './DefaultExploreEntry';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { toJS } from 'mobx';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { MeditationEntry, MeditationEntryJson } from './MeditationEntry';
import { BreathingEntry, BreathingEntryJson } from './BreathingEntry';
import { YogaEntry, YogaEntryJson } from './YogaEntry';
import { WorkplaceWorkoutEntry, WorkplaceWorkoutEntryJson } from './WorkplaceWorkoutEntry';
import { ExploreEntry } from './ExploreEntry';
import { VideoEntry, VideoEntryJson } from './VideoEntry';
import { LinkEntry } from './LinkEntry';
import { OnlineMeetingEntry } from './OnlineMeetingEntry';
import { ExploreEntryUpcomingQueryRequest } from './ExploreEntryUpcomingQueryRequest';
import { SubscribableEntry } from './SubscribableEntry';
import { WebinarEntry, WebinarEntryJson } from './WebinarEntry';

export type ExploreEntryJson =
  | WorkplaceWorkoutEntryJson
  | ActivityWorkoutEntryJson
  | ArticleEntryJson
  | WorkoutTemplateEntryJson
  | MeditationEntryJson
  | BreathingEntryJson
  | YogaEntryJson
  // | ZoomMeetingEntryJson
  | VideoEntryJson
  | WebinarEntryJson
  | DefaultExploreEntryJson;

export class ExploreEntryBuilder {
  static build(json: ExploreEntryJson): ExploreEntry {
    switch (json.type) {
      case 'article':
        return new ArticleEntry(json);
      case 'workplace':
        return new WorkplaceWorkoutEntry(json);
      case 'workoutTemplate':
        return new WorkoutTemplateEntry(json);
      case 'activityWorkout':
        return new ActivityWorkoutEntry(json);
      case 'meditation':
        return new MeditationEntry(json);
      case 'breathing':
        return new BreathingEntry(json);
      case 'yoga':
        return new YogaEntry(json);
      // case 'zoomMeeting':
      //   return new ZoomMeetingEntry(json);
      case 'video':
        return new VideoEntry(json);
      case 'link':
        return new LinkEntry(json);
      case 'onlineMeeting':
        return new OnlineMeetingEntry(json);
      case 'webinar':
        return new WebinarEntry(json);
      default:
        return new DefaultExploreEntry(json);
    }
  }

  static find(request: ExploreEntryQueryRequest): Promise<ExploreEntry[]> {
    return HttpBackend.get(`/coach/explore`, toJS(Object.assign({}, request ?? {}, { contentType: 'entry' }))).then(
      (res) => {
        return (res ?? []).map((r) => ExploreEntryBuilder.build(r));
      },
    );
  }

  static random(request: ExploreEntryQueryRequest): Promise<ExploreEntry[]> {
    return HttpBackend.get(
      `/coach/explore/random`,
      toJS(Object.assign({}, request ?? {}, { contentType: 'entry' })),
    ).then((res) => {
      return (res ?? []).map((r) => ExploreEntryBuilder.build(r));
    });
  }

  static findOne(id: string): Promise<ExploreEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => ExploreEntryBuilder.build(res));
  }

  static upcoming(request?: Omit<ExploreEntryUpcomingQueryRequest, 'type'>): Promise<SubscribableEntry[]> {
    return HttpBackend.get(`/coach/explore/upcoming`, Object.assign(toJS(request ?? {}))).then((res) =>
      (res ?? []).map((r) => this.build(r)),
    );
  }
}
