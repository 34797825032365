/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';
import { PlaceholderScreen } from '../../Components/PlaceholderScreen/PlaceholderScreen';
import ReactMarkdown from 'react-markdown';

const defaultContent = `
# MINDFULNESS

This content is available only in the app at the moment:

General Explanation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque mauris pellentesque pulvinar pellentesque. 

## Choose between Fitness programs, Workplace workouts

This content is available only in the app at the moment:

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Scelerisque mauris pellentesque pulvinar pellentesque. 
`;

export type MentalHealthIndexScreenProps = {};

export const MentalHealthIndexScreen: React.FC<MentalHealthIndexScreenProps> = observer((props) => {
  const { t } = useTrans();
  return (
    <PlaceholderScreen headerImage={require('../../Assets/Images/mindfulness_placeholder_content.png')}>
      <ReactMarkdown>{t('mindfulnessPlaceholderScreen.content', { defaultValue: defaultContent })}</ReactMarkdown>
    </PlaceholderScreen>
  );
});
