/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../Model/Explore/ArticleEntry';
import { ExploreEntryContainer, ExploreEntryContainerProps } from './ExploreEntryContainer';
import styled from '@emotion/styled';
import { ExploreCardTitle } from './ExploreCardTitle';
import { ExploreCardShortDescription } from './ExploreCardShortDescription';

export type ExploreEntryRendererArticleProps = ExploreEntryContainerProps & {
  entry: ArticleEntry;
};

export const ExploreEntryRendererArticle: React.FC<ExploreEntryRendererArticleProps> = observer(
  ({ entry, ...moreProps }) => {
    return <ExploreEntryContainer entry={entry} {...moreProps}>

    </ExploreEntryContainer>;
  },
);
