import { observable, ObservableMap, toJS } from 'mobx';
import { PageResult } from '../PageResult';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type BodyPartSize = 'tiny' | 'small' | 'medium' | 'large' | 'huge';
export type BodyPartType = 'MUSCLE' | 'SINEW' | 'BONE';

export type BodyPartJson = {
  id: string;
  identifier: string;
  type: BodyPartType;
  size: BodyPartSize;
  name: string;
  names: {
    [key: string]: string;
  };
  description?: string;
  latinName?: string;
  childrenIds: string[];
};

export class BodyPart {
  @observable id: string = '';
  @observable identifier = '';
  @observable size: BodyPartSize = 'medium';

  @observable name: string = '';
  @observable names: ObservableMap<string, string> = observable.map({});
  @observable description?: string;
  @observable latinName?: string;
  @observable type: BodyPartType = 'MUSCLE';
  @observable childrenIds: string[] = [];
  children?: BodyPart[];

  constructor(json?: Partial<BodyPartJson>) {
    if (json) {
      this.id = json.id ?? '';
      this.name = json.name ?? '';
      this.names = observable.map(json.names || {});
      this.description = json.description;
      this.latinName = json.latinName;
      this.type = json.type || 'MUSCLE';
    }
  }

  fetchChildren = async (): Promise<BodyPart[]> => {
    if (!this.children) {
      this.children = await BodyPart.getAll(this.childrenIds);
    }
    return this.children;
  };

  async flatten(): Promise<BodyPart[]> {
    let result: BodyPart[] = [this];
    const children = await this.fetchChildren();
    result = result.concat(children);
    for (const child of children) {
      result = result.concat(await child.flatten());
    }
    return result.filter((item, pos) => result.indexOf(item) === pos);
  }

  toJS(): BodyPartJson {
    return {
      id: this.id,
      identifier: this.identifier,
      type: this.type,
      size: this.size,
      latinName: this.latinName,
      name: this.name,
      names: this.names.toJSON(),
      description: this.description,
      childrenIds: toJS(this.childrenIds),
    };
  }

  static async getAll(ids: string[]): Promise<BodyPart[]> {
    if (ids.length > 0) {
      const data = await Promise.all(ids.map((id) => BodyPart.get(id)));
      return data.filter((b) => !!b) as BodyPart[];
    }
    return [];
  }

  static get(id: string): Promise<BodyPart | undefined> {
    return HttpBackend.get(`/exercise/bodypart/${id}`).then((res) =>
      res ? new BodyPart(res) : undefined,
    );
  }

  static async flatten(id: string): Promise<string[]> {
    return HttpBackend.get(`/exercise/bodypart/${id}/flatten`).then((res) => res ?? []);
  }

  static async list(params?: any): Promise<BodyPart[]> {
    return HttpBackend.getOrCached('bodyparts', '/exercise/bodypart/list', params).then(
      (result: any) => result?.map((r: any) => new BodyPart(r)) ?? [],
    );
  }

  static async find(query?: string, page: number = 0, lang?: string, size: number = 30): Promise<PageResult<BodyPart>> {
    const data = await HttpBackend.get('/exercise/bodypart', {
      query,
      page,
      lang,
      size,
      sort: 'name,ASC',
    });
    if (data) {
      data.content = ((data || {}).content || []).map((b: any) => new BodyPart(b));
      return new PageResult(data);
    }
    return new PageResult();
  }
}
