/**
 * Created by katarinababic on 27.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AuthLayout } from '../../../../Components/Auth/AuthLayout/AuthLayout';
import { useTrans } from '../../../../Store/System/LocalizationStore';
import { RegistrationVerificationForm } from './RegistrationVerificationForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CoachRegistrationVerificationRequest } from '../../../../Store/SignupStore/CoachRegistrationVerificationRequest';
import { CoachRegistrationRequest } from '../../../../Model/CoachSubscription/CoachRegistrationRequest';
import { ErrorAlert } from '../../../../Components/Auth/Alerts/ErrorAlert';

export type RegistrationVerificationScreenProps = {};

export const RegistrationVerificationScreen: React.FC<RegistrationVerificationScreenProps> = observer((props) => {
  const { t } = useTrans();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const verificationCode = searchParams.get('verificationCode');

  const [codeRequest, setCodeRequest] = useState<CoachRegistrationRequest | undefined>();
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const request = React.useMemo(
    () =>
      new CoachRegistrationVerificationRequest({
        verificationCode: verificationCode ?? '',
        email: codeRequest?.email,
      }),
    [codeRequest, verificationCode],
  );

  useEffect(() => {
    if (verificationCode) {
      CoachRegistrationRequest.find(verificationCode).then((res) => setCodeRequest(res));
    }
  }, [verificationCode]);

  const handleShowErrorAlert = React.useCallback((error?: string) => {
    setErrorCode(error);
    setShowErrorAlert(true);
  }, []);

  const handleCloseAlert = React.useCallback(() => setShowErrorAlert(false), []);

  const handleGoToLoginScreen = React.useCallback(() => {
    handleCloseAlert();
    navigate('/auth', { replace: true });
  }, [navigate, handleCloseAlert]);

  return (
    <AuthLayout
      title={t('registrationCreateAccountScreen.title', {
        defaultValue: 'That worked! Now switch to your personal email address.',
      })}
      image={require('../../../../Assets/Images/auth_3.png')}
      overlayRightTitle={t('registration.stepTwo.introTitle', { defaultValue: 'Hello \n' + 'Good\n' + 'Habits' })}
    >
      {codeRequest && (
        <RegistrationVerificationForm request={request} codeRequest={codeRequest} onError={handleShowErrorAlert} />
      )}
      <ErrorAlert
        isOpen={showErrorAlert}
        title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
        toggle={handleCloseAlert}
        onDismiss={handleCloseAlert}
        onButtonClicked={errorCode === 'email_exists' ? handleGoToLoginScreen : handleCloseAlert}
        buttonText={t('authentication.loginButton', { defaultValue: 'Login' }).toUpperCase()}
      />
    </AuthLayout>
  );
});
