import { action, computed } from 'mobx';
import { Phase } from './Phase';
import { ExerciseBlock } from './ExerciseBlock';
import { IntervalRound } from './IntervalRound';

export class IntervalPhase extends Phase {
  @action
  moveBlock(oldIndex: number, newIndex: number): ExerciseBlock | undefined {
    const removed = this.exerciseBlocks.splice(oldIndex, 1)[0];
    if (removed) {
      const replace = this.exerciseBlocks[newIndex];
      if (newIndex < this.exerciseBlocks.length) {
        this.exerciseBlocks.splice(newIndex, 0, removed);
      } else {
        this.exerciseBlocks.push(removed);
      }

      // we moved the first entry of a round
      if (removed.roundInfo) {
        const prevNextRemoved = this.exerciseBlocks[oldIndex];
        prevNextRemoved.roundInfo = removed.roundInfo;
        removed.roundInfo = undefined;
      } else if (replace.roundInfo && newIndex < oldIndex) {
        // we are about to replace to first of the round
        removed.roundInfo = replace.roundInfo;
        replace.roundInfo = undefined;
      }
    }
    return removed;
  }

  @action
  removeExerciseBlock(exerciseBlock: ExerciseBlock) {
    const index = this.exerciseBlocks.findIndex((b) => b.exerciseBlockId === exerciseBlock.exerciseBlockId);
    if (index !== -1) {
      const remove = this.exerciseBlocks[index];
      const next = this.exerciseBlocks[index + 1];
      if (remove.roundInfo && next) {
        // pass over roundInfo
        next.roundInfo = remove.roundInfo;
      }
      this.exerciseBlocks.splice(index, 1);
    }
  }

  @action
  copyLastRound(): ExerciseBlock[] {
    const { lastRound } = this;
    if (lastRound && lastRound.blocks.length > 0) {
      const newRound = lastRound.copy();
      this.exerciseBlocks = this.exerciseBlocks.concat(newRound);
      return newRound;
    }
    return [];
  }

  /* computed */

  @computed
  get rounds(): IntervalRound[] {
    const rounds: IntervalRound[] = [];
    if (this.exerciseBlocks.length > 0) {
      let currentRound: IntervalRound | undefined;
      let roundIndex = -1;
      this.exerciseBlocks.forEach((block: ExerciseBlock) => {
        if (block.roundInfo || !currentRound) {
          currentRound = new IntervalRound(++roundIndex, [], block.roundInfo, currentRound);
          rounds.push(currentRound);
        }
        currentRound.blocks.push(block);
      });
    }
    return rounds;
  }

  @computed
  get roundsCount(): number {
    return this.rounds.length;
  }

  @computed
  get lastRound(): IntervalRound | undefined {
    const { rounds, roundsCount } = this;
    return roundsCount > 0 ? rounds[roundsCount - 1] : undefined;
  }
}
