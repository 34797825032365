/**
 * Created by neo on 28.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';

const ChangeButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
`;

const ChangeButton = styled.button`
  background-color: #f6f6f7;
  border-width: 0;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  i {
    width: 32px;
    height: 32px;
  }
`;

export type ChangeButtonsProps = {
  onPrevious?: () => void;
  onNext?: () => void;
  className?: string;
};

export const ChangeButtons: React.FC<ChangeButtonsProps> = observer(({ className, onPrevious, onNext }) => {
  return (
    <ChangeButtonContainer className={className}>
      <ChangeButton onClick={onPrevious}>
        <i className="bi bi-chevron-left"></i>
      </ChangeButton>
      <ChangeButton onClick={onNext}>
        <i className="bi bi-chevron-right"></i>
      </ChangeButton>
    </ChangeButtonContainer>
  );
});
