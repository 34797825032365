/**
 *
 * Created by neo on 18.01.17.
 */

import { action, computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from './LocalizedValue';
import { Audited, AuditedJson } from './Audited';

export const languagePriority = ['de', 'en', 'fr', 'it'];

export type LocalizedNamedEntityJson = AuditedJson & {
  name: LocalizedValueJson[];
};

export class LocalizedNamedEntity extends Audited {
  @observable
  name: LocalizedValue[] = [];

  constructor(json?: Partial<LocalizedNamedEntityJson>) {
    super(json);
    if (json) {
      this.name = (json.name || []).map((l) => new LocalizedValue(l));
    }
  }

  toJS(newId?: boolean): LocalizedNamedEntityJson {
    return Object.assign(super.toJS(newId), {
      id: this.id,
      name: this.name.map((v) => v.toJS()),
    });
  }

  toDefaultJS(): Omit<LocalizedNamedEntityJson, 'name'> & { name: string } {
    return Object.assign(super.toJS(), {
      name: this.defaultName,
    });
  }

  @action
  changeName(lang: string, value: string) {
    const existing = this.name.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.name.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getName(lang: string): string {
    return this.name.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultName;
  }

  nameMatches(query?: string): boolean {
    if (query) {
      const clean = query
        .trim()
        .split(' ')
        .filter((q) => !!q.trim())
        .map((c) => c.toLowerCase());
      return clean.some((c) => this.name.some((n) => n.value.toLowerCase().includes(c)));
    }
    return true;
  }

  @computed
  get defaultName(): string {
    for (const lang of languagePriority) {
      const entry = this.name.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.name[0];
    return first?.value ?? '';
  }
}
