/**
 * Created by neo on 13.10.2023
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useTrans } from '../Store/System/LocalizationStore';
import { PlaceholderScreen } from '../Components/PlaceholderScreen/PlaceholderScreen';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

const images = [
  require('../Assets/Images/blog_placeholder_content.png'),
  require('../Assets/Images/mindfulness_placeholder_content.png'),
  require('../Assets/Images/move_placeholder_content.png'),
  require('../Assets/Images/nutrition_placeholder_content.png'),
];

const defaultContent = `
## CHECK OUR CONTENT IN THE APP

**This content is currently only available in our app**.

Scan the QR code below with our phone to open the content in the app.
`;

const defaultSubContent = `
Information: your KINASTIC login is also valid for the KINASTIC app.
`;

export type ScanQrCodeScreenProps = {};

export const ScanQrCodeScreen: React.FC<ScanQrCodeScreenProps> = observer((props) => {
  const { t } = useTrans();
  const location = useLocation();

  const randomImage = images[Math.floor(Math.random() * images.length)];
  const fullUrl = `https://coach.kinastic.com/${location.pathname}${location.search}`;
  const dynamicLink = `https://open.kinastic.com/?link=${encodeURIComponent(
    fullUrl,
  )}&apn=app.flinq.athlete&ibi=app.flinq.athlete&isi=1465156373&efr=1`;

  return (
    <PlaceholderScreen headerImage={randomImage}>
      <ReactMarkdown>{t('scanQrCodeScreen.content', { defaultValue: defaultContent })}</ReactMarkdown>
      <div style={{ textAlign: 'center' }}>
        <QRCodeCanvas
          value={dynamicLink}
          includeMargin
          size={720}
          style={{ width: '100%', height: 'auto' }}
          imageSettings={{
            src: require('../Assets/Images/logo.png'),
            width: 120,
            height: 120,
            excavate: true,
          }}
        />
      </div>
      <ReactMarkdown>{t('scanQrCodeScreen.subContent', { defaultValue: defaultSubContent })}</ReactMarkdown>
    </PlaceholderScreen>
  );
});
