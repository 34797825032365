import { computed, observable, toJS } from 'mobx';

export type TagConditionJson = {
  includedTags: string[];
  excludedTags: string[];
};

export class TagCondition {
  @observable
  includedTags: string[] = [];
  @observable
  excludedTags: string[] = [];

  constructor(json?: Partial<TagConditionJson>) {
    if (json) {
      this.includedTags = json.includedTags || [];
      this.excludedTags = json.excludedTags || [];
    }
  }

  matches(tags?: string[]) {
    if (tags) {
      return (
        (this.includedTags.length === 0 || this.includedTags.every((t) => tags.indexOf(t) !== -1)) &&
        !tags.some((t) => this.excludedTags.indexOf(t) !== -1)
      );
    }
    return true;
  }

  matchCount(tags: string[]) {
    return this.includedTags.filter((v) => tags.includes(v)).length;
  }

  toJS(): TagConditionJson {
    return {
      includedTags: toJS(this.includedTags),
      excludedTags: toJS(this.excludedTags),
    };
  }

  @computed
  get empty(): boolean {
    return this.includedTags.length === 0 && this.excludedTags.length === 0;
  }

  @computed
  get tagString(): string {
    return this.includedTags.join(', ');
  }

  @computed
  get friendlyString(): string {
    const included = this.includedTags.join(', ');
    const excluded = this.excludedTags.join(', ');
    if (this.includedTags.length > 0) {
      if (this.excludedTags.length > 0) {
        return `${included} | ${excluded}`;
      }
      return included;
    } else if (this.excludedTags.length > 0) {
      return excluded;
    }
    return '<default>';
  }
}
