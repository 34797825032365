/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { AppDownloadButtons } from './AppDownloadButtons';
import { useTrans } from '../../Store/System/LocalizationStore';

const ColWithImage = styled(Col)`
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right center !important;
  height: 360px;
  display: flex;

  @media (min-width: 600px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 640px;
  }
`;

const OverlayContainer = styled.div`
  flex: 1;
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Circle = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
  padding: 16px;
  background-color: #24dca4;
  filter: drop-shadow(0px 2.01975px 2.01975px rgba(0, 0, 0, 0.25));
  color: white;
  font-weight: 700;
  width: 160px;
  height: 160px;
`;

const Slogan = styled.div`
  text-align: right;
  text-transform: capitalize;
  align-self: flex-end;
  color: rgba(36, 220, 164, 0.45);
  font-weight: 800;
  font-size: 51px;
  line-height: 89.9%;
`;

const ContentContainer = styled(Col)`
  margin-top: 32px;
`;

const AppDownloadContainer = styled(Col)`
  @media (min-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export type PlaceholderScreenProps = {
  headerImage: any;
};

export const PlaceholderScreen: React.FC<PropsWithChildren<PlaceholderScreenProps>> = observer(
  ({ headerImage, children }) => {
    const { t } = useTrans();

    return (
      <Container>
        <Row>
          <ContentContainer md={6} lg={4} className="d-none d-md-block">
            {children}
          </ContentContainer>
          <ColWithImage xs={12} md={6} lg={8} style={{ background: `url('${headerImage}')` }}>
            <OverlayContainer>
              <Circle>
                {t('placerholderScreen.onlyAvailableInApp', {
                  defaultValue: 'Only available in the app at the moment',
                })}
              </Circle>
              <Slogan>
                Hello
                <br />
                healthy
                <br />
                life
              </Slogan>
            </OverlayContainer>
          </ColWithImage>
          <AppDownloadContainer xs={12}>
            <AppDownloadButtons />
          </AppDownloadContainer>
          <ContentContainer xs={12} className="d-block d-md-none">
            {children}
          </ContentContainer>
        </Row>
      </Container>
    );
  },
);
