import { toJS } from 'mobx';
import { Token } from './Token';
import * as ls from 'local-storage';
import AuthenticationService from './AuthenticationService';

export class AppAuthTokenClass {
  static STORE_KEY = 'coach_web_user:auth_data';

  refreshPromise?: Promise<Token>;
  authData?: Token;

  storeAuthData(authData: Token): Token {
    this.authData = authData;
    ls.set(AppAuthTokenClass.STORE_KEY, toJS(authData));
    return authData;
  }

  loadAuthData(): Token | undefined {
    const json = ls.get(AppAuthTokenClass.STORE_KEY);
    if (json) {
      this.authData = new Token(json);
    }
    return this.authData;
  }

  async getAuthData(): Promise<Token | undefined> {
    const token = await this.loadAuthData();
    if (this.refreshPromise) {
      console.log('returing refreshPromise');
      return this.refreshPromise;
    }
    if (token?.expired === true) {
      console.log('token expired');
      try {
        this.refreshPromise = token.refresh();
        const result = await this.refreshPromise;
        await this.storeAuthData(result);
        return this.refreshPromise;
      } catch (err: any) {
        if (err.response?.status === 401 || err.response?.status === 403) {
          AuthenticationService.logout();
        }
      } finally {
        this.refreshPromise = undefined;
      }
      // return token
      //   .refresh()
      //   .then((res) => this.storeAuthData(res))
      //   .catch((err) => {
      //     if (err.response?.status === 401 || err.response?.status === 403) {
      //       AuthenticationService.logout();
      //     }
      //     return err;
      //   });
      // .finally(() => (this.refreshPromise = undefined));
    }
    // return Promise.resolve(this.authData);
    return token;
  }

  deleteAuthData() {
    this.authData = undefined;
    return ls.remove(AppAuthTokenClass.STORE_KEY);
  }
}

const AppAuthToken = new AppAuthTokenClass();
export default AppAuthToken;
