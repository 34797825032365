/**
 * Created by katarinababic on 8.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ExploreEntry } from '../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../Model/Explore/ExploreEntryBuilder';
import { HorizontalSlider } from './HorizontalSlider';
import { ExploreCardEntryRenderer, ExploreCardEntryRendererProps } from './ExploreEntryCard/ExploreCardEntryRenderer';
import { useTrans } from '../Store/System/LocalizationStore';
import { ExploreCategory } from '../Model/Explore/ExploreCategory';
import { useRootStore } from '../Store/useRootStore';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f7;
  padding: 2rem;
  margin-top: 2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h4`
  color: #3c3c3c;
  font-size: 1.5rem;
`;

const SeeAllLink = styled(Link)`
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
`;

export type RelatedEntriesViewProps = {
  entry: ExploreEntry;
} & Pick<ExploreCardEntryRendererProps, 'linkPrefix'>;

export const RelatedEntriesView: React.FC<RelatedEntriesViewProps> = observer(({ entry, linkPrefix }) => {
  const { language } = useRootStore();
  const { t } = useTrans();

  const [relatedEntries, setRelatedEntries] = useState<ExploreEntry[]>([]);
  const [category, setCategory] = useState<ExploreCategory | undefined>(undefined);

  const defaultTitle = t('relatedEntriesView.title', { defaultValue: 'Related content' });

  useEffect(() => {
    ExploreEntryBuilder.find({ categories: entry.categories, size: 20 })
      .then((res) => res.filter((e) => e.id !== entry.id))
      .then((r) => setRelatedEntries(r));
  }, [entry]);

  useEffect(() => {
    ExploreCategory.find({ query: entry.categories[0] })
      .then((res) => res.find((cat) => cat.identifier === entry.categories[0]))
      .then((res) => setCategory(res));
  }, [entry]);

  return (
    <StyledContainer>
      <Container>
        <TitleContainer>
          <Title>{category?.getName(language.language) ?? defaultTitle}</Title>
          <SeeAllLink to={category ? `${linkPrefix}${category?.identifier}` : `${linkPrefix}`}>
            {t('exploreCategoryIntroCard.seeAllButton', { defaultValue: 'See all' })}
          </SeeAllLink>
        </TitleContainer>
        <HorizontalSlider>
          {relatedEntries.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={linkPrefix} />
          ))}
        </HorizontalSlider>
      </Container>
    </StyledContainer>
  );
});
