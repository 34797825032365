/**
 * Created by neo on 18.01.17.
 */
import { observable, computed, action, ObservableMap, onBecomeObserved, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { CoachWorkout } from './CoachWorkout';
import { LocalizedValue, LocalizedValueJson } from '../../../LocalizedValue';
import { CoachExerciseBlock, CoachExerciseBlockJson } from './CoachExerciseBlock';
import { languagePriority } from '../../../LocalizedEntity';
import { ExerciseVariation } from '../../../ProgramPortfolio/ExerciseVariation';
import { BasePhaseJson, PhaseJson } from '../../../ProgramPortfolio/Phase';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { PipelineContext } from '../../PipelineContext';

export type CoachWorkoutPhaseJson = Omit<BasePhaseJson, 'exerciseBlocks'> & {
  scriptId: string;
  description: LocalizedValueJson[];
  exerciseBlocks: CoachExerciseBlockJson[];
};

export class CoachWorkoutPhase {
  @observable scriptId: string = '';
  @observable phaseId: string = UUID();
  @observable type: string = 'strength';
  @observable
  description: LocalizedValue[] = [];
  @observable.shallow exerciseBlocks: CoachExerciseBlock[] = [];
  @observable options: ObservableMap<string> = observable.map({});
  @observable workout: CoachWorkout;
  @observable
  hasReplaceSuggestions?: boolean;
  @observable
  private suggestionsPromise?: Promise<boolean>;

  constructor(workout: CoachWorkout, json?: Partial<CoachWorkoutPhaseJson>) {
    this.workout = workout;
    if (json) {
      this.scriptId = json.scriptId ?? '';
      this.phaseId = json.phaseId || UUID();
      this.type = json.type || 'strength';
      this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
      this.options = observable.map(json.options || {});
      this.exerciseBlocks = (json.exerciseBlocks || [])
        .filter((b: any) => b && b.exercise && b.exercise.id)
        .map((b: any) => new CoachExerciseBlock(this, b));
    }

    onBecomeObserved(this, 'hasReplaceSuggestions', this.fetchHasReplaceSuggestions);
  }

  fetchHasReplaceSuggestions = () => {
    if (!this.hasReplaceSuggestions) {
      if (!this.suggestionsPromise) {
        this.suggestionsPromise = HttpBackend.post(
          `/coach/workout/${this.workout.id}/${this.phaseId}/suggest`,
          new PipelineContext({ paramValues: { limit: 1 } }).toJS(),
        )
          .then((res) => runInAction(() => (this.hasReplaceSuggestions = (res ?? []).length > 0)))
          .finally(() => runInAction(() => (this.suggestionsPromise = undefined)));
      }
      return this.suggestionsPromise;
    }
    return Promise.resolve(this.hasReplaceSuggestions);
  };

  toJS(newId: boolean = false, excludedExerciseBlockIds: string[] = []): CoachWorkoutPhaseJson {
    return {
      scriptId: this.scriptId,
      phaseId: newId ? UUID() : this.phaseId,
      type: this.type,
      description: this.description.map((l) => l.toJS()),
      exerciseBlocks: this.exerciseBlocks
        .filter((b) => !excludedExerciseBlockIds.includes(b.exerciseBlockId))
        .map((b) => b.toJS(newId)),
      options: this.options.toJSON(),
    };
  }

  toWorkoutJS(newId?: boolean): PhaseJson {
    return {
      phaseId: newId ? UUID() : this.phaseId,
      type: this.type,
      name: '',
      description: this.defaultDescription,
      exerciseBlocks: this.exerciseBlocks.map((b) => b.toWorkoutJS(newId)),
      options: this.options.toJSON(),
    };
  }

  hasExercise(exercise: ExerciseVariation, exerciseBlockId?: string): boolean {
    if (exerciseBlockId) {
      return !!this.exerciseBlocks.find((b) => b.exerciseBlockId === exerciseBlockId);
    }
    const index = this.exerciseBlocks.findIndex((b) => b.exercise.id === exercise.id);
    return index !== -1;
  }

  fetchSuggestions(): Promise<CoachExerciseBlock[]> {
    return HttpBackend.post(
      `/coach/workout/${this.workout.id}/${this.phaseId}/suggest`,
      new PipelineContext({ paramValues: { limit: 10 } }).toJS(),
    ).then((result) => result.map((r) => new CoachExerciseBlock(this, r)));
  }

  @action
  replaceBlock(oldBlock: CoachExerciseBlock, newBlock: CoachExerciseBlock) {
    const index = this.exerciseBlocks.findIndex((b) => b.exerciseBlockId === oldBlock.exerciseBlockId);
    if (index !== -1) {
      this.exerciseBlocks[index] = newBlock;
    } else {
      this.exerciseBlocks.push(newBlock);
    }
  }

  @computed
  get totalExercises(): number {
    return this.exerciseBlocks.length;
  }

  @computed
  get duration(): number {
    return this.exerciseBlocks.reduce((total: number, block: CoachExerciseBlock) => total + block.duration, 0);
  }

  @computed
  get breakTime(): number {
    return this.exerciseBlocks.reduce((total: number, block: CoachExerciseBlock) => total + block.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.exerciseBlocks.reduce(
      (total: number, block: CoachExerciseBlock) => total + block.totalTimeExercising,
      0,
    );
  }

  @computed
  get totalSets(): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.sets.length, 0);
  }

  @computed
  get tons(): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.tons, 0);
  }

  @computed
  get lastBlock(): CoachExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[length - 1];
    }
    return undefined;
  }

  @computed
  get firstBlock(): CoachExerciseBlock | undefined {
    const {
      exerciseBlocks: { length },
    } = this;
    if (length > 0) {
      return this.exerciseBlocks[0];
    }
    return undefined;
  }

  @computed
  get prevPhase(): CoachWorkoutPhase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      if (phaseIndex > 0) {
        return this.workout.phases[phaseIndex - 1];
      }
    }
    return undefined;
  }

  @computed
  get nextPhase(): CoachWorkoutPhase | undefined {
    if (this.workout) {
      const { phaseIndex } = this;
      const nextIndex = phaseIndex + 1;
      return nextIndex < this.workout.phases.length ? this.workout.phases[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get phaseIndex(): number {
    return this.workout ? this.workout.phases.findIndex((p) => p.phaseId === this.phaseId) : -1;
  }

  @computed
  get prevBlock(): CoachExerciseBlock | undefined {
    return this.lastBlock || (this.prevPhase ? this.prevPhase.prevBlock : undefined);
  }

  @computed
  get nextBlock(): CoachExerciseBlock | undefined {
    return this.firstBlock || (this.nextPhase ? this.nextPhase.nextBlock : undefined);
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.exerciseBlocks.reduce((total, block) => total + block.estimatedTotalExerciseTime, 0);
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }

  calculateCalories(bmr24: number): number {
    return this.exerciseBlocks.reduce((total: number, b: CoachExerciseBlock) => total + b.calculateCalories(bmr24), 0);
  }
}
