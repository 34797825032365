import * as React from 'react';
import { Footer } from './Footer';
import { useNavigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Header } from './Header';
import styled from '@emotion/styled';
import {Container} from "reactstrap";

const Content = styled(Container)`
  flex: 1;
  min-height: 100%;
  padding: 60px 0;
`;

export type MainLayoutComponentProps = {};

export const MainLayout: React.FC<MainLayoutComponentProps> = observer(() => {
  return (
    <React.Fragment>
      <Header />
      <Content fluid={true}>
        <Outlet />
      </Content>
      <Footer />
    </React.Fragment>
  );
});
