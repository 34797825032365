/**
 * Created by katarinababic on 13.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EmptyScheduleCard } from './EmptyScheduleCard';
import { useTrans } from '../../Store/System/LocalizationStore';
import { useNavigate } from 'react-router-dom';

export type CurrentWeekEmptyScheduleCardProps = {
  linkPrefix?: string;
};

export const CurrentWeekEmptyScheduleCard: React.FC<CurrentWeekEmptyScheduleCardProps> = observer(({ linkPrefix }) => {
  const { t } = useTrans();
  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    navigate(`${linkPrefix}library`);
  }, [navigate]);

  return (
    <EmptyScheduleCard
      title={t('emptyScheduleCard.currentWeek.title', { defaultValue: 'This week schedule is over.' })}
      subtitle={t('emptyScheduleCard.currentWeek.subtitle', {
        defaultValue: 'Enjoy your free time or check more On demand content in the app. See you next week!',
      })}
      description={t('emptyScheduleCard.currentWeek.description', {
        defaultValue: "Wanna give a suggestion for the future workouts? We're all ears!",
      })}
      image={require('../../Assets/Images/week_schedule_over.png')}
      buttonText={t('emptyScheduleCard.currentWeek.buttonText', { defaultValue: 'Train now' }).toUpperCase()}
      onClick={handleClick}
    />
  );
});
