import { computed, observable, ObservableMap, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { BaseTrackingKey, TrackingKeysList } from './TrackingKeys';
import { ExerciseId } from './ExerciseId';
import { v4 as UUID } from 'uuid';
import { BodyPart, BodyPartJson } from './BodyPart';
import { Media, MediaJson } from '../Media/Media';
import { ExercisePosition, ExercisePositionJson } from './ExercisePosition';
import { Pageable } from '../Interfaces/Pageable';

export type ExerciseForce = 'PUSH' | 'PULL' | 'PUSH_PULL';
export type ExerciseMechanics = 'ISOLATED' | 'COMPOUND';
export type ExerciseUtility = 'AUXILIARY' | 'BASIC';
export type ExerciseExecutionType = 'ONE_SIDED' | 'BOTH_SIDED' | 'ALTERNATING';
export type SpineForceType = 'stretch' | 'contract';

export type ExerciseVariationRequest = Pageable & {
  query: string;
  lang: string;
  type: string[];
  bodyPartIds: string[];
  excludedBodyPartIds: string[];
  synergistsIds: string[];
  stabilizersIds: string[];
  joints: string[];
  excludedJoints: string[];
  force: string[];
  mechanics: string[];
  utilities: string[];
  primaryPositions: string[];
  secondaryPositions: string[];
  excludedPrimaryPositions: string[];
  excludedSecondaryPositions: string[];
  executionTypes: string[];
  excludedExecutionTypes: string[];
  requiredEquipmentTypes: string[];
  equipmentTypes: string[];
  secondaryEquipmentTypes: string[];
  excludedEquipmentTypes: string[];
  excludeEmptyEquipmentTypes: boolean;
  /**
   * do not enforce that all equipmentTypes are present -> $in
   */
  equipmentTypeMatchNotAll: boolean;
  trackingKeys: string[];
  excludedTrackingKeys: string[];
  tags: string[];
  hipFlexing?: boolean;
  spineFlexing?: boolean;
  spineForceType?: string;
  excludedTags: string[];
  sourceType?: string;
  sourceId?: string;
  branchId?: string;
  available?: boolean;
  spotClassId?: string;
  spotGroupId?: string;
  archived: boolean;
  excludeExerciseIds: string[];
  noAlternatives?: boolean;
  noHarderAlternatives?: boolean;
};

export type ExerciseVariationJson = {
  id: string;
  name: string;
  names: {
    [key: string]: string;
  };
  type: string;
  description?: string;
  force?: ExerciseForce;
  mechanics?: ExerciseMechanics;
  utility?: ExerciseUtility;
  met: number;
  bodyParts: BodyPartJson[];
  synergists: BodyPartJson[];
  stabilizers: BodyPartJson[];
  medias: MediaJson[];
  previewMedias: MediaJson[];
  executionType?: ExerciseExecutionType;
  sourceType?: string;
  sourceId?: string;
  equipmentTypes: string[];
  secondaryEquipmentTypes: string[];
  initialPosition?: ExercisePositionJson;
  trackingKeys: BaseTrackingKey[];
  optionalTrackingKeys: BaseTrackingKey[];
  tags: string[];
  hipFlexing: boolean;
  spineFlexing: boolean;
  spineForceType?: SpineForceType;
  easierAlternativeIds: string[];
  alternativeIds: string[];
  harderAlternativeIds: string[];
};

export class ExerciseVariation {
  @observable id: string = UUID();
  @observable name: string = '';
  @observable names: ObservableMap<string, string> = observable.map({});
  @observable type: string = 'STRENGTH';
  @observable description?: string;
  @observable force?: ExerciseForce;
  @observable mechanics?: ExerciseMechanics;
  @observable utility?: ExerciseUtility;
  @observable met: number = 5.5;
  @observable bodyParts: BodyPart[] = [];
  @observable synergists: BodyPart[] = [];
  @observable stabilizers: BodyPart[] = [];
  @observable medias: Media[] = [];
  @observable previewMedias: Media[] = [];
  @observable executionTypes: string[] = [];
  @observable executionType?: ExerciseExecutionType;
  @observable
  sourceType?: string;
  @observable
  sourceId?: string;
  @observable equipmentTypes: string[] = [];
  @observable secondaryEquipmentTypes: string[] = [];
  @observable initialPosition?: ExercisePosition;
  @observable trackingKeys: BaseTrackingKey[] = [];
  @observable optionalTrackingKeys: BaseTrackingKey[] = [];
  F;
  @observable tags: string[] = [];
  @observable hipFlexing = false;
  @observable spineFlexing = false;
  @observable spineForceType?: SpineForceType;
  @observable
  easierAlternativeIds: string[] = [];
  @observable
  alternativeIds: string[] = [];
  @observable
  harderAlternativeIds: string[] = [];

  constructor(json?: Partial<ExerciseVariationJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.name = json.name ?? '';
      this.names = observable.map(json.names || {});
      this.type = json.type || 'STRENGTH';
      this.description = json.description;
      this.medias = (json.medias || []).map((m: any) => new Media(m));
      this.previewMedias = (json.previewMedias || []).map((m: any) => new Media(m));
      this.force = json.force;
      this.mechanics = json.mechanics;
      this.bodyParts = (json.bodyParts || []).map((b: any) => new BodyPart(b));
      this.synergists = (json.synergists || []).map((b: any) => new BodyPart(b));
      this.stabilizers = (json.stabilizers || []).map((b: any) => new BodyPart(b));
      this.executionType = json.executionType;
      this.utility = json.utility;
      this.met = json.met || 5.5;
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
      this.equipmentTypes = json.equipmentTypes || [];
      this.secondaryEquipmentTypes = json.secondaryEquipmentTypes ?? [];
      this.initialPosition = json.initialPosition ? new ExercisePosition(json.initialPosition) : undefined;
      this.trackingKeys = json.trackingKeys || [];
      this.optionalTrackingKeys = json.optionalTrackingKeys || [];
      this.tags = json.tags ?? [];
      this.hipFlexing = json.hipFlexing ?? false;
      this.spineFlexing = json.spineFlexing ?? false;
      this.spineForceType = json.spineForceType;
      this.easierAlternativeIds = json.easierAlternativeIds ?? [];
      this.alternativeIds = json.alternativeIds ?? [];
      this.harderAlternativeIds = json.harderAlternativeIds ?? [];
    }
  }

  toJS(): ExerciseVariationJson {
    return {
      id: this.id,
      name: this.name,
      names: this.names.toJSON(),
      type: this.type,
      description: this.description,
      force: this.force,
      mechanics: this.mechanics,
      utility: this.utility,
      met: this.met,
      bodyParts: this.bodyParts.map((s) => s.toJS()),
      synergists: this.synergists.map((s) => s.toJS()),
      stabilizers: this.stabilizers.map((s) => s.toJS()),
      medias: this.medias.map((m) => m.toJS()),
      previewMedias: this.previewMedias.map((m) => m.toJS()),
      executionType: this.executionType,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
      equipmentTypes: toJS(this.equipmentTypes),
      secondaryEquipmentTypes: toJS(this.secondaryEquipmentTypes),
      initialPosition: this.initialPosition?.toJS(),
      trackingKeys: toJS(this.trackingKeys),
      optionalTrackingKeys: toJS(this.optionalTrackingKeys),
      tags: toJS(this.tags),
      hipFlexing: this.hipFlexing,
      spineFlexing: this.spineFlexing,
      spineForceType: this.spineForceType,
      easierAlternativeIds: this.easierAlternativeIds,
      alternativeIds: this.alternativeIds,
      harderAlternativeIds: this.harderAlternativeIds,
    };
  }

  localeName(lang: string): string {
    if (this.names.has(lang)) {
      return this.names.get(lang) || this.name || this.names.get('de') || '';
    }
    return this.name;
  }

  @computed
  get videoMedia(): Media | undefined {
    return this.medias.find((m) => m.mediaType.startsWith('video'));
  }

  @computed
  get previewVideoMedia(): Media | undefined {
    return this.previewMedias.find((m) => m.mediaType.startsWith('video'));
  }

  @computed
  get imageMedia(): Media | undefined {
    return this.medias.find((m) => m.mediaType.startsWith('image'));
  }

  @computed
  get firstSmallestMediaUrl(): string | undefined {
    return this.imageMedia?.smallest;
  }

  @computed
  get exerciseIdentifier(): ExerciseId {
    return new ExerciseId({
      variationId: this.id,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    });
  }

  @computed
  get defaultTrackingKeys(): BaseTrackingKey[] {
    switch (this.type.toLowerCase()) {
      case 'strength':
        if (this.equipmentTypes.indexOf('FREE') !== -1) {
          return ['REPETITIONS', 'DURATION', 'BREAK'];
        }
        return ['REPETITIONS', 'WEIGHT', 'DURATION', 'BREAK'];
      case 'endurance':
      case 'flexibility':
      case 'balance':
      case 'relax':
      default:
        return ['DURATION', 'BREAK'];
    }
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    if (this.trackingKeys.length > 0) {
      const hasBreak = this.trackingKeys.indexOf('BREAK') !== -1;
      const hasDuration = this.trackingKeys.indexOf('DURATION') !== -1;
      if (!hasBreak) {
        if (!hasDuration) {
          return this.trackingKeys.concat(['DURATION', 'BREAK']);
        }
        return this.trackingKeys.concat(['BREAK']);
      } else if (!hasDuration) {
        if (!hasBreak) {
          return this.trackingKeys.concat(['DURATION', 'BREAK']);
        }
        return this.trackingKeys.concat(['DURATION']);
      }
      return this.trackingKeys;
    }
    return this.defaultTrackingKeys;
  }

  @computed
  get tracksDuration(): boolean {
    return this.trackingParameters.indexOf('DURATION') !== -1;
  }

  @computed
  get tracksWeightOrReps(): boolean {
    return this.trackingParameters.indexOf('WEIGHT') !== -1 || this.trackingParameters.indexOf('REPETITIONS') !== -1;
  }

  @computed
  get sortedTrackingParameters(): BaseTrackingKey[] {
    return this.trackingParameters.sort((a, b) => TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b));
  }

  @computed
  get isAlternating() {
    return this.executionType === 'ALTERNATING';
  }

  @computed
  get isOneSided() {
    return this.executionType === 'ONE_SIDED';
  }

  static find(params?: Partial<ExerciseVariationRequest>): Promise<ExerciseVariation[]> {
    return HttpBackend.get('/exercise/v3', params).then((result) =>
      (result ?? []).map((res) => new ExerciseVariation(res)),
    );
  }

  static random(params?: Partial<ExerciseVariationRequest>): Promise<ExerciseVariation[]> {
    return HttpBackend.get('/exercise/v3/random', params).then((result) =>
      (result ?? []).map((res) => new ExerciseVariation(res)),
    );
  }

  static get(id: string): Promise<ExerciseVariation> {
    return HttpBackend.get(`/exercise/v3/variation/${id}`).then((res) => new ExerciseVariation(res));
  }
}
