/**
 * Created by katarinababic on 12.4.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Button } from '../../Button';
import { AlertModal, AlertModalProps } from './AlertModal';
import styled from '@emotion/styled';
import { useTrans } from '../../../Store/System/LocalizationStore';

const WarningIconCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const AlertTitle = styled.p`
  font-size: 1rem;
  color: #ffffff;
  padding-left: 1rem;
  text-align: center;
`;

const iconStyle: React.CSSProperties = {
  color: '#ffffff',
  fontSize: '1.5rem',
  cursor: 'pointer',
};

const ButtonRow = styled(Row)`
  display: flex;
  padding: 1rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

export type ErrorAlertProps = AlertModalProps & {
  errorCode?: string;
  onDismiss?: () => void;
  title: string;
  onButtonClicked?: () => void;
  buttonText?: string;
};

export const ErrorAlert: React.FC<ErrorAlertProps> = observer(
  ({ title, toggle, isOpen, errorCode, onDismiss, onButtonClicked, buttonText }) => {
    const { t } = useTrans();
    const mainButtonText = buttonText || t('globals.back', { defaultValue: 'Back' }).toUpperCase();

    return (
      <AlertModal
        centered={true}
        toggle={toggle}
        isOpen={isOpen}
        AlertHeader={<i className="bi bi-x" style={iconStyle} onClick={onDismiss} />}
        AlertBody={
          <React.Fragment>
            <Row>
              <WarningIconCol xs={12}>
                <i className="bi bi-exclamation-triangle" style={iconStyle} />
              </WarningIconCol>
            </Row>
            <Row>
              <Col xs={11}>
                <AlertTitle>{title}</AlertTitle>
              </Col>
            </Row>
            <ButtonRow>
              <Button color="warning" onClick={onButtonClicked || onDismiss}>
                {mainButtonText}
              </Button>
            </ButtonRow>
          </React.Fragment>
        }
      />
    );
  },
);
