import { observable, computed } from 'mobx';
import { NativeFeedComponent } from './NativeFeedComponent';
import { Media } from '../../Media/Media';
import { LocalizedValue } from '../../LocalizedValue';
import { languagePriority } from '../../LocalizedEntity';

export class FeedObjectCollectionItemData {
  @observable
  content: string | NativeFeedComponent[] = [];
  @observable contentType: 'text/html' | 'native' = 'native';
  @observable medias?: Media;
  @observable
  preview: string | LocalizedValue[];
  @observable
  title: string | LocalizedValue[];

  constructor(json?: any) {
    if (json) {
      this.content = this.setContent(json.content);
      this.contentType = json.contentType || 'native';
      this.medias = json.medias ? new Media(json.medias) : undefined;
      this.preview =
        typeof json.preview === 'string' ? json.preview ?? '' : (json.preview ?? []).map((l) => new LocalizedValue(l));
      this.title =
        typeof json.title === 'string' ? json.title ?? '' : (json.title ?? []).map((l) => new LocalizedValue(l));
    } else {
      this.preview = [];
      this.title = [];
    }
  }

  setContent(content: any) {
    if (typeof content === 'string') {
      return content;
    }
    return (content ?? []).map((c) => new NativeFeedComponent(c));
  }

  getTitle(lang: string) {
    if (typeof this.title === 'string') {
      return this.title;
    }
    return this.title.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultTitle;
  }

  getPreview(lang: string) {
    if (typeof this.preview === 'string') {
      return this.preview;
    }
    return this.preview.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultPreview;
  }

  @computed
  get defaultTitle(): string {
    if (typeof this.title === 'string') {
      return this.title;
    }
    for (const lang of languagePriority) {
      const entry = this.title.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.title[0];
    return first?.value ?? '';
  }

  @computed
  get defaultPreview(): string {
    if (typeof this.preview === 'string') {
      return this.preview;
    }
    for (const lang of languagePriority) {
      const entry = this.preview.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.preview[0];
    return first?.value ?? '';
  }
}
