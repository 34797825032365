/**
 * Created by katarinababic on 28.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Button as BootstrapButton } from 'reactstrap';
import { useTrans } from '../../../Store/System/LocalizationStore';

const Container = styled.div`
  display: flex;
  padding-top: 1rem;
`;

const StyledButton = styled(BootstrapButton)`
  background-color: white;
  width: 100%;
  border-radius: 200px;
  border-color: white;
  padding: 1rem 2rem 1rem 2rem;
`;

const ButtonText = styled.span`
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 0.8rem;
  padding-left: 1rem;
`;

export type ContinueWithAppleButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  text: string;
};

export const ContinueWithAppleButton: React.FC<ContinueWithAppleButtonProps> = observer(
  ({ onClick, disabled = false, text }) => {
    const { t } = useTrans();

    return (
      <Container>
        <StyledButton onClick={onClick} disabled={disabled}>
          <i className="bi bi-apple" style={{ color: 'black' }} />
          <ButtonText>{text}</ButtonText>
        </StyledButton>
      </Container>
    );
  },
);
