import { observable } from 'mobx';

export type ExercisePositionPrimary = 'standing' | 'sitting' | 'kneeling' | 'cross_legged';
export type ExercisePositionSecondary =
  | 'straight'
  | 'inclined_front'
  | 'inclined_back'
  | 'inclined_side'
  | 'back'
  | 'belly'
  | 'side';

export type ExercisePositionJson = {
  primary: ExercisePositionPrimary;
  secondary?: ExercisePositionSecondary;
};

export class ExercisePosition {
  @observable
  primary: ExercisePositionPrimary = 'standing';
  @observable
  secondary?: ExercisePositionSecondary;

  constructor(json?: Partial<ExercisePositionJson>) {
    if (json) {
      this.primary = json.primary ?? 'standing';
      this.secondary = json.secondary;
    }
  }

  toJS(): ExercisePositionJson {
    return {
      primary: this.primary,
      secondary: this.secondary,
    };
  }
}
