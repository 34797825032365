/**
 * Created by neo on 24.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from '../../Components/Button';
import { buildUrl, downloadBlob, ICalEvent, isIOSSafari } from './utils';
import { useTrans } from '../../Store/System/LocalizationStore';
import { useRootStore } from '../../Store/useRootStore';
import { ButtonProps } from 'reactstrap';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { useLocation } from 'react-router-dom';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';
import { WebinarEntry } from '../../Model/Explore/WebinarEntry';

function convertToIcalEvent(entry: SubscribableEntry, language: string, pageLink?: string): ICalEvent {
  const startDate = entry.nextDate;
  const endDate = startDate?.add(entry.dayjsDuration);

  return {
    title: entry.getName(language),
    description: entry.getShortDescription(language),
    startTime: startDate?.toISOString() ?? '',
    endTime: endDate?.toISOString() ?? '',
    url: pageLink || (entry instanceof OnlineMeetingEntry ? entry.meetingLink : ''),
    location: pageLink || (entry instanceof OnlineMeetingEntry ? entry.meetingLink : ''),
    // url: `https://coach.kinastic.com/academy/${entry.id}`,
    // location: `https://coach.kinastic.com/academy/${entry.id}`,
  };
}

export type AddToCalendarButtonProps = Omit<ButtonProps, 'children' | 'value'> & {
  entry: SubscribableEntry;
  rawContent?: string;
};

export const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = observer(
  ({ entry, rawContent = '', ...moreProps }) => {
    const { language, analytics } = useRootStore();
    const { t } = useTrans();

    const isCrappyIE = !!(typeof window !== 'undefined' && (window.navigator as any).msSaveOrOpenBlob && window.Blob);

    const handleClick = React.useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const filename = `${entry.getName(language.language)}.ics`;
        const url: string = buildUrl(
          convertToIcalEvent(entry, language.language, window.location.href),
          isIOSSafari(),
          rawContent,
        );
        const blob: Blob = new Blob([url], {
          type: 'text/calendar;charset=utf-8',
        });

        // IE
        if (isCrappyIE) {
          (window.navigator as any).msSaveOrOpenBlob(blob, filename);
          return;
        }

        // Safari
        if (isIOSSafari()) {
          window.open(url, '_blank');
          return;
        }

        // Desktop
        downloadBlob(blob, filename);

        analytics.logEvent('webinar_calendar_downloaded', {
          objectId: entry.id,
          objectType: entry.type,
          url: entry instanceof OnlineMeetingEntry ? entry.meetingLink : '',
        });
      },
      [entry, language, rawContent, isCrappyIE, analytics],
    );

    return (
      <Button color="secondary" onClick={handleClick} {...moreProps}>
        <i className="bi bi-calendar-event"></i>&nbsp;
        {t('globals.addToCalendarButton', { defaultValue: 'Add to calendar' })}
      </Button>
    );
  },
);
