/**
 * Created by katarinababic on 13.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { EmptyScheduleCard } from './EmptyScheduleCard';
import { useTrans } from '../../Store/System/LocalizationStore';
import { useNavigate } from 'react-router-dom';

export type FutureEmptyScheduleCardProps = {};

export const FutureEmptyScheduleCard: React.FC<FutureEmptyScheduleCardProps> = observer((props) => {
  const { t } = useTrans();
  const navigate = useNavigate();

  const handleGiveFeedback = React.useCallback(() => navigate('/feedback'), [navigate]);

  return (
    <EmptyScheduleCard
      title={t('emptyScheduleCard.future.title', { defaultValue: "Ooops, you're ahead of us!" })}
      subtitle={t('emptyScheduleCard.future.subtitle', {
        defaultValue: 'We did not schedule events yet at this moment...',
      })}
      description={t('emptyScheduleCard.future.description', {
        defaultValue: 'But we are always open for suggestions!',
      })}
      image={require('../../Assets/Images/ahead_of_schedule.png')}
      buttonText={t('accountScreen.giveFeedbackButton', { defaultValue: 'Give Feedback' }).toUpperCase()}
      onClick={handleGiveFeedback}
    />
  );
});
