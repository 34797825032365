/**
 * Created by neo on 01.02.21.
 */
import { observable } from 'mobx';
import { Media, MediaJson } from '../Media/Media';

export type MediaNarratorAudioTrackJson = {
  language: string;
  media: MediaJson;
};

export class MediaNarratorAudioTrack {
  @observable
  language = 'de';
  @observable
  media: Media = new Media();

  constructor(json?: Partial<MediaNarratorAudioTrackJson>) {
    if (json) {
      this.language = json.language ?? 'de';
      this.media = new Media(json.media);
    }
  }

  toJS(): MediaNarratorAudioTrackJson {
    return {
      language: this.language,
      media: this.media.toJS(),
    };
  }
}
