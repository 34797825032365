/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Container, Col } from 'reactstrap';
import { AuthOverlayLeft } from './AuthOverlayLeft';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { AuthOverlayRight } from './AuthOverlayRight';
import { InputHTMLAttributes, PropsWithChildren } from 'react';
import { CookieConsentBanner } from '../../CookieConsentBanner';
import { AuthLayoutBlackBox, AuthLayoutBlackBoxProps } from './AuthLayoutBlackBox';

const AuthWrapper = styled(Container)`
  display: flex;
  flex-grow: 1 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center 40% !important;
  background: linear-gradient(0deg, rgb(0 0 0 / 75%), rgb(255 255 255 / 0%)), 30%, url('${({ image }) => image}');
  padding: 0;
`;

const OverlayColumn = styled(Col)`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 4.5rem 1.5rem;
  }
`;

const BlackBoxColumn = styled(Col)`
  display: flex;
  justify-content: center;
  flex-grow: 1 !important;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const MobileCookiesContainer = styled.div`
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopCookiesContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export type AuthLayoutProps = AuthLayoutBlackBoxProps & {
  image: InputHTMLAttributes<HTMLImageElement>['src'];
  overlayRightTitle: string;
};

export const AuthLayout: React.FC<PropsWithChildren<AuthLayoutProps>> = observer(
  ({ title, subtitle, text, image, children, overlayRightTitle }) => {
    const { t } = useTrans();

    return (
      <AuthWrapper fluid={true} image={image}>
        <OverlayColumn xs={2} md={3} lg={4}>
          <AuthOverlayLeft
            text={t('registration.stepOne.introSubtitle', { defaultValue: 'For all around healthy employees' })}
          />
        </OverlayColumn>
        <BlackBoxColumn xs={12} md={6} lg={4}>
          <MobileCookiesContainer>
            <CookieConsentBanner />
          </MobileCookiesContainer>
          <AuthLayoutBlackBox title={title} subtitle={subtitle} text={text}>
            {children}
          </AuthLayoutBlackBox>
          <DesktopCookiesContainer>
            <CookieConsentBanner />
          </DesktopCookiesContainer>
        </BlackBoxColumn>
        <OverlayColumn xs={2} md={3} lg={4}>
          <AuthOverlayRight text={overlayRightTitle} />
        </OverlayColumn>
      </AuthWrapper>
    );
  },
);
