/**
 * Created by shmbgd on 29.11.22.
 */
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import './Store/Firebase/firebase';
import { Root } from './Root';
import { rootStore } from './Store/useRootStore';

export type AppProps = {};

export const App: React.FC<AppProps> = observer(() => {
  return (
    <Provider rootStore={rootStore}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </Provider>
  );
});
