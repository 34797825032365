/**
 * Created by neo on 02.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRootStore } from '../../../Store/useRootStore';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { Col, Container, Row } from 'reactstrap';
import { ExploreCardEntryRenderer } from '../../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { PageResult } from '../../../Model/PageResult';
import { Button } from '../../../Components/Button';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { Translation } from '../../../Components/Translation';
import {ExploreFilterTagsSection} from "../ExploreFilterTagsSection";

const Header = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
`;

const Description = styled.p`
  margin-top: 16px;
`;

const FiltersTagsContainer = styled(Row)`
  padding-bottom: 32px;
`;

export type AcademyLibraryScreenProps = {};

export const AcademyLibraryScreen: React.FC<AcademyLibraryScreenProps> = observer((props) => {
  const { language } = useRootStore();

  const { t } = useTrans();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(new PageResult<ExploreEntry>());
  const [page, setPage] = useState(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (page === 0) {
      setResult(new PageResult());
    }
    setLoading(true);
    PageResult.execute(
      ExploreEntryBuilder.find(
        {
          rootCategories: ['academy'],
          size: 16,
          filterTags: selectedTags,
          type: ['video', 'article', 'webinar'],
          page
        }
      ),
      ExploreEntry.count({
        rootCategories: ['academy'],
        type: ['video', 'article', 'webinar'],
      }),
      page,
      16,
    )
      .then((res) => setResult((prev) => prev.merge(res)))
      .finally(() => setLoading(false));
  }, [page, selectedTags]);

  const handleLoadMore = React.useCallback(() => {
    setPage((p) => p + 1);
  }, []);

  const handleSelectFilterTag = React.useCallback((tags: string[]) => {
    setSelectedTags(tags);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs={12}>
            <Header>
              <Translation
                i18nKey="academyLibrary.title"
                defaultValue={`Academy\n<span style="color:#24dca4">Library</span>`}
              />
            </Header>
          </Col>
          <Col md={7}>
            <Description>
              <Translation
                i18nKey="academyLibrary.description"
                defaultValue={`Qualified international experts lecture on the topics of exercise, mental health and nutrition and provide tips and tricks that are easy to implement in everyday life.`}
              />
            </Description>
          </Col>
        </Row>
        <FiltersTagsContainer>
          <ExploreFilterTagsSection category={'academy'} selectedTags={selectedTags} onFilter={handleSelectFilterTag} />
        </FiltersTagsContainer>
        <Row>
          {result.content.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={`/academy/`} />
          ))}
        </Row>
        {!result.last && (
          <Row>
            <Col style={{ textAlign: 'center', paddingTop: 32 }}>
              <Button color="link" disabled={loading} onClick={handleLoadMore}>
                {t('globals.loadMore', { defaultValue: 'Load More' })}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
});
