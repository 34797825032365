// @flow

import { GetStreamFeed } from './GetStreamFeed';
import { FeedActivity } from './FeedActivity';

export class FeedActivityNotificationUpdate extends FeedActivity {
  group: string = '';

  constructor(readonly feed?: GetStreamFeed, json?: any) {
    super(feed, json);
    if (json) {
      this.group = json.group || '';
    }
  }
}
