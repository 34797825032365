/**
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, ObservableMap } from 'mobx';
import dayjs from '../../Utils/dayjs';
import { BaseTrackingKey, PlanableTrackingKey, TrackingKey } from './TrackingKeys';
import { ExerciseBlock } from './ExerciseBlock';

export type BaseExerciseBlockSetJson = {
  values: {
    [key in TrackingKey]?: number;
  };
};

export type ExerciseBlockSetJson = BaseExerciseBlockSetJson & {
  updateTimestamp: number;
};

export class ExerciseBlockSet {
  @observable
  values: ObservableMap<TrackingKey, number> = observable.map({});
  @observable
  updateTimestamp: number = 0;
  @observable
  exerciseBlock?: ExerciseBlock;

  constructor(exerciseBlock?: ExerciseBlock, source?: Partial<ExerciseBlockSetJson>) {
    this.exerciseBlock = exerciseBlock;
    if (source) {
      this.setData(source);
    }
  }

  toJS(): ExerciseBlockSetJson {
    return {
      values: this.values.toJSON(),
      updateTimestamp: this.updateTimestamp,
    };
  }

  calculateCalories(bmr24: number): number {
    if (this.plannedCalories > 0) {
      return this.plannedCalories;
    }
    const met = this.exerciseBlock ? this.exerciseBlock.exercise.met || 5.5 : 5.5;
    const timeInHours = Math.max(1, this.plannedDurationMs / 1000.0) / 3600.0;
    return Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours)) * 1.2;
  }

  @action
  setData(json: any) {
    this.values = observable.map(json.values || {});
    this.updateTimestamp = json.updateTimestamp || 0;
  }

  @action
  updateData(json: any) {
    this.setData(json);
  }

  @action
  setKey(key: TrackingKey, value?: number) {
    this.setUpdateTimestamp();
    if (value) {
      this.values.set(key, value);
    } else {
      this.values.delete(key);
    }
  }

  @action
  setUpdateTimestamp() {
    this.updateTimestamp = Date.now();
  }

  @computed
  get plannedCalories(): number {
    return this.values.get('CALORIES') || this.values.get('MIN_CALORIES') || this.values.get('MAX_CALORIES') || 0;
  }

  @computed
  get calories(): number {
    return this.plannedCalories;
  }

  @computed
  get duration(): number {
    return this.plannedDurationMs;
  }

  @computed
  get plannedRepetitionBasedDuration(): number {
    return (
      (this.values.get('MIN_REPETITIONS') ||
        this.values.get('MAX_REPETITIONS') ||
        this.values.get('REPETITIONS') ||
        0) * 4
    );
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationSeconds(): number {
    return this.plannedDuration;
  }

  @computed
  get breakTime(): number {
    return this.plannedBreakTime;
  }

  @computed
  get breakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedBreakTime(): number {
    return this.values.get('MIN_BREAK') || this.values.get('MAX_BREAK') || this.values.get('BREAK') || 60;
  }

  @computed
  get plannedBreakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedDuration(): number {
    return (
      this.values.get('MIN_DURATION') ||
      this.values.get('MAX_DURATION') ||
      this.values.get('DURATION') ||
      this.plannedRepetitionBasedDuration
    );
  }

  /**
   * Includes breakTime if there is a next set
   */
  @computed
  get totalDuration(): number {
    return this.plannedDurationMs + (this.nextSet ? this.breakTimeMs : 0);
  }

  @computed
  get plannedDurationMs(): number {
    return this.plannedDuration * 1000;
  }

  @computed
  get tons(): number {
    const weight = this.values.get('MIN_WEIGHT') || this.values.get('MAX_WEIGHT') || this.values.get('WEIGHT') || 0;
    const reps =
      this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || this.values.get('REPETITIONS') || 0;
    return (reps * weight) / 1000;
  }

  @computed
  get index(): number {
    return this.exerciseBlock ? this.exerciseBlock.sets.findIndex((s) => s === this) : -1;
  }

  @computed
  get isLastSet(): boolean {
    if (this.exerciseBlock) {
      const { index } = this;
      return index + 1 === this.exerciseBlock.sets.length;
    }
    return false;
  }

  @computed
  get prevSet(): ExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index } = this;
      if (index === 0) {
        return this.exerciseBlock.prevBlock?.prevSet;
      }
      return this.exerciseBlock.sets[index - 1];
    }
    return undefined;
  }

  @computed
  get nextSet(): ExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index, isLastSet } = this;
      if (isLastSet) {
        return this.exerciseBlock.nextBlock?.followingSet;
      }
      const nextIndex = index + 1;
      return nextIndex < this.exerciseBlock.sets.length ? this.exerciseBlock.sets[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get assignedTrackingKeys(): string[] {
    const result: string[] = [];
    const temp = Array.from(this.values.keys());
    temp.map((item: string) => {
      if (item && (item.startsWith('MIN_') || item.startsWith('MAX_'))) {
        const index = result.findIndex((k: string) => k === item.substr(4));
        if (index === -1) {
          result.push(item.substr(4));
        }
      } else {
        const index = result.findIndex((k: string) => k === item);
        if (index === -1) {
          result.push(item);
        }
      }
    });
    return result;
  }

  @action
  assignTrackingKey(trackingKey: BaseTrackingKey, value?: number) {
    this.values.set(`MIN_${trackingKey}` as PlanableTrackingKey, value || 0);
    this.values.set(`MAX_${trackingKey}` as PlanableTrackingKey, value || 0);
  }

  @action
  deleteTrackingKey(trackingKey: BaseTrackingKey) {
    this.values.delete(`MIN_${trackingKey}` as PlanableTrackingKey);
    this.values.delete(`MAX_${trackingKey}` as PlanableTrackingKey);
  }
}
