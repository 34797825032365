/**
 * Created by neo on 03.01.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { CookieConsent, Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { Config } from '../Config/Config';
import ReactGA from 'react-ga4';
import { useTrans } from '../Store/System/LocalizationStore';
import { Row, Col } from 'reactstrap';
import styled from '@emotion/styled';

const CookiesContainer = styled.div`
  width: 100%;
  border-radius: 0.3rem;
  align-self: center;

  @media (min-width: 1280px) {
    max-width: 90%;
  }

  @media (min-width: 1600px) {
    max-width: 80%;
  }

  @media (min-width: 1768px) {
    max-width: 70%;
  }

  @media (min-width: 2300px) {
    max-width: 50%;
  }
`;

const ContentRow = styled(Row)`
  padding: 1.8rem 0.5em 1.8rem 0;

  @media (min-width: 1080px) {
    padding: 0.2rem 0.2rem 0.2rem 0;
  }
  @media (min-width: 2300px) {
    padding: 1rem 0.5em 1rem 0;
  }
`;

const cookieContainerStyle: React.CSSProperties = {
  backgroundColor: '#3C3C3C',
  color: '#ffffff',
  display: 'flex',
  borderRadius: '0.3rem',
  fontSize: '0.8rem',
  marginTop: '1rem',
  paddingLeft: '0.2rem',
};

const acceptButtonStyle: React.CSSProperties = {
  backgroundColor: '#ffffff',
  color: '#000000',
  fontSize: '0.8rem',
  fontWeight: 700,
  width: '100%',
  borderWidth: 0,
  borderBottomRightRadius: '0.3rem',
  height: '50%',
};

const declineButtonStyle: React.CSSProperties = {
  backgroundColor: '#616161',
  color: '#ffffff',
  fontSize: '0.8rem',
  fontWeight: 700,
  width: '100%',
  borderWidth: 0,
  borderTopRightRadius: '0.3rem',
  height: '50%',
};

export type CookieConsentBannerProps = {};

export const CookieConsentBanner: React.FC<CookieConsentBannerProps> = observer((props) => {
  const { t } = useTrans();

  const handleAcceptCookie = React.useCallback(() => {
    ReactGA.initialize(Config.googleAnalytics.analyticsId);
  }, []);

  const handleDeclineCookie = React.useCallback(() => {
    //remove google analytics cookies
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  }, []);

  useEffect(() => {
    const cookiesAccepted = getCookieConsentValue();
    if (cookiesAccepted === 'true') {
      handleAcceptCookie();
    }
  }, [handleAcceptCookie]);

  return (
    <CookiesContainer>
      <CookieConsent
        enableDeclineButton
        disableStyles={true}
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText={t('cookieConsent.acceptButton', { defaultValue: 'Accept' }).toUpperCase()}
        declineButtonText={t('cookieConsent.declineButton', { defaultValue: 'Decline' }).toUpperCase()}
        buttonStyle={acceptButtonStyle}
        declineButtonStyle={declineButtonStyle}
        style={cookieContainerStyle}
      >
        <ContentRow>
          <Col xs={2}>
            <img
              src={require('../Assets/Images/cookies.png')}
              alt="cookie"
              style={{ width: '2.5rem', height: '2.5rem' }}
            />
          </Col>
          <Col xs={10}>
            {t('cookieConsent.text', { defaultValue: 'We use cookies to personalise your experience.' })}
          </Col>
        </ContentRow>
      </CookieConsent>
    </CookiesContainer>
  );
});
