import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import { UpcomingCarouselItem, UpcomingCarouselItemProps } from './UpcomingCarouselItem';
import { ChangeButtons } from './ChangeButtons';
import { observer } from 'mobx-react';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';

const Container = styled.div`
  margin-bottom: 16px;
`;

const CarouselContainer = styled(Carousel)`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export type UpcomingCarouselProps = Pick<UpcomingCarouselItemProps, 'linkPrefix'> & {
  upcoming: SubscribableEntry[];
};

export const UpcomingWebinarCarousel: React.FC<UpcomingCarouselProps> = observer(({ upcoming, linkPrefix }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === upcoming.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? upcoming.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <Container>
      <CarouselContainer activeIndex={activeIndex} next={next} previous={previous} dark={true}>
        {upcoming.map((next) => (
          <CarouselItem
            key={next.id}
            entry={next}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >
            <UpcomingCarouselItem entry={next} onNext={next as any} onPrevious={previous} linkPrefix={linkPrefix} />
          </CarouselItem>
        ))}
      </CarouselContainer>
      {/*<ChangeButtons className="d-block d-sm-none" onPrevious={previous} onNext={next} />*/}
      <ChangeButtons onPrevious={previous} onNext={next} />
    </Container>
  );
});
