/**
 * Created by neo on 28.08.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';

const languageMap = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiano',
  pt: 'Português',
  nl: 'Nederlands',
  sv: 'Svenska',
  da: 'Dansk',
  fi: 'Suomi',
  no: 'Norsk',
  pl: 'Polski',
  cs: 'Čeština',
  ro: 'Română',
  hu: 'Magyar',
  el: 'Ελληνικά',
  bg: 'Български',
  hr: 'Hrvatski',
  lt: 'Lietuvių',
  lv: 'Latviešu',
  et: 'Eesti',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
};

export type VideoLanguageSelectorProps = {
  availableLanguages?: string[];
  selectedLanguage?: string;
  onChangeLanguage?: (language: string) => void;
};

export const VideoLanguageSelector: React.FC<VideoLanguageSelectorProps> = observer(
  ({ availableLanguages = [], selectedLanguage, onChangeLanguage }) => {
    const { t } = useTrans();
    if (availableLanguages.length < 1) {
      return null; // Don't render anything if there are no available languages
    }

    const handleLanguageChange = React.useCallback(
      (event) => {
        onChangeLanguage?.(event.target.value);
      },
      [onChangeLanguage],
    );

    return (
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '5px',
          borderRadius: '5px',
        }}
      >
        <select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          style={{
            backgroundColor: 'white',
            color: 'black',
            border: 'none',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          {availableLanguages.map((lang, index) => (
            <option key={index} value={lang}>
              {languageMap[lang] || t(`globals.language.${lang}`, { defaultValue: lang })}
            </option>
          ))}
        </select>
      </div>
    );
  },
);
