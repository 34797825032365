import { FeedActivity } from './FeedActivity';
import { GetStreamFeed } from './GetStreamFeed';

export class GetStreamUpdate {
  constructor(readonly deleted: string[], readonly newItems: FeedActivity[]) {}

  static fromJson(feed: GetStreamFeed, json?: any): GetStreamUpdate {
    const newItems = (json.new || []).map((u: any) => new FeedActivity(feed, u));
    return new GetStreamUpdate(json.deleted || [], newItems);
  }
}
