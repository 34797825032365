import { FeedObjectCollectionItemData } from './FeedObjectCollectionItemData';

export class FeedCollectionItem {
  id: string = '';
  collection: string = '';
  foreign_id: string = '';
  updated_at: Date = new Date();
  created_at: Date = new Date();
  data: FeedObjectCollectionItemData = new FeedObjectCollectionItemData();

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.collection = json.collection || 'article';
      this.foreign_id = json.foreign_id || '';
      this.updated_at = json.updated_at || new Date();
      this.created_at = json.created_at || new Date();
      this.data = new FeedObjectCollectionItemData(json.data || {});
    }
  }
}
