/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { computed, observable, onBecomeObserved, onBecomeUnobserved, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { Activity } from '../Activity/Activity';
import { SubscribableEntry, SubscribableEntryJson } from './SubscribableEntry';
import { ExploreEntryUpcomingQueryRequest } from './ExploreEntryUpcomingQueryRequest';
import dayjs from 'dayjs';
import { OnlineMeetingEntryEmbedData, OnlineMeetingEntryEmbedDataJson } from './OnlineMeetingEntryEmbedData';

export type OnlineMeetingEntryJson = SubscribableEntryJson & {
  meetingLink: string;
  language?: string;
  /**
   * optional -> if set it means we will log an activity log
   */
  activityIdentifier?: string;
  data: Record<string, any>;
  embedData?: OnlineMeetingEntryEmbedDataJson;
};

export class OnlineMeetingEntry extends SubscribableEntry {
  @observable
  meetingLink = '';
  @observable
  language?: string;
  @observable
  activityIdentifier?: string;
  @observable
  data: Record<string, any> = {};
  @observable
  embedData?: OnlineMeetingEntryEmbedData;
  @observable
  activity?: Activity;
  private canJoinInterval?: ReturnType<typeof setInterval>;

  constructor(json?: Partial<OnlineMeetingEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'onlineMeeting' }));
    if (json) {
      this.type = 'onlineMeeting';
      this.meetingLink = json.meetingLink ?? '';
      this.language = json.language;
      this.activityIdentifier = json.activityIdentifier;
      this.data = json.data ?? {};
      this.embedData = json.embedData ? new OnlineMeetingEntryEmbedData(json.embedData) : undefined;
    }

    onBecomeObserved(this, 'activity', this.fetchActivity);
    onBecomeObserved(this, 'canJoinMeeting', () => this.startPollingCanJoin());
    onBecomeUnobserved(this, 'canJoinMeeting', () => this.stopPollingCanJoin());
  }

  fetchActivity = () => {
    if (!this.activity) {
      if (this.activityIdentifier) {
        return Activity.get(this.activityIdentifier).then((result) => {
          runInAction(() => (this.activity = result));
          return result;
        });
      }
    }
    return Promise.resolve(this.activity);
  };

  private startPollingCanJoin() {
    if (!this.canJoinInterval) {
      // let's run once it since setInterval has a delay
      runInAction(() => (this.canJoinMeeting = this.canAlreadyJoinNextDate));
      this.canJoinInterval = setInterval(() => {
        runInAction(() => (this.canJoinMeeting = this.canAlreadyJoinNextDate));
      }, 1000);
    }
  }

  private stopPollingCanJoin() {
    this.canJoinInterval && clearInterval(this.canJoinInterval);
    this.canJoinInterval = undefined;
  }

  toJS(): OnlineMeetingEntryJson {
    return Object.assign(super.toJS(), {
      meetingLink: this.meetingLink,
      language: this.language,
      activityIdentifier: this.activityIdentifier,
      data: toJS(this.data),
      embedData: this.embedData?.toJS(),
    });
  }

  @computed
  get canAlreadyJoinNextDate(): boolean {
    const nextMeetingDate = this.nextDate;
    const nextMeetingEndDate = nextMeetingDate?.add(dayjs.duration(this.duration));
    return nextMeetingDate && nextMeetingEndDate
      ? !dayjs().isBefore(nextMeetingDate.subtract(60, 'minutes')) &&
          !dayjs().isAfter(nextMeetingEndDate.add(15, 'minutes'))
      : false;
  }

  @computed
  get isNotVimeoLink(): boolean {
    return !this.meetingLink.startsWith('https://vimeo.com/');
  }

  static upcoming(request?: Omit<ExploreEntryUpcomingQueryRequest, 'type'>): Promise<OnlineMeetingEntry[]> {
    return HttpBackend.get(
      `/coach/explore/upcoming`,
      Object.assign(toJS(request ?? {}), { type: 'onlineMeeting' }),
    ).then((res) => (res ?? []).map((r) => new OnlineMeetingEntry(r)));
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<OnlineMeetingEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'onlineMeeting' })).then((res) =>
      (res ?? []).map((r) => new OnlineMeetingEntry(r)),
    );
  }

  static async findOne(id: string): Promise<OnlineMeetingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new OnlineMeetingEntry(res));
  }
}
