/**
 * Created by katarinababic on 4.11.24.
 */
import * as React from 'react';
import {observer} from 'mobx-react';
import {ExploreCategoryRenderer, ExploreCategoryRendererProps} from "./ExploreCategoryRenderer";
import {ExploreCategoryGroup} from "./ExploreCategoryGroup";

export type ExploreCategorySwitchProps = ExploreCategoryRendererProps & {};

export const ExploreCategorySwitch: React.FC<ExploreCategorySwitchProps> = observer(({ category, isLast, linkPrefix}) => {
  if (category.type === 'category_group') {
    return <ExploreCategoryGroup category={category} />;
  }
  return <ExploreCategoryRenderer category={category} isLast={isLast} linkPrefix={linkPrefix} />
});
