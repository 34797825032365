import { action, computed, observable } from 'mobx';
import dayjs from '../../Utils/dayjs';

export type MediaLoopJson = {
  tag?: string;
  startTime: string;
  endTime: string;
  loopCount: number;
  dynamicLoopCount: boolean;
};

export class MediaLoop {
  @observable
  startTime = '00:00:00.000';
  @observable
  endTime = '00:00:00.000';
  @observable
  tag?: string;
  @observable
  loopCount = -1;
  @observable
  dynamicLoopCount = false;

  constructor(json?: Partial<MediaLoopJson>) {
    if (json) {
      this.startTime = json.startTime ?? '00:00:00.000';
      this.endTime = json.endTime ?? '00:00:00.000';
      this.tag = json.tag;
      this.loopCount = json.loopCount ?? -1;
      this.dynamicLoopCount = json.dynamicLoopCount ?? false;
    }
  }

  @action
  setStartSeconds(seconds: number) {
    this.startTime = MediaLoop.calculateTime(seconds);
  }

  @action
  setEndSeconds(seconds: number) {
    this.endTime = MediaLoop.calculateTime(seconds);
  }

  toJS() {
    return {
      startTime: this.startTime,
      endTime: this.endTime,
      tag: this.tag,
      loopCount: this.loopCount,
      dynamicLoopCount: this.dynamicLoopCount,
    };
  }

  @computed
  get startSeconds(): number {
    const split = this.startTime.split(':').reverse();
    const seconds = split.length > 0 ? Number(split[0]) : 0;
    const minutes = split.length > 1 ? Number(split[1]) : 0;
    const hours = split.length > 2 ? Number(split[2]) : 0;
    return hours * 3600 + minutes * 60 + seconds;
  }

  @computed
  get endSeconds(): number | undefined {
    if (this.endTime) {
      const split = this.endTime.split(':').reverse();
      const seconds = split.length > 0 ? Number(split[0]) : 0;
      const minutes = split.length > 1 ? Number(split[1]) : 0;
      const hours = split.length > 2 ? Number(split[2]) : 0;
      return hours * 3600 + minutes * 60 + seconds;
    }
    return undefined;
  }

  static calculateTime(videoSeconds: number) {
    const duration = dayjs.duration(videoSeconds, 'seconds');
    const hours = duration.hours(); // Math.trunc(videoSeconds / 3600);
    const minutes = duration.minutes(); // Math.trunc((videoSeconds - hours * 3600) / 60);
    const seconds = duration.seconds(); // Math.trunc(videoSeconds - hours * 3600 - minutes * 60);
    const milliseconds = duration.milliseconds().toFixed(0); // ((videoSeconds - hours * 3600 - minutes * 60 - seconds) * 1000).toFixed(0);
    return `${hours.toString(10).padStart(2, '0')}:${minutes.toString(10).padStart(2, '0')}:${seconds
      .toString(10)
      .padStart(2, '0')}.${milliseconds.padStart(3, '0')}`;
  }
}
