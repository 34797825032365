import { observable, action, computed } from 'mobx';
import { logger } from '../../Utils/logger';

export type OnProgressData = { played: number; playedSeconds: number; loaded: number; loadedSeconds: number };
export type LoadError = any;

export class VideoPlayerStore {
  @observable paused: boolean = false;
  @observable muted: boolean = false;
  @observable showOnStart: boolean = true;
  @observable currentTime: number = 0;
  @observable duration: number = 0;
  @observable seeking: boolean = false;
  @observable loading: boolean = true;
  @observable error: boolean = false;
  @observable iconOffset: number = 0;

  /** actions **/

  /**
   * When load starts we display a loading icon
   * and show the controls.
   */
  @action
  onLoadStart() {
    this.loading = true;
  }

  /**
   * When load is finished we hide the load icon
   * and hide the controls. We also set the
   * video duration.
   * @param duration data The video meta data
   */
  @action
  onDuration(duration: number) {
    this.duration = duration;
  }

  /**
   * For onprogress we fire listeners that
   * update our SeekBar and timer.
   * @param {object} data The video meta data
   */
  @action
  handleProgress(data: OnProgressData) {
    this.currentTime = data.playedSeconds;
  }

  /**
   * Set the error state to true which then
   * changes our renderError function
   * @param {object} err  Err obj returned from <Video> component
   */
  @action
  onError(err: LoadError) {
    logger('VideoPlayer::error', err);
    this.loading = false;
  }

  @action
  setCurrentTime(time: number) {
    this.currentTime = time;
  }

  @computed
  get seekPercentage(): number {
    return this.currentTime / this.duration;
  }
}
