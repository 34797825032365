/**
 * Created by neo on 08.02.17.
 */
import { observable } from 'mobx';
import { MediaInfo } from './MediaInfo';

export type MediaSizeJson = {
  url: string;
  size?: string;
  mediaInfo: MediaInfo;
};

export class MediaSize {
  @observable
  url: string = '';
  @observable
  size?: string;
  @observable
  mediaInfo: MediaInfo = {};

  constructor(json?: Partial<MediaSizeJson>) {
    if (json) {
      this.url = json.url ?? '';
      this.size = json.size;
      this.mediaInfo = json.mediaInfo ?? {};
    }
  }

  toJS(): MediaSizeJson {
    return {
      url: this.url,
      size: this.size,
      mediaInfo: this.mediaInfo,
    };
  }
}
