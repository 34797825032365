/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRootStore } from '../../../Store/useRootStore';
import { AuthLayout } from '../../../Components/Auth/AuthLayout/AuthLayout';
import { Button } from '../../../Components/Button';
import { TextWithMiddleLine } from '../../../Components/TextWithMiddleLine';
import { ErrorAlert } from '../../../Components/Auth/Alerts/ErrorAlert';
import { RegisterBottomButton } from '../../../Components/Auth/Buttons/RegisterBottomButton';

export type LoginCheckInboxViewProps = {
  authCode: string;
  onBack?: () => void;
};

export const LoginVerifyAuthenticationView: React.FC<LoginCheckInboxViewProps> = observer(({ authCode, onBack }) => {
  const { t } = useTrans();
  const { authentication, gymCustomer, coachSubscription, analytics } = useRootStore();

  const location = useLocation();
  const navigate = useNavigate();

  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [processing, setProcessing] = useState(false);

  const _processing = React.useRef(false);

  useEffect(() => {
    if (authCode && !_processing.current) {
      _processing.current = true;
      setProcessing(true);
      authentication
        .otpLogin(authCode)
        .catch((e) => {
          analytics.logError('login_verification', e.response?.data?.errorCode);
          setErrorCode(e.response?.data?.errorCode);
          setShowErrorAlert(true);
        })
        .then(() => coachSubscription.fetchSubscription())
        .then(() => {
          analytics.logLogin('magicLink');
          const redirect = new URLSearchParams(location.search).get('redirect');
          if (redirect) {
            navigate(redirect as string, { replace: true });
          } else {
            navigate('/', { replace: true });
          }
        })
        .finally(() => setProcessing(false))
        .finally(() => (_processing.current = false));
    }
  }, [analytics, authCode, authentication, coachSubscription, location, navigate]);

  const handleCloseAlert = React.useCallback(() => {
    setShowErrorAlert(false);
  }, []);

  return (
    <AuthLayout
      title={t('loginScreen.verification.title', { defaultValue: 'Hold on a second!' })}
      text={t('loginScreen.verification.text', {
        defaultValue: 'We are logging you in!',
      })}
      overlayRightTitle={t('registration.stepOne.introTitle', { defaultValue: `Hello\nHealthy\nLife` })}
      image={require('../../../Assets/Images/auth_5.png')}
    >
      <Button block={true} onClick={onBack} disabled={processing}>
        {t('globals.back', { defaultValue: 'Back' }).toUpperCase()}
      </Button>
      <TextWithMiddleLine text={t('globals.or', { defaultValue: 'Or' })} />
      <RegisterBottomButton />
      <ErrorAlert
        toggle={handleCloseAlert}
        isOpen={showErrorAlert}
        title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
        onDismiss={handleCloseAlert}
      />
    </AuthLayout>
  );
});
