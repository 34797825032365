/**
 * Created by katarinababic on 4.11.24.
 */
import * as React from 'react';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import {ExploreCategory} from "../../Model/Explore/ExploreCategory";
import {ExploreCategoryRenderer} from "./ExploreCategoryRenderer";

export type ExploreCategoryGroupProps = {
    category: ExploreCategory;
}

export const ExploreCategoryGroup: React.FC<ExploreCategoryGroupProps> = observer(({ category}) => {
    const [subCategories, setSubCategories] = React.useState<ExploreCategory[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        ExploreCategory.find({ parentId: category.identifier })
            .then((result) => setSubCategories(result))
            .finally(() => {
                setLoading(false);
            });
    }, [category]);

    return (
        <React.Fragment>
            {subCategories.map((subCategory) => (
                <ExploreCategoryRenderer key={subCategory.id} category={subCategory}  />
            ))}
        </React.Fragment>
    );
});
