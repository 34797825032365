import { computed, observable } from 'mobx';
import { LocalizedValue } from '../../../LocalizedValue';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { LocalizedEntity, LocalizedEntityJson } from '../../../LocalizedEntity';
import { Media } from '../../../Media/Media';
import { CoachWorkoutPhase, CoachWorkoutPhaseJson } from './CoachWorkoutPhase';
import { CoachIntervalPhase } from './CoachIntervalPhase';
import { CoachExerciseBlock } from './CoachExerciseBlock';
import dayjs from '../../../../Utils/dayjs';
import { BaseWorkoutJson, Workout, WorkoutJson } from '../../../ProgramPortfolio/Workout';
import { PipelineContext } from '../../PipelineContext';

export type CoachWorkoutJson = Omit<BaseWorkoutJson, 'phases'> &
  LocalizedEntityJson & {
    scriptId: string;
    phases: CoachWorkoutPhaseJson[];
    intensity: string;
  };

export class CoachWorkout extends LocalizedEntity {
  @observable scriptId: string = '';
  @observable type: string = 'gym_strength';
  @observable.shallow phases: CoachWorkoutPhase[] = [];
  @observable image?: Media = undefined;
  @observable
  intensity = 'medium';

  constructor(json?: Partial<CoachWorkoutJson>) {
    super(json);
    if (json) {
      this.scriptId = json.scriptId ?? '';
      this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
      this.description = (json.description ?? []).map((l) => new LocalizedValue(l));
      this.type = json.type ?? 'gym_strength';
      this.image = json.image ? new Media(json.image) : undefined;
      this.intensity = json.intensity ?? 'medium';
      this.phases = (json.phases || []).map((p: any) => this.createPhase(p));
    }
  }

  toJS(newId: boolean = false, excludedExerciseBlockIds: string[] = []): CoachWorkoutJson {
    return Object.assign(super.toJS(newId), {
      scriptId: this.scriptId,
      type: this.type,
      intensity: this.intensity,
      phases: this.phases.map((p) => p.toJS(newId, excludedExerciseBlockIds)),
      image: this.image ? this.image.toJS() : undefined,
    });
  }

  toWorkoutJS(newId?: boolean, lang?: string): WorkoutJson {
    return Object.assign(super.toDefaultJS(), {
      type: this.type,
      name: this.getName(lang || 'en'),
      phases: this.phases.map((p) => p.toWorkoutJS()),
      image: this.image ? this.image.toJS() : undefined,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      executedCount: 0,
      lastExecutionTimestamp: 0,
    });
  }

  protected createPhase(phase: any): CoachWorkoutPhase {
    if (phase.type === 'interval') {
      return new CoachIntervalPhase(this, phase);
    }
    return new CoachWorkoutPhase(this, phase);
  }

  calculateCalories(bmr24: number): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.calculateCalories(bmr24), 0);
  }

  @computed
  get workoutImage(): any {
    const mediaUrl = this.image ? this.image.largeOrMediumOrSmallest : null;
    return mediaUrl ? { uri: mediaUrl } : this.defaultImage;
  }

  @computed
  get smallestWorkoutImage(): any {
    const mediaUrl = this.image ? this.image.smallest : null;
    return mediaUrl ? { uri: mediaUrl } : this.defaultImage;
  }

  @computed
  get mediumWorkoutImage(): any {
    const mediaUrl = this.image ? this.image.mediumOrSmallest : null;
    return mediaUrl ? { uri: mediaUrl } : this.defaultImage;
  }

  @computed
  get largeWorkoutImage(): any {
    const mediaUrl = this.image ? this.image.largeOrMediumOrSmallest : null;
    return mediaUrl ? { uri: mediaUrl } : this.defaultImage;
  }

  @computed
  get defaultImage(): any {
    return undefined;
  }

  @computed
  get totalExercises(): number {
    return this.phases.reduce((total, phase: CoachWorkoutPhase) => total + phase.totalExercises, 0);
  }

  @computed
  get duration(): number {
    return this.phases.reduce((total, phase: CoachWorkoutPhase) => total + phase.duration, 0);
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationMinutes(): number {
    return Math.round(dayjs.duration(this.duration).asMinutes());
  }

  @computed
  get breakTime(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.totalTimeExercising, 0);
  }

  @computed
  get isNew(): boolean {
    return !this.id || this.id.length <= 0;
  }

  @computed
  get hasExercises() {
    return this.totalExercises > 0;
  }

  @computed
  get hasPhases(): boolean {
    return this.phases.length > 0;
  }

  @computed
  get totalSets(): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.totalSets, 0);
  }

  @computed
  get tons(): number {
    return this.phases.reduce((total: number, p: CoachWorkoutPhase) => total + p.tons, 0);
  }

  @computed
  get exerciseBlocks(): CoachExerciseBlock[] {
    return this.phases.reduce(
      (arr: CoachExerciseBlock[], phase: CoachWorkoutPhase) => arr.concat(phase.exerciseBlocks.slice()),
      [],
    );
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.phases.reduce((total: number, phase: CoachWorkoutPhase) => total + phase.estimatedTotalExerciseTime, 0);
  }

  @computed
  get equipmentTypes(): string[] {
    return [
      ...new Set<string>(
        this.exerciseBlocks.reduce(
          (arr, viewBlock) => arr.concat(viewBlock.exercise.equipmentTypes.slice()),
          [] as string[],
        ),
      ),
    ];
  }

  @computed
  get isBodyWeight(): boolean {
    const { equipmentTypes } = this;
    if (equipmentTypes.length <= 2) {
      return equipmentTypes.every((e) => e === 'FREE' || e === 'FREE_OUTDOOR');
    }
    return false;
  }

  save(excludedExerciseBlockIds: string[] = []): Promise<CoachWorkout> {
    return HttpBackend.post('/coach/workout', this.toJS(false, excludedExerciseBlockIds)).then(() => this);
  }

  static get(id: string): Promise<CoachWorkout> {
    return HttpBackend.get(`/coach/workout/${id}`).then((result) => new CoachWorkout(result));
  }

  static fromWorkout(workout: Workout | WorkoutJson): CoachWorkout {
    const workoutJson = workout instanceof Workout ? workout.toJS() : workout;
    const json: CoachWorkoutJson = Object.assign(workoutJson, {
      scriptId: '',
      name: [{ lang: 'de', value: workoutJson.name }],
      description: [{ lang: 'de', value: workoutJson.name }],
      intensity: 'medium',
      phases: workoutJson.phases.map(
        (p) =>
          Object.assign(p, {
            scriptId: '',
            name: [{ lang: 'de', value: p.name }],
            description: [{ lang: 'de', value: p.description }],
            exerciseBlocks: p.exerciseBlocks.map((b) =>
              Object.assign(b, {
                sets: b.sets.map((s) => Object.assign(s, { previousValues: {} })),
                description: [{ lang: 'de', value: b.description }],
              }),
            ),
          }) as CoachWorkoutPhaseJson,
      ),
    });
    return new CoachWorkout(json);
  }

  static generate(workoutTemplateId: String, context?: PipelineContext): Promise<CoachWorkout> {
    return HttpBackend.post(`/coach/workout/generate`, { workoutTemplateId, context: context?.toJS() }).then(
      (result) => new CoachWorkout(result),
    );
  }
}
