/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';

const Date = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  min-width: 100px;
`;

const Month = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const Day = styled.div`
  font-size: 24px;
`;

export type DateTagProps = {
  day?: string;
  month?: string;
  date?: string;
};

export const DateTag: React.FC<DateTagProps> = observer(({ month, date, day }) => {
  return (
    <Date style={{ marginTop: 16 }}>
      <Month>{day}</Month>
      <Day>{date}</Day>
      <Month>{month}</Month>
    </Date>
  );
});
