import styled from '@emotion/styled';
import React from 'react';
import PlayStore from '../../../Assets/Images/play-store.png';
import { GOOGLE_DOWNLOAD_LINK } from '../../../Utils/Constants';

const ButtonLogo = styled.img`
  width: 94px;
  height: 34px;
`;

export const GoogleDownloadButton: React.FC = () => {
  return (
    <a href={GOOGLE_DOWNLOAD_LINK}>
      <ButtonLogo src={PlayStore} alt="play-store-logo" />
    </a>
  );
};
