/**
 * Created by neo on 12.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';

export type DefaultExploreEntryJson = ExploreEntryJson & {};

export class DefaultExploreEntry extends ExploreEntry {
  constructor(json?: Partial<DefaultExploreEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'default' }));
    if (json) {
    }
  }
}
