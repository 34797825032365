/**
 * Created by andreaskarantzas on 15.05.18.
 */
import { action, observable, ObservableMap, reaction, runInAction, toJS } from 'mobx';
import { DisposableStore } from '../../Store/DisposableStore';
import { AthleteConfiguration } from '../../Model/Athlete/AthleteConfiguration';
import { FirebaseAuthStore } from './FirebaseAuthStore';
import { FirebaseDatabase } from '../../Store/Firebase/firebase';
import { ref, set, update, onValue } from 'firebase/database';

/**
 * Used for user specific settings (device independent) -> Global just for users.
 * e.g. language settings
 */
export class RemoteSettingsStore extends DisposableStore {
  @observable settings: ObservableMap<string> = observable.map({});
  @observable loaded: boolean = false;
  @observable
  config?: AthleteConfiguration;
  readonly database = FirebaseDatabase;

  constructor(readonly firebaseAuth: FirebaseAuthStore) {
    super();

    this.disposers.push(
      reaction(
        () => firebaseAuth.user?.uid,
        (uid) => {
          if (uid) {
            this.loadSettings(uid);
          } else {
            this.clear();
          }
        },
        { fireImmediately: true, name: 'Firebase get app preferences' },
      ),
      reaction(
        () => this.loaded,
        (loaded) => {
          if (loaded && this.firebaseAuth.authentication.athleteId) {
            this.saveSetting('athleteId', this.firebaseAuth.authentication.athleteId);
          }
        },
        { fireImmediately: true, name: 'Store athleteId in appSettings', delay: 250 },
      ),
    );
  }

  /**
   * Clears only local data no delete
   */
  @action
  clear() {
    this.settings = observable.map({});
    this.loaded = false;
  }

  @action
  loadSettings(uid: string) {
    return AthleteConfiguration.get()
      .then((config) => {
        runInAction(() => (this.config = config));
        return config;
      })
      .then((config) =>
        this.loadFirebaseSettings(uid).then((settings) => {
          Array.from(settings.entries()).forEach(([key, value]) => {
            if (!config.data[key]) {
              config.data[key] = value;
            }
          });
          return config;
        }),
      )
      .then(() => runInAction(() => (this.loaded = true)));
  }

  private loadFirebaseSettings(uid: string): Promise<ObservableMap<string>> {
    return new Promise((resolve) => {
      onValue(ref(this.database, `appSettings/${uid}`), (snapshot) => {
        runInAction(() => {
          if (snapshot) {
            snapshot.forEach((child) => {
              this.settings.set(child.key || '', child.val());
            });
          }
        });
        resolve(this.settings);
      });
    });
  }

  @action
  remove(key: string) {
    this.settings.delete(key);
    if (this.firebaseAuth.user?.uid) {
      if (this.config) {
        delete this.config.data[key];
      }
      return Promise.all([
        update(ref(this.database, `appSettings/${this.firebaseAuth.user.uid}`), { [key]: '' }),
        this.config?.save() ?? Promise.resolve(new AthleteConfiguration()),
      ]);
    }
    return Promise.resolve();
  }

  @action
  saveSetting(key: string, value: unknown) {
    this.settings.set(key, value);
    if (this.firebaseAuth.user?.uid) {
      if (this.config) {
        this.config.data[key] = value;
      }
      return Promise.all([
        update(ref(this.database, `appSettings/${this.firebaseAuth.user.uid}`), { [key]: toJS(value) }),
        this.config?.save() ?? Promise.resolve(new AthleteConfiguration()),
      ]).then(([_, config]) => config);
    }
    return Promise.resolve(new AthleteConfiguration());
  }

  @action
  setMany(keyValue: Record<string, unknown>) {
    if (this.firebaseAuth.user?.uid) {
      Array.from(Object.entries(keyValue)).forEach(([key, value]) => {
        this.settings.set(key, value);
        if (this.config) {
          this.config.data[key] = value;
        }
      });
      return Promise.all([
        update(ref(this.database, `appSettings/${this.firebaseAuth.user.uid}`), keyValue),
        this.config?.save() ?? Promise.resolve(new AthleteConfiguration()),
      ]).then(([_, config]) => config);
    }
    return Promise.resolve(new AthleteConfiguration());
  }

  @action
  set(key: string, value: any) {
    return this.saveSetting(key, value);
  }

  get(key: string, defaultValue?: any): any {
    return this.settings.get(key) ?? defaultValue;
  }

  async delete(): Promise<any> {
    return Promise.resolve();
  }
}
