import styled from '@emotion/styled';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { observer } from 'mobx-react';

const CheckboxElement = styled(Input)`
  width: 24px;
  height: 24px;
  background: transparent;
  border: 1px solid white;
  margin-right: 8px;

  ${({ invalid }) => (invalid ? 'border-color: red;' : '')}

  &.is-invalid {
    border-color: red;
  }

  &.form-check-input:checked {
    background-color: #00dda3;
    border-color: #00dda3;
  }

  &.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
`;

const CheckboxLabel = styled(Label)`
  display: flex;
  color: white;
  ${({ invalid }) => (invalid ? 'color: red !important;' : '')};
  font-size: 0.7rem;
  font-weight: 700;
`;

export type CheckboxProps = {
  onToggle?: () => void;
  checked?: boolean;
  text: string | React.ReactNode;
  invalid?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = observer(({ onToggle, checked, invalid, text }) => {
  return (
    <FormGroup check={true}>
      <CheckboxElement type="checkbox" checked={checked} onChange={onToggle} invalid={invalid} defaultChecked={false} />{' '}
      <CheckboxLabel check={true} onClick={onToggle} invalid={invalid}>
        {text}
      </CheckboxLabel>
    </FormGroup>
  );
});
