import { action, observable, toJS } from 'mobx';
import { TagCondition, TagConditionJson } from './TagCondition';

export type ParamConditionJson = {
  tagCondition: TagConditionJson;
  params: {
    [key: string]: any;
  };
};

export class ParamCondition {
  @observable
  tagCondition: TagCondition = new TagCondition();
  @observable
  params: {
    [key: string]: any;
  } = {};

  constructor(json?: Partial<ParamConditionJson>) {
    if (json) {
      this.tagCondition = new TagCondition(json.tagCondition);
      this.params = json.params ?? {};
    }
  }

  toJS(): ParamConditionJson {
    return {
      tagCondition: this.tagCondition.toJS(),
      params: toJS(this.params),
    };
  }

  copy() {
    return new ParamCondition(this.toJS());
  }

  @action
  setParam(paramName: string, value?: any) {
    this.params = this.params ?? {};
    this.params[paramName] = value;
  }

  @action
  removeParam(paramName: string) {
    this.params = this.params ?? {};
    delete this.params[paramName];
  }
}
