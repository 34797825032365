import { FeedActorData } from './FeedActorData';

export class FeedActivityActor {
  id: string = '';
  data: FeedActorData = {};
  created_at: Date = new Date();
  updated_at: Date = new Date();

  constructor(json?: any) {
    if (json) {
      this.id = json.id || '';
      this.data = json.data || {};
      this.created_at = json.created_at ? new Date(json.created_at) : new Date();
      this.updated_at = json.updated_at ? new Date(json.updated_at) : new Date();
    }
  }
}
