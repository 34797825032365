/**
 * Created by neo on 14.12.22.
 */
import { computed, observable, toJS } from 'mobx';
import { EMAIL_REGEX } from '../../Utils/Constants';
import AppBackend from '../../Services/Http/AppBackend';
import __DEV__, { __BETA__ } from '../../Utils/Dev';

export type RequestCodeRequestJson = {
  gymId?: string | null;
  email: string;
  language: string;
  firstname?: string;
  numeric?: boolean;
};

export class RequestCodeRequest {
  @observable
  gymId?: string | null;
  @observable
  email = '';
  @observable
  language = 'de';
  @observable
  firstname?: string;
  @observable
  numeric = true;

  constructor(json?: Partial<RequestCodeRequestJson>) {
    if (json) {
      this.gymId = json.gymId;
      this.firstname = json.firstname;
      this.email = json.email ?? '';
      this.language = json.language ?? 'de';
      this.numeric = json.numeric ?? true;
    }
  }

  toJS(): RequestCodeRequestJson {
    return {
      gymId: this.gymId,
      email: this.email,
      language: this.language,
      firstname: this.firstname,
      numeric: this.numeric,
    };
  }

  requestCode(): Promise<any> {
    return AppBackend.post(
      `/coach/registration/request`,
      Object.assign(this.toJS(), {
        callbackUrl: this.callbackUrl(),
      }),
    );
  }

  private callbackUrl(): string {
    if (__DEV__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fregistration%2Ffinal&apn=app.flinq.athlete&ibi=app.flinq.athlete&efr=1&afl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fregistration%2Ffinal&ifl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fregistration%2Ffinal&isi=1465156373';
    } else if (__BETA__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fbeta.coach.kinastic.com%2Fregistration%2Ffinal&apn=app.flinq.athlete&ibi=app.flinq.athlete&efr=1&afl=https%3A%2F%2Fbeta.coach.kinastic.com%2Fregistration%2Ffinal&ifl=https%3A%2F%2Fbeta.coach.kinastic.com%2Fregistration%2Ffinal&isi=1465156373';
    }
    return 'https://open.kinastic.com/?link=https%3A%2F%2Fcoach.kinastic.com%2Fregistration%2Ffinal&apn=app.flinq.athlete&ibi=app.flinq.athlete&efr=1&afl=https%3A%2F%2Fcoach.kinastic.com%2Fregistration%2Ffinal&ifl=https%3A%2F%2Fcoach.kinastic.com%2Fregistration%2Ffinal&isi=1465156373';
  }

  @computed
  get nameValid(): boolean {
    return (this.firstname?.trim().length ?? 0) > 0;
  }

  @computed
  get emailValid(): boolean {
    if (this.email.trim().length > 0) {
      return EMAIL_REGEX.test(this.email.trim());
    }
    return false;
  }

  @computed
  get valid(): boolean {
    return this.emailValid && this.nameValid;
  }

  @computed
  get invalid(): boolean {
    return !this.valid;
  }
}
