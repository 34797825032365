/**
 * Created by katarinababic on 25.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Input } from '../../Input';
import { Container, FormGroup } from 'reactstrap';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Button } from '../../Button';
import { TextWithMiddleLine } from '../../TextWithMiddleLine';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { LoginBottomButton } from '../Buttons/LoginBottomButton';
import { RegisterBottomButton } from '../Buttons/RegisterBottomButton';
import { SixDigitCodeInput, SixDigitCodeInputProps } from '../SixDigitCodeInput/SixDigitCodeInput';

const FormContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const ResendEmailContainer = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 5.5rem;
`;

const TouchableText = styled.span<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#6E6E6E' : '#e5842b')};
  font-size: 0.8rem;
  font-weight: 700;
  text-decoration-color: #e5842b;
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

const resendEmailSpanStyle: React.CSSProperties = {
  color: '#ffffff',
  fontSize: '0.8rem',
  fontWeight: 700,
};

const DEFAULT_RESEND_TIMEOUT = 60;

export type CheckInboxFormProps = Pick<SixDigitCodeInputProps, 'code'> & {
  email?: string;
  onBack?: () => void;
  onResendEmail?: () => void;
  onSubmitCode?: (code: string) => void;
  type?: 'loginCheckInbox' | 'registerCheckInbox';
  codeDisabled?: SixDigitCodeInputProps['disabled'];
};

export const CheckInboxForm: React.FC<CheckInboxFormProps> = observer(
  ({ email, onBack, onResendEmail, type = 'registerCheckInbox', onSubmitCode, codeDisabled, code }) => {
    const { t } = useTrans();

    const [remainingTime, setRemainingTime] = useState(DEFAULT_RESEND_TIMEOUT);
    const interval = React.useRef<ReturnType<typeof setInterval>>();

    const resendEnabled = remainingTime <= 0;

    useEffect(() => {
      if (remainingTime === DEFAULT_RESEND_TIMEOUT) {
        interval.current && clearInterval(interval.current);
        interval.current = setInterval(() => setRemainingTime((prev) => prev - 1), 1000);
      } else if (remainingTime <= 0) {
        interval.current && clearInterval(interval.current);
      }
    }, [remainingTime]);

    useEffect(() => {
      return () => interval.current && clearInterval(interval.current);
    }, []);

    const handleResend = React.useCallback(() => {
      if (resendEnabled) {
        onResendEmail && onResendEmail();
        setRemainingTime(DEFAULT_RESEND_TIMEOUT);
      }
    }, [onResendEmail, resendEnabled]);

    return (
      <React.Fragment>
        <FormContainer>
          <FormGroup>
            <SixDigitCodeInput code={code} onChange={onSubmitCode} disabled={codeDisabled} />
            <Input
              placeholder={t('registrationCheckInboxScreen.emailPlaceholder', { defaultValue: 'Your e-mail address' })}
              disabled={true}
              value={email}
              disabledColor={'#3c3c3c'}
            />
          </FormGroup>
          <ResendEmailContainer>
            <TouchableText onClick={resendEnabled ? handleResend : undefined} disabled={!resendEnabled}>
              {t('registrationCheckInboxScreen.resendEmail', { defaultValue: 'Resend e-mail ' })}
            </TouchableText>
            <span style={resendEmailSpanStyle}>
              &nbsp;
              {resendEnabled
                ? ''
                : t('registrationCheckInboxScreen.resendEmailSeconds', {
                    value: Math.max(0, remainingTime),
                    defaultValue: 'in {{value}} seconds',
                  })}
            </span>
          </ResendEmailContainer>
        </FormContainer>
        <Button block={true} onClick={onBack}>
          {t('globals.back', { defaultValue: 'Back' }).toUpperCase()}
        </Button>
        <TextWithMiddleLine text={t('globals.or', { defaultValue: 'Or' })} />
        {type === 'loginCheckInbox' ? <RegisterBottomButton /> : <LoginBottomButton />}
      </React.Fragment>
    );
  },
);
