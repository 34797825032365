/**
 * Created by andreaskarantzas on 20.10.20.
 */

import { action, observable } from 'mobx';

export class FirebaseUser {
  @observable
  uid?: string;
  @observable
  email: string | null = null;
  @observable
  displayName: string | null = null;
  @observable
  emailVerified: boolean = false;
  @observable
  isAnonymous: boolean = false;
  @observable
  metadata?: any;
  @observable
  phoneNumber: string | null = null;
  @observable
  photoURL: string | null = null;
  @observable
  providerData?: any;
  @observable
  providerId?: string;

  constructor(json?: any) {
    if (json) {
      this.setData(json);
    }
  }

  @action
  setData(json: any) {
    this.uid = json.uid;
    this.email = json.email;
    this.displayName = json.displayName;
    this.emailVerified = json.emailVerified;
    this.isAnonymous = json.isAnonymous;
    this.metadata = json.metadata;
    this.phoneNumber = json.phoneNumber;
    this.photoURL = json.photoURL;
    this.providerData = json.providerData;
    this.providerId = json.providerId;
  }
}
