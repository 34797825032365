import Color from 'color';

interface ColorProps {
  [key: string]: string;
}

export const ConfigColors: ColorProps = {
  /* updated based on the brandBook*/
  primary: '#00DDA3',
  primaryDark: '#00AF96',
  primaryDarkest: '#007763',
  secondary: '#00C3FF',
  secondaryDark: '#0094C1',
  secondaryDarkest: '#076282',
  success: '#00DDA3',
  successDark: '#00AF96',
  successDarkest: '#007763',
  error: '#f01b42',
  errorDark: '#A41C41',
  errorDarkest: '#771534',
  white: '#FFFFFF',
  darkGrey: '#3c3c3c',
  black: '#000000',
  screen: '#FFFFFF',
  orange: '#E5842B',
  orangeLight: 'rgba(226, 142, 43, 0.15)',

  /* colors outside of the brandBook*/
  lightestPrimary: '#b2f4e3',
  purple: '#712BE2',
  textColor: '#2E2E2E',
  darkestBlue: '#26323A',
  charcoal: '#5D6268',
  warmestGrey: '#6E6E6E',
  warmGrey: '#A4A6AA',
  whiteBlue: '#FBFDFF',
  lighterGrey: '#EDEEF0',
  lightGrey: '#c8cacc',
  lightestGrey: '#F6F6F7',
  lightestGreyBlue: '#cfd4d7',
  navBarColor: '#fafafa',
  transparent: 'transparent',
  modalBackground: 'rgba(0,0,0, 0.6)',
  inputSelectionColorAndroid: 'rgba(0,0,0,0.2)',
  facebook: '#3B5998',
  google: '#036bb7',
  twitter: '#1DA1F2',
  whatsapp: '#25D366',
  overlayDark: 'rgba(0,0,0, 0.8)',
  overlay: 'rgba(0,0,0, 0.5)',
  overlayLight: 'rgba(0,0,0, 0.3)',
  primaryOpacity: 'rgba(240, 27, 66, 0.2)',
  successOpacity: 'rgba(0, 221, 163, 0.1)',
  yellow: '#f7ce45',
  waterColor: '#42AAF3',
  spotifyColor: '#1DB954',
  authenticationBackgroundColor: '#ECF1F9',
  authenticationTouchableText: '#2563eb',
  authenticationCheckboxColor: '#00dda3',
  authenticationCheckboxText: '#161F2B',
  authenticationLinkColor: '#1F71EC',
  authenticationPlaceholderText: '#7c8ca8',
  subscriptionCodeResendColor: '#40E9BC',
  subscriptionCodeInfoText: '#4DEDC3',
  alternativeText: '#536379',
};

const enhanceColor = (colorObject: any, colorKey: string) => {
  const color = colorObject[colorKey];
  for (let i = 1; i < 10; i++) {
    const fade = i / 10;
    const colorIndex = 100 - i * 10;
    const newColor = Color(color).fade(fade).string();
    const newKey = `${colorKey}${colorIndex}`;
    colorObject[newKey] = newColor;
  }
};

const colorKeys = Object.keys(ConfigColors);
export const Colors = Object.assign({}, ConfigColors);
for (const colorKey of colorKeys) {
  enhanceColor(Colors, colorKey);
}
