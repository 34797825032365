/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import dayjs from 'dayjs';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import { CountdownButton } from '../../CountdownButton';
import { Button } from '../../Button';
import styled from '@emotion/styled';
import { AddToCalendarButton } from '../../../Utils/ical/AddToCalendarButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export type ExploreContentViewWebinarMainButtonsProps = {
  entry: WebinarEntry;
  subscription: SubscribedEventEntry;
  onUnregistered?: () => void;
  canJoinMeeting?: boolean;
};

export const ExploreContentViewWebinarMainButtons: React.FC<ExploreContentViewWebinarMainButtonsProps> = observer(
  ({ entry, subscription, onUnregistered, canJoinMeeting }) => {
    const { analytics } = useRootStore();
    const { t } = useTrans();

    const nextMeeting = entry.nextOrLastDate;

    const canRemind = nextMeeting?.isAfter(dayjs().subtract(5, 'minutes'));

    const handleUnregister = React.useCallback(() => {
      analytics.logEvent('webinar_unregistered', { objectId: entry.id });
      subscription.delete().then(() => onUnregistered && onUnregistered());
    }, [analytics, entry, onUnregistered, subscription]);

    return (
      <Container>
        <CountdownButton
          disabled={!canJoinMeeting}
          style={{ marginBottom: 16 }}
          date={nextMeeting?.valueOf()}
          countdownDeltaSeconds={1800}
        />
        {canRemind && (
          <React.Fragment>
            <AddToCalendarButton entry={entry} />
            <Button color="link" onClick={handleUnregister}>
              {t('exploreEntryViewScreen.remindMe.unregister', { defaultValue: 'Unregister' })}
            </Button>
          </React.Fragment>
        )}
      </Container>
    );
  },
);
