/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { BreathingTechniqueStep, BreathingTechniqueStepJson } from './BreathingTechniqueStep';
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { ActivityLog } from '../Activity/ActivityLog';

export type BreathingEntryJson = ExploreEntryJson & {
  activityIdentifier: string;
  data: Record<string, any>;
  technique: BreathingTechniqueStepJson[];
};

export class BreathingEntry extends ExploreEntry {
  @observable
  activityIdentifier = 'breathing';
  @observable
  data: Record<string, any> = observable.map({});
  @observable
  technique: BreathingTechniqueStep[] = [];

  constructor(json?: Partial<BreathingEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'breathing' }));
    if (json) {
      this.type = 'breathing';
      this.activityIdentifier = 'breathing';
      this.data = observable.map(json.data ?? {});
      this.technique = (json.technique ?? []).map((s) => new BreathingTechniqueStep(s));
    }
  }

  createActivityLog(startDate: Date, endDate: Date, data: Record<string, number> = {}): ActivityLog {
    if (!data.DURATION) {
      data.DURATION = Math.round((endDate.valueOf() - startDate.valueOf()) / 1000);
    }

    return new ActivityLog({
      type: 'breathing',
      source: {
        source: 'kinastic',
        sourceId: this.id,
        sourceType: 'explore-entry',
      },
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      data,
    });
  }

  static find(request?: Omit<ExploreEntryQueryRequest, 'type'>): Promise<BreathingEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request ?? {}), { type: 'breathing' })).then((res) =>
      (res ?? []).map((r) => new BreathingEntry(r)),
    );
  }

  static findOne(id: string): Promise<BreathingEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new BreathingEntry(res));
  }
}
