/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../Model/Explore/ArticleEntry';
import { ExploreEntryContainer, ExploreEntryContainerProps } from './ExploreEntryContainer';
import styled from '@emotion/styled';
import { ExploreCardTitle } from './ExploreCardTitle';
import { ExploreCardShortDescription } from './ExploreCardShortDescription';
import { VideoEntry } from '../../Model/Explore/VideoEntry';
import { InstructorTag } from './InstructorTag';
import { useTrans } from '../../Store/System/LocalizationStore';

const InnerContainer = styled.div`
  position: relative;
  height: 100%;
`;

const PlayOverlayContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ContentOverlayContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const PlayIcon = styled.img`
  position: absolute;
  width: 50px;
`;

const Duration = styled.div`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  padding: 3.70012px 11.1003px 4.62514px;
  font-size: 14px;
`;

export type ExploreEntryRendererVideoProps = ExploreEntryContainerProps & {
  entry: VideoEntry;
};

export const ExploreEntryRendererVideo: React.FC<ExploreEntryRendererVideoProps> = observer(
  ({ entry, ...moreProps }) => {
    const { t } = useTrans();
    const { instructor, durationInMinutes } = entry;

    return (
      <ExploreEntryContainer entry={entry} {...moreProps}>
        <InnerContainer>
          <PlayOverlayContainer>
            <PlayIcon src={require('../../Assets/Images/play_icon.png')} />
          </PlayOverlayContainer>
          <ContentOverlayContainer>
            {instructor ? <InstructorTag instructor={instructor} /> : <div />}
            {durationInMinutes ? (
              <Duration>
                {t('global.timeMinShort', { defaultValue: '{{minutes}} mins', minutes: durationInMinutes })}
              </Duration>
            ) : (
              <div />
            )}
          </ContentOverlayContainer>
        </InnerContainer>
      </ExploreEntryContainer>
    );
  },
);
