import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';

export type HealthDataPointSourceJson = {
  type: string;
  sourceId: string;
};

export class HealthDataPointSource {
  @observable
  type: string = 'healthkit';
  @observable
  sourceId = UUID();

  constructor(json?: Partial<HealthDataPointSourceJson>) {
    if (json) {
      this.type = json.type || 'healthkit';
      this.sourceId = json.sourceId ?? UUID();
    }
  }

  toJS(): HealthDataPointSourceJson {
    return {
      type: this.type,
      sourceId: this.sourceId,
    };
  }
}
