import { computed, observable } from 'mobx';
import { Media, MediaJson } from '../Media/Media';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { languagePriority } from '../LocalizedEntity';

/**
 * Created by neo on 19.04.21.
 */

export type InstructorJson = {
  name: string;
  description: LocalizedValueJson[];
  image?: MediaJson;
};

export class Instructor {
  @observable
  name: string = '';
  @observable
  description: LocalizedValue[] = [];
  @observable
  image?: Media;

  constructor(json?: Partial<InstructorJson>) {
    if (json) {
      this.name = json.name ?? '';
      this.description = json.description?.map((l) => new LocalizedValue(l)) ?? [];
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(): InstructorJson {
    return {
      name: this.name,
      description: this.description.map((l) => l.toJS()),
      image: this.image?.toJS(),
    };
  }

  getDescription(lang: string): string {
    return this.description.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultDescription;
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }
}
