/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';
import { PlaceholderScreen } from '../../Components/PlaceholderScreen/PlaceholderScreen';
import ReactMarkdown from 'react-markdown';

const defaultContent = `
# NUTRITION

Find everything about nutrition in our health app.

Healthy and delicious recipes for you to try. Thanks to the filter function, you can easily find new dishes that suit you. Add recipes to your favourites and share them with your friends.

## Over 1’500 recipes at your fingertip

We provide recipes for all kind of diets like vegetarian, gluten free, paleo, and more. Whether it's breakfast, a healthy snack or a dessert, we got you.
`;

export type NutritionIndexScreenProps = {};

export const NutritionIndexScreen: React.FC<NutritionIndexScreenProps> = observer((props) => {
  const { t } = useTrans();
  return (
    <PlaceholderScreen headerImage={require('../../Assets/Images/nutrition_placeholder_content.png')}>
      <ReactMarkdown>{t('nutritionPlaceholderScreen.content', { defaultValue: defaultContent })}</ReactMarkdown>
    </PlaceholderScreen>
  );
});
