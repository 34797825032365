/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { AuthLayout } from '../../../Components/Auth/AuthLayout/AuthLayout';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { LoginForm, LoginFormProps } from './LoginForm';
import { useNavigate } from 'react-router-dom';
import { ErrorAlert } from '../../../Components/Auth/Alerts/ErrorAlert';
import { useState } from 'react';

export type LoginRequestViewProps = Pick<LoginFormProps, 'onSubmitted' | 'onChangeEmail' | 'onError'> & {};

export const LoginRequestView: React.FC<LoginRequestViewProps> = observer(({ ...moreProps }) => {
  const { t } = useTrans();

  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleShowErrorAlert = React.useCallback((error?: string) => {
    setErrorCode(error);
    setShowErrorAlert(true);
  }, []);

  const handleCloseAlert = React.useCallback(() => {
    setShowErrorAlert(false);
  }, []);

  return (
    <AuthLayout
      title={t('loginScreen.title', { defaultValue: 'Return to Your Account' })}
      subtitle={t('loginScreen.subtitle', { defaultValue: 'No password needed' })}
      text={t('loginScreen.text', {
        defaultValue: 'Enter your email below and we will send you a 6-digit code to log in.',
      })}
      overlayRightTitle={t('authentication.introTitle', { defaultValue: 'Hello\n' + 'New\n' + 'You' })}
      image={require('../../../Assets/Images/auth_4.png')}
    >
      <LoginForm onError={handleShowErrorAlert} {...moreProps} />
      <ErrorAlert
        toggle={handleCloseAlert}
        isOpen={showErrorAlert}
        title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
        onDismiss={handleCloseAlert}
      />
    </AuthLayout>
  );
});
