/**
 * Created by neo on 11.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { observable, toJS } from 'mobx';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { NativeFeedComponent, NativeFeedComponentJson } from '../Feed/GetStream/NativeFeedComponent';

export type ArticleEntryJson = ExploreEntryJson & {
  content: NativeFeedComponentJson[];
  preview: LocalizedValueJson[];
  readTimeInMinutes: number;
};

export class ArticleEntry extends ExploreEntry {
  @observable
  content: NativeFeedComponent[] = [];
  @observable
  preview?: LocalizedValue[];
  @observable
  readTimeInMinutes: number = 0;

  constructor(json?: Partial<ArticleEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'article' }));
    if (json) {
      this.content = (json.content ?? []).map((c) => new NativeFeedComponent(c));
      this.preview = (json.preview ?? []).map((c) => new LocalizedValue(c));
      this.readTimeInMinutes = json.readTimeInMinutes ?? 0;
    }
  }

  getPreview(lang: string): string | undefined {
    return this.preview?.find((n) => n.lang === lang.toLowerCase())?.value || undefined;
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<ArticleEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'article' })).then((res) =>
      (res ?? []).map((r) => new ArticleEntry(r)),
    );
  }

  static async findOne(id: string): Promise<ArticleEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new ArticleEntry(res));
  }
}
