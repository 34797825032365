/**
 * Created by neo on 02.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import { DurationTracker } from '../../../../Model/DurationTracker';
import { useRootStore } from '../../../../Store/useRootStore';
import { VideoPlayerStore } from '../../../Video/VideoPlayerStore';
import { KinasticVideo } from '../../../Video/KinasticVideo';

export type VideoElementProps = {
  element: NativeFeedComponent;
  eventParams?: Record<string, string | number | boolean | undefined>;
};

export const VideoElement: React.FC<VideoElementProps> = observer(({ element, eventParams }: VideoElementProps) => {
  const { analytics } = useRootStore();

  const [videoStore] = useState(new VideoPlayerStore());
  const [durationTracker] = useState(new DurationTracker());
  const [videoMuted, setVideoMuted] = useState(true);
  const videoMedia = element.media;
  const videoSource = videoMedia?.largeOrMediumOrSmallest;
  const repeat = !!element.props.extraProps?.get('repeat') ?? true;

  // const handleToggleMute = React.useCallback(() => {
  //   setVideoMuted((previous) => !previous);
  // }, []);

  // const handleFullscreenWillDismiss = React.useCallback(() => {
  //   const durationInSeconds = durationTracker.durationInSeconds;
  //   const percentageCompleted = Math.min(1, durationInSeconds / videoStore.duration);
  //
  //   analytics.logEvent('video_stopped', {
  //     mediaId: videoMedia?.id,
  //     title: videoMedia?.name,
  //     url: videoMedia?.url,
  //     videoDuration: videoStore.duration,
  //     playedDuration: durationInSeconds,
  //     percentageCompleted: Math.round(percentageCompleted * 1000) / 10,
  //   });
  // }, [analytics, durationTracker, videoMedia, videoStore]);

  if (videoMedia) {
    return (
      <KinasticVideo
        // style={styles.video}
        url={videoSource}
        muted={videoMuted}
        loop={repeat}
      />
    );
  }
  return null;
});

// const styles = StyleSheet.create({
//   video: {
//     aspectRatio: 1.9,
//     flex: 1,
//     borderRadius: 8,
//   },
// });
