/**
 * Created by katarinababic on 24.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Col, Row } from 'reactstrap';
import InfoSvg from '../../../Assets/SVG/info_white.svg';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { AlertModal, AlertModalProps } from './AlertModal';

const InfoIconCol = styled(Col)`
  padding-top: 0.5rem;
`;

const iconStyle: React.CSSProperties = {
  color: '#ffffff',
  fontSize: '1.5rem',
  cursor: 'pointer',
};

const AlertTitle = styled.p`
  font-size: 1rem;
  color: #ffffff;
  padding-left: 0.5rem;
`;

const AlertText = styled.p`
  font-size: 0.8rem;
  color: #ffffff;
`;

const TouchableText = styled.p`
  color: #ffffff;
  text-decoration: underline;
  text-decoration-color: #ffffff;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
`;

const defaultAlertBusinessEmailDescription =
  'You can only register for KINASTIC if your employer works with us. If this is the case, you will receive a registration code and will be able to sign up for the KINASTIC app for free. If your company has no collaboration with KINASTIC, you will not receive a registration code.';

const defaultAlertPrivateEmailDescription =
  'You can use it in a later step. First we need your business e-mail address to assign you to your company.';

export type BusinessEmailInfoAlertProps = Pick<AlertModalProps, 'toggle' | 'isOpen'> & {
  onClickNoBusinessEmail?: () => void;
  onDismiss?: () => void;
};

export const BusinessEmailInfoAlert: React.FC<BusinessEmailInfoAlertProps> = observer(
  ({ toggle, isOpen, onClickNoBusinessEmail, onDismiss }) => {
    const { t } = useTrans();

    return (
      <AlertModal
        centered={true}
        toggle={toggle}
        isOpen={isOpen}
        AlertHeader={<i className="bi bi-x" style={iconStyle} onClick={onDismiss} />}
        AlertBody={
          <React.Fragment>
            <Row>
              <InfoIconCol xs={1}>
                <img src={InfoSvg} alt="info" height={24} width={24} />
              </InfoIconCol>
              <Col xs={11}>
                <AlertTitle>
                  {t('registrationScreen.infoAlert.businessEmailTitle', {
                    defaultValue: 'Why do I have to use my business e-mail address?',
                  })}
                </AlertTitle>
              </Col>
            </Row>
            <AlertText>
              {t('registrationScreen.infoAlert.businessEmailDescription', {
                defaultValue: defaultAlertBusinessEmailDescription,
              })}
            </AlertText>
            <TouchableText onClick={onClickNoBusinessEmail}>
              {t('registrationScreen.stepOne.noBusinessEmail', {
                defaultValue: "I don't own a business e-mail address",
              })}
            </TouchableText>
            <Row>
              <InfoIconCol xs={1}>
                <img src={InfoSvg} alt="info" height={24} width={24} />
              </InfoIconCol>
              <Col xs={11}>
                <AlertTitle>
                  {t('registrationScreen.infoAlert.privateEmailTitle', {
                    defaultValue: 'Can I use my private e-mail address?',
                  })}
                </AlertTitle>
              </Col>
            </Row>
            <AlertText>
              {t('registrationScreen.infoAlert.privateEmailDescription', {
                defaultValue: defaultAlertPrivateEmailDescription,
              })}
            </AlertText>
          </React.Fragment>
        }
      />
    );
  },
);
