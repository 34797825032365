/**
 * Created by katarinababic on 1.6.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Input, Form } from 'reactstrap';
import styled from '@emotion/styled';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const StyledInput = styled(Input)`
  margin: 0.5rem;
  font-size: 1.5rem;
  text-align: center;
  border-color: rgba(0, 221, 163, 0.3);
  border-width: 0 0 0.3rem 0;
  border-radius: 0;
  color: #ffffff;
  background-color: transparent;

  &.form-control:focus {
    border-bottom-color: rgba(0, 221, 163, 1);
    border-width: 0 0 0.3rem 0;
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
  }

  &.form-control:disabled {
    border-width: 0 0 0.3rem 0;
    background-color: transparent;
    color: #ffffff;
    box-shadow: none;
  }
`;

export type SixDigitCodeInputProps = {
  code?: string;
  onChange?: (code: string) => void;
  disabled?: boolean;
};

export const SixDigitCodeInput: React.FC<SixDigitCodeInputProps> = observer(({ code = '', onChange, disabled }) => {
  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');
  const [third, setThird] = useState('');
  const [fourth, setFourth] = useState('');
  const [fifth, setFifth] = useState('');
  const [sixth, setSixth] = useState('');

  useEffect(() => {
    setFirst(code[0] ?? '');
    setSecond(code[1] ?? '');
    setThird(code[2] ?? '');
    setFourth(code[3] ?? '');
    setFifth(code[4] ?? '');
    setSixth(code[5] ?? '');
  }, [code]);

  const handleChangeFirst = React.useCallback(({ target: { value, validity } }) => {
    setFirst((previous) => (validity.valid ? value : previous));
    value && validity.valid && document.getElementById('2')?.focus();
  }, []);

  const handleChangeSecond = React.useCallback(({ target: { value, validity } }) => {
    setSecond((previous) => (validity.valid ? value : previous));
    value && validity.valid && document.getElementById('3')?.focus();
  }, []);

  const handleChangeThird = React.useCallback(({ target: { value, validity } }) => {
    setThird((previous) => (validity.valid ? value : previous));
    value && validity.valid && document.getElementById('4')?.focus();
  }, []);

  const handleChangeFourth = React.useCallback(({ target: { value, validity } }) => {
    setFourth((previous) => (validity.valid ? value : previous));
    value && validity.valid && document.getElementById('5')?.focus();
  }, []);

  const handleChangeFifth = React.useCallback(({ target: { value, validity } }) => {
    setFifth((previous) => (validity.valid ? value : previous));
    value && validity.valid && document.getElementById('6')?.focus();
  }, []);

  const handleChangeSixth = React.useCallback(
    ({ target: { value, validity } }) => {
      setSixth((previous) => (validity.valid ? value : previous));
      if (value && validity.valid) {
        document.getElementById('6')?.blur();
        const completeCode = [first, second, third, fourth, fifth, value].join('');
        if (completeCode.length === 6) {
          onChange && onChange(completeCode);
        }
      }
    },
    [first, second, third, fourth, fifth, onChange],
  );

  const handleKeyDown = React.useCallback((e, id) => {
    const targetId = parseInt(id);

    // Arrow left and backspace on empty field, focus on previous input
    if ((e.key === 'ArrowLeft' || (e.key === 'Backspace' && e.target.value === '')) && targetId > 1) {
      e.preventDefault(); // Prevent arrow from moving cursor within the input
      document.getElementById((targetId - 1).toString())?.focus();
    }

    // Arrow right, focus on next input
    else if (e.key === 'ArrowRight' && targetId < 6) {
      e.preventDefault(); // Prevent arrow from moving cursor within the input
      document.getElementById((targetId + 1).toString())?.focus();
    }
  }, []);

  const handlePaste = React.useCallback(
    (event: React.ClipboardEvent) => {
      const paste = event.clipboardData.getData('text');

      if (paste.match(/^\d{6}$/)) {
        setFirst(paste.charAt(0));
        setSecond(paste.charAt(1));
        setThird(paste.charAt(2));
        setFourth(paste.charAt(3));
        setFifth(paste.charAt(4));
        setSixth(paste.charAt(5));

        if (onChange) {
          onChange(paste);
        }
      }
    },
    [onChange],
  );

  return (
    <StyledForm onPaste={handlePaste}>
      <StyledInput
        id="1"
        value={first}
        onChange={handleChangeFirst}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '1')}
        autoFocus
      />
      <StyledInput
        id="2"
        value={second}
        onChange={handleChangeSecond}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '2')}
      />
      <StyledInput
        id="3"
        value={third}
        onChange={handleChangeThird}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '3')}
      />
      <StyledInput
        id="4"
        value={fourth}
        onChange={handleChangeFourth}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '4')}
      />
      <StyledInput
        id="5"
        value={fifth}
        onChange={handleChangeFifth}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '5')}
      />
      <StyledInput
        id="6"
        value={sixth}
        onChange={handleChangeSixth}
        maxLength={1}
        disabled={disabled}
        inputMode="numeric"
        autoComplete="off"
        pattern="[0-9]*"
        onKeyDown={(e) => handleKeyDown(e, '6')}
      />
    </StyledForm>
  );
});
