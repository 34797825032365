/**
 * Created by neo on 11.01.21.
 */
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Pageable } from '../Interfaces/Pageable';
import { ExploreCategoryLink, ExploreCategoryLinkJson } from './ExploreCategoryLink';
import { ExploreContent, ExploreContentJson } from './ExploreContent';

export type ExploreCategoryType =
  | 'preview'
  | 'big_preview'
  | 'single_exercises'
  | 'card_link'
  | 'category_group'
  | 'link_list'
  | 'recipe_recommendations'
  | 'recipe_favorites'
  | 'recipe_all'
  | 'tools'
  | 'blank';

export type ExploreCategoryQueryRequest = Pageable & {
  query?: string;
  parentId?: string;
  tags?: string[];
  root?: true;
  appVersion?: string;
  system?: 'ios' | 'android';
};

export type ExploreCategoryJson = ExploreContentJson & {
  identifier: string;
  link?: string;
  links: ExploreCategoryLinkJson[];
};

export class ExploreCategory extends ExploreContent {
  @observable
  identifier: string = '';
  @observable
  parentId?: string;
  @observable
  parent?: ExploreCategory;
  @observable
  link?: string;
  @observable
  links: ExploreCategoryLink[] = [];
  fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<ExploreCategoryJson>) {
    super(json);
    if (json) {
      this.identifier = json.identifier ?? '';
      this.link = json.link;
      this.links = (json.links ?? []).map((l) => new ExploreCategoryLink(l));
    }

    onBecomeObserved(this, 'parent', this.startFetchParent);
    onBecomeUnobserved(this, 'parent', this.stopFetchParent);
  }

  startFetchParent = () => {
    this.fetchDisposer = reaction(
      () => this.parentId,
      (parentId) => {
        if (parentId) {
          ExploreCategory.findOne(parentId).then((result) => runInAction(() => (this.parent = result)));
        } else {
          runInAction(() => (this.parent = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchParent = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  toJS(): ExploreCategoryJson {
    return Object.assign(super.toJS(), {
      identifier: this.identifier,
      link: this.link,
      links: this.links.map((l) => l.toJS()),
    });
  }

  save(): Promise<ExploreCategory> {
    return HttpBackend.post(`/coach/explore/category`, this.toJS()).then(() => this);
  }

  delete(): Promise<ExploreCategory> {
    return HttpBackend.delete(`/coach/explore/category/${this.id}`).then(() => this);
  }

  static find(request?: Partial<ExploreCategoryQueryRequest>): Promise<ExploreCategory[]> {
    return HttpBackend.get(`/coach/explore/category`, toJS(request)).then((res) =>
      (res ?? []).map((r) => new ExploreCategory(r)),
    );
  }

  static findOne(id: string): Promise<ExploreCategory> {
    return HttpBackend.get(`/coach/explore/category/${id}`).then((res) => new ExploreCategory(res));
  }

  static findAll(ids: string[]): Promise<ExploreCategory[]> {
    return HttpBackend.get(`/coach/explore/category/findAll/${ids.join(',')}`).then((result) =>
      (result ?? []).map((res) => new ExploreCategory(res)),
    );
  }
}
