/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { ExploreCategory } from '../../Model/Explore/ExploreCategory';
import styled from '@emotion/styled';
import { useRootStore } from '../../Store/useRootStore';
import { Col, Container, Row } from 'reactstrap';
import { PageResult } from '../../Model/PageResult';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../Model/Explore/ExploreEntryBuilder';
import {
  ExploreCardEntryRenderer,
  ExploreCardEntryRendererProps,
} from '../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { Link } from 'react-router-dom';
import { SUPPORTED_TYPES } from '../../Utils/Constants';
import { ExploreCategoryIntroCard } from './ExploreCategoryIntroCard';
import { HorizontalSlider } from '../../Components/HorizontalSlider';

export const ExploreCategoryContainer = styled.div<{ isLast?: boolean }>`
  border-bottom:  ${({ isLast }) => (isLast ? 'transparent' :  '1px solid #e5e5e5')};
  padding: 32px 0;
`;

export type ExploreCategoryRendererProps = Pick<ExploreCardEntryRendererProps, 'linkPrefix'> & {
  category: ExploreCategory;
  isLast?: boolean;
};

export const ExploreCategoryRenderer: React.FC<ExploreCategoryRendererProps> = observer(
  ({ category, linkPrefix, isLast }) => {
    const [entries, setEntries] = useState<PageResult<ExploreEntry>>(new PageResult());
    const [page, setPage] = useState(0);

    useEffect(() => {
      PageResult.execute(
        ExploreEntryBuilder.find({ rootCategories: [category.identifier, category.id], type: SUPPORTED_TYPES }),
        ExploreEntry.count({ rootCategories: [category.identifier, category.id], type: SUPPORTED_TYPES }),
        page,
        10,
      ).then((res) => setEntries(res));
    }, [page, category]);

    if (entries.content.length > 0) {
      return (
        <ExploreCategoryContainer isLast={isLast}>
          <Container>
            <HorizontalSlider>
              <ExploreCategoryIntroCard category={category} />
              {entries.content.map((entry) => (
                <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={linkPrefix} />
              ))}
            </HorizontalSlider>
          </Container>
        </ExploreCategoryContainer>
      );
    }

    return null;
  },
);
