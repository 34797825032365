/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { Translation } from '../../../Components/Translation';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import dayjs from 'dayjs';
import { ScheduleItem } from '../../../Components/Schedule/ScheduleItem';
import { TimeNavigationBar } from '../../../Components/TimeNavigationBar';
import { CurrentWeekEmptyScheduleCard } from '../../../Components/Schedule/CurrentWeekEmptyScheduleCard';
import { FutureEmptyScheduleCard } from '../../../Components/Schedule/FutureEmptyScheduleCard';

const Header = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
`;

const Description = styled.p`
  margin-top: 16px;
`;

export type MoveScheduleScreenProps = {};

export const MoveScheduleScreen: React.FC<MoveScheduleScreenProps> = observer((props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [fetching, setFetching] = useState(false);
  const [upcoming, setUpcoming] = useState<OnlineMeetingEntry[]>([]);

  const defaultValue = React.useMemo(() => dayjs().startOf('isoWeek'), []);
  const weekValue = searchParams.get('time') || defaultValue.format('YYYY-MM-DD');
  const weekParsed = React.useMemo(() => dayjs(weekValue, 'YYYY-MM-DD').startOf('isoWeek'), [weekValue]);
  const week = weekParsed.isValid() && !weekParsed.isBefore(defaultValue) ? weekParsed : defaultValue;
  const weekBefore = week.subtract(1, 'week').startOf('isoWeek');
  const hasPrevious = !weekBefore.isBefore(dayjs().startOf('isoWeek'));
  const maxDate = defaultValue.add(24, 'week').startOf('isoWeek');
  const isCurrentWeek = week.startOf('isoWeek').diff(dayjs().startOf('isoWeek')) === 0;
  const futureDisabled = week.startOf('isoWeek').diff(maxDate.startOf('isoWeek')) === 0;

  useEffect(() => {
    setFetching(true);
    setUpcoming([]);
    Promise.all([
      OnlineMeetingEntry.upcoming({
        size: 20,
        categories: ['live_training'],
        startDateTime: week.startOf('isoWeek').toDate(),
        endDateTime: week.endOf('isoWeek').toDate(),
      }),
      OnlineMeetingEntry.upcoming({
        size: 1,
        categories: ['live_training'],
        startDateTime: week.add(1, 'week').startOf('isoWeek').toDate(),
        endDateTime: week.add(1, 'week').endOf('isoWeek').toDate(),
      }),
    ])
      .then(([res, nextWeek]) => {
        setUpcoming(res);
      })
      .finally(() => setFetching(false));
  }, [week]);

  const handlePrevious = React.useCallback(() => {
    if (hasPrevious) {
      searchParams.set('time', weekBefore.format('YYYY-MM-DD'));
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [hasPrevious, location, weekBefore, navigate, searchParams]);

  const handleNext = React.useCallback(() => {
    searchParams.set('time', week.add(1, 'week').format('YYYY-MM-DD'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [location, week, navigate, searchParams]);

  const handleClickEntry = React.useCallback(
    (entry) => {
      navigate(`/move/${entry.id}`);
    },
    [navigate],
  );

  const handleFirst = React.useCallback(() => {
    searchParams.set('time', dayjs().startOf('isoWeek').format('YYYY-MM-DD'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [searchParams, navigate, location]);

  const handleLast = React.useCallback(() => {
    searchParams.set('time', maxDate.format('YYYY-MM-DD'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [searchParams, maxDate, navigate, location]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Header>
            <Translation
              i18nKey="moveSchedule.title"
              defaultValue={`Move\n<span style="color:#24dca4">Schedule</span>`}
            />
          </Header>
        </Col>
        <Col md={7}>
          <Description>
            <Translation
              i18nKey="moveSchedule.description"
              defaultValue={`Plan your fitness week ahead: Our live trainings schedule changes monthly and includes a wide range of workouts for every level, age and daily routine.`}
            />
          </Description>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TimeNavigationBar
            displayTime={`${week.startOf('isoWeek').format('MMM DD')} - ${week.day(5).format('DD')}`}
            onNext={handleNext}
            onPrevious={handlePrevious}
            hasPrevious={hasPrevious}
            hasNext={!futureDisabled}
            onFirst={handleFirst}
            onLast={handleLast}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!fetching && upcoming.length === 0 && isCurrentWeek && (
            <CurrentWeekEmptyScheduleCard linkPrefix={'/move/'} />
          )}
          {!fetching && upcoming.length === 0 && !isCurrentWeek && <FutureEmptyScheduleCard />}
          {upcoming.map((entry) => (
            <ScheduleItem
              key={entry.id}
              entry={entry}
              onClick={handleClickEntry}
              currentStartDate={week}
              linkPrefix="/move/"
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
});
