/**
 * Created by neo on 02.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import styled from '@emotion/styled';

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

export type ImageElementProps = {
  element: NativeFeedComponent;
};

export const ImageElement: React.FC<ImageElementProps> = observer(({ element }: ImageElementProps) => {
  return element.media?.medium ? <Image src={element.media.medium} /> : null;
});
