import { AuthenticationStore } from './AuthenticationStore';
import { computed, observable, reaction, runInAction } from 'mobx';
import { AthleteCoachSubscription } from '../Model/CoachSubscription/AthleteCoachSubscription';

export class CoachSubscriptionStore {
  @observable
  current?: AthleteCoachSubscription;

  constructor(readonly authentication: AuthenticationStore) {
    reaction(
      () => authentication.athleteId,
      (athleteId) => {
        if (!athleteId) {
          runInAction(() => (this.current = undefined));
        }
      },
    );
  }

  fetchSubscription(): Promise<AthleteCoachSubscription> {
    return AthleteCoachSubscription.current().then((subscription) => {
      runInAction(() => (this.current = subscription));
      return subscription;
    });
  }

  @computed
  get canShowAllContent(): boolean {
    return this.current?.plan === 'standard' || this.current?.plan === 'engagement';
  }

  @computed
  get canShowAcademy(): boolean {
    return this.current?.plan === 'academy' || this.current?.plan === 'standard' || this.current?.plan === 'engagement';
  }

  @computed
  get canShowMove(): boolean {
    return this.current?.plan === 'move' || this.current?.plan === 'standard' || this.current?.plan === 'engagement';
  }

  @computed
  get onlyMove(): boolean {
    return this.current?.plan === 'move';
  }

  @computed
  get onlyAcademy(): boolean {
    return this.current?.plan === 'academy';
  }
}
