/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useRootStore } from '../../Store/useRootStore';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-self: flex-end;
`;

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
`;

const ImageContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const HeaderProfileSubmenu = styled.div`
  position: absolute;
  z-index: 1000;
  border-radius: 4px;
  background-color: white;
  padding: 16px;
  border: 1px solid grey;
`;

export type HeaderProfileMenuProps = {};

export const HeaderProfileMenu: React.FC<HeaderProfileMenuProps> = observer((props) => {
  const { authentication, firebaseAuth } = useRootStore();

  const navigate = useNavigate();

  const [mouseOver, setMouseOver] = useState(false);
  const [clicked, setClicked] = useState(false);

  const menuVisible = mouseOver || clicked;

  const profileSource = authentication.athlete?.profilePicture?.smallest;

  const handleToggle = React.useCallback(() => {
    navigate('/account');
    setClicked((p) => !p);
  }, [navigate]);

  const handleMouseOver = React.useCallback(() => setMouseOver(true), []);

  const handleMouseOut = React.useCallback(() => setMouseOver(false), []);

  const handleLogout = React.useCallback(() => {
    authentication.logout();
    firebaseAuth.signOut();

    navigate('/auth', { replace: true });
  }, [authentication, firebaseAuth, navigate]);

  return (
    <Container>
      <ImageContainer onClick={handleToggle} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {profileSource ? (
          <ProfileImage src={profileSource} />
        ) : (
          <i className="bi bi-person-circle" style={{ fontSize: 32 }}></i>
        )}
        {/*{menuVisible && (*/}
        {/*  <HeaderProfileSubmenu>*/}
        {/*    <button type="button" onClick={handleLogout}>*/}
        {/*      Logout*/}
        {/*    </button>*/}
        {/*  </HeaderProfileSubmenu>*/}
        {/*)}*/}
      </ImageContainer>
    </Container>
  );
});
