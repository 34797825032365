/**
 * Created by neo on 21.12.20.
 */
import { computed, observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Audited, AuditedJson } from '../Audited';

export type AthleteConfigurationJson = AuditedJson & {
  id: string;
  data: Record<string, any>;
};

export class AthleteConfiguration extends Audited {
  @observable
  data: Record<string, any> = {};

  constructor(json?: Partial<AthleteConfigurationJson>) {
    super(json);
    if (json) {
      this.data = json.data ?? {};
    }
  }

  toJS(): AthleteConfigurationJson {
    return Object.assign(super.toJS(), {
      data: toJS(this.data),
    });
  }

  save(): Promise<AthleteConfiguration> {
    return HttpBackend.post(`/athlete/configuration`, this.toJS())
      .then(() => this)
      .catch(() => this);
  }

  @computed
  get language(): string {
    if (this.data.locales && this.data.locales.length > 0) {
      return this.data.locales[0].languageCode ?? 'de';
    }
    return 'de';
  }

  static get(athleteId?: string): Promise<AthleteConfiguration> {
    return HttpBackend.get(`/athlete/configuration`, { athleteId })
      .then((res) => new AthleteConfiguration(res))
      .catch(() => new AthleteConfiguration());
  }
}
