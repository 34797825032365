/**
 * Created by andreaskarantzas on 05.05.20.
 */
import { computed, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Media, MediaJson } from '../../Media/Media';
import { NativeFeedComponentProps, NativeFeedComponentPropsJson } from './NativeFeedComponentProps';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { languagePriority } from '../../LocalizedEntity';

export type NativeFeedComponentType =
  | 'View'
  | 'Image'
  | 'Video'
  | 'Heading'
  | 'ItalicHeading'
  | 'Paragraph'
  | 'ItalicParagraph'
  | 'Caption'
  | 'Summary'
  | 'InnerBoldText'
  | 'InnerLinkText'
  | 'BulletPoint'
  | 'NumberPoint'
  | 'Button'
  | 'SecondaryButton';

export type NativeFeedComponentJson = {
  key: string;
  type: NativeFeedComponentType;
  props: NativeFeedComponentPropsJson;
  children: NativeFeedComponentJson[];
  text: LocalizedValueJson[];
  media?: MediaJson;
};

export class NativeFeedComponent {
  @observable key: string = UUID();
  @observable type: NativeFeedComponentType = 'View';
  @observable props: NativeFeedComponentProps = new NativeFeedComponentProps({});
  @observable children: NativeFeedComponent[] = [];
  @observable text: LocalizedValue[] = [];
  @observable media?: Media;

  constructor(json: Partial<NativeFeedComponentJson>) {
    if (json) {
      this.key = json.key || UUID();
      this.type = json.type || 'View';
      this.props = new NativeFeedComponentProps(json.props || {});
      this.children = (json.children || []).map((c) => new NativeFeedComponent(c));
      this.text = (json.text || []).map((c) => new LocalizedValue(c));
      this.media = json.media ? new Media(json.media) : undefined;
    }
  }

  getText(lang: string) {
    return this.text.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultText;
  }

  @computed
  get defaultText(): string {
    for (const lang of languagePriority) {
      const entry = this.text.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.text[0];
    return first?.value ?? '';
  }
}
