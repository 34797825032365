import { observable, ObservableMap, toJS } from 'mobx';

export type RoundInfoJson = {
  options: {
    [key: string]: any;
  };
  name?: string;
  description?: string;
};

export class RoundInfo {
  @observable
  options: ObservableMap<string> = observable.map({});
  @observable
  name?: string;
  @observable
  description?: string;

  constructor(json?: Partial<RoundInfoJson>) {
    if (json) {
      this.options = observable.map(json.options || {});
      this.name = json.name;
      this.description = json.description;
    }
  }

  toJS(): RoundInfoJson {
    return {
      options: toJS(this.options),
      name: this.name,
      description: this.description,
    };
  }
}
