/**
 * Created by neo on 18.01.17.
 */

import { action, observable, computed, ObservableMap } from 'mobx';
import { CoachExerciseBlock } from './CoachExerciseBlock';
import dayjs from '../../../../Utils/dayjs';
import {
  BaseTrackingKey,
  PlanableTrackingKey,
  TrackingKey,
  TrackingKeysList,
} from '../../../ProgramPortfolio/TrackingKeys';
import { BaseExerciseBlockSetJson, ExerciseBlockSetJson } from '../../../ProgramPortfolio/ExerciseBlockSet';

export const BasicTrackingKeys: BaseTrackingKey[] = ['REPETITIONS', 'DURATION', 'WEIGHT', 'BREAK', 'CALORIES'];

export type CoachExerciseBlockSetJson = BaseExerciseBlockSetJson & {
  previousValues: {
    [key in TrackingKey]?: number;
  };
};

export class CoachExerciseBlockSet {
  @observable
  values: ObservableMap<TrackingKey, number> = observable.map({});
  @observable
  previousValues: ObservableMap<TrackingKey, number> = observable.map({});
  @observable
  exerciseBlock?: CoachExerciseBlock;

  constructor(exerciseBlock?: CoachExerciseBlock, json?: any) {
    this.exerciseBlock = exerciseBlock;
    if (json) {
      this.values = observable.map(json.values || {});
      this.previousValues = observable.map(json.previousValues || {});
    }
  }

  toJS(): CoachExerciseBlockSetJson {
    return {
      values: this.values.toJSON(),
      previousValues: this.previousValues.toJSON(),
    };
  }

  toWorkoutJS(): ExerciseBlockSetJson {
    return {
      values: this.values.toJSON(),
      updateTimestamp: 0,
    };
  }

  calculateCalories(bmr24: number): number {
    if (this.plannedCalories > 0) {
      return this.plannedCalories;
    }
    const met = this.exerciseBlock ? this.exerciseBlock.exercise.met || 5.5 : 5.5;
    const timeInHours = Math.max(1, this.plannedDurationMs / 1000.0) / 3600.0;
    return Math.max(1, Math.round(bmr24 * (met || 5.5) * timeInHours)) * 1.2;
  }

  @action
  setKey(key: TrackingKey, value?: number) {
    if (value || value === 0) {
      this.values.set(key, value);
    }
  }

  @computed
  get plannedCalories(): number {
    return this.values.get('CALORIES') || this.values.get('MIN_CALORIES') || this.values.get('MAX_CALORIES') || 0;
  }

  @computed
  get calories(): number {
    return this.plannedCalories;
  }

  @computed
  get duration(): number {
    return this.plannedDurationMs;
  }

  @computed
  get remainingDuration(): number {
    return this.duration;
  }

  @computed
  get estimatedRepsDuration(): number {
    switch (this.exerciseBlock?.exercise.type) {
      case 'MOBILISATION':
        return 1.5;
      case 'STRENGTH':
        return this.exerciseBlock?.phase?.type === 'interval'
          ? 1.5
          : this.exerciseBlock?.exercise.equipmentTypes.includes('FREE')
          ? 2
          : 3;
      default:
        return 3;
    }
  }

  @computed
  get plannedRepetitions(): number | undefined {
    return this.values.get('MAX_REPETITIONS') || this.values.get('MIN_REPETITIONS') || this.values.get('REPETITIONS');
  }

  @computed
  get plannedWeight(): number | undefined {
    return this.values.get('MAX_WEIGHT') || this.values.get('MIN_WEIGHT') || this.values.get('WEIGHT');
  }

  @computed
  get plannedRepetitionBasedDuration(): number {
    return (this.plannedRepetitions || 0) * this.estimatedRepsDuration;
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationSeconds(): number {
    return this.plannedDuration;
  }

  @computed
  get breakTime(): number {
    return this.plannedBreakTime;
  }

  @computed
  get breakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedBreakTime(): number {
    return this.values.get('MIN_BREAK') || this.values.get('MAX_BREAK') || this.values.get('BREAK') || 10;
  }

  @computed
  get plannedBreakTimeMs(): number {
    return this.plannedBreakTime * 1000;
  }

  /**
   * Value is in seconds
   */
  @computed
  get plannedDuration(): number {
    return (
      this.values.get('MIN_DURATION') ||
      this.values.get('MAX_DURATION') ||
      this.values.get('DURATION') ||
      this.plannedRepetitionBasedDuration
    );
  }

  /**
   * Includes breakTime if there is a next set
   */
  @computed
  get totalDuration(): number {
    return this.plannedDurationMs + (this.nextSet ? this.breakTimeMs : 0);
  }

  @computed
  get plannedDurationMs(): number {
    return this.plannedDuration * 1000;
  }

  @computed
  get tons(): number {
    const weight = this.values.get('MIN_WEIGHT') || this.values.get('MAX_WEIGHT') || this.values.get('WEIGHT') || 0;
    const reps =
      this.values.get('MIN_REPETITIONS') || this.values.get('MAX_REPETITIONS') || this.values.get('REPETITIONS') || 0;
    return (reps * weight) / 1000;
  }

  @computed
  get index(): number {
    return this.exerciseBlock ? this.exerciseBlock.sets.findIndex((s) => s === this) : -1;
  }

  @computed
  get isLastSet(): boolean {
    if (this.exerciseBlock) {
      const { index } = this;
      return index + 1 === this.exerciseBlock.sets.length;
    }
    return false;
  }

  @computed
  get prevSet(): CoachExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index } = this;
      if (index === 0) {
        return this.exerciseBlock.prevBlock?.prevSet;
      }
      return this.exerciseBlock.sets[index - 1];
    }
    return undefined;
  }

  @computed
  get nextSet(): CoachExerciseBlockSet | undefined {
    if (this.exerciseBlock) {
      const { index, isLastSet } = this;
      if (isLastSet) {
        return this.exerciseBlock.nextBlock?.followingSet;
      }
      const nextIndex = index + 1;
      return nextIndex < this.exerciseBlock.sets.length ? this.exerciseBlock.sets[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get assignedTrackingKeys(): BaseTrackingKey[] {
    return Array.from(this.values.keys())
      .map((item) => (item.startsWith('MIN_') || item.startsWith('MAX_') ? item.substr(4) : item) as BaseTrackingKey)
      .filter((tk) => BasicTrackingKeys.includes(tk))
      .reduce((result, trackingKey) => {
        if (!result.includes(trackingKey)) {
          result.push(trackingKey);
        }
        return result;
      }, [] as BaseTrackingKey[]);
  }

  @action
  assignTrackingKey(trackingKey: string, value?: number) {
    this.values.set(`MIN_${trackingKey}` as PlanableTrackingKey, value || 0);
    this.values.set(`MAX_${trackingKey}` as PlanableTrackingKey, value || 0);
  }

  @action
  deleteTrackingKey(trackingKey: string) {
    this.values.delete(`MIN_${trackingKey}` as PlanableTrackingKey);
    this.values.delete(`MAX_${trackingKey}` as PlanableTrackingKey);
  }

  /**
   * sorting for the next two function is based on the order
   * provided by the TrackingKeys.ts file
   */
  @computed
  get assignedTrackingKeysSorted(): BaseTrackingKey[] {
    return this.assignedTrackingKeys.sort((a, b) => TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b));
  }

  @computed
  get unassignedTrackingKeys(): BaseTrackingKey[] {
    const { exerciseBlock, assignedTrackingKeys } = this;
    if (exerciseBlock) {
      return Array.from(
        new Set(exerciseBlock.trackingParameters.filter((tk) => assignedTrackingKeys.indexOf(tk) < 0)).values(),
      );
    }
    return [];
  }

  /**
   * sorting for the next two function is based on the order
   * provided by the TrackingKeys.ts file
   */
  @computed
  get unassignedTrackingKeysSorted(): BaseTrackingKey[] {
    return this.unassignedTrackingKeys.sort((a, b) => TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b));
  }

  @computed
  get allTrackingKeysSorted(): BaseTrackingKey[] {
    return this.assignedTrackingKeysSorted.concat(this.unassignedTrackingKeysSorted);
  }

  /**
   * This is used solely for the list of tracking keys shown in the
   * exerciseRun and exerciseCards
   * return the keys with a preference in REPETITIONS over DURATION
   * and exclude BREAK
   */
  @computed
  get unassignedTrackingKeysSortedWithRepetitionsPreference(): BaseTrackingKey[] {
    if (this.unassignedTrackingKeysSorted.includes('REPETITIONS')) {
      return this.unassignedTrackingKeysSorted.filter((tk) => tk !== 'DURATION').filter((tk) => tk !== 'BREAK');
    }
    return this.unassignedTrackingKeysSorted.filter((tk) => tk !== 'BREAK');
  }

  /**
   * This is used solely for the list of tracking keys shown in the
   * exerciseRun and exerciseCards
   * return the keys with a preference in DURATION over REPETITIONS
   * and exclude BREAK
   */
  @computed
  get unassignedTrackingKeysSortedWithDurationPreference(): BaseTrackingKey[] {
    if (this.unassignedTrackingKeysSorted.includes('DURATION')) {
      return this.unassignedTrackingKeysSorted.filter((tk) => tk !== 'REPETITIONS').filter((tk) => tk !== 'BREAK');
    }
    return this.unassignedTrackingKeysSorted.filter((tk) => tk !== 'BREAK');
  }

  /**
   * This is used solely for the list of tracking keys shown in the
   * exerciseRun and exerciseCards
   * return the keys with a preference in REPETITIONS over DURATION
   * and exclude BREAK
   */
  @computed
  get assignedTrackingKeysSortedWithRepetitionsPreference(): BaseTrackingKey[] {
    if (this.assignedTrackingKeysSorted.includes('REPETITIONS')) {
      return this.assignedTrackingKeysSorted.filter((tk) => tk !== 'DURATION').filter((tk) => tk !== 'BREAK');
    }
    return this.assignedTrackingKeysSorted.filter((tk) => tk !== 'BREAK');
  }

  /**
   * This is used solely for the list of tracking keys shown in the
   * exerciseRun and exerciseCards
   * return the keys with a preference in DURATION over REPETITIONS
   * and exclude BREAK
   */
  @computed
  get assignedTrackingKeysSortedWithDurationPreference(): BaseTrackingKey[] {
    if (this.assignedTrackingKeysSorted.includes('DURATION')) {
      return this.assignedTrackingKeysSorted.filter((tk) => tk !== 'REPETITIONS').filter((tk) => tk !== 'BREAK');
    }
    return this.assignedTrackingKeysSorted.filter((tk) => tk !== 'BREAK');
  }
}
