import { initializeApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { getAuth, Auth } from 'firebase/auth';
import { getDatabase, Database } from 'firebase/database';
import { getMessaging, Messaging } from 'firebase/messaging';
import { Config } from '../../Config/Config';

export const FirebaseApp = initializeApp(Config.firebase);
export const FirebaseAnalytics: Analytics = getAnalytics(FirebaseApp);

export const FirebaseAuth: Auth = getAuth(FirebaseApp);

export const FirebaseDatabase: Database = getDatabase(FirebaseApp);

export const FirebaseMessaging: Messaging = getMessaging(FirebaseApp);
