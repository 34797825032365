import { FeedActivity } from './FeedActivity';
import { GetStreamFeed } from './GetStreamFeed';
import { action, computed, observable } from 'mobx';
import { GetStreamUpdate } from './GetStreamUpdate';

export class FlatFeedResponse {
  @observable.shallow
  results: FeedActivity[] = [];
  @observable
  next?: string;
  duration?: string;

  constructor(readonly feed: GetStreamFeed, json?: any) {
    if (json) {
      this.results = (json.results || []).map((r) => new FeedActivity(feed, r));
      this.next = json.next;
      this.duration = json.duration;
    }
  }

  /**
   * Removes feed entries from a feed like gym:<gymId>
   * @param origin the complete full id <slug>:<feedId>
   */
  @action
  removeFrom(origin: string) {
    for (let i = this.results.length - 1; i >= 0; i--) {
      const result = this.results[i];
      if (result.origin === origin) {
        this.results.splice(i, 1);
      }
    }
  }

  @action
  remove(ids: string[]) {
    ids.forEach((id: string) => {
      const index = this.results.findIndex((r) => r.id === id);
      if (index !== -1) {
        this.results.splice(index, 1);
      }
    });
  }

  @action
  update(update: GetStreamUpdate) {
    this.remove(update.deleted);
    update.newItems
      .filter((u) => this.results.findIndex((r) => r.id === u.id) === -1)
      .forEach((u) => {
        // on an update we unshift no matter what since the activities are new
        this.results.unshift(u);
      });
  }

  /**
   * Use this when you do not know if the results belong to the end or begining of the results like after an upate() -> following someone
   * @param response
   */
  @action
  pushOrUnshift(response: FlatFeedResponse) {
    response.results
      .filter((update) => this.results.findIndex((r) => r.id === update.id) === -1)
      .forEach((update: FeedActivity) => {
        const position = this.results.findIndex((i) => i.dateMoment.isBefore(update.dateMoment));
        if (position === -1) {
          // it's either empty or none of the current activities are older
          this.results.push(update);
        } else {
          this.results.splice(position, 0, update);
        }
      });
    this.next = response.next;
    this.duration = response.duration;
  }

  /**
   * Use this after simply fetching older activities (more performant than pushOrUnshift)
   * @param response
   */
  @action
  push(response: FlatFeedResponse) {
    response.results
      .filter((update) => this.results.findIndex((r) => r.id === update.id) === -1)
      .forEach((update: FeedActivity) => {
        this.results.push(update);
      });
    this.next = response.next;
    this.duration = response.duration;
  }

  /**
   * Use this after simply fetching newer activities
   * @param response
   */
  @action
  unshift(response: FlatFeedResponse) {
    // add in reverse order otherwise the ordering is wrong
    response.results
      .slice()
      .reverse()
      .filter((r) => this.results.findIndex((e) => e.id === r.id) === -1)
      .forEach((r: any) => this.results.unshift(r));
  }

  @computed
  get hasMore(): boolean {
    return this.next === undefined || this.next !== '';
  }

  @computed
  get lastId(): string | undefined {
    return this.results.length > 0 ? this.results[this.results.length - 1].id : undefined;
  }

  @computed
  get firstId(): string | undefined {
    return this.results.length > 0 ? this.results[0].id : undefined;
  }
}
