/**
 * Created by katarinababic on 10.5.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useRootStore } from '../../Store/useRootStore';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Button } from '../../Components/Button';

const Title = styled.h5`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled(Col)`
  margin-top: 1rem;
`;

export type AccountChangeEmailVerificationScreenProps = {};

export const AccountChangeEmailVerificationScreen: React.FC<AccountChangeEmailVerificationScreenProps> = observer(
  (props) => {
    const { authentication, firebaseAuth } = useRootStore();
    const { t } = useTrans();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const verificationCode = searchParams.get('verificationCode');

    const [processing, setProcessing] = React.useState(false);
    const [redirectDisabled, setRedirectDisabled] = React.useState(true);
    const [verificationCodeExpired, setVerificationCodeExpired] = React.useState(false);

    useEffect(() => {
      if (verificationCode) {
        setProcessing(true);
        authentication.athlete?.user
          .verifyEmailChange(verificationCode)
          .then((res) => {
            setRedirectDisabled(false);
          })
          .catch((e) => {
            setVerificationCodeExpired(true);
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    }, [authentication, verificationCode]);

    const title = verificationCodeExpired
      ? t('accountChangeEmailVerificationScreen.codeExpired', {
          defaultValue: 'The verification code is not valid or has expired.',
        })
      : t('accountChangeEmailVerificationScreen.verificationSuccess', {
          defaultValue: 'You have successfully changed your e-mail address!',
        });

    const subtitle = verificationCodeExpired
      ? t('accountChangeEmailVerificationScreen.codeExpiredSubtitle', {
          defaultValue: 'Please go back and request an email address change again.',
        })
      : t('accountChangeEmailVerificationScreen.redirectToLogin', {
          defaultValue: 'You will now be redirected to login with your new e-mail address.',
        });

    const buttonText = verificationCodeExpired
      ? t('accountChangeEmailVerificationScreen.goBack', { defaultValue: 'Go back' })
      : t('accountChangeEmailVerificationScreen.goToLogin', { defaultValue: 'Go to Login page' });
    const buttonDisabled = verificationCodeExpired ? false : redirectDisabled;

    const handleGoBack = React.useCallback(() => {
      navigate('/account');
    }, [navigate]);

    const handleRedirectToLogin = React.useCallback(() => {
      authentication.logout();
      firebaseAuth.signOut();

      navigate('/auth', { replace: true });
    }, [firebaseAuth, authentication, navigate]);

    return (
      <Container>
        <h1>{t('accountChangeEmailVerificationScreen.title', { defaultValue: 'Change email verification' })}</h1>
        <Row>
          <Title>{title}</Title>
          <p>{subtitle}</p>
          <ButtonContainer xs={3}>
            <Button
              block={true}
              disabled={processing || buttonDisabled}
              onClick={verificationCodeExpired ? handleGoBack : handleRedirectToLogin}
            >
              {buttonText}
            </Button>
          </ButtonContainer>
        </Row>
      </Container>
    );
  },
);
