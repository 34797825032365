/**
 * Created by neo on 02.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ElementRenderer } from './ElementRenderer';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import styled from '@emotion/styled';

const Child = styled.div`
  padding: 0 8px;
`;

export type ViewElementProps = {
  element: NativeFeedComponent;
};

export const ViewElement: React.FC<ViewElementProps> = observer(({ element }: ViewElementProps) => {
  return (
    <div>
      {element.children.map((child) => (
        <Child key={child.key}>
          <ElementRenderer element={child} />
        </Child>
      ))}
    </div>
  );
});
