/**
 * Created by neo on 02.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../../Store/useRootStore';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import { MarkdownRenderer } from '../../../Markdown/MarkdownRenderer';
import { LatoText } from '../../../Text/LatoText';
import styled from '@emotion/styled';
import { Theme } from '../../../../Theme/Theme';
import { logger } from '../../../../Utils/logger';

const SummaryContainer = styled.div`
  border-left: 4px solid ${Theme.Colors.primary};
`;

export type TextElementProps = {
  element: NativeFeedComponent;
};

export const TextElement: React.FC<TextElementProps> = observer(({ element }: TextElementProps) => {
  const { language } = useRootStore();

  logger('element', element);

  if (element.type === 'Heading') {
    return <LatoText text={element.getText(language.language)} variant="title" />;
  } else if (element.type === 'ItalicHeading') {
    return <LatoText text={element.getText(language.language)} variant="titleItalic" />;
  } else if (element.type === 'Caption') {
    return <LatoText text={element.getText(language.language)} variant="captionItalic" />;
  } else if (element.type === 'InnerBoldText') {
    return (
      <LatoText>
        <LatoText text={element.getText(language.language)} variant="body1" />
        <LatoText text={element.children[0]?.getText(language.language) ?? ''} variant="body2" />
        <LatoText text={element.children[1]?.getText(language.language) ?? ''} variant="body1" />
      </LatoText>
    );
  } else if (element.type === 'ItalicParagraph') {
    return <LatoText text={element.getText(language.language)} variant="bodyItalic" />;
  } else if (element.type === 'Summary') {
    return (
      <SummaryContainer>
        <LatoText style={styles.summaryText} variant="caption2" text={element.getText(language.language)} />
      </SummaryContainer>
    );
  } else if (element.type === 'InnerLinkText') {
    return (
      <LatoText>
        <MarkdownRenderer
          text={element.getText(language.language)}
          variant="body1"
          // style={element.props.style.toPOJO()}
        />
        {element.children.map((child) => (
          <MarkdownRenderer
            key={child.key}
            text={child.getText(language.language)}
            variant="body1"
            // style={element.props.style.toPOJO()}
          />
        ))}
      </LatoText>
    );
  }

  return (
    <MarkdownRenderer
      text={element.getText(language.language)}
      variant="body1"
      // style={element.props.style.toPOJO()}
    />
  );
});

const styles = {
  linkStyle: {
    color: Theme.Colors.authenticationLinkColor,
  },
  summaryText: {
    paddingLeft: 8,
    fontSize: 18,
  },
};
