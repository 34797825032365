/**
 * Created by neo on 01.04.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginRequestView } from './LoginRequestView';
import { LoginCheckInboxView } from './LoginCheckInboxView';
import { LoginVerifyAuthenticationView } from './LoginVerifyAuthenticationView';

export type LoginScreenProps = {};

export const LoginScreen: React.FC<LoginScreenProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const authCode = searchParams.get('authCode');

  const [viewState, setViewState] = useState<'request' | 'submitted' | 'verify'>(authCode ? 'verify' : 'request');
  const [email, setEmail] = useState('');

  const handleChangeEmail = React.useCallback((email) => setEmail(email), []);

  const handleRequestSubmitted = React.useCallback(() => setViewState('submitted'), []);

  const handleResetViewState = React.useCallback(() => {
    setViewState('request');
    navigate('/auth', { replace: true });
  }, [navigate]);

  if (viewState === 'submitted') {
    return <LoginCheckInboxView email={email} onBack={handleResetViewState} authCode={authCode} />;
  } else if (viewState === 'verify' && authCode) {
    return <LoginVerifyAuthenticationView authCode={authCode} onBack={handleResetViewState} />;
  }

  return <LoginRequestView onSubmitted={handleRequestSubmitted} onChangeEmail={handleChangeEmail} />;
});
