/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry, ActivityWorkoutEntryJson } from './ActivityWorkoutEntry';
import { Instructor, InstructorJson } from './Instructor';

export type YogaEntryJson = ActivityWorkoutEntryJson & {
  narrator: InstructorJson;
};

export class YogaEntry extends ActivityWorkoutEntry {
  @observable.ref
  narrator = new Instructor();

  constructor(json?: Partial<YogaEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'yoga', activityIdentifier: 'yoga' }));
    if (json) {
      this.type = 'yoga';
      this.instructor = new Instructor(json.instructor);
      this.narrator = new Instructor(json.narrator);
      this.recommendedMusicKey = json.recommendedMusicKey;
    }
  }

  toJS(): YogaEntryJson {
    return Object.assign(super.toJS(), {
      type: 'yoga',
      narrator: this.narrator.toJS(),
    });
  }

  static find(request?: Omit<ExploreEntryQueryRequest, 'type'>): Promise<YogaEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request ?? {}), { type: 'yoga' })).then((res) =>
      (res ?? []).map((r) => new YogaEntry(r)),
    );
  }

  static random(request?: Omit<ExploreEntryQueryRequest, 'type'>): Promise<YogaEntry[]> {
    return HttpBackend.get(`/coach/explore/random`, Object.assign(toJS(request ?? {}), { type: 'yoga' })).then(
      (res) => (res ?? []).map((r) => new YogaEntry(r)),
    );
  }

  static findOne(id: string): Promise<YogaEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new YogaEntry(res));
  }
}
