import React from 'react';
import { AppleDownloadButton } from '../Buttons/AppleDownloadButton';
import { GoogleDownloadButton } from '../Buttons/GoogleDownloadButton';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: auto;
`;

const Text = styled.p`
  font-weight: 800;
  line-height: 48px;
  opacity: 0.5;
  color: #fff;
  margin: 0 0 40px;
  text-align: right;
  font-size: 48px;

  @media (min-width: 600px) {
    font-size: calc(1.45rem + 3vw);
    line-height: calc(1.25rem + 3vw);
  }
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;

export type AuthOverlayRightProps = {
  text: string;
};

export const AuthOverlayRight: React.FC<AuthOverlayRightProps> = observer(({ text }) => {
  return (
    <Container>
      <Text dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, '<br />') }} />
      <ButtonContainer>
        <AppleDownloadButton />
        <GoogleDownloadButton />
      </ButtonContainer>
    </Container>
  );
});
