import { action, observable, toJS } from 'mobx';

export type ParamValues = {
  [key: string]: any;
};

export type PipelineContextJson = {
  tags: string[];
  equipmentTypes: string[];
  excludeExerciseIds: string[];
  paramValues: ParamValues;
  executeUntilStageId?: string;
  debug?: boolean;
};

export class PipelineContext {
  @observable
  tags: string[] = [];
  @observable
  equipmentTypes: string[] = [];
  @observable
  excludeExerciseIds: string[] = [];
  @observable
  paramValues: ParamValues = {};
  executeUntilStageId?: string;

  constructor(json?: any) {
    if (json) {
      this.tags = json.tags || [];
      this.equipmentTypes = json.equipmentTypes || [];
      this.excludeExerciseIds = json.excludeExerciseIds ?? [];
      this.executeUntilStageId = json.executeUntilStageId;
      this.paramValues = json.paramValues || {};
    }
  }

  copy(params?: any): PipelineContext {
    return new PipelineContext(Object.assign(this.toJS(), params ?? {}));
  }

  toJS(): any {
    return {
      tags: toJS(this.tags),
      equipmentTypes: toJS(this.equipmentTypes),
      paramValues: this.paramValues,
      excludeExerciseIds: toJS(this.excludeExerciseIds),
      executeUntilStageId: this.executeUntilStageId,
    };
  }

  @action
  merge(other: PipelineContext) {
    return new PipelineContext({
      tags: this.tags.concat(other.tags),
      equipmentTypes: this.equipmentTypes.concat(other.equipmentTypes),
      paramValues: Object.assign(this.paramValues, other.paramValues),
    });
  }
}
