import { FlatFeedResponse } from './FlatFeedResponse';
import { GetStreamUpdate } from './GetStreamUpdate';
import { NotificationFeedResponse } from './NotificationFeedResponse';
import { GetStreamNotificationUpdate } from './GetStreamNotificationUpdate';
import { FeedActivityNotificationUpdate } from './FeedActivityNotificationUpdate';
import { StreamFeed, StreamClient } from 'getstream';
import { FeedToken } from '../FeedToken';
import { computed, observable, runInAction } from 'mobx';
import { Retry } from '../../../Utils/Retry';
import { sleep } from '../../../Utils/Sleep';
import { logger } from '../../../Utils/logger';

const FETCH_SIZE = 20;

export class GetStreamFeed {
  private notificationHandler?: (update: GetStreamNotificationUpdate) => any;

  readonly feed: StreamFeed;

  @observable
  currentFetchOlder?: Promise<FlatFeedResponse>;
  @observable
  currentFetchNewer?: Promise<FlatFeedResponse>;
  @observable
  flatResult: FlatFeedResponse;
  @observable
  notificationResult: NotificationFeedResponse;
  subscribed: boolean = false;

  constructor(
    readonly client: StreamClient,
    readonly slug: string,
    readonly feedId: string,
    readonly userId: string,
    readonly token: FeedToken,
  ) {
    this.feed = client.feed(slug, feedId, token.token);
    this.flatResult = new FlatFeedResponse(this);
    this.notificationResult = new NotificationFeedResponse(this);
  }

  subscribe(): Promise<any> {
    if (!this.subscribed) {
      logger(`Feed Subscribing to ${this.slug}:${this.feedId}`);
      this.subscribed = true;
      return this.feed.subscribe(this.onMessage).then((response) => logger('subscribed getstream.io', response));
    } else {
      logger(`Feed Already subscribed to ${this.slug}:${this.feedId}`);
    }
    return Promise.resolve();
  }

  /**
   * Removes feed entries from a feed like gym:<gymId>
   * @param origin the complete full id <slug>:<feedId>
   */
  removeFrom(origin: string) {
    this.flatResult.removeFrom(origin);
  }

  follow(slug: string, feedId: string, update: boolean = true): Promise<GetStreamFeed> {
    return Retry.tryTimes(() => this.feed.follow(slug, feedId))
      .then(() => sleep(1000))
      .then(() => (update ? this.update() : undefined))
      .then(() => this);
  }

  unfollow(slug: string, feedId: string): Promise<void> {
    return Retry.tryTimes(() => this.feed.unfollow(slug, feedId)).then(() => this.removeFrom(`${slug}:${feedId}`));
  }

  onMessage = (data: any) => {
    logger(`getstream message: ${this.slug}:${this.feedId}`, data);
    const update = GetStreamUpdate.fromJson(this, data);
    this.flatResult.update(update);
  };

  subscribeNotification(handler: (update: GetStreamNotificationUpdate) => any): Promise<any> {
    this.notificationHandler = handler;
    return this.feed.subscribe((update: any) => {
      const newItems = (update.new || []).map((u: any) => new FeedActivityNotificationUpdate(this, u));
      handler(new GetStreamNotificationUpdate(update.deleted || [], update.deleted_foreign_ids || [], newItems));
    });
  }

  unsubscribe() {
    logger(`Feed unsubscribing from ${this.slug}:${this.feedId}`);
    this.subscribed = false;
    this.notificationHandler = undefined;
    this.feed.unsubscribe();
  }

  /**
   * Costly operation that should be called carefully. Do not just call it because it's easier to use.
   * Use it only when you expect new activities but do not know if they are "older" than the current oldest one or newer than the current newest one.
   * A good use case is after following someone -> there we cannot tell if the activities are newer, older or in between.
   * Algorithm: <fetchNewer from newest> <--- [newest entry / top entry] ---> <fetchOlder from newest>
   */
  async update() {
    // let remember our current newest activity
    const currentNewestId = this.firstFlatId;
    logger('currentNewestId', currentNewestId);
    await this.fetchTillNewest();
    return this.fetchTillOldest(currentNewestId, 3);
  }

  /**
   * Uses fetchNewer but iterates till there are no newer feed entries
   */
  async fetchTillNewest() {
    let response: FlatFeedResponse | undefined;
    while (!response || response.hasMore) {
      response = await this.fetchNewer();
      if (!response?.results.length) {
        // return just in case it gets stuck;
        return;
      }
    }
  }

  /**
   * like fetchTillNewest but with an optional starting point only used for update()
   * @param id_lt
   * @param iterations limit the fetch count since this could end up in a lot of calls
   */
  private async fetchTillOldest(id_lt?: string, iterations: number = 3) {
    let response: FlatFeedResponse | undefined;
    let iteration = 0;
    while ((!response || response.hasMore) && iteration++ < iterations) {
      const options = {
        userId: this.userId,
        limit: FETCH_SIZE,
        id_lt: id_lt,
        enrich: true,
        reactions: { own: true, recent: true, counts: true },
      };
      response = await Retry.tryTimes(() => this.feed.get(options)).then(
        (result: any) => new FlatFeedResponse(this, result),
      );
      if (!response?.results.length) {
        // return just in case it gets stuck;
        return;
      }
      this.flatResult.pushOrUnshift(response);
      id_lt = response.results[response.results.length - 1].id;
    }
  }

  /**
   * Like fetchOlder but queries by using `id_gt`
   * @param params some optional additional params
   */
  async fetchNewer(params?: any): Promise<FlatFeedResponse | undefined> {
    if (!this.currentFetchNewer) {
      const options = Object.assign(params || {}, {
        userId: this.userId,
        limit: FETCH_SIZE,
        id_gt: this.firstFlatId,
        enrich: true,
        reactions: { own: true, recent: true, counts: true },
      });
      this.currentFetchNewer = Retry.tryTimes(() => this.feed.get(options)).then(
        (result: any) => new FlatFeedResponse(this, result),
      );
      const response = await this.currentFetchNewer;
      this.currentFetchNewer = undefined;
      runInAction(() => {
        this.flatResult.unshift(response);
        this.currentFetchNewer = undefined;
      });
      return response;
    }
    return this.currentFetchNewer;
  }

  /**
   * Fetches older items (based on the oldest activity ID)
   */
  async fetchOlder(): Promise<FlatFeedResponse | undefined> {
    if (this.flatResult.hasMore && !this.currentFetchOlder) {
      const options = {
        userId: this.userId,
        limit: FETCH_SIZE,
        id_lt: this.lastFlatId,
        enrich: true,
        reactions: { own: true, recent: true, counts: true },
      };
      const response = await Retry.tryTimes(() => this.feed.get(options)).then(
        (result: any) => new FlatFeedResponse(this, result),
      );
      runInAction(() => {
        this.flatResult.push(response);
        this.currentFetchOlder = undefined;
      });
      return response;
    }
    return this.currentFetchOlder;
  }

  @computed
  get fetching(): boolean {
    return !!this.currentFetchOlder || !!this.currentFetchNewer;
  }

  @computed
  get lastFlatId(): string | undefined {
    return this.flatResult.lastId;
  }

  @computed
  get firstFlatId(): string | undefined {
    return this.flatResult.firstId;
  }

  @computed
  get hasMoreFlat(): boolean {
    return this.flatResult.hasMore;
  }

  @computed
  get hasMoreNotification(): boolean {
    return this.notificationResult.hasMore;
  }

  getNotification(params?: any): Promise<NotificationFeedResponse> {
    return Retry.tryTimes(() => this.feed.get(params)).then(
      (result: any) => new NotificationFeedResponse(this, result),
    );
  }
}
