import { IReactionDisposer } from 'mobx';

export class DisposableStore {
  disposers: IReactionDisposer[] = [];

  dispose(): void {
    while (this.disposers.length > 0) {
      const disposer = this.disposers.pop();
      disposer && disposer();
    }
  }
}
