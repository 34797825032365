import { TagCondition, TagConditionJson } from '../Coach/SuperMacro/TagCondition';
import { languagePriority, LocalizedEntity, LocalizedEntityJson } from '../LocalizedEntity';
import { computed, observable, toJS } from 'mobx';
import { Media, MediaJson } from '../Media/Media';
import { Pageable } from '../Interfaces/Pageable';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { notUndefined } from '../../Utils/notUndefined';

export type ExploreContentType = 'category' | 'entry';

export type ExploreContentViewType = 'small_cards' | 'big_cards' | 'full_size';

export type ExploreContentQueryRequest = Pageable & {
  query?: string;
  type?: string[];
  categories?: string[];
  tags?: string[];
  gymId?: string;
  organizationId?: string;
  contentType?: string;
  root?: boolean;
};

/**
 * Created by neo on 11.03.22.
 */

export type ExploreContentJson = LocalizedEntityJson & {
  contentType: string;
  allParentIdentifiers: string[];
  type?: string;
  tags: string[];
  categories: string[];
  organizationId?: string;
  gymId?: string;
  order: number;
  images: MediaJson[];
  videos: MediaJson[];
  publishDate?: string;
  unpublishDate?: string;
  tagConditions: TagConditionJson[];
  viewType?: ExploreContentViewType;
  shortDescription: LocalizedValueJson[];
  language?: string;
};

export class ExploreContent extends LocalizedEntity {
  @observable
  contentType: string = 'entry';
  @observable
  allParentIdentifiers: string[] = [];
  @observable
  type: string = 'default';
  @observable
  tags: string[] = [];
  @observable
  categories: string[] = [];
  @observable
  organizationId?: string;
  @observable
  gymId?: string;
  @observable
  order = 0;
  @observable
  images: Media[] = [];
  @observable
  videos: Media[] = [];
  @observable
  publishDate?: Date;
  @observable
  unpublishDate?: Date;
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  viewType?: ExploreContentViewType;
  @observable
  shortDescription: LocalizedValue[] = [];
  @observable
  language?: string;

  constructor(json?: Partial<ExploreContentJson>) {
    super(json);
    if (json) {
      this.allParentIdentifiers = json.allParentIdentifiers ?? [];
      this.contentType = json.contentType ?? 'entry';
      this.type = json.type ?? 'preview';
      this.tags = json.tags ?? [];
      this.categories = json.categories ?? [];
      this.organizationId = json.organizationId;
      this.gymId = json.gymId;
      this.order = json.order ?? 0;
      this.images = json.images?.map((m) => new Media(m)) ?? [];
      this.videos = (json.videos ?? []).map((t) => new Media(t));
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.publishDate = json.publishDate ? new Date(json.publishDate) : undefined;
      this.unpublishDate = json.unpublishDate ? new Date(json.unpublishDate) : undefined;
      this.viewType = json.viewType;
      this.shortDescription = (json.shortDescription ?? []).map((s) => new LocalizedValue(s));
      this.language = json.language;
    }
  }

  toJS(newId: boolean = false): ExploreContentJson {
    return Object.assign(super.toJS(newId), {
      allParentIdentifiers: this.allParentIdentifiers,
      contentType: this.contentType,
      type: this.type,
      tags: toJS(this.tags),
      categories: toJS(this.categories),
      organizationId: this.organizationId,
      gymId: this.gymId,
      order: this.order,
      images: this.images.map((i) => i.toJS()),
      videos: this.videos.map((v) => v.toJS()),
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      publishDate: this.publishDate?.toISOString(),
      unpublishDate: this.unpublishDate?.toISOString(),
      viewType: this.viewType,
      shortDescription: this.shortDescription.map((s) => s.toJS()),
    });
  }

  getShortDescription(lang: string): string {
    return this.shortDescription.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultShortDescription;
  }

  getVideo(lang: string): Media | undefined {
    const languageVideo = this.videos.find((a) => a.language?.toLowerCase() === lang.toLowerCase());
    const germanVideo = this.videos.find((a) => a.language?.toLowerCase() === 'de');
    return languageVideo ?? germanVideo ?? this.videos[0];
  }

  getImage(lang: string): Media | undefined {
    const languageMedia = this.images.find((a) => a.language?.toLowerCase() === lang.toLowerCase());
    const germanMedia = this.images.find((a) => a.language?.toLowerCase() === 'de');
    return languageMedia ?? germanMedia ?? this.images[0];
  }

  @computed
  get isRoot(): boolean {
    return this.categories.length === 0;
  }

  @computed
  get defaultShortDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.shortDescription.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.shortDescription[0];
    return first?.value ?? '';
  }

  /**
   * TODO just temporary remove later
   */
  @computed
  get image(): Media | undefined {
    return this.images[0];
  }

  @computed
  get availableLanguages(): string[] {
    return this.videos
      .flatMap((s) => s.language)
      .filter((v) => !!v)
      .filter(notUndefined);
  }
}
