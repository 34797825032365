/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import { useRootStore } from '../../../Store/useRootStore';
import styled from '@emotion/styled';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { VideoEntry } from '../../../Model/Explore/VideoEntry';
import { MarkdownRenderer } from '../../Markdown/MarkdownRenderer';

const Title = styled.h1``;

export type ExploreContentViewArticleContentProps = {
  entry: VideoEntry;
};

export const ExploreContentViewVideoContent: React.FC<ExploreContentViewArticleContentProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const title = entry.getName(language.language);
  const content = entry.getDescription(language.language);

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <MarkdownRenderer text={content} />
    </React.Fragment>
  );
});
