/**
 * Created by neo on 20.01.21.
 */
import { observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';

export type MediaVideoTrackJson = {
  trackId: string;
  bitrate: number;
  codecs: string[];
  width: number;
  height: number;
  url: string;
};

export class MediaVideoTrack {
  @observable
  trackId: string = '';
  @observable
  bitrate: number = 0;
  @observable
  codecs: string[] = [];
  @observable
  width: number = 0;
  @observable
  height: number = 0;
  @observable
  url: string = '';

  constructor(json?: Partial<MediaVideoTrackJson>) {
    if (json) {
      this.trackId = json.trackId ?? UUID();
      this.bitrate = json.bitrate ?? 0;
      this.codecs = json.codecs ?? [];
      this.width = json.width ?? 0;
      this.height = json.height ?? 0;
      this.url = json.url ?? '';
    }
  }

  toJS(): MediaVideoTrackJson {
    return {
      trackId: this.trackId,
      bitrate: this.bitrate,
      codecs: toJS(this.codecs),
      width: this.width,
      height: this.height,
      url: this.url,
    };
  }
}
