/**
 * Created by neo on 10.01.21.
 */
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import {
  computed,
  IReactionDisposer,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
} from 'mobx';
import { ExploreCategory } from './ExploreCategory';
import { Media, MediaJson } from '../Media/Media';
import { NotificationMessage, NotificationMessageJson } from './NotificationMessage';
import { translate } from '../../Store/System/LocalizationStore';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreContent, ExploreContentJson } from './ExploreContent';
import { ExploreEntryOrientation } from './ExploreEntryOrientation';

export type ExploreEntryJson = ExploreContentJson & {
  backgroundImage?: MediaJson;
  backgroundVideos: MediaJson[];
  notificationMessages: NotificationMessageJson[];
  quotes: LocalizedValueJson[];
  orientation: ExploreEntryOrientation;
  spotifyPlaylistLink?: string;
};

export class ExploreEntry extends ExploreContent {
  @observable
  categories: string[] = [];
  @observable
  backgroundImage?: Media;
  @observable
  backgroundVideos: Media[] = [];
  @observable
  notificationMessages: NotificationMessage[] = [];
  @observable
  quotes: LocalizedValue[] = [];
  @observable
  orientation: ExploreEntryOrientation = 'landscape';
  @observable
  categoryData?: ExploreCategory[];
  @observable
  spotifyPlaylistLink?: string;

  /** a non-persistent property used to show / pause video in categories where viewType = 'full_size' if there is one **/
  @observable
  visible = false;
  categoryFetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<ExploreEntryJson>) {
    super(json);
    if (json) {
      this.backgroundImage = json.backgroundImage ? new Media(json.backgroundImage) : undefined;
      this.backgroundVideos = (json.backgroundVideos ?? []).map((m) => new Media(m));
      this.notificationMessages = (json.notificationMessages ?? []).map((n) => new NotificationMessage(n));
      this.quotes = (json.quotes ?? []).map((q) => new LocalizedValue(q));
      this.orientation = json.orientation ?? 'landscape';
      this.spotifyPlaylistLink = json.spotifyPlaylistLink;
    }
    onBecomeObserved(this, 'categoryData', this.startFetchCategories);
    onBecomeUnobserved(this, 'categoryData', this.stopFetchCategories);
  }

  startFetchCategories = () => {
    this.categoryFetchDisposer = reaction(
      () => this.categories.map((c) => c),
      (categories) => {
        if (categories.length > 0) {
          ExploreCategory.findAll(categories).then((result) => runInAction(() => (this.categoryData = result)));
        } else {
          runInAction(() => (this.categoryData = []));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchCategories = () => {
    this.categoryFetchDisposer && this.categoryFetchDisposer();
    this.categoryFetchDisposer = undefined;
  };

  toJS(newId: boolean = false): ExploreEntryJson {
    return Object.assign(super.toJS(newId), {
      backgroundImage: this.backgroundImage?.toJS(),
      backgroundVideos: this.backgroundVideos.map((c) => c.toJS()),
      notificationMessages: this.notificationMessages.map((n) => n.toJS()),
      quotes: this.quotes.map((q) => q.toJS()),
      orientation: this.orientation,
      spotifyPlaylistLink: this.spotifyPlaylistLink,
    });
  }

  getNotificationTitle(language: string, tags: string[]): string {
    return (
      this.notificationMessages.find((m) => m.tagCondition.matches(tags))?.title?.find((l) => l.lang === language)
        ?.value ??
      translate(`explore.scheduler.notificationTitle.${this.type}`, { defaultValue: this.getName(language) })
    );
  }

  getNotificationMessage(language: string, tags: string[]): string {
    return (
      this.notificationMessages.find((m) => m.tagCondition.matches(tags))?.message?.find((l) => l.lang === language)
        ?.value ?? translate(`explore.scheduler.notificationTitle.${this.type}`)
    );
  }

  static count(request?: Partial<ExploreEntryQueryRequest>): Promise<number> {
    return HttpBackend.get('/coach/explore/count', Object.assign({}, request ?? {}, { contentType: 'entry' })).then(
      (res) => res ?? 0,
    );
  }

  @computed
  get tagString(): string {
    return this.tagConditions.map((t) => t.tagString).join(' | ');
  }

  @computed
  get media(): Media | undefined {
    return this.image;
  }
}
