/**
 * Created by neo on 10.01.21.
 */
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ActivityWorkoutEntry, ActivityWorkoutEntryJson } from './ActivityWorkoutEntry';
import { MeditationNarrator, MeditationNarratorJson } from './MeditationNarrator';

export type MeditationEntryJson = ActivityWorkoutEntryJson & {
  narrators: MeditationNarratorJson[];
};

export class MeditationEntry extends ActivityWorkoutEntry {
  @observable
  narrators: MeditationNarrator[] = [];

  constructor(json?: Partial<MeditationEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'meditation', activityIdentifier: 'mindAndBody' }));
    if (json) {
      this.type = 'meditation';
      this.narrators = (json.narrators ?? []).map((n) => new MeditationNarrator(n));
    }
  }

  static async find(request?: Omit<ExploreEntryQueryRequest, 'type'>): Promise<MeditationEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request ?? {}), { type: 'meditation' })).then(
      (res) => (res ?? []).map((r) => new MeditationEntry(r)),
    );
  }

  static async findOne(id: string): Promise<MeditationEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new MeditationEntry(res));
  }
}
