import { HealthDataPoint, HealthDataPointJson } from './HealthDataPoint';
import { observable } from 'mobx';
import { HealthDataPointCategoryType } from './HealthDataPointCategoryType';
import { HealthDataPointType } from './HealthDataPointType';

export type HealthDataPointCategoryJson = {
  type: HealthDataPointCategoryType;
  value: string;
} & HealthDataPointJson;

export class HealthDataPointCategory extends HealthDataPoint {
  @observable
  type: HealthDataPointCategoryType = HealthDataPointCategoryType.audioExposureEvent;
  @observable
  value: string = '';

  constructor(json?: Partial<HealthDataPointCategoryJson>) {
    super(Object.assign(json ?? {}, { pointType: HealthDataPointType.category }));
    if (json) {
      this.type = json.type ?? HealthDataPointCategoryType.audioExposureEvent;
      this.value = json.value ?? '';
    }
  }

  toJS(): HealthDataPointCategoryJson {
    return Object.assign(super.toJS(), {
      type: this.type,
      value: this.value,
    });
  }

  save(): Promise<HealthDataPointCategory> {
    return super.save().then(() => this);
  }
}
