/**
 * Created by neo on 01.02.21.
 */
import { TagCondition, TagConditionJson } from '../Coach/SuperMacro/TagCondition';
import { MediaNarratorAudioTrack, MediaNarratorAudioTrackJson } from './MediaNarratorAudioTrack';
import { computed, observable } from 'mobx';
import { Media } from '../Media/Media';
import { languagePriority } from '../LocalizedEntity';
import { Instructor, InstructorJson } from './Instructor';

export type MeditationNarratorJson = InstructorJson & {
  tagConditions: TagConditionJson[];
  audioTracks: MediaNarratorAudioTrackJson[];
};

export class MeditationNarrator extends Instructor {
  @observable
  tagConditions: TagCondition[] = [];
  @observable
  audioTracks: MediaNarratorAudioTrack[] = [];

  constructor(json?: Partial<MeditationNarratorJson>) {
    super(json);
    if (json) {
      this.tagConditions = (json.tagConditions ?? []).map((t) => new TagCondition(t));
      this.audioTracks = (json.audioTracks ?? []).map((a) => new MediaNarratorAudioTrack(a));
    }
  }

  toJS(): MeditationNarratorJson {
    return Object.assign(super.toJS(), {
      tagConditions: this.tagConditions.map((t) => t.toJS()),
      audioTracks: this.audioTracks.map((t) => t.toJS()),
    });
  }

  getAudioTrack(lang: string) {
    return this.audioTracks.find((n) => n.language === lang.toLowerCase())?.media || this.defaultAudioTrack;
  }

  @computed
  get defaultAudioTrack(): Media {
    for (const lang of languagePriority) {
      const entry = this.audioTracks.find((l) => l.language === lang);
      if (entry) {
        return entry.media;
      }
    }
    return this.audioTracks[0]?.media;
  }
}
