import { AuthenticationStore } from './AuthenticationStore';
import { reaction } from 'mobx';
import { logger } from '../Utils/logger';

export class SupportStore {
  constructor(readonly authentication: AuthenticationStore) {
    reaction(
      () => authentication.athlete,
      (athlete) => {
        if (athlete?.user.email && (window as any).FreshworksWidget) {
          (window as any).FreshworksWidget('identify', 'ticketForm', {
            name: athlete.fullName,
            email: athlete.user.email,
          });
        } else if ((window as any).FreshworksWidget) {
          logger('hiding widget');
          (window as any).FreshworksWidget('identify', 'ticketForm', {
            name: '',
            email: '',
          });
          // (window as any).FreshworksWidget('hide');
        }
      },
      { fireImmediately: true },
    );
  }
}
