import { FirebaseMessaging } from './firebase';
import { AuthenticationStore } from '../AuthenticationStore';
import { LocalizationStore } from '../System/LocalizationStore';
import { autorun, reaction, runInAction } from 'mobx';
import { getToken } from 'firebase/messaging';
import { logger } from '../../Utils/logger';
import { NotificationToken } from '../../Model/Message/NotificationToken';
import { Config } from '../../Config/Config';
import { onMessage } from 'firebase/messaging';

export class NotificationStore {
  readonly messaging = FirebaseMessaging;
  private hasPermission = false;

  private token: string;

  constructor(readonly authentication: AuthenticationStore, readonly language: LocalizationStore) {
    onMessage(this.messaging, (payload) => {
      logger('received message', payload);
    });

    reaction(
      () => this.authentication.athleteId,
      (athleteId) => {
        if (athleteId) {
          this.mayRegisterNotification();
        } else if (this.token) {
          this.tryUnregisterToken(this.token);
        }
      },
      { fireImmediately: true },
    );
  }

  private tryRegisterToken(token: string) {
    logger('NotificationStore::tryRegisterToken', token);
    return NotificationToken.registerToken(token).then(() => token);
  }

  private tryUnregisterToken(token: string) {
    logger('NotificationStore::tryUnregisterToken', token);
    return NotificationToken.unregisterToken(token);
  }

  requestPermission(): Promise<string | void> {
    return Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        return this.mayRegisterNotification();
      }
      return Promise.resolve();
    });
  }

  private mayRegisterNotification(): Promise<string | void> {
    return getToken(this.messaging, { vapidKey: Config.firebase.messaging.publicKey })
      .then((token) => {
        logger('NotificationStore::mayRegisterNotification', token);
        runInAction(() => (this.token = token));
        if (token) {
          return this.tryRegisterToken(token);
        }
        return token;
      })
      .catch((err) => {
        logger('Notification::mayRegisterNotification error', err);
        logger(err);
      });
  }
}
