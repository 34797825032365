/**
 * Created by neo on 05.04.23.
 */
import { observable } from 'mobx';
import AppBackend from '../../Services/Http/AppBackend';
import {CustomerGroup} from "../CustomerGroup/CustomerGroup";

export type CoachRegistrationRequestJson = {
  verificationCode: string;
  gymIdHash: string;
  email: string;
  firstname?: string;
  lastname?: string;
};

export class CoachRegistrationRequest {
  @observable
  verificationCode = '';
  @observable
  gymIdHash = '';
  @observable
  email = '';
  @observable
  firstname?: string;
  @observable
  lastname?: string;

  constructor(json?: Partial<CoachRegistrationRequestJson>) {
    if (json) {
      this.verificationCode = json.verificationCode ?? '';
      this.gymIdHash = json.gymIdHash ?? '';
      this.email = json.email ?? '';
      this.firstname = json.firstname;
      this.lastname = json.lastname;
    }
  }

  findGroups(): Promise<CustomerGroup[]> {
    return AppBackend.get(`/coach/registration/${this.verificationCode}/groups`).then(
        (res) => res?.map((r) => new CustomerGroup(r)) ?? [],
    );
  }

  static find(verificationCode: string): Promise<CoachRegistrationRequest> {
    return AppBackend.get(`/coach/registration/${verificationCode}`).then((res) => new CoachRegistrationRequest(res));
  }
}
