/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Container, Form, FormGroup, InputGroup, InputGroupText } from 'reactstrap';
import { FormLabel } from '../../../../Components/Form/FormLabel';
import { Input } from '../../../../Components/Input';
import InfoSvg from '../../../../Assets/SVG/info.svg';
import { LinkComponent } from '../../../../Components/LinkComponent';
import { Button } from '../../../../Components/Button';
import { TextWithMiddleLine } from '../../../../Components/TextWithMiddleLine';
import styled from '@emotion/styled';
import { useTrans } from '../../../../Store/System/LocalizationStore';
import { useState } from 'react';
import { RequestCodeRequest } from '../../../../Model/CoachSubscription/RequestCodeRequest';
import { runInAction } from 'mobx';
import { useRootStore } from '../../../../Store/useRootStore';
import { LoginBottomButton } from '../../../../Components/Auth/Buttons/LoginBottomButton';

const FormContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const inputGroupTextStyle: React.CSSProperties = {
  background: '#ffffff',
  borderWidth: 0,
};

const TouchableText = styled.p`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
  color: #e5842b;
  text-decoration: underline;
  text-decoration-color: #e5842b;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
`;

export type RegistrationRequestFormProps = {
  request: RequestCodeRequest;
  onClickBusinessEmailInfo?: () => void;
  onClickNoBusinessEmail?: () => void;
  onSubmitted?: () => void;
  onError?: (err?: string) => void;
};

export const RegistrationRequestForm: React.FC<RegistrationRequestFormProps> = observer(
  ({ request, onClickBusinessEmailInfo, onClickNoBusinessEmail, onSubmitted, onError }) => {
    const { t } = useTrans();
    const { analytics, authentication } = useRootStore();

    const [emailDirty, setEmailDirty] = useState(false);
    const [nameDirty, setNameDirty] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const buttonDisabled = request.invalid || submitting;

    const handleSubmit = React.useCallback(
      (e) => {
        e.preventDefault();
        setSubmitting(true);
        request
          .requestCode()
          .then(() => analytics.logEvent('registration_code_requested'))
          .then(() => onSubmitted && onSubmitted())
          .catch((e) => {
            const errorCode = e.response?.data?.errorCode;
            analytics.logError('registration_code_request', errorCode);
            if (errorCode === 'email_already_associated') {
              authentication.requestMagicLink(request.email).then(() => analytics.logEvent('signup_magic_link_sent'));
            }
            onError && onError(errorCode);
          })
          .finally(() => setSubmitting(false));
      },
      [request, analytics, onSubmitted, onError, authentication],
    );

    const handleNameChanged = React.useCallback(
      (e) => {
        runInAction(() => (request.firstname = e.target.value));
      },
      [request],
    );

    const handleEmailChanged = React.useCallback(
      (e) => {
        runInAction(() => (request.email = e.target.value?.trim()?.toLowerCase()));
      },
      [request],
    );

    return (
      <Form onSubmit={handleSubmit}>
        <FormContainer>
          <FormGroup>
            <FormLabel>
              {t('registrationScreen.stepOne.nameLabel', { defaultValue: 'How do you want us to call you?' })}
            </FormLabel>
            <Input
              type="text"
              value={request.firstname}
              placeholder={t('registrationScreen.stepOne.namePlaceholder', { defaultValue: 'Your username' })}
              invalid={nameDirty && !request.nameValid}
              disabled={submitting}
              onChange={handleNameChanged}
              onBlur={() => setNameDirty(true)}
              data-lpignore="true"
              autocomplete={false}
              disabledColor={'#3c3c3c'}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>
              {t('registrationScreen.stepOne.emailLabel', { defaultValue: 'What is your business e-mail address?' })}
            </FormLabel>
            <InputGroup>
              <Input
                type="email"
                value={request.email}
                disabled={submitting}
                onChange={handleEmailChanged}
                onBlur={() => setEmailDirty(true)}
                data-lpignore="true"
                autocomplete={false}
                invalid={emailDirty && !request.emailValid}
                placeholder={t('registrationScreen.stepOne.emailPlaceholder', {
                  defaultValue: 'Your business e-mail address',
                })}
                disabledColor={'#3c3c3c'}
              />
              <InputGroupText style={inputGroupTextStyle}>
                <img
                  src={InfoSvg}
                  style={{ cursor: 'pointer' }}
                  alt="info"
                  height={24}
                  width={24}
                  onClick={onClickBusinessEmailInfo}
                />
              </InputGroupText>
            </InputGroup>
          </FormGroup>
          <TouchableText onClick={onClickNoBusinessEmail}>
            {t('registrationScreen.stepOne.noBusinessEmail', { defaultValue: "I don't own a business e-mail address" })}
          </TouchableText>
        </FormContainer>
        <Button type="submit" block={true} disabled={buttonDisabled}>
          {t('registrationScreen.continue', { defaultValue: "Let's do this!" }).toUpperCase()}
        </Button>
        <TextWithMiddleLine text={t('globals.or', { defaultValue: 'Or' })} />
        <LoginBottomButton />
      </Form>
    );
  },
);
