import { action, computed, observable } from 'mobx';
import { NotificationFeedResult } from './NotificationFeedResult';
import { GetStreamFeed } from './GetStreamFeed';

export class NotificationFeedResponse {
  @observable.shallow
  results: NotificationFeedResult[] = [];
  next?: string;
  duration?: string;
  @observable
  unseen: number = 0;
  @observable
  unread: number = 0;

  constructor(readonly feed: GetStreamFeed, json?: any) {
    if (json) {
      this.results = (json.results || []).map((r: any) => new NotificationFeedResult(feed, r));
      this.next = json.next;
      this.duration = json.duration;
      this.unread = json.unread || 0;
      this.unseen = json.unseen || 0;
    }
  }

  @action
  merge(response: NotificationFeedResponse) {
    response.results.forEach((r: any) => this.results.push(r));
    this.unseen = response.unseen;
    this.unread = response.unread;
    this.next = response.next;
    this.duration = response.duration;
  }

  @computed
  get hasMore(): boolean {
    return this.next === undefined || this.next !== '';
  }

  @computed
  get lastId(): string | undefined {
    return this.results[this.results.length - 1]?.id;
  }

  @computed
  get firstId(): string {
    return this.results[0]?.id;
  }
}
