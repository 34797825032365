/**
 * Created by neo on 04.03.17.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media } from '../Media/Media';

export type GymType = 'gym' | 'creator' | 'coach';

export class Gym {
  @observable
  id: string = '';
  @observable
  name: string = '';
  @observable
  type: GymType = 'gym';
  @observable
  virtual = false;
  @observable.ref
  logo?: Media;
  @observable.shallow
  medias: Media[] = [];

  constructor(json?: any) {
    if (json) {
      this.id = json.id || '';
      this.type = json.type || 'gym';
      this.name = json.name || '';
      this.virtual = json.virtual;
      this.logo = json.logo ? new Media(json.logo) : undefined;
      this.medias = (json.medias || []).map((m: any) => new Media(m));
    }
  }

  toJS(): any {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      logo: this.logo ? this.logo.toJS() : undefined,
      medias: this.medias.map((m) => m.toJS()),
    };
  }

  assign(athleteId?: string) {
    return HttpBackend.post(`/gym/customer/assign?gymId=${this.id}`, { athleteId });
  }

  static find(page: number = 0): Promise<Gym[]> {
    return HttpBackend.get('/gym', { page }).then((data) => data.map((g: any) => new Gym(g)));
  }

  static currentGym(athleteId?: string): Promise<Gym | undefined> {
    return HttpBackend.getOrCached('gyms', '/gym/customer/recentCoachGym', { athleteId }).then((result) =>
      result ? new Gym(result) : undefined,
    );
  }

  static async get(id: string): Promise<Gym | undefined> {
    const data = await HttpBackend.get(`/gym/${id}`);
    if (data) {
      return new Gym(data);
    }
    return undefined;
  }
}
