import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import calendarPlugin from 'dayjs/plugin/calendar';
import weekYearPlugin from 'dayjs/plugin/weekYear';
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(customParseFormat);
dayjs.extend(utcPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(calendarPlugin);
dayjs.extend(weekYearPlugin);
dayjs.extend(weekOfYearPlugin);
dayjs.extend(advancedFormatPlugin);
dayjs.extend(timezone);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(isBetween);
dayjs.extend(minMax);

export default dayjs;
