/**
 * Created by neo on 01.04.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { RequestCodeRequest } from '../../../../Model/CoachSubscription/RequestCodeRequest';
import { AuthLayout } from '../../../../Components/Auth/AuthLayout/AuthLayout';
import { BusinessEmailInfoAlert } from '../../../../Components/Auth/Alerts/BusinessEmailInfoAlert';
import { ContactHrAlert } from '../../../../Components/Auth/Alerts/ContactHrAlert';
import { RegistrationRequestForm, RegistrationRequestFormProps } from './RegistrationRequestForm';
import { useTrans } from '../../../../Store/System/LocalizationStore';
import { ErrorAlert } from '../../../../Components/Auth/Alerts/ErrorAlert';

export type RegistrationRequestViewProps = Pick<RegistrationRequestFormProps, 'onSubmitted'> & {
  request: RequestCodeRequest;
};

export const RegistrationRequestView: React.FC<RegistrationRequestViewProps> = observer(({ request, onSubmitted }) => {
  const { t } = useTrans();

  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showContactHrAlert, setShowContactHrAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

  const handleCloseAllAlerts = React.useCallback(() => {
    setShowErrorAlert(false);
    setShowInfoAlert(false);
    setShowContactHrAlert(false);
  }, []);

  const handleShowInfoAlert = React.useCallback(() => {
    handleCloseAllAlerts();
    setShowInfoAlert(true);
  }, [handleCloseAllAlerts]);

  const handleShowContactHrAlert = React.useCallback(() => {
    handleCloseAllAlerts();
    setShowContactHrAlert(true);
  }, [handleCloseAllAlerts]);

  const handleShowErrorAlert = React.useCallback(
    (error?: string) => {
      handleCloseAllAlerts();
      setErrorCode(error);
      setShowErrorAlert(true);
    },
    [handleCloseAllAlerts],
  );

  return (
    <AuthLayout
      title={t('registrationScreen.stepOne.title', { defaultValue: 'Start your registration' })}
      subtitle={t('registrationScreen.stepOne.subtitle', { defaultValue: 'Create your free account' })}
      text={t('registrationScreen.stepOne.text', {
        defaultValue:
          'Please use your business e-mail address to sign up. Like this we can confirm that you are eligible.',
      })}
      overlayRightTitle={t('authentication.introTitle', { defaultValue: 'Hello\n' + 'New\n' + 'You' })}
      image={require('../../../../Assets/Images/auth_1.png')}
    >
      <BusinessEmailInfoAlert
        isOpen={showInfoAlert}
        onDismiss={handleCloseAllAlerts}
        onClickNoBusinessEmail={handleShowContactHrAlert}
        toggle={handleCloseAllAlerts}
      />
      <ContactHrAlert toggle={handleCloseAllAlerts} onDismiss={handleCloseAllAlerts} isOpen={showContactHrAlert} />
      <ErrorAlert
        toggle={handleCloseAllAlerts}
        isOpen={showErrorAlert}
        title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
        onDismiss={handleCloseAllAlerts}
      />
      <RegistrationRequestForm
        request={request}
        onClickBusinessEmailInfo={handleShowInfoAlert}
        onClickNoBusinessEmail={handleShowContactHrAlert}
        onSubmitted={onSubmitted}
        onError={handleShowErrorAlert}
      />
    </AuthLayout>
  );
});
