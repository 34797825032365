/**
 *
 * Created by neo on 13.06.17.
 */

import { BackendFactory } from './BackendFactory';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Retry } from '../../Utils/Retry';

export class HttpBackend {
  static async post(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<any> {
    const backend = await BackendFactory();
    return backend.post(url, data, config);
  }

  static async postForm(url: string, data: FormData, headers: any = {}): Promise<any> {
    const backend = await BackendFactory();
    return backend.postForm(url, data, headers);
  }

  static async get(url: string, params?: any, headers: any = {}): Promise<any> {
    const backend = await BackendFactory();
    return backend.get(url, params, { headers });
  }

  static async head(url: string, params?: any, headers: any = {}): Promise<AxiosResponse<any>> {
    const backend = await BackendFactory();
    return backend.head(url, params, { headers });
  }

  static async put(url: string, data?: any, headers: any = {}): Promise<any> {
    const backend = await BackendFactory();
    return backend.put(url, data, headers);
  }

  static async delete(url: string, params?: any, headers: any = {}): Promise<any> {
    const backend = await BackendFactory();
    return backend.delete(url, params, { headers });
  }

  static async getOrCached(cacheKey: string, url: string, params?: any, options?: AxiosRequestConfig) {
    const backend = await BackendFactory();
    return backend.get(url, params, options);
    // return Retry.tryWithCache(cacheKey, () => backend.get(url, params, options));
  }
}
