/**
 * Created by neo on 15.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useTrans } from '../Store/System/LocalizationStore';
import { SelectHTMLAttributes } from 'react';

export type TranslationProps = SelectHTMLAttributes<HTMLSpanElement> & {
  i18nKey: string;
  defaultValue?: string;
};

export const Translation: React.FC<TranslationProps> = observer(({ i18nKey, defaultValue, ...moreProps }) => {
  const { t } = useTrans();

  const text = t(i18nKey, { defaultValue })?.replace(/\n/g, '<br />');

  return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
});
