/**
 * Created by katarinababic on 28.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout, AuthLayoutProps } from '../AuthLayout/AuthLayout';
import { CheckInboxForm, CheckInboxFormProps } from './CheckInboxForm';
import { NoReceivedEmailAlert } from '../Alerts/NoReceivedEmailAlert';

export type CheckInboxScreenWrapperProps = AuthLayoutProps & CheckInboxFormProps & {};

export const CheckInboxScreenWrapper: React.FC<CheckInboxScreenWrapperProps> = observer(
  ({ onResendEmail, ...moreProps }) => {
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [resendEmailClickCount, setResendEmailClickCount] = useState(0);

    useEffect(() => {
      if (resendEmailClickCount > 1) {
        setShowAlert(true);
      }
    }, [resendEmailClickCount]);

    const handleClickResendEmail = React.useCallback(() => {
      setResendEmailClickCount((prev) => prev + 1);
      onResendEmail && onResendEmail();
    }, [onResendEmail]);

    const handleToggleAlert = React.useCallback(() => {
      setShowAlert((prev) => !prev);
    }, []);

    const handleClickContactUs = React.useCallback(() => {
      window.open('mailto:hello@kinastic.com');
      setShowAlert(false);
    }, []);

    return (
      <AuthLayout {...moreProps}>
        <CheckInboxForm {...moreProps} onResendEmail={handleClickResendEmail} />
        <NoReceivedEmailAlert toggle={handleToggleAlert} onClickContactUs={handleClickContactUs} isOpen={showAlert} />
      </AuthLayout>
    );
  },
);
