/**
 * Created by neo on 30.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { useState } from 'react';
import { OnProgressData, VideoPlayerStore } from './VideoPlayerStore';
import { runInAction } from 'mobx';
import styled from '@emotion/styled';

const VideoContainer = styled.div`
  video {
    object-fit: cover;
  }
`;

const VideoPlayer = styled(ReactPlayer)``;

export type KinasticVideoProps = ReactPlayerProps & {};

export const KinasticVideo: React.FC<KinasticVideoProps> = observer(
  ({ onDuration, onPause, onPlay, onProgress, ...moreProps }) => {
    const [videoStore] = useState(() => new VideoPlayerStore());

    const handleDuration = React.useCallback((duration: number) => videoStore.onDuration(duration), [videoStore]);

    const handlePause = React.useCallback(() => {
      runInAction(() => (videoStore.paused = true));
      onPause && onPause();
    }, [onPause, videoStore]);

    const handlePlay = React.useCallback(() => {
      runInAction(() => (videoStore.paused = false));
      onPlay && onPlay();
    }, [onPlay, videoStore]);

    const handleProgress = React.useCallback(
      (data: OnProgressData) => {
        videoStore.handleProgress(data);
        onProgress && onProgress(data);
      },
      [onProgress, videoStore],
    );

    return (
      <VideoContainer>
        <VideoPlayer
          playsinline={true}
          width="100%"
          height="100%"
          muted={true}
          controls={true}
          playing={true}
          onPlay={handlePlay}
          onPause={handlePause}
          onDuration={handleDuration}
          onProgress={handleProgress}
          style={{ objectFit: 'cover' }}
          {...moreProps}
        />
      </VideoContainer>
    );
  },
);
