/**
 * Created by neo on 10.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { Instructor, InstructorJson } from './Instructor';

export type VideoEntryJson = ExploreEntryJson & {
  instructor?: InstructorJson;
  durationInMinutes?: number;
};

export class VideoEntry extends ExploreEntry {
  @observable.ref
  instructor?: Instructor;
  @observable
  durationInMinutes?: number;

  constructor(json?: Partial<VideoEntryJson>) {
    super(Object.assign({ type: 'video' }, json ?? {}));
    if (json) {
      this.instructor = json.instructor ? new Instructor(json.instructor) : undefined;
      this.durationInMinutes = json.durationInMinutes;
    }
  }

  toJS(): VideoEntryJson {
    return Object.assign(super.toJS(), {
      instructor: this.instructor?.toJS(),
      durationInMinutes: this.durationInMinutes,
    });
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<VideoEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'video' })).then((res) =>
      (res ?? []).map((r) => new VideoEntry(r)),
    );
  }

  static async findOne(id: string): Promise<VideoEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new VideoEntry(res));
  }
}
