/**
 * Created by andreaskarantzas on 05.05.20.
 */
import { action, computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { languagePriority } from '../../LocalizedEntity';

export type NativeFeedComponentPropUrlJson = {
  url?: string;
  text: LocalizedValueJson[];
};

export default class NativeFeedComponentPropUrl {
  @observable url?: string;
  @observable text: LocalizedValue[] = [];

  constructor(json: Partial<NativeFeedComponentPropUrlJson>) {
    if (json) {
      this.url = json.url;
      this.text = (json.text || []).map((c: any) => new LocalizedValue(c));
    }
  }

  @action
  changeText(lang: string, value: string) {
    const existing = this.text.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.text.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getText(lang: string) {
    return this.text.find((n) => n.lang === lang.toLowerCase())?.value ?? this.defaultText;
  }

  @computed
  get defaultText(): string {
    for (const lang of languagePriority) {
      const entry = this.text.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.text[0];
    return first?.value ?? '';
  }
}
