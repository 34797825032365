/**
 * Created by neo on 01.04.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { RequestCodeRequest } from '../../../../Model/CoachSubscription/RequestCodeRequest';
import {
  CheckInboxScreenWrapper,
  CheckInboxScreenWrapperProps,
} from '../../../../Components/Auth/CheckInbox/CheckInboxScreenWrapper';
import { useTrans } from '../../../../Store/System/LocalizationStore';
import { CoachRegistrationRequest } from '../../../../Model/CoachSubscription/CoachRegistrationRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from '../../../../Store/useRootStore';
import { ErrorAlert } from '../../../../Components/Auth/Alerts/ErrorAlert';

export type RegistrationRequestSubmittedViewProps = Pick<CheckInboxScreenWrapperProps, 'onBack' | 'onResendEmail'> & {
  request: RequestCodeRequest;
};

const CODE_LENGTH = 6;

export const RegistrationRequestSubmittedView: React.FC<RegistrationRequestSubmittedViewProps> = observer(
  ({ request, ...moreProps }) => {
    const { analytics } = useRootStore();

    const { t } = useTrans();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const authCode = searchParams.get('authCode');

    const [processing, setProcessing] = useState(false);
    const [currentCode, setCurrentCode] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

    const handleSubmitCode = React.useCallback(
      (code: string) => {
        setCurrentCode(code);
        if (code.length === CODE_LENGTH) {
          setProcessing(true);
          CoachRegistrationRequest.find(code)
            .finally(() => setProcessing(false))
            .then(() => navigate(`final?verificationCode=${code}`, { replace: true }))
            .catch((e) => {
              setCurrentCode('');
              setErrorCode(
                e.response?.data?.errorCode ?? e.response?.data.status === 'NOT_FOUND' ? 'invalid_code' : undefined,
              );
              setShowErrorAlert(true);
              analytics.logError('registration_verification', e.response?.data?.errorCode);
            });
        }
      },
      [analytics, navigate, t],
    );

    useEffect(() => {
      handleSubmitCode(authCode ?? '');
    }, [authCode, handleSubmitCode]);

    const handleCloseAlert = React.useCallback(() => {
      setShowErrorAlert(false);
    }, []);

    return (
      <React.Fragment>
        <CheckInboxScreenWrapper
          title={t('registrationCheckInboxScreen.title', { defaultValue: 'Check your Inbox and spam folder!' })}
          text={t('registrationCheckInboxScreen.text', {
            defaultValue: 'If your employer works with us you will receive an e-mail from us..',
          })}
          overlayRightTitle={t('registration.stepOne.introTitle', { defaultValue: 'Hello \n' + 'Healthy\n' + 'Life' })}
          image={require('../../../../Assets/Images/auth_2.png')}
          email={request.email}
          code={currentCode}
          onSubmitCode={handleSubmitCode}
          codeDisabled={processing}
          {...moreProps}
        />
        <ErrorAlert
          toggle={handleCloseAlert}
          isOpen={showErrorAlert}
          title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
          onDismiss={handleCloseAlert}
        />
      </React.Fragment>
    );
  },
);
