/**
 * Created by neo on 20.05.21.
 */
import { action } from 'mobx';

type VideoPause = { start: number; stop?: number };

export class DurationTracker {
  startTimestamp = Date.now();
  pauses: VideoPause[] = [];

  @action
  start() {
    this.startTimestamp = Date.now();
  }

  pause() {
    const lastIndex = this.pauses.length - 1;
    const lastPause = lastIndex > -1 ? this.pauses[lastIndex] : undefined;
    if (!lastPause || lastPause.stop) {
      this.pauses.push({ start: Date.now() });
    }
  }

  resume() {
    const lastIndex = this.pauses.length - 1;
    const lastPause = lastIndex > -1 ? this.pauses[lastIndex] : undefined;
    if (lastPause && !lastPause.stop) {
      lastPause.stop = Date.now();
    }
  }

  get totalPauses(): number {
    return this.pauses.reduce((prev, pause) => prev + ((pause.stop ?? Date.now()) - pause.start), 0);
  }

  get duration(): number {
    return Date.now() - this.startTimestamp - this.totalPauses;
  }

  get durationInSeconds(): number {
    return this.duration / 1000.0;
  }
}
