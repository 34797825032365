/**
 * Created by neo on 18.01.17.
 */

import { action, computed, observable, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media, MediaJson } from '../Media/Media';
import { v4 as UUID } from 'uuid';
import dayjs from '../../Utils/dayjs';
import { logger } from '../../Utils/logger';
import { BasePhaseJson, Phase, PhaseJson } from './Phase';
import { IntervalPhase } from './IntervalPhase';
import {ExerciseBlock} from "./ExerciseBlock";

export type BaseWorkoutJson = {
  id: string;
  type: string;
  phases: BasePhaseJson[];
  image?: MediaJson;
  createdBy?: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt: string;
};

export type WorkoutJson = Omit<BaseWorkoutJson, 'phases'> & {
  name: string;
  description: string;
  lastExecutionTimestamp: number;
  phases: PhaseJson[];
  executedCount: number;
};

export class Workout {
  @observable id: string = UUID();
  @observable name: string = '';
  @observable description: string = '';
  @observable type: string = 'strength';
  @observable lastExecutionTimestamp: number = 0;
  @observable.shallow phases: Phase[] = [];
  @observable executedCount: number = 0;
  @observable image?: Media = undefined;
  @observable createdAt = new Date();
  @observable updatedAt = new Date();
  @observable
  createdBy?: string;
  @observable
  updatedBy?: string;

  constructor(json?: Partial<WorkoutJson>) {
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): WorkoutJson {
    return {
      id: newId ? UUID() : this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      lastExecutionTimestamp: this.lastExecutionTimestamp,
      phases: this.phases.map((p) => p.toJS()),
      executedCount: this.executedCount,
      image: this.image ? this.image.toJS() : undefined,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
    };
  }

  @action
  reOrderPhases(order: number[]) {
    this.phases = order.map((index: number) => this.phases[index]);
  }

  @action
  addPhase(phase: Phase) {
    this.phases.push(phase);
  }

  @action
  removePhase(phase: Phase) {
    const index = this.phases.findIndex((p: Phase) => p.phaseId === phase.phaseId);
    if (index !== -1) {
      this.phases.splice(index, 1);
    }
  }

  @action
  setBasicData(json: any) {
    this.id = json.id || UUID();
    this.name = json.name || '';
    this.description = json.description;
    this.type = json.type;
    this.lastExecutionTimestamp = json.lastExecutionTimestamp;
    this.executedCount = json.executedCount;
    this.image = json.image ? new Media(json.image) : undefined;
    this.createdAt = json.createTimestamp;
    this.updatedAt = json.updateTimestamp;
    this.createdBy = json.createdAthleteId;
    this.updatedBy = json.updatedAthleteId;
  }

  protected createPhase(phase: any): Phase {
    if (phase.type === 'interval') {
      return new IntervalPhase(this, phase);
    }
    return new Phase(this, phase);
  }

  @action
  movePhase(oldIndex: number, newIndex: number) {
    const removed = this.phases.splice(oldIndex, 1)[0];
    if (removed) {
      if (newIndex < this.phases.length) {
        this.phases.splice(newIndex, 0, removed);
      } else {
        this.phases.push(removed);
      }
    }
  }

  @action
  updateData(json: any): void {
    this.setBasicData(json);
    this.phases.forEach((p: Phase, index: number) => {
      if (!json.phases.find((p0: any) => p0.phaseId === p.phaseId)) {
        logger(
          `Removing phase ${p.name}, ${p.phaseId}`,
          this.phases.map((phase: any) => phase.phaseId),
        );
        this.phases.splice(index, 1);
      }
    });
    json.phases.forEach((p: any, i: number) => {
      logger(`Processing ${p.name} (${p.phaseId})`);
      const existing = this.phases.find((w0: any) => w0.phaseId === p.phaseId);
      const phase = existing || this.createPhase(p);
      if (existing) {
        logger(`Updating phase`);
        existing.updateData(p);
      } else {
        logger(`No existing found ${p.name} (${p.phaseId})`);
      }

      const element = this.phases[i];
      if (!element || element.phaseId !== phase.phaseId) {
        if (i < this.phases.length) {
          logger(`Inserting ${phase.name} at ${i}`);
          this.phases.splice(i, 0, phase);
        } else {
          logger(`Pushing ${phase.name}`);
          this.phases.push(phase);
        }
      } else {
        logger(`Phase ${phase.name} is the same`);
      }
    });

    logger('New Workout', this.toJS());
  }

  @action
  setData(json: Partial<WorkoutJson>): void {
    this.setBasicData(json);
    this.phases = (json.phases || []).map((p: any) => this.createPhase(p));
    this.createdAt = this.createdAt || Date.now();
    this.updatedAt = this.updatedAt || Date.now();
  }

  calculateCalories(bmr24: number): number {
    return this.phases.reduce((total: number, p: Phase) => total + p.calculateCalories(bmr24), 0);
  }

  remove() {
    if (!this.isNew) {
      return HttpBackend.delete(`/workout/${this.id}`);
    }
    return Promise.resolve();
  }

  save(): Promise<Workout> {
    const oldCreateTimestamp = this.createdAt;
    const oldUpdateTimestamp = this.updatedAt;
    this.createdAt = new Date();
    this.updatedAt = new Date(Date.now() + 5000); // add a buffer... to prevent loading again remotely

    return HttpBackend.post('/workout/save', this.toJS())
      .catch(() => {
        runInAction(() => {
          this.createdAt = oldCreateTimestamp;
          this.updatedAt = oldUpdateTimestamp;
        });
        return this;
      })
      .then(() => this);
  }

  async fetchLastUpdated(): Promise<number> {
    try {
      const res = await HttpBackend.head(`/workout/${this.id}`);
      if (res.status >= 200 && res.status < 300) {
        return Number(res.headers['x-last-modified']) || 0;
      }
    } catch (e) {}
    return 0;
  }

  @computed
  get workoutImage(): any {
    const mediaUrl = this.image ? this.image.medium : null;
    return mediaUrl ? { uri: mediaUrl } : this.defaultImage;
  }

  @computed
  get defaultImage(): any {
    return undefined;
    // return Theme.Images.logoBanner;
  }

  @computed
  get totalExercises(): number {
    return this.phases.reduce((total: number, phase: Phase) => total + phase.totalExercises, 0);
  }

  @computed
  get duration(): number {
    return this.phases.reduce((total: number, phase: Phase) => total + phase.duration, 0);
  }

  @computed
  get durationFormatted(): string {
    const { duration } = this;
    if (duration > 0) {
      if (duration > 3599999) {
        return dayjs.utc(duration).format('HH:mm:ss');
      }
      return dayjs.utc(duration).format('mm:ss');
    }
    return '00:00';
  }

  @computed
  get durationMinutes(): number {
    return Math.round(dayjs.duration(this.duration).asMinutes());
  }

  @computed
  get breakTime(): number {
    return this.phases.reduce((total: number, phase: Phase) => total + phase.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.phases.reduce((total: number, phase: Phase) => total + phase.totalTimeExercising, 0);
  }

  @computed
  get lastExecutedText(): string {
    const timestamp = this.lastExecutionTimestamp;
    if (!timestamp || timestamp === 0) {
      return 'Nie';
    }
    const time = dayjs(timestamp).startOf('day');
    const today = dayjs().startOf('day');
    const days = Math.round(dayjs.duration(today.diff(time)).asDays());
    return `${days}T`;
  }

  @computed
  get isNew(): boolean {
    return !this.id || this.id.length <= 0;
  }

  @computed
  get nameValid(): boolean {
    return this.name.trim().length > 0;
  }

  @computed
  get hasExercises() {
    return this.totalExercises > 0;
  }

  @computed
  get hasPhases(): boolean {
    return this.phases.length > 0;
  }

  @computed
  get totalSets(): number {
    return this.phases.reduce((total: number, p: Phase) => total + p.totalSets, 0);
  }

  @computed
  get tons(): number {
    return this.phases.reduce((total: number, p: Phase) => total + p.tons, 0);
  }

  @computed
  get exerciseBlocks(): ExerciseBlock[] {
    return this.phases.reduce((arr: ExerciseBlock[], phase: Phase) => arr.concat(phase.exerciseBlocks.slice()), []);
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.phases.reduce((total: number, phase: Phase) => total + phase.estimatedTotalExerciseTime, 0);
  }

  static get(id: string): Promise<Workout> {
    return HttpBackend.get(`/workout/${id}`).then((result) => new Workout(result));
  }
}
