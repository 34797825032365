/**
 * Created by neo on 18.01.17.
 */

import { action, computed, observable, toJS } from 'mobx';
import { RoundInfo, RoundInfoJson } from './RoundInfo';
import { ExerciseVariation, ExerciseVariationJson } from './ExerciseVariation';
import { BaseTrackingKey, TrackingKeys, TrackingKeysList } from './TrackingKeys';
import { v4 as UUID } from 'uuid';
import { Media } from '../Media/Media';
import { logger } from '../../Utils/logger';
import { BaseExerciseBlockSetJson, ExerciseBlockSet, ExerciseBlockSetJson } from './ExerciseBlockSet';
import { Phase } from './Phase';

export type BaseExerciseBlockJson = {
  exerciseBlockId: string;
  exercise: ExerciseVariationJson;
  sets: BaseExerciseBlockSetJson[];
  roundInfo?: RoundInfoJson;
};

export type ExerciseBlockJson = Omit<BaseExerciseBlockJson, 'sets'> & {
  description?: string;
  spotClassId?: string;
  sets: ExerciseBlockSetJson[];
};

export class ExerciseBlock {
  @observable exerciseBlockId: string = UUID();
  @observable description?: string;
  @observable spotClassId?: string;
  @observable exercise: ExerciseVariation = new ExerciseVariation();
  @observable.shallow sets: ExerciseBlockSet[] = [];
  @observable roundInfo?: RoundInfo;
  @observable phase: Phase;

  constructor(
    phase?: Phase,
    json?: Partial<Omit<ExerciseBlockJson, 'exercise'> & { exercise: ExerciseVariationJson | ExerciseVariation }>,
  ) {
    this.phase = phase || new Phase();
    if (json) {
      this.setData(json);
    }
  }

  toJS(newId?: boolean): ExerciseBlockJson {
    return {
      exerciseBlockId: newId ? UUID() : this.exerciseBlockId,
      description: this.description,
      spotClassId: this.spotClassId,
      exercise: this.exercise.toJS(),
      sets: this.sets.map((s) => s.toJS()),
      roundInfo: this.roundInfo ? this.roundInfo.toJS() : undefined,
    };
  }

  copy(): ExerciseBlock {
    return new ExerciseBlock(this.phase, this.toJS(true));
  }

  @action
  setBasicData(
    json: Partial<Omit<ExerciseBlockJson, 'exercise'> & { exercise: ExerciseVariationJson | ExerciseVariation }>,
  ) {
    this.exerciseBlockId = json.exerciseBlockId || UUID();
    this.description = json.description;
    this.exercise = json.exercise instanceof ExerciseVariation ? json.exercise : new ExerciseVariation(json.exercise);
    this.spotClassId = json.spotClassId;
    this.roundInfo = json.roundInfo ? new RoundInfo(json.roundInfo) : undefined;
  }

  @action
  updateData(json: any) {
    this.setBasicData(json);
    const diff = this.sets.length - json.sets.length;
    if (diff > 0) {
      logger(`Removing ${diff} sets start from ${json.sets.length}`);
      this.sets.splice(json.sets.length, diff);
    }
    const length = this.sets.length;
    json.sets.forEach((s: any, i: number) => {
      if (i < length) {
        logger(`Updating set ${i}`);
        this.sets[i].updateData(s);
      } else {
        logger(`Pushing set ${i}`);
        this.sets.push(new ExerciseBlockSet(this, s));
      }
    });
  }

  @action
  setData(
    json: Partial<Omit<ExerciseBlockJson, 'exercise'> & { exercise: ExerciseVariationJson | ExerciseVariation }>,
  ) {
    this.setBasicData(json);
    this.sets = (json.sets || []).map((s) => new ExerciseBlockSet(this, s));
  }

  @action
  createSet(setValues?: any): ExerciseBlockSet {
    const { lastSet } = this;
    const lastOrDefault = lastSet ? toJS(lastSet.values) : {};
    const values = setValues || lastOrDefault;
    logger('valuesPassed::', values);
    const set = new ExerciseBlockSet(this, { values: this.cleanValues(values) });
    logger('set::', set);
    this.sets.push(set);
    return set;
  }

  cleanValues(values: any): any {
    return TrackingKeysList.filter(
      (k: string) => undefined !== values[k] || undefined !== values[`MIN_${k}`] || undefined !== values[`MAX_${k}`],
    )
      .map((k: string) => [
        k,
        values[`MIN_${k}`] || values[`MAX_${k}`] || values[k],
        undefined !== values[k]
          ? values[k]
          : values[`MAX_${k}`] === 0
          ? values[`MAX_${k}`]
          : values[`MAX_${k}`] || values[`MIN_${k}`],
      ])
      .reduce((obj: any, curr: any[]) => {
        obj[`MIN_${curr[0]}`] = curr[2] === 0 ? curr[1] : Math.min(curr[1], curr[2]);
        obj[`MAX_${curr[0]}`] = curr[2] === 0 ? curr[2] : Math.max(curr[1], curr[2]);
        return obj;
      }, {});
  }

  calculateCalories(bmr24: number): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.calculateCalories(bmr24), 0);
  }

  @action
  removeSet(index: number) {
    this.sets.splice(index, 1);
  }

  @action
  async save() {
    return true;
  }

  @computed
  get valid(): boolean {
    return true;
  }

  @computed
  get duration(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.totalDuration, 0);
  }

  @computed
  get breakTime(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.breakTime, 0);
  }

  @computed
  get totalTimeExercising(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.plannedDurationMs, 0);
  }

  @computed
  get tons(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.tons, 0);
  }

  @computed
  get lastSet(): ExerciseBlockSet | undefined {
    const { length } = this.sets;
    if (length > 0) {
      return this.sets[length - 1];
    }
    return undefined;
  }

  @computed
  get firstSet(): ExerciseBlockSet | undefined {
    const { sets } = this;
    if (sets.length > 0) {
      return sets[0];
    }
    return undefined;
  }

  @computed
  get prevSet(): ExerciseBlockSet | undefined {
    if (!this.lastSet) {
      return this.prevBlock && this.prevBlock.prevSet;
    }
    return this.lastSet;
  }

  @computed
  get followingSet(): ExerciseBlockSet | undefined {
    return this.firstSet || (this.nextBlock ? this.nextBlock.followingSet : undefined);
  }

  @computed
  get exerciseBlockIndex(): number {
    return this.phase ? this.phase.exerciseBlocks.findIndex((b) => b.exerciseBlockId === this.exerciseBlockId) : -1;
  }

  @computed
  get isLastBlock(): boolean {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      return exerciseBlockIndex + 1 === this.phase.exerciseBlocks.length;
    }
    return true;
  }

  @computed
  get prevBlock(): ExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex } = this;
      if (exerciseBlockIndex === 0) {
        return this.phase.prevPhase?.prevBlock;
      }
      return this.phase.exerciseBlocks[exerciseBlockIndex - 1];
    }
    return undefined;
  }

  @computed
  get nextBlock(): ExerciseBlock | undefined {
    if (this.phase) {
      const { exerciseBlockIndex, isLastBlock } = this;
      if (isLastBlock) {
        return this.phase.nextPhase?.nextBlock;
      }
      const nextIndex = exerciseBlockIndex + 1;
      return nextIndex < this.phase.exerciseBlocks.length ? this.phase.exerciseBlocks[nextIndex] : undefined;
    }
    return undefined;
  }

  @computed
  get trackingParameters(): BaseTrackingKey[] {
    return this.exercise.trackingParameters;
  }

  @computed
  get sortedTrackingParameters(): string[] {
    const trackingKeys = Object.keys(TrackingKeys);
    return this.trackingParameters.slice().sort((a: string, b: string) => {
      return trackingKeys.indexOf(a) - trackingKeys.indexOf(b);
    });
  }

  @computed({ name: 'ExerciseBlock::spotClassMedia' })
  get spotClassMedia(): Media | undefined {
    const { exercise } = this;
    return exercise.imageMedia;
  }

  @computed
  get estimatedTotalExerciseTime(): number {
    return this.sets.reduce((total: number, set: ExerciseBlockSet) => total + set.totalDuration, 0);
  }
}
