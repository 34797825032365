/**
 * Created by katarinababic on 28.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Container, Form, FormGroup } from 'reactstrap';
import { ContinueWithAppleButton } from '../../../Components/Auth/Buttons/ContinueWithAppleButton';
import { FormLabel } from '../../../Components/Form/FormLabel';
import { Input } from '../../../Components/Input';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Button } from '../../../Components/Button';
import { TextWithMiddleLine } from '../../../Components/TextWithMiddleLine';
import { useState } from 'react';
import { EMAIL_REGEX, APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from '../../../Utils/Constants';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { useRootStore } from '../../../Store/useRootStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegisterBottomButton } from '../../../Components/Auth/Buttons/RegisterBottomButton';

const FormContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const ButtonContainer = styled.div`
  padding-top: 1rem;
`;

export type LoginFormProps = {
  onSubmitted?: () => void;
  onChangeEmail?: (email: string) => void;
  onError?: (err?: string) => void;
};

export const LoginForm: React.FC<LoginFormProps> = observer(({ onSubmitted, onChangeEmail, onError }) => {
  const { authentication, analytics } = useRootStore();
  const { t } = useTrans();

  const location = useLocation();
  const navigate = useNavigate();

  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);

  const validEmail = EMAIL_REGEX.test(email);
  const buttonDisabled = !validEmail || processing;

  const handleChangeEmail = React.useCallback(
    ({ target: { value } }) => {
      setEmail(value);
      onChangeEmail && onChangeEmail(value);
    },
    [onChangeEmail],
  );

  const handleAppleLogin = React.useCallback(() => {
    appleAuthHelpers
      .signIn({
        authOptions: {
          clientId: APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: APPLE_REDIRECT_URI,
          usePopup: true,
        },
        onError: (error) => console.log('Error performing apple signin', error),
      })
      .then((res) => ({
        identityToken: res.authorization?.id_token,
        email: res.user?.email,
        fullName: { givenName: res.user?.name?.firstName, familyName: res.user?.name?.lastName },
      }))
      .then((response) => authentication.appleLogin(response))
      .then(() => {
        analytics.logLogin('apple');
        const redirect = new URLSearchParams(location.search).get('redirect');
        if (redirect) {
          navigate(redirect as string, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      })
      .catch((e) => {
        console.log(e);
        analytics.logError('apple_login', e.response?.data?.errorCode);
        onError && onError(e.response?.data?.errorCode);
      });
  }, [analytics, authentication, location, navigate, onError]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      setProcessing(true);
      authentication
        .requestMagicLink(email)
        .then(() => analytics.logEvent('magic_link_requested'))
        .then(() => onSubmitted && onSubmitted())
        .catch((e) => {
          analytics.logError('magic_link_request', e.response?.data?.errorCode);
          onError && onError(e.response?.data?.errorCode);
        })
        .finally(() => setProcessing(false));
    },
    [authentication, email, onSubmitted, onError, analytics],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormContainer>
        <FormGroup>
          <FormLabel>{t('registrationScreen.stepTwo.emailLabel', { defaultValue: 'Your e-mail address' })}</FormLabel>
          <Input
            placeholder={t('registrationScreen.stepTwo.emailPlaceholder', { defaultValue: 'Your e-mail address' })}
            onChange={handleChangeEmail}
            value={email}
            disabled={processing}
            disabledColor={'#3c3c3c'}
          />
        </FormGroup>
      </FormContainer>
      <ButtonContainer>
        <Button type="submit" block={true} color="success" disabled={buttonDisabled}>
          {t('authentication.loginButton', { defaultValue: 'Login' }).toUpperCase()}
        </Button>
        <ContinueWithAppleButton
          onClick={handleAppleLogin}
          text={t('authentication.continueWithApple', { defaultValue: 'Continue with Apple' })}
        />
      </ButtonContainer>
      <TextWithMiddleLine text={t('globals.or', { defaultValue: 'Or' })} />
      <RegisterBottomButton />
    </Form>
  );
});
