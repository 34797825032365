/**
 * Created by neo on 14.12.20.
 */
import { observable, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { SubscriptionPlan } from './SubscriptionPlan';
import { Gym } from '../Gym/Gym';
import { logger } from '../../Utils/logger';

export type RequestWithCodeResponse = {
  gym: Gym;
  subscription: AthleteCoachSubscription;
};

export type AthleteCoachSubscriptionJson = {
  id: string;
  athleteId: string;
  gymId?: string;
  plan: SubscriptionPlan;
  validFrom: string;
  validUntil?: string;
  cancelDate?: string;
  trial: boolean;
};

export class AthleteCoachSubscription {
  @observable
  id = UUID();
  @observable
  athleteId: string = '';
  @observable
  gymId?: string;
  @observable
  plan: SubscriptionPlan = 'free';
  @observable
  validFrom = new Date();
  @observable
  validUntil?: Date;
  @observable
  cancelDate?: string;
  @observable
  trial = false;

  constructor(json?: Partial<AthleteCoachSubscriptionJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.athleteId = json.athleteId ?? '';
      this.gymId = json.gymId;
      this.plan = json.plan ?? 'free';
      this.validFrom = json.validFrom ? new Date(json.validFrom) : new Date();
      this.validUntil = json.validUntil ? new Date(json.validUntil) : undefined;
      this.trial = json.trial ?? false;
    }
  }

  static current(): Promise<AthleteCoachSubscription> {
    return HttpBackend.get(`/coach/subscription/current`).then((res) => new AthleteCoachSubscription(res));
  }

  static requestTrial(): Promise<AthleteCoachSubscription> {
    return HttpBackend.post(`/coach/subscription/requestTrial`).then((res) => new AthleteCoachSubscription(res));
  }

  static requestByCode(code: string): Promise<RequestWithCodeResponse> {
    return HttpBackend.post(`/coach/subscription/requestByCode?code=${code}`).then((res) => ({
      gym: new Gym(res.gym),
      subscription: new AthleteCoachSubscription(res.subscription),
    }));
  }

  static canTrial(): Promise<boolean> {
    return HttpBackend.get(`/coach/subscription/canTrial`)
      .then((res) => true)
      .catch((err) => {
        logger('no can do brother', err);
        // 402 === PAYMENT_REQUIRED
        if (err.response?.status === 402) {
          return false;
        } else {
          throw err;
        }
      });
  }
}
