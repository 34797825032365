import { HealthDataPoint, HealthDataPointJson } from './HealthDataPoint';
import { observable } from 'mobx';
import { HealthDataPointCorrelationType } from './HealthDataPointCorrelationType';
import { HealthDataPointQuantity, HealthDataPointQuantityJson } from './HealthDataPointQuantity';
import { HealthDataPointType } from './HealthDataPointType';
import { notUndefined } from '../../Utils/notUndefined';
import { HealthDataPointQuantityType } from './HealthDataPointQuantityType';

const correlationSort = [
  HealthDataPointQuantityType.bloodPressureSystolic,
  HealthDataPointQuantityType.bloodPressureDiastolic,
];

export type HealthDataPointCorrelationJson = {
  type: HealthDataPointCorrelationType;
  values: HealthDataPointQuantityJson[];
} & HealthDataPointJson;

export class HealthDataPointCorrelation extends HealthDataPoint {
  @observable
  type: HealthDataPointCorrelationType = HealthDataPointCorrelationType.bloodPressure;
  @observable
  values: HealthDataPointQuantity[] = [];

  constructor(json?: Partial<HealthDataPointCorrelationJson>) {
    super(Object.assign(json ?? {}, { pointType: HealthDataPointType.correlation }));
    if (json) {
      this.type = json.type ?? HealthDataPointCorrelationType.bloodPressure;
      this.values = (json.values || []).map((q) => new HealthDataPointQuantity(q));
    }
  }

  toJS(): HealthDataPointCorrelationJson {
    return Object.assign(super.toJS(), {
      type: this.type,
      values: this.values.map((q) => q.toJS()),
    });
  }

  save(): Promise<HealthDataPointCorrelation> {
    return super.save().then(() => this);
  }

  getValue(metric: boolean = true): number[] {
    return this.values
      .slice()
      .sort((a, b) => correlationSort.indexOf(a.type) - correlationSort.indexOf(b.type))
      .map((v) => v.getValue(metric));
  }

  getUnit(metric: boolean = true): string | undefined {
    return [
      ...new Set(
        this.values
          .slice()
          .sort((a, b) => correlationSort.indexOf(a.type) - correlationSort.indexOf(b.type))
          .map((v) => v.getUnit(metric))
          .filter(notUndefined),
      ),
    ].join('/');
  }
}
