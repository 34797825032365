/**
 * Created by neo on 27.02.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import { Input } from '../../Input';
import { DurationBefore, NotificationInterval } from '../../../Model/Coach/Schedule/NotificationInterval';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { runInAction } from 'mobx';
import { Button } from '../../Button';
import { useRootStore } from '../../../Store/useRootStore';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

export type ExploreContentViewOnlineMeetingSubscribeButtonsProps = {
  entry: OnlineMeetingEntry;
  onSubscribed?: (event: SubscribedEventEntry) => void;
  canJoinMeeting?: boolean;
};

export const ExploreContentViewOnlineMeetingSubscribeButtons: React.FC<ExploreContentViewOnlineMeetingSubscribeButtonsProps> =
  observer(({ entry, onSubscribed, canJoinMeeting }) => {
    const { notification, analytics } = useRootStore();
    const { t } = useTrans();
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [event, setEvent] = useState(new SubscribedEventEntry());
    const earliestReminder = event.earliestInterval;

    useEffect(() => {
      setEvent(
        new SubscribedEventEntry(
          Object.assign({}, entry.event.toJS(), {
            notificationIntervals: [
              {
                durationBefore: DurationBefore.MIN_15_BEFORE,
              },
            ],
          }),
        ),
      );
    }, [entry]);

    const handleChangeReminder = React.useCallback(
      ({ target: { value } }) => {
        runInAction(() => {
          if (value) {
            event.notificationIntervals = [new NotificationInterval({ durationBefore: value })];
          } else {
            event.notificationIntervals = [];
          }
        });
      },
      [event],
    );

    const handleRegister = React.useCallback(() => {
      setProcessing(true);
      event
        .save()
        .then(() => {
          onSubscribed && onSubscribed(event);
          return notification.requestPermission();
        })
        .finally(() => setProcessing(false));
      analytics.logEvent('webinar_registered', { objectId: entry.id, url: entry.meetingLink });
    }, [analytics, entry, event, notification, onSubscribed]);

    const handleJoinMeeting = React.useCallback(() => {
      if (canJoinMeeting) {
        window.open(entry.meetingLink, '_blank');
      } else {
        navigate(entry.id);
      }
    }, [canJoinMeeting, entry, navigate]);

    return (
      <Row>
        {canJoinMeeting ? (
          <Col xs={12}>
            <Button block={true} onClick={handleJoinMeeting} disabled={processing}>
              {t('onlineMeeting.joinMeetingButton', {
                defaultValue: 'Join Now',
              })}
            </Button>
          </Col>
        ) : (
          <React.Fragment>
            <Col xs={12} style={{ marginBottom: 16 }}>
              <Input type="select" value={earliestReminder?.durationBefore} onChange={handleChangeReminder}>
                <option value={DurationBefore.ONE_HOUR_BEFORE}>
                  {t('exploreEntryViewScreen.reminder.pt1h', {
                    defaultValue: '1 hour before',
                  })}
                </option>
                <option value={DurationBefore.HALF_HOUR_BEFORE}>
                  {t('exploreEntryViewScreen.reminder.pt30m', {
                    defaultValue: '30 minutes before',
                  })}
                </option>
                <option value={DurationBefore.MIN_15_BEFORE}>
                  {t('exploreEntryViewScreen.reminder.pt15m', {
                    defaultValue: '15 minutes before',
                  })}
                </option>
                <option value={DurationBefore.AT_EVENT}>
                  {t('exploreEntryViewScreen.reminder.pt0s', {
                    defaultValue: 'When it starts',
                  })}
                </option>
                <option value="">
                  {t('exploreEntryViewScreen.reminder.noReminder', {
                    defaultValue: 'Do not remind me',
                  })}
                </option>
              </Input>
            </Col>
            <Col xs={12}>
              <Button block={true} onClick={handleRegister} disabled={processing}>
                {t('exploreEntryViewScreen.reminder.register', {
                  defaultValue: 'Register',
                })}
              </Button>
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  });
