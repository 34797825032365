/**
 * Created by neo on 18.01.17.
 */

import { observable, computed, action, toJS, set, remove } from 'mobx';
import { LocalizedEntity } from '../../../LocalizedEntity';
import { LocalizedValue } from '../../../LocalizedValue';
import { HttpBackend } from '../../../../Services/Http/HttpBackend';
import { PhaseConfiguration } from './PhaseConfiguration';
import { ConditionalMedia } from '../ConditionalMedia';
import { Retry } from '../../../../Utils/Retry';
import { Pageable } from '../../../Interfaces/Pageable';
import { PipelineContext } from '../../PipelineContext';
import { CoachWorkout } from '../../Program/Workout/CoachWorkout';

export type WorkoutTemplateQueryRequest = Pageable & {
  name?: string;
  tags?: string[];
  archived?: boolean;
  phaseScriptId?: string;
};

export class WorkoutTemplate extends LocalizedEntity {
  @observable
  templateName?: string = undefined;
  @observable
  type: string = 'gym_strength';
  @observable
  medias: ConditionalMedia[] = [];
  @observable
  params: {
    [key: string]: any;
  } = {};
  @observable
  tags: string[] = [];
  @observable
  phaseConfigurations: PhaseConfiguration[] = [new PhaseConfiguration()];
  @observable
  minDurationInMinutes?: number;
  @observable
  maxDurationInMinutes?: number;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.type = json.type;
      this.templateName = json.templateName;
      this.medias = (json.medias ?? []).map((m) => new ConditionalMedia(m));
      this.name = (json.name || []).map((v) => new LocalizedValue(v));
      this.tags = json.tags || [];
      this.params = json.params ?? {};
      this.phaseConfigurations = (json.phaseConfigurations ?? []).map((p) => new PhaseConfiguration(p));
      this.minDurationInMinutes = json.minDurationInMinutes;
      this.maxDurationInMinutes = json.maxDurationInMinutes;
      this.description = (json.description || []).map((v) => new LocalizedValue(v));
    }
  }

  toJS(replaceId: boolean = false): any {
    return Object.assign(super.toJS(replaceId), {
      templateName: this.templateName,
      type: this.type,
      tags: toJS(this.tags),
      phaseConfigurations: this.phaseConfigurations.map((p) => p.toJS(replaceId)),
      minDurationInMinutes: this.minDurationInMinutes,
      maxDurationInMinutes: this.maxDurationInMinutes,
      params: toJS(this.params),
      medias: this.medias.map((m) => m.toJS()),
    });
  }

  copy(data?: any): WorkoutTemplate {
    return new WorkoutTemplate(Object.assign(this.toJS(true), data || {}));
  }

  delete() {
    return HttpBackend.delete(`/coach/program/template/workout/${this.id}`);
  }

  @action
  setParam(paramName: string, value?: any) {
    this.params = this.params ?? {};
    set(this.params, paramName, value);
    // this.params[paramName] = value;
  }

  @action
  removeParam(paramName: string) {
    this.params = this.params ?? {};
    remove(this.params, paramName);
    // delete this.params[paramName];
  }

  async save(): Promise<WorkoutTemplate> {
    return HttpBackend.post('/coach/program/template/workout', this.toJS()).then(() => this);
  }

  @computed
  get isNew(): boolean {
    return !this.id || this.id.length <= 0;
  }

  @computed
  get nameValid(): boolean {
    if (this.defaultName) {
      return this.defaultName.trim().length > 0;
    }
    return false;
  }

  @computed
  get valid(): boolean {
    return this.nameValid;
  }

  @computed
  get invalid(): boolean {
    return !this.valid;
  }

  @computed
  get tagMap(): any {
    return this.tags
      .map((t) => t.split(':'))
      .reduce((result: any, tag: string[]) => {
        const entry = result[tag[0]] || [];
        entry.push(tag[1]);
        result[tag[0]] = entry.sort();
        return result;
      }, {});
  }

  execute(context: PipelineContext = new PipelineContext()): Promise<CoachWorkout> {
    return Retry.tryTimes(() =>
      HttpBackend.post(`/coach/program/template/workout/generate`, {
        workoutTemplateId: this.id,
        context: context.toJS(),
      }).then((result) => new CoachWorkout(result)),
    );
  }

  static async find(params: WorkoutTemplateQueryRequest = {}): Promise<WorkoutTemplate[]> {
    const res = await HttpBackend.get('/coach/program/template/workout', params);
    if (res) {
      return res.map((w) => new WorkoutTemplate(w));
    }
    return [];
  }

  static async list(params: WorkoutTemplateQueryRequest = {}): Promise<WorkoutTemplate[]> {
    const res = await HttpBackend.get('/coach/program/template/workout/list', params);
    if (res) {
      return res.map((w) => new WorkoutTemplate(w));
    }
    return [];
  }

  static async get(workoutId: string): Promise<WorkoutTemplate | undefined> {
    const res = await HttpBackend.get(`/coach/program/template/workout/${workoutId}`);
    if (res) {
      return new WorkoutTemplate(res);
    }
    return undefined;
  }

  static async getAll(workoutIds: string[]): Promise<WorkoutTemplate[]> {
    const result = await Promise.all(workoutIds.map((w) => WorkoutTemplate.get(w)));
    return result.filter((w) => !!w) as WorkoutTemplate[];
  }
}
