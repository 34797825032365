import styled from '@emotion/styled';
import React from 'react';
import { Instructor } from '../Model/Explore/Instructor';
import { observer } from 'mobx-react';
import { useRootStore } from '../Store/useRootStore';

const Text = styled.div<{ darkMode?: boolean }>`
  font-weight: 700;
  letter-spacing: 0em;
  font-size: 1.2rem;

  color: ${({ darkMode }) => (darkMode ? '#ffffff' : '#000000')};
`;

const Description = styled.div<{ darkMode?: boolean }>`
  font-size: 1rem;
  letter-spacing: 0em;
  color: ${({ darkMode }) => (darkMode ? '#ffffff' : '#000000')};

  @media (min-width: 1280px) {
  }
`;

const AuthorImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  object-fit: cover;

  @media (min-width: 1280px) {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    margin-right: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorDataContainer = styled.div`
  margin-left: 8px;
`;

export type CarouselEntryAuthorProps = {
  host?: Instructor;
  darkMode?: boolean;
};

export const EntryAuthor: React.FC<CarouselEntryAuthorProps> = observer(({ host, darkMode }) => {
  const { language } = useRootStore();

  const description = host?.getDescription(language.language);

  if (host) {
    return (
      <Container>
        <AuthorImage src={host.image?.smallest} alt="Author" />
        <AuthorDataContainer>
          <Text darkMode={darkMode}>{host.name}</Text>
          <Description darkMode={darkMode}>{description}</Description>
        </AuthorDataContainer>
      </Container>
    );
  }

  return null;
});
