import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Col, Container, Row } from 'reactstrap';
import { useTrans } from '../../Store/System/LocalizationStore';
import { FooterLanguageSelect } from './FooterLanguageSelect';
import { Link } from 'react-router-dom';
import { useRootStore } from '../../Store/useRootStore';

const MainFooter = styled.footer`
  font-size: 1rem;
  padding: 16px 0;
  background-color: black;
  color: white;
  position: relative;
`;

const FooterColumn = styled(Col)`
  margin-top: 16px;
`;

const LastFooterColumn = styled(Col)`
  margin-top: 16px;
`;

const FooterHeader = styled.div`
  font-weight: bold;
  font-size: 1.1em;
`;

const FooterLinkEntry = styled.div`
  font-size: 12px;
  margin: 4px 0;
`;

const FooterLogo = styled.img`
  height: 48px;
  margin: 16px 0;
`;

const FooterSimpleLink = styled.a`
  font-size: 12px;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

const FeedbackLink = styled(Link)`
  display: inline-block;
  padding: 8px;
  border-radius: 32px;
  border: 1px solid white;
  font-size: 12px;
  color: white;
  text-decoration: none;
  margin-top: 16px;

  &:hover {
    background-color: white;
    color: black;
  }
`;

export type FooterProps = {};

export const Footer: React.FC<FooterProps> = observer(() => {
  const { language, coachSubscription } = useRootStore();
  const { t } = useTrans();

  const privacyLinkEnglish = 'https://www.kinastic.com/privacy-policy-blank/';
  const privacyLinkGerman = 'https://www.kinastic.com/datenschutzerklarung-blank/';
  const privacyPolicyLink = language.language === 'en' ? privacyLinkEnglish : privacyLinkGerman;

  const termsLinkEnglish = 'https://www.kinastic.com/agb-app-en/';
  const termsLinkGerman = 'https://www.kinastic.com/agb-app/';
  const source = language.language === 'en' ? termsLinkEnglish : termsLinkGerman;

  const handleContactClick = React.useCallback(() => {
    window.open('mailto:hello@kinastic.com');
  }, []);

  return (
    <MainFooter className="fixed-bottom footer">
      <Container>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              {coachSubscription.canShowMove && (
                <FooterColumn md={6} lg={3}>
                  <FooterHeader>{t('footer.move.title', { defaultValue: 'MOVE' })}</FooterHeader>
                  <FooterLinkEntry>
                    <FooterLink to="/move">
                      {t('footer.move.liveTrainings', { defaultValue: 'LIVE TRAININGS' })}
                    </FooterLink>
                  </FooterLinkEntry>
                  <FooterLinkEntry>
                    <FooterLink to="/move/schedule">
                      {t('footer.move.schedule', { defaultValue: 'SCHEDULE' })}
                    </FooterLink>
                  </FooterLinkEntry>
                  <FooterLinkEntry>
                    <FooterLink to="/move/library">{t('footer.move.library', { defaultValue: 'LIBRARY' })}</FooterLink>
                  </FooterLinkEntry>
                </FooterColumn>
              )}
              {coachSubscription.canShowAllContent && (
                <React.Fragment>
                  <FooterColumn md={6} lg={3}>
                    <FooterHeader>{t('footer.mindfulness', { defaultValue: 'MINDFULNESS' })}</FooterHeader>
                    <FooterLinkEntry>{t('footer.comingSoonLink', { defaultValue: 'COMING SOON' })}</FooterLinkEntry>
                  </FooterColumn>
                  <FooterColumn md={6} lg={3}>
                    <FooterHeader>{t('footer.nutrition', { defaultValue: 'NUTRITION' })}</FooterHeader>
                    <FooterLinkEntry>{t('footer.comingSoonLink', { defaultValue: 'COMING SOON' })}</FooterLinkEntry>
                  </FooterColumn>
                </React.Fragment>
              )}
              {coachSubscription.canShowAcademy && (
                <FooterColumn md={6} lg={3}>
                  <FooterHeader>{t('footer.knowledge', { defaultValue: 'KNOWLEDGE' })}</FooterHeader>
                  <FooterLinkEntry>
                    <FooterLink to="/academy">{t('footer.academyLink', { defaultValue: 'ACADEMY' })}</FooterLink>
                  </FooterLinkEntry>
                  <FooterLinkEntry>
                    <FooterLink to="/academy/schedule">
                      {t('footer.academySchedule', { defaultValue: 'SCHEDULE' })}
                    </FooterLink>
                  </FooterLinkEntry>
                  <FooterLinkEntry>
                    <FooterLink to="/academy/library">
                      {t('footer.academyLibrary', { defaultValue: 'LIBRARY' })}
                    </FooterLink>
                  </FooterLinkEntry>
                </FooterColumn>
              )}
            </Row>
          </Col>
          <LastFooterColumn md={12} lg={3}>
            <Row>
              <Col>
                <FooterLanguageSelect />
              </Col>
            </Row>
            <Row>
              <Col>
                <a href="https://coach.kinastic.com">
                  <FooterLogo src={require('../../Assets/Images/kinastic-logo.png')} alt="KINASTIC logo" />
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <FooterLinkEntry>
                  <FooterSimpleLink href="javascript:void(0);" onClick={handleContactClick}>
                    {t('footer.contactLink', { defaultValue: 'CONTACT' })}
                  </FooterSimpleLink>
                </FooterLinkEntry>
                <FooterLinkEntry>
                  <FooterSimpleLink href={privacyPolicyLink} target="_blank">
                    {t('footer.privacyLink', { defaultValue: 'PRIVACY POLICY' })}
                  </FooterSimpleLink>
                  <FooterLink to="/privacy-policy"></FooterLink>
                </FooterLinkEntry>
                <FooterLinkEntry>
                  <FooterSimpleLink href={source} target="_blank">
                    {t('footer.termsLink', { defaultValue: 'TERMS & CONDITIONS' })}
                  </FooterSimpleLink>
                </FooterLinkEntry>
                <FooterLinkEntry>
                  <FeedbackLink to="/feedback">
                    {t('footer.giveFeedbackLink', { defaultValue: 'GIVE FEEDBACK' })}
                  </FeedbackLink>
                </FooterLinkEntry>
              </Col>
            </Row>
          </LastFooterColumn>
        </Row>
      </Container>
    </MainFooter>
  );
});
