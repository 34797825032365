import { AuthBackend } from '../Http/AuthBackend';

export class Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
  exp = Date.now() / 1000;
  expires_in: number;
  scope: string;
  jti: string;
  created: number;
  authorities: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      this.access_token = json.access_token;
      this.refresh_token = json.refresh_token;
      this.token_type = json.token_type;
      this.exp = json.exp ?? Date.now() / 1000;
      this.expires_in = json.expires_in;
      this.scope = json.scope;
      this.jti = json.jti;
      this.created = json.created;
      this.authorities = json.authorities;
    }
  }

  refresh(): Promise<Token> {
    console.log(`Refreshing token`, this.refresh_token);
    return new AuthBackend()
      .postForm(`/uaa/oauth/token`, {
        grant_type: 'refresh_token',
        refresh_token: this.refresh_token,
      })
      .then((res) => new Token({ created: Date.now(), ...res }));
  }

  hasAuthority(authority: string): boolean {
    return this.authorities.findIndex((a) => a === authority) !== -1;
  }

  get expired(): boolean {
    if (this.exp > 0) {
      return Date.now() > this.exp * 1000;
    }
    return true;
  }
}
