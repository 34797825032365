/**
 * Created by andreaskarantzas on 09.04.2021.
 */
import { computed, observable, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { RecurringPattern, RecurringPatternJson } from '../Coach/Schedule/RecurringPattern';
import dayjs, { Dayjs } from 'dayjs';
import { EMPTY_ARRAY } from '../../Utils/Constants';
import { SubscribedEventEntry } from '../Coach/Schedule/SubscribedEventEntry';
import { Duration } from 'dayjs/plugin/duration';
import { Instructor, InstructorJson } from './Instructor';

export type SubscribableEntryJson = ExploreEntryJson & {
  startDate: string;
  startTime: string;
  timezone: string;
  duration: string;
  recurringPattern?: RecurringPatternJson;
  hosts: InstructorJson[];
  // coming from backend
  dates?: string[];
};

export class SubscribableEntry extends ExploreEntry {
  @observable
  startDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
  @observable
  startTime = dayjs().add(1, 'day').format('HH:mm:ss');
  @observable
  timezone = 'Europe/Zurich';
  @observable
  duration = 'PT1H';
  @observable
  recurringPattern?: RecurringPattern;

  /**
   * backend only value since timezone operations require Intl and not all platform support it properly
   */
  @observable
  dates: Date[] = [];
  @observable
  hosts: Instructor[] = [];
  @observable
  canJoinMeeting = false;

  constructor(json?: Partial<SubscribableEntryJson>) {
    super(
      Object.assign(
        json ?? {},
        {
          type: 'zoomMeeting',
        },
        { version: { minVersionIos: '1.37.0', minVersionAndroid: '1.37.0' } },
      ),
    );
    if (json) {
      this.type = 'zoomMeeting';
      this.startDate = json.startDate ?? dayjs().add(1, 'day').format('YYYY-MM-DD');
      this.startTime = json.startTime ?? dayjs().add(1, 'day').format('HH:mm:ss');
      this.timezone = json.timezone ?? dayjs.tz.guess();
      this.duration = json.duration ?? 'PT1H';
      this.recurringPattern = json.recurringPattern ? new RecurringPattern(json.recurringPattern) : undefined;
      this.dates = (json.dates ?? EMPTY_ARRAY).map((d) => new Date(d));
      this.hosts = (json.hosts ?? []).map((h) => new Instructor(h));
    }
  }

  toJS(newId: boolean = false): SubscribableEntryJson {
    return Object.assign(super.toJS(newId), {
      startDate: this.startDate,
      startTime: this.startTime,
      timezone: this.timezone,
      duration: this.duration,
      recurringPattern: this.recurringPattern?.toJS(),
      hosts: this.hosts.map((h) => h.toJS()),
    });
  }

  nextDateWithinTimeRange(startDate: Dayjs): Dayjs | undefined {
    return this.dates.map((d) => dayjs(d)).find((d) => !d.isBefore(startDate));
  }

  @computed
  get startTimeFixed(): string | undefined {
    if (this.startTime) {
      if (this.startTime?.length === 5) {
        return `${this.startTime}:00`;
      } else if ((this.startTime?.length ?? 0) >= 8) {
        return this.startTime.substr(0, 8);
      }
    }
    return undefined;
  }

  @computed
  get startDateTime(): Dayjs | undefined {
    if (this.startDate) {
      const time = this.startTimeFixed ?? '00:00';
      return dayjs(`${this.startDate} ${time}`, 'YYYY-MM-DD HH:mm');
      // return dayjs.tz(`${this.meetingStartDate} ${time}`, this.meetingTimezone);
    }
    return undefined;
  }

  @computed
  get localStartDateTime(): Dayjs | undefined {
    if (this.startDateTime) {
      return dayjs(this.startDateTime.toDate());
    }
    return undefined;
  }

  @computed
  get dayjsDuration(): Duration {
    return dayjs.duration(this.duration);
  }

  @computed
  get durationDayjs(): Dayjs {
    return dayjs.utc(0).add(this.dayjsDuration);
  }

  @computed
  get nextDateIndex(): number {
    const now = dayjs();
    return this.dates
      .map((date) => [dayjs(date), dayjs(date).add(dayjs.duration(this.duration))])
      .findIndex(([start, end]) => (!now.isBefore(start) && !now.isAfter(end)) || now.isBefore(start));
  }

  @computed
  get nextStartDateIndex(): number {
    const now = dayjs();
    return this.dates
      .sort((a, b) => dayjs(a).diff(dayjs(b)))
      .map((date) => dayjs(date))
      .findIndex((start) => now.isBefore(start));
  }

  @computed
  get hasMoreMeetings(): boolean {
    return this.dates.length > 1 && this.nextDateIndex !== -1 && this.nextDateIndex < this.dates.length - 1;
  }

  @computed
  get nextDate(): Dayjs | undefined {
    const nextDate = this.dates[this.nextDateIndex];
    return nextDate ? dayjs(nextDate) : undefined;
  }

  @computed
  get nextStartDate(): Dayjs | undefined {
    const nextDate = this.dates[this.nextStartDateIndex];
    return nextDate ? dayjs(nextDate) : undefined;
  }

  @computed
  get nextOrLastDate(): Dayjs | undefined {
    const nextDate = this.dates[this.nextDateIndex] ?? this.dates[this.dates.length - 1];
    return nextDate ? dayjs(nextDate) : undefined;
  }

  @computed
  get event(): SubscribedEventEntry {
    return new SubscribedEventEntry({
      objectId: this.id,
      objectType: this.type,
      duration: this.duration,
    });
  }

  static find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<SubscribableEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'zoomMeeting' })).then((res) =>
      (res ?? []).map((r) => new SubscribableEntry(r)),
    );
  }

  static findOne(id: string): Promise<SubscribableEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new SubscribableEntry(res));
  }
}
