import { observable } from 'mobx';

export class FeedActivityReactionCounts {
  @observable
  like: number = 0;
  @observable
  comment: number = 0;

  constructor(json?: any) {
    if (json) {
      this.like = json.like || 0;
      this.comment = json.comment || 0;
    }
  }
}
