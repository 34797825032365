/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  CheckInboxScreenWrapper,
  CheckInboxScreenWrapperProps,
} from '../../../Components/Auth/CheckInbox/CheckInboxScreenWrapper';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { useRootStore } from '../../../Store/useRootStore';
import { useNavigate } from 'react-router-dom';
import { ErrorAlert } from '../../../Components/Auth/Alerts/ErrorAlert';
import { CoachRegistrationRequest } from '../../../Model/CoachSubscription/CoachRegistrationRequest';

export type LoginCheckInboxViewProps = Pick<CheckInboxScreenWrapperProps, 'onBack'> & {
  email: string;
  authCode: string | null;
};

export const LoginCheckInboxView: React.FC<LoginCheckInboxViewProps> = observer(({ email, onBack, authCode }) => {
  const { t } = useTrans();
  const navigate = useNavigate();
  const { authentication, gymCustomer, coachSubscription, analytics } = useRootStore();

  const [currentCode, setCurrentCode] = useState('');
  const [processing, setProcessing] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [alertTitle, setAlertTitle] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState<string | null>(null);

  const handleResendEmail = React.useCallback(() => {
    authentication
      .requestMagicLink(email)
      .then(() => analytics.logEvent('resend_magic_link_clicked'))
      .catch((e) => analytics.logError('resend_magic_link_clicked', e.response?.data?.errorCode));
  }, [authentication, email, analytics]);

  const handleSubmitCode = React.useCallback(
    (code: string) => {
      setCurrentCode(code);
      if (code.length === 6) {
        setProcessing(true);
        authentication
          .otpLogin(code)
          .then(() => coachSubscription.fetchSubscription())
          .finally(() => setProcessing(false))
          .then(() => {
            analytics.logLogin('magicLink');
            navigate('/', { replace: true });
          })
          .catch((e) => {
            CoachRegistrationRequest.find(code)
              .then((request) => {
                analytics.logEvent('registration_code_during_login');
                setAlertTitle(
                  t('loginCheckInboxScreen.registrationCodeAlert', {
                    defaultValue:
                      'It looks like you do not have an account and entered a verification code.\nWe will redirect you to the registration where you can create your account',
                  }),
                );
                setShowErrorAlert(true);
                setVerificationCode(code);
              })
              .catch((e) => {
                // it was neither a login code nor a registration code...
                setCurrentCode('');
                setErrorCode(e.response?.data?.errorCode || 'invalid_code');
                setShowErrorAlert(true);
              });
          });
      }
    },
    [analytics, authentication, coachSubscription, navigate, t],
  );

  const handleCloseAlert = React.useCallback(() => {
    setShowErrorAlert(false);
    alertTitle && verificationCode && navigate(`/registration/final?verificationCode=${verificationCode}`);
  }, [navigate, alertTitle, verificationCode]);

  useEffect(() => {
    handleSubmitCode(authCode ?? '');
  }, [authCode, handleSubmitCode]);

  return (
    <React.Fragment>
      <CheckInboxScreenWrapper
        title={t('loginCheckInboxScreen.title', { defaultValue: 'Check your Inbox and spam folder!' })}
        text={t('loginCheckInboxScreen.text', {
          defaultValue: 'We sent you a six-digit code to your email address. Please enter it below.',
        })}
        overlayRightTitle={t('registration.stepOne.introTitle', { defaultValue: `Hello\nHealthy\nLife` })}
        image={require('../../../Assets/Images/auth_5.png')}
        email={email}
        onResendEmail={handleResendEmail}
        onBack={onBack}
        onSubmitCode={handleSubmitCode}
        code={currentCode}
        codeDisabled={processing}
        type="loginCheckInbox"
      />
      <ErrorAlert
        toggle={handleCloseAlert}
        isOpen={showErrorAlert}
        title={alertTitle ?? t(`errors.${errorCode}`, { defaultValue: errorCode })}
        onDismiss={handleCloseAlert}
      />
    </React.Fragment>
  );
});
