/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';

const Tag = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

export type TimeTagProps = {
  time?: string;
};

export const TimeTag: React.FC<TimeTagProps> = observer(({ time }) => {
  return <Tag>{time}</Tag>;
});
