/**
 *
 * Created by neo on 15.01.17.
 */

import { observable, action, onBecomeObserved } from 'mobx';
import { v4 as UUID } from 'uuid';
import { Audited } from './Audited';
import { Gym } from './Gym/Gym';
import { Athlete } from './Athlete/Athlete';
import { HttpBackend } from '../Services/Http/HttpBackend';

export class Customer extends Audited {
  @observable
  gymId: string = '';
  @observable
  athlete = new Athlete();
  @observable
  status: string = '';
  @observable
  gym?: Gym = undefined;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.id = json.id;
      this.gymId = json.gymId;
      this.status = json.status;
      this.athlete = new Athlete(json.athlete);
    }

    if (!this.id || !(this.id.length > 0)) {
      this.id = UUID();
    }

    onBecomeObserved(this, 'gym', this.fetchGym);
  }

  fetchGym = async () => {
    if (this.gymId && !this.gym) {
      this.gym = await Gym.get(this.gymId);
    }
    return this.gym;
  };

  @action
  save() {
    return this.athlete.save();
  }

  @action
  remove() {
    return HttpBackend.delete(`/gym/customer/${this.id}`);
  }

  static recentCoachCustomer(): Promise<Customer | undefined> {
    return HttpBackend.get(`/gym/customer/recentCoachCustomer`).then((res) => (res ? new Customer(res) : undefined));
  }

  static find(data: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<Customer[]> {
    return HttpBackend.get(`/gym/customer`, data).then((res) => (res ?? []).map((c) => new Customer(c)));
  }

  static count(data: any = { start: 0, sort: 'athlete.firstname,ASC' }): Promise<number> {
    return HttpBackend.get(`/gym/customer/count`, data).then((res) => res ?? 0);
  }
}
