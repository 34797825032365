import styled from '@emotion/styled';
import React from 'react';
import { APPLE_DOWNLOAD_LINK } from '../../../Utils/Constants';

const ButtonLogo = styled.img`
  width: 94px;
  height: 34px;
`;

export const AppleDownloadButton: React.FC = () => {
  return (
    <a href={APPLE_DOWNLOAD_LINK}>
      <ButtonLogo src={require('../../../Assets/Images/app-store.png')} alt="app-store-logo" />
    </a>
  );
};
