/**
 * Created by neo on 05.01.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ButtonHTMLAttributes, PropsWithChildren, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button as BootstrapButton } from 'reactstrap';
import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { useTrans } from '../Store/System/LocalizationStore';
import { logger } from '../Utils/logger';

const StyledButton = styled(BootstrapButton)`
  border-radius: 200px;
  border-width: 2px;
  font-weight: 600;
  line-height: 14px;
  color: #fff;
  font-size: ${({ size }) => (size === 'sm' ? '0.8em' : '1em')};
  padding: ${({ size }) => (size === 'sm' ? '10px 16px' : '16px 32px')};

  &.d-sm-block {
    @media (min-width: 576px) {
      display: flex !important;
    }
  }

  &.d-sm-block.d-none {
    display: none;
  }

  &.d-none {
    display: none;
  }

  &:hover {
    ${({ disabled }) => (disabled ? '' : 'cursor: pointer')};
  }

  &.btn-primary {
    background-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : '#000')};
    border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : '#000')};

    &:active,
    &:hover {
      background-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : '#000')};
      border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : '#000')};
      // background-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : 'white')};
      // border-color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.5)' : 'black')};
      // color: black;
    }
  }

  &.disabled {
    border: none;
  }

  &.btn-danger {
    background-color: #dc3545;

    &:active {
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }

  &.btn-secondary {
    background-color: white;
    color: black;
    border-color: black;

    &:hover,
    &:active {
      background-color: black;
      border-color: white;
      color: white;
    }
  }

  &.btn-link {
    color: black;
    border-width: 0;
  }

  @media (min-width: 600px) {
    font-size: 1em;
    padding: 12px 8px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
`;

const TimeUnit = styled.div``;

const Value = styled.div`
  font-weight: bold;
`;

const Unit = styled.div<{ disabled?: boolean }>`
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? 'white' : '#919191')};
`;

const Separator = styled.div<{ disabled?: boolean }>`
  width: 1px;
  //height: 80%;
  height: 20px; // for some reason percentage height does not always work
  background: ${({ disabled }) => (disabled ? 'white' : '#737373')};
`;

export type CountdownButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> & {
  date?: Date | number;
  countdownEndText?: string | null;
  countdownDeltaSeconds?: number;
  block?: boolean;
  size?: 'sm';
};

export const CountdownButton: React.FC<CountdownButtonProps> = observer(
  ({ date, color = 'primary', countdownEndText, disabled, countdownDeltaSeconds = 0, ...moreProps }) => {
    const { t } = useTrans();

    const [duration, setDuration] = useState<Duration | undefined>();

    const dayjsDate = React.useMemo(() => (date ? dayjs(date) : undefined), [date]);
    const dayjsDateDelta = React.useMemo(
      () => (date ? dayjs(dayjs(date).valueOf() - countdownDeltaSeconds * 1000) : undefined),
      [countdownDeltaSeconds, date],
    );

    useEffect(() => {
      let timeout: ReturnType<typeof setInterval> | undefined = undefined;
      if (dayjsDate && dayjs().isBefore(dayjsDateDelta)) {
        setDuration(dayjs.duration(dayjsDate.diff(dayjs().valueOf())));
        timeout = setInterval(() => {
          if (dayjs().isBefore(dayjsDateDelta)) {
            setDuration(dayjs.duration(dayjsDate.diff(dayjs())));
          } else {
            setDuration(undefined);
          }
        }, 1000);
      } else {
        setDuration(undefined);
      }

      return () => timeout && clearInterval(timeout);
    }, [dayjsDate, dayjsDateDelta]);

    logger('duration', duration);

    if (duration) {
      return (
        <StyledButton disabled={disabled} {...moreProps} color={color}>
          <InnerContainer>
            <TimeUnit>
              <Value>{Math.floor(duration.asDays())}</Value>
              <Unit disabled={disabled}>{t('countdownButton.days', { defaultValue: 'Days' })}</Unit>
            </TimeUnit>
            <Separator disabled={disabled} />
            <TimeUnit>
              <Value>{duration.hours()}</Value>
              <Unit disabled={disabled}>{t('countdownButton.hours', { defaultValue: 'Hours' })}</Unit>
            </TimeUnit>
            <Separator disabled={disabled} />
            <TimeUnit>
              <Value>{duration.minutes()}</Value>
              <Unit disabled={disabled}>{t('countdownButton.minutes', { defaultValue: 'Minutes' })}</Unit>
            </TimeUnit>
            <Separator disabled={disabled} />
            <TimeUnit>
              <Value>{duration.seconds()}</Value>
              <Unit disabled={disabled}>{t('countdownButton.seconds', { defaultValue: 'Seconds' })}</Unit>
            </TimeUnit>
            {/*{children ?? String(value).toUpperCase()}*/}
          </InnerContainer>
        </StyledButton>
      );
    }

    return countdownEndText ? (
      <StyledButton {...moreProps} color={color}>
        <InnerContainer>{countdownEndText}</InnerContainer>
      </StyledButton>
    ) : null;
  },
);
