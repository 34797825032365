/**
 * Created by katarinababic on 23.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { RequestCodeRequest } from '../../../../Model/CoachSubscription/RequestCodeRequest';
import { RegistrationRequestView } from './RegistrationRequestView';
import { RegistrationRequestSubmittedView } from './RegistrationRequestSubmittedView';
import { useRootStore } from '../../../../Store/useRootStore';

export type RegistrationRequestScreenProps = {};

export const RegistrationRequestScreen: React.FC<RegistrationRequestScreenProps> = observer((props) => {
  const { language, analytics } = useRootStore();

  const [request, setRequest] = useState(new RequestCodeRequest({ language: language.language }));
  const [viewState, setViewState] = useState<'registration' | 'submitted'>('registration');

  const handleRequestSubmitted = React.useCallback(() => {
    setViewState('submitted');
  }, []);

  const handleResetViewState = React.useCallback(() => setViewState('registration'), []);

  const handleResendEmail = React.useCallback(() => {
    request
      .requestCode()
      .then(() => {
        analytics.logEvent('resend_registration_code_clicked');
      })
      .catch((e) => {
        console.log('RegistrationRequestScreen:handleResendEmail', e);
        analytics.logError('resend_registration_code_clicked', e.response?.data?.errorCode);
      });
  }, [analytics, request]);

  if (viewState === 'submitted') {
    return (
      <RegistrationRequestSubmittedView
        request={request}
        onBack={handleResetViewState}
        onResendEmail={handleResendEmail}
      />
    );
  }

  return <RegistrationRequestView request={request} onSubmitted={handleRequestSubmitted} />;
});
