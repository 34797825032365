import { action, observable } from 'mobx';
import { RoundInfo } from '../../../ProgramPortfolio/RoundInfo';
import { CoachExerciseBlock } from './CoachExerciseBlock';

export class CoachIntervalRound {
  @observable index: number = 0;
  @observable blocks: CoachExerciseBlock[] = [];
  @observable roundInfo: RoundInfo;
  @observable prevRound?: CoachIntervalRound;
  @observable nextRound?: CoachIntervalRound;

  constructor(index: number = 0, blocks?: CoachExerciseBlock[], roundInfo?: RoundInfo, prevRound?: CoachIntervalRound) {
    this.index = index || 0;
    this.blocks = blocks || [];
    this.roundInfo = roundInfo || new RoundInfo();
    this.setPrevRound(prevRound);
  }

  @action
  setPrevRound(prevRound?: CoachIntervalRound) {
    if (prevRound) {
      this.prevRound = prevRound;
      prevRound.nextRound = this;
    }
  }
}
