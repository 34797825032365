/**
 * Created by neo on 23.02.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreCategory } from '../../Model/Explore/ExploreCategory';
import { ExploreCardWrapper } from './ExploreCardWrapper';
import styled from '@emotion/styled';
import { useRootStore } from '../../Store/useRootStore';
import { Button } from '../../Components/Button';
import { Link } from 'react-router-dom';
import { useTrans } from '../../Store/System/LocalizationStore';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  &:hover .line {
    background-color: #24dca4;
  }
`;

const Title = styled.h3`
  color: black;
  font-weight: 800;
  font-size: 2em;
`;

const Description = styled.div`
  flex: 1;
  margin: 16px 0;
`;

const Line = styled.div`
  height: 8px;
  margin: 8px 0;
  background-color: #F6F6F7;
`;

const SeeAllButton = styled(Button)`
  align-self: flex-start;
  text-transform: uppercase;
`;

export type ExploreCategoryIntroCardProps = {
  category: ExploreCategory;
};

export const ExploreCategoryIntroCard: React.FC<ExploreCategoryIntroCardProps> = observer(({ category }) => {
  const { language } = useRootStore();

  const { t } = useTrans();

  const categoryTitle = category.getName(language.language);
  const description = category.getShortDescription(language.language) || category.getDescription(language.language);

  return (
    <ExploreCardWrapper>
      <Container>
        <Title>{categoryTitle}</Title>
        <Line className="line" />
        <Description>{description}</Description>
        <SeeAllButton tag={Link} to={category.identifier} size="sm">
          {t('exploreCategoryIntroCard.seeAllButton', { defaultValue: 'See all' })}
        </SeeAllButton>
      </Container>
    </ExploreCardWrapper>
  );
});
