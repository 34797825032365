import { AppleAuthenticationRequest } from './AppleAuthenticationRequest';
import { computed, observable, runInAction, toJS } from 'mobx';
import AppBackend from '../../Services/Http/AppBackend';
import { EMAIL_REGEX } from '../../Utils/Constants';
import { OneTimePasswordResponse } from '../../Services/Security/OneTimePasswordResponse';

export type CoachRegistrationVerificationRequestJson = {
  verificationCode: string;
  email?: string;
  apple?: AppleAuthenticationRequest;
  customerGroupId?: string;
};

export class CoachRegistrationVerificationRequest {
  @observable
  verificationCode = '';
  @observable
  email?: string;
  @observable
  apple?: AppleAuthenticationRequest;
  @observable
  customerGroupId?: string;

  @observable
  validatingEmail = false;

  @observable
  emailExists?: boolean;

  @observable
  allowsDataSharing = false;
  @observable acceptsMarketing: boolean = false;
  @observable tosAndPrivacyAccepted: boolean = false;

  constructor(json?: Partial<CoachRegistrationVerificationRequestJson>) {
    if (json) {
      this.verificationCode = json.verificationCode ?? '';
      this.email = json.email;
      this.customerGroupId = json.customerGroupId;
    }
  }

  toJS(): CoachRegistrationVerificationRequestJson {
    return {
      verificationCode: this.verificationCode,
      email: this.email,
      apple: toJS(this.apple),
      customerGroupId: this.customerGroupId,
    };
  }

  /**
   * @param request
   * @return the otp for further authentication
   */
  signup(apple?: AppleAuthenticationRequest): Promise<OneTimePasswordResponse> {
    return AppBackend.post(`/coach/registration/verify`, Object.assign({}, this.toJS(), { apple }));
  }

  checkEmailExists(): Promise<boolean> {
    this.validatingEmail = true;
    this.emailExists = undefined;
    return AppBackend.get('/athlete/signup/emailcheck', { email: this.email })
      .then(() => {
        runInAction(() => (this.emailExists = false));
        return false;
      })
      .catch(() => {
        runInAction(() => (this.emailExists = true));
        return true;
      })
      .finally(() => {
        runInAction(() => (this.validatingEmail = false));
      });
  }

  @computed
  get emailValid(): boolean {
    if (this.email && this.email.trim().length > 0) {
      return EMAIL_REGEX.test(this.email.trim());
    }
    return false;
  }
}
