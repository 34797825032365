/**
 * Created by katarinababic on 12/06/2020.
 */
import { Typography } from './Typography';
import { Colors } from './Colors';

export const Text = {
  body1: {
    color: Colors.darkGrey,
    ...Typography.font.normal,
    fontSize: 16,
  },
  body2: {
    color: Colors.black,
    ...Typography.font.bold,
    fontSize: 16,
  },
  body3: {
    color: Colors.darkGrey,
    ...Typography.font.heavy,
    fontSize: 16,
  },
  bodyItalic: {
    color: Colors.darkGrey,
    ...Typography.font.italic,
    fontSize: 16,
  },
  button1: {
    color: Colors.primaryDarkest,
    ...Typography.font.normal,
    fontSize: 14,
  },
  button2: {
    color: Colors.primaryDarkest,
    ...Typography.font.bold,
    fontSize: 14,
  },
  medium16: {
    color: Colors.black,
    ...Typography.font.medium,
    fontSize: 16,
  },
  medium14: {
    color: Colors.black,
    ...Typography.font.medium,
    fontSize: 14,
  },
  caption1: {
    color: Colors.darkGrey80,
    ...Typography.font.normal,
    fontSize: 14,
  },
  caption2: {
    color: Colors.darkGrey80,
    ...Typography.font.bold,
    fontSize: 14,
  },
  captionItalic: {
    color: Colors.darkGrey80,
    ...Typography.font.italic,
    fontSize: 14,
  },
  header: {
    color: Colors.black,
    ...Typography.font.bold,
    fontSize: 24,
  },
  headerNormal: {
    color: Colors.black,
    ...Typography.font.normal,
    fontSize: 24,
  },
  headerLight: {
    color: Colors.black,
    ...Typography.font.light,
    fontSize: 24,
  },
  italic: {
    color: Colors.black,
    ...Typography.font.heavyItalic,
    fontStyle: 'italic',
    fontSize: 14,
  },
  subtitle1: {
    color: Colors.darkGrey70,
    ...Typography.font.normal,
    fontSize: 16,
  },
  subtitle2: {
    color: Colors.darkGrey70,
    ...Typography.font.bold,
    fontSize: 16,
  },
  tiny1: {
    color: Colors.darkGrey70,
    ...Typography.font.normal,
    fontSize: 12,
  },
  tiny2: {
    color: Colors.darkGrey70,
    ...Typography.font.bold,
    fontSize: 12,
  },
  title: {
    color: Colors.black,
    ...Typography.font.bold,
    fontSize: 18,
  },
  titleItalic: {
    color: Colors.black,
    ...Typography.font.boldItalic,
    fontSize: 18,
  },
  titleNormal: {
    color: Colors.black,
    ...Typography.font.normal,
    fontSize: 18,
  },
  titleLight: {
    color: Colors.black,
    ...Typography.font.light,
    fontSize: 18,
  },
};
