/**
 * Created by neo on 26.07.21.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';
import { LinkUtils } from '../../../../Utils/LinkUtils';
import { useRootStore } from '../../../../Store/useRootStore';
import { Button } from '../../../Button';
import styled from '@emotion/styled';
import { LatoText } from '../../../Text/LatoText';
import { Theme } from '../../../../Theme/Theme';

const Container = styled.div``;

export type ButtonElementProps = {
  element: NativeFeedComponent;
};

export const ButtonElement: React.FC<ButtonElementProps> = observer(({ element }) => {
  const { language } = useRootStore();

  const buttonLink = element.props.extraProps.get('link')?.toString()?.trim();

  const handlePress = React.useCallback(() => {
    if (buttonLink) {
      if (LinkUtils.isInternalUrl(buttonLink)) {
        window.open(buttonLink);
      } else {
        window.open(buttonLink, '_blank');
      }
    }
  }, [buttonLink]);

  if (buttonLink) {
    return (
      <Container>
        {element.type === 'Button' && <Button onClick={handlePress}>{element.getText(language.language)}</Button>}
        {element.type === 'SecondaryButton' && (
          <LatoText
            variant="caption2"
            text={element.getText(language.language).toUpperCase()}
            style={styles.secondaryButtonText}
            onClick={handlePress}
          />
        )}
      </Container>
    );
  }
  return null;
});

const styles = {
  secondaryButtonText: {
    paddingRight: 16,
    color: Theme.Colors.black,
  },
};
