/**
 * Created by neo on 28.07.21.
 */
import { observable } from 'mobx';

export type MarkedDateJson = {
  date: string;
  count: number;
};

export class MarkedDate {
  @observable
  date: string = '';
  @observable
  count: number = 0;

  constructor(json?: Partial<MarkedDateJson>) {
    if (json) {
      this.date = json.date ?? '';
      this.count = json.count ?? 0;
    }
  }
}
