/**
 * Created by katarinababic on 28.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Container } from 'reactstrap';
import { PropsWithChildren } from 'react';

const BlackBox = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem;

  @media (min-width: 390px) {
    padding: 2rem;
  }

  @media (min-width: 768px) {
    align-self: center;
    border-radius: 1rem;
    padding: 3rem 2rem 2rem 2rem;
  }

  @media (min-width: 1280px) {
    max-width: 90%;
  }

  @media (min-width: 1600px) {
    max-width: 80%;
  }

  @media (min-width: 1768px) {
    max-width: 70%;
  }

  @media (min-width: 2300px) {
    max-width: 50%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

const logoStyle: React.CSSProperties = {
  width: '3.5rem',
  height: '3.5rem',
};

const TextContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const Title = styled.h5`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
`;

const TitleUnderline = styled.div`
  background-color: rgba(240, 164, 75, 0.1);
  height: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.h5`
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.7rem;
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.2rem;
  padding-top: 0.5rem;
`;

export type AuthLayoutBlackBoxProps = {
  title: string;
  subtitle?: string | null;
  text?: string | null;
};

export const AuthLayoutBlackBox: React.FC<PropsWithChildren<AuthLayoutBlackBoxProps>> = observer(
  ({ title, subtitle, text, children }) => {
    return (
      <BlackBox>
        <LogoContainer>
          <img alt="logo" src={require('../../../Assets/Images/logo.png')} style={logoStyle} />
        </LogoContainer>
        <TextContainer>
          <Title>{title}</Title>
          <TitleUnderline />
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
          <Text>{text}</Text>
        </TextContainer>
        {children}
      </BlackBox>
    );
  },
);
