/**
 * Created by neo on 02.11.20.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ImageElement } from './ImageElement';
import { VideoElement } from './VideoElement';
import { ViewElement } from './ViewElement';
import { TextElement } from './TextElement';
import { ButtonElement } from './ButtonElement';
import { BulletAndNumberElement } from './BulletAndNumberElement';
import { NativeFeedComponent } from '../../../../Model/Feed/GetStream/NativeFeedComponent';

export type ElementRendererProps = {
  element: NativeFeedComponent;
  eventParams?: Record<string, string | number | boolean | undefined>;
};

export const ElementRenderer: React.FC<ElementRendererProps> = observer(
  ({ element, eventParams }: ElementRendererProps) => {
    switch (element.type) {
      case 'View':
        return <ViewElement element={element} />;
      case 'Image':
        return <ImageElement element={element} />;
      case 'Video':
        return <VideoElement element={element} eventParams={eventParams} />;
      case 'Heading':
      case 'ItalicHeading':
      case 'Paragraph':
      case 'ItalicParagraph':
      case 'Caption':
      case 'Summary':
      case 'InnerBoldText':
      case 'InnerLinkText':
        return <TextElement element={element} />;
      case 'BulletPoint':
      case 'NumberPoint':
        return <BulletAndNumberElement element={element} />;
      case 'Button':
      case 'SecondaryButton':
        return <ButtonElement element={element} />;
      default:
        return null;
    }
  },
);
