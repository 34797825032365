import { ConfigColors } from './Colors';
import { Typography } from './Typography';

export const ConfigTheme: any = {
  header: {},
  tabbar: {
    backgroundColor: ConfigColors.primary,
    iconColor: ConfigColors.warmGrey,
    selectedIconColor: ConfigColors.primaryDark,
    selectedBackgroundColor: ConfigColors.primary,
  },
  button: {
    outline: {
      borderColor: ConfigColors.white,
      textColor: ConfigColors.white,
    },
    primary: {
      backgroundColor: ConfigColors.primaryDark,
      textColor: ConfigColors.white,
    },
    success: {
      backgroundColor: ConfigColors.success,
      textColor: ConfigColors.white,
    },
    danger: {
      backgroundColor: ConfigColors.error,
      textColor: ConfigColors.white,
    },
    light: {
      backgroundColor: ConfigColors.warmGrey,
      textColor: ConfigColors.black,
    },
  },
  list: {
    separator: {
      borderColor: ConfigColors.white,
      backgroundColor: ConfigColors.lightGrey,
      textColor: ConfigColors.darkGrey,
    },
  },
  outerTouchable: {
    flex: 1,
    flexGrow: 1,
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    flexGrow: 1,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardStyle: {
    backgroundColor: ConfigColors.white,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  centeredRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heavyBlack: {
    fontFamily: Typography.font.heavy,
    color: ConfigColors.black,
  },
  heavydarkGrey: {
    fontFamily: Typography.font.heavy,
    color: ConfigColors.darkGrey,
  },
  heavyPrimary: {
    fontFamily: Typography.font.heavy,
    color: ConfigColors.primaryDark,
  },
  heavyWhite: {
    fontFamily: Typography.font.heavy,
    color: ConfigColors.white,
  },
  heavyWarmestGrey: {
    fontFamily: Typography.font.heavy,
    color: ConfigColors.warmestGrey,
  },
  normaldarkGrey: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.darkGrey,
  },
  normalWhite: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.white,
  },
  normalBlack: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.black,
  },
  normalPrimary: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.primaryDark,
  },
  normalWarmGrey: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.warmGrey,
  },
  normalWarmestGrey: {
    fontFamily: Typography.font.normal,
    color: ConfigColors.warmestGrey,
  },
};
