/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Container = styled.div`
  background-color: #f6f6f7;
  padding: 8px 16px;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CurrentTimeInterval = styled.h5`
  color: #3c3c3c;
  font-weight: 700;
  text-align: center;
  padding: 0 16px;
  width: 240px;
`;

const ChangeButton = styled.button`
  border-width: 0;
  background-color: transparent;
  color: #000000;

  i {
    color: black;
    font-size: 1.5rem;
  }

  :disabled i {
    color: lightgrey;
  }
`;

export type TimeNavigationBarProps = {
  displayTime: string;
  onPrevious?: () => void;
  onNext?: () => void;
  onFirst?: () => void;
  onLast?: () => void;
  hasNext?: boolean;
  hasPrevious?: boolean;
};

export const TimeNavigationBar: React.FC<TimeNavigationBarProps> = observer(
  ({ displayTime, onNext, onPrevious, hasPrevious, hasNext, onFirst, onLast }) => {
    return (
      <Container>
        <ChangeButton onClick={onFirst}>
          <i className="bi bi-chevron-double-left"></i>
        </ChangeButton>
        <ChangeButton onClick={onPrevious} disabled={!hasPrevious}>
          <i className="bi bi-chevron-left"></i>
        </ChangeButton>
        <CurrentTimeInterval>{displayTime}</CurrentTimeInterval>
        <ChangeButton onClick={onNext} disabled={!hasNext}>
          <i className="bi bi-chevron-right"></i>
        </ChangeButton>
        <ChangeButton onClick={onLast}>
          <i className="bi bi-chevron-double-right"></i>
        </ChangeButton>
      </Container>
    );
  },
);
