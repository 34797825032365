/**
 * Created by neo on 20.12.20.
 */
import { observable } from 'mobx';

export type RouteLocationJson = {
  alt?: number;
  hAcc?: number;
  vAcc?: number;
  course?: number;
  speed?: number;
  lat: number;
  lon: number;
  time: string;
};

export class RouteLocation {
  @observable
  alt?: number;
  @observable
  hAcc?: number;
  @observable
  vAcc?: number;
  @observable
  course?: number;
  @observable
  speed?: number;
  @observable
  lat: number = 0;
  @observable
  lon: number = 0;
  @observable
  time = new Date();

  constructor(json?: Partial<RouteLocationJson>) {
    if (json) {
      this.alt = json.alt;
      this.hAcc = json.hAcc;
      this.vAcc = json.vAcc;
      this.course = json.course;
      this.speed = json.speed;
      this.lat = json.lat ?? 0;
      this.lon = json.lon ?? 0;
      this.time = json.time ? new Date(json.time) : new Date();
    }
  }

  toJS(): RouteLocationJson {
    return {
      alt: this.alt,
      hAcc: this.hAcc,
      vAcc: this.vAcc,
      course: this.course,
      speed: this.speed,
      lat: this.lat,
      lon: this.lon,
      time: this.time.toISOString(),
    };
  }
}
