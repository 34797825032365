/**
 * Created by katarinababic on 5.11.24.
 */

import { observable } from 'mobx';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { HttpBackend } from '../../Services/Http/HttpBackend';

export type ExploreFilterTagJson = {
 id: string;
 count: number;
};

export class ExploreFilterTag {
 @observable
 id: string = '';
 @observable
 count: number = 0;

 constructor(json?: Partial<ExploreFilterTagJson>) {
  if (json) {
   this.id = json.id ?? '';
   this.count = json.count ?? 0;
  }
 }

 static find(request: ExploreEntryQueryRequest): Promise<ExploreFilterTag[]> {
  return HttpBackend.get(`/coach/explore/tags`, request).then((res) =>
      (res ?? []).map((r) => new ExploreFilterTag(r)),
  );
 }
}
