import { action, computed, observable, ObservableMap, onBecomeObserved, runInAction, toJS } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ActivityLogSource, ActivityLogSourceJson } from './ActivityLogSource';
import { Activity } from './Activity';
import dayjs, { Dayjs } from 'dayjs';
import { RouteLocation, RouteLocationJson } from './RouteLocation';
import { Pageable } from '../Interfaces/Pageable';
import { ExploreEntry } from '../Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../Explore/ExploreEntryBuilder';
import { MarkedDate } from './MarkedDate';

export type ActivityLogQueryRequest = Pageable & {
  athleteId?: string;
  type?: string;
  source?: string;
  sourceType?: string;
  sourceId?: string;
  startDate?: Date;
  endDate?: Date;
  excludedSourceType?: string;
  excludedSourceId?: string[];
};

export type ActivityLogJson = {
  id: string;
  athleteId?: string;
  type?: string;
  activityId?: string;
  source: ActivityLogSourceJson;
  startDate: string;
  endDate: string;
  data: Record<string, number>;
  linkedData: Record<string, any>;
  route: RouteLocationJson[];
};

export class ActivityLog {
  @observable id: string = UUID();
  @observable athleteId?: string;
  /**
   * Refers to the `identifier` of an Activity from our system
   */
  @observable type?: string;
  @observable activityId?: string;
  @observable source = new ActivityLogSource();
  @observable startDate = new Date();
  @observable endDate = new Date();
  @observable data: ObservableMap<string, number> = observable.map({});
  @observable
  linkedData: Record<string, any> = {};
  @observable
  route: RouteLocation[] = [];
  // @observable
  // workoutLog?: WorkoutLog;
  @observable
  activity?: Activity;
  @observable
  exploreEntry?: ExploreEntry;

  constructor(json?: Partial<ActivityLogJson> & { /* workoutLog?: WorkoutLog; */ activity?: Activity }) {
    if (json) {
      this.id = json.id || UUID();
      this.athleteId = json.athleteId;
      this.type = json.type;
      this.activityId = json.activityId;
      this.startDate = json.startDate ? new Date(json.startDate) : new Date();
      this.endDate = json.endDate ? new Date(json.endDate) : new Date();
      this.data = observable.map(json.data || {});
      this.route = (json.route ?? []).map((r) => new RouteLocation(r));
      this.source = new ActivityLogSource(json.source);
      // this.workoutLog = json.workoutLog;
      this.activity = json.activity;
      this.linkedData = json.linkedData ?? {};
    }

    // onBecomeObserved(this, 'workoutLog', () => this.fetchWorkoutLog());
    onBecomeObserved(this, 'activity', this.fetchActivity);
    onBecomeObserved(this, 'exploreEntry', this.fetchExploreEntry);
  }

  // fetchWorkoutLog(): Promise<WorkoutLog | undefined> {
  //   if (!this.workoutLog && this.source.sourceId && this.source.sourceType === 'workout') {
  //     return WorkoutLog.get(this.source.sourceId).then((result) => {
  //       runInAction(() => (this.workoutLog = result));
  //       return result;
  //     });
  //   }
  //
  //   return Promise.resolve(this.workoutLog);
  // }

  fetchActivity = () => {
    const id = this.type ?? this.activityId;
    if (id) {
      Activity.get(id).then((result) => {
        runInAction(() => (this.activity = result));
      });
    }
  };

  fetchExploreEntry = () => {
    if (this.source.sourceType === 'explore-entry' && this.source.sourceId) {
      ExploreEntryBuilder.findOne(this.source.sourceId).then((result) => {
        runInAction(() => (this.exploreEntry = result));
      });
    }
  };

  fetchRoute(): Promise<RouteLocation[]> {
    return HttpBackend.get(`/activity/tracking/${this.id}/route`).then((res) =>
      (res ?? []).map((r) => new RouteLocation(r)),
    );
  }

  toJS(): ActivityLogJson {
    return {
      id: this.id,
      athleteId: this.athleteId,
      type: this.type,
      activityId: this.activityId,
      source: this.source.toJS(),
      startDate: this.startDate?.toISOString(),
      endDate: this.endDate?.toISOString(),
      data: this.data.toJSON(),
      route: this.route.map((r) => r.toJS()),
      linkedData: toJS(this.linkedData),
    };
  }

  @action
  setDataPoint(key: string, value: number) {
    this.data.set(key, value);
  }

  save(): Promise<ActivityLog> {
    return HttpBackend.post('/activity/tracking', this.toJS()).then((res) => {
      return new ActivityLog(res);
    });
  }

  delete(): Promise<ActivityLog> {
    return HttpBackend.delete(`/activity/tracking/${this.id}`).then(() => {
      return this;
    });
  }

  @computed
  get totalCaloriesBurnt(): number {
    return this.data.get('CALORIES') || 0;
  }

  @computed
  get object(): Activity | ExploreEntry | undefined {
    if (this.source.sourceType === 'explore-entry') {
      return this.exploreEntry;
    } else if (this.source.sourceType === 'workout' && this.source.source === 'kinastic') {
      // return this.workoutLog;
      return undefined;
    }
    return this.activity;
  }

  @computed
  get durationMs(): number {
    // if (this.workoutLog) {
    //   return this.workoutLog.totalDuration;
    // }
    return dayjs(this.endDate).diff(dayjs(this.startDate));
  }

  @computed
  get valid(): boolean {
    const start = dayjs(this.startDate);
    const end = dayjs(this.endDate);

    if (!end.isBefore(start)) {
      const diff = end.diff(start, 'minute');
      return diff <= 480;
    }

    return false;
  }

  @computed
  get startDayjs(): Dayjs {
    return dayjs(this.startDate);
  }

  @computed
  get endDayjs(): Dayjs {
    return dayjs(this.endDate);
  }

  @computed
  get durationInSeconds(): number {
    return this.data.get('DURATION') || this.durationMs / 1000;
  }

  static find(params?: Partial<ActivityLogQueryRequest>): Promise<ActivityLog[]> {
    return HttpBackend.get('/activity/tracking', params).then((result) => result.map((e) => new ActivityLog(e)));
  }

  static markedDates(params?: Partial<ActivityLogQueryRequest>): Promise<MarkedDate[]> {
    return HttpBackend.get('/activity/tracking/markedDates', params).then((result) =>
      result.map((e) => new MarkedDate(e)),
    );
  }

  static get(id: string): Promise<ActivityLog | undefined> {
    return HttpBackend.get(`/activity/tracking/${id}`).then((res) => (res ? new ActivityLog(res) : undefined));
  }

  // static fromWorkoutLog(workoutLog: WorkoutLog): ActivityLog {
  //   return new ActivityLog({
  //     id: `activitylog:${workoutLog.id}`,
  //     type: workoutLog.type || 'gym_strength',
  //     startDate: workoutLog.startDateTime?.toISOString(),
  //     endDate: workoutLog.endDateTime?.toISOString(),
  //     source: {
  //       source: 'kinastic',
  //       sourceId: workoutLog.id,
  //       sourceType: 'workout',
  //     },
  //     data: workoutLog.aggregatedValues,
  //     workoutLog,
  //   });
  // }
}
