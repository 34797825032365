import styled from '@emotion/styled';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';

type TextWithMiddleLineProps = {
  text: string;
};

const Container = styled(Row)`
  padding-top: 1rem;
  justify-content: center;
`;

const Line = styled(Col)`
  border-bottom: 1px solid #c0ccdf;
  line-height: 0.05em;
  margin: 10px 0;
`;

const TextColumn = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Text = styled.span`
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
`;

export const TextWithMiddleLine: React.FC<TextWithMiddleLineProps> = observer(({ text }) => {
  return (
    <Container>
      <Line xs={5} />
      <TextColumn xs={1}>
        <Text>{text}</Text>
      </TextColumn>
      <Line xs={5} />
    </Container>
  );
});
