/**
 *
 * Created by neo on 18.01.17.
 */

import { action, computed, observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from './LocalizedValue';
import { LocalizedNamedEntity, LocalizedNamedEntityJson } from './LocalizedNamedEntity';

export const languagePriority = ['de', 'en', 'fr', 'it'];

export type LocalizedEntityJson = LocalizedNamedEntityJson & {
  description: LocalizedValueJson[];
};

export class LocalizedEntity extends LocalizedNamedEntity {
  @observable
  description: LocalizedValue[] = [];

  constructor(json?: Partial<LocalizedEntityJson>) {
    super(json);
    if (json) {
      this.description = (json.description || []).map((l) => new LocalizedValue(l));
    }
  }

  toJS(newId?: boolean): LocalizedEntityJson {
    return Object.assign(super.toJS(newId), {
      id: this.id,
      description: this.description.map((v) => v.toJS()),
    });
  }

  toDefaultJS(): Omit<LocalizedEntityJson, 'name' | 'description'> & { name: string; description: string } {
    return Object.assign(super.toDefaultJS(), {
      description: this.defaultDescription,
    });
  }

  @action
  changeDescription(lang: string, value: string) {
    const existing = this.description.find((n) => n.lang === lang.toLowerCase());
    if (existing) {
      existing.value = value;
    } else {
      this.description.push(
        new LocalizedValue({
          lang: lang.toLowerCase(),
          value,
        }),
      );
    }
  }

  getDescription(lang: string): string {
    return this.description.find((n) => n.lang === lang.toLowerCase())?.value || this.defaultDescription;
  }

  @computed
  get defaultDescription(): string {
    for (const lang of languagePriority) {
      const entry = this.description.find((l) => l.lang === lang);
      if (entry) {
        return entry.value ?? '';
      }
    }
    const first = this.description[0];
    return first?.value ?? '';
  }
}
