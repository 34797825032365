/**
 * Created by neo on 24.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { AddToCalendarButton } from '../../../Utils/ical/AddToCalendarButton';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { CountdownButton } from '../../CountdownButton';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import { Button } from '../../Button';
import { useRootStore } from '../../../Store/useRootStore';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export type ExploreContentViewOnlineMeetingMainButtonsProps = {
  entry: OnlineMeetingEntry;
  subscription: SubscribedEventEntry;
  onUnregistered?: () => void;
  canJoinMeeting?: boolean;
};

export const ExploreContentViewOnlineMeetingMainButtons: React.FC<ExploreContentViewOnlineMeetingMainButtonsProps> =
  observer(({ entry, subscription, onUnregistered, canJoinMeeting }) => {
    const { analytics } = useRootStore();
    const { t } = useTrans();

    const nextMeeting = entry.nextOrLastDate;

    // are there more meetings after the current one
    const hasMoreMeetings =
      entry.nextDateIndex < entry.dates.length - 1 && entry.dates.length > 1 && entry.nextDateIndex !== -1;
    const canRemind = nextMeeting?.isAfter(dayjs().subtract(5, 'minutes')) || hasMoreMeetings;

    const handleClick = React.useCallback(() => {
      window.open(entry.meetingLink, '_blank');
      // we log online_meeting since a webinar will be hosted embedded in our system
      analytics.logEvent('online_meeting_joined', {
        objectId: entry.id,
        objectType: entry.type,
        url: entry.meetingLink,
      });
    }, [analytics, entry]);

    const handleUnregister = React.useCallback(() => {
      analytics.logEvent('webinar_unregistered', { objectId: entry.id, url: entry.meetingLink });
      subscription.delete().then(() => onUnregistered && onUnregistered());
    }, [analytics, entry, onUnregistered, subscription]);

    return (
      <Container>
        <CountdownButton
          disabled={!canJoinMeeting}
          style={{ marginBottom: 16 }}
          onClick={handleClick}
          date={nextMeeting?.valueOf()}
          countdownDeltaSeconds={1800}
          countdownEndText={t('onlineMeeting.joinMeetingButton', {
            defaultValue: 'Join Now',
          })}
        />
        {canRemind && (
          <React.Fragment>
            <AddToCalendarButton entry={entry} />
            <Button color="link" onClick={handleUnregister}>
              {t('exploreEntryViewScreen.remindMe.unregister', { defaultValue: 'Unregister' })}
            </Button>
          </React.Fragment>
        )}
      </Container>
    );
  });
