/**
 * Created by neo on 15.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { Col, Row } from 'reactstrap';
import styled from '@emotion/styled';

const VideoColumn = styled(Col)``;

const ChatColumn = styled(Col)`
  display: flex;
  flex: 1;
`;

export type ExploreContentViewOnlineMeetingEmbeddedVideoProps = {
  entry: OnlineMeetingEntry;
};

export const ExploreContentViewOnlineMeetingEmbeddedVideo: React.FC<ExploreContentViewOnlineMeetingEmbeddedVideoProps> =
  observer(({ entry }) => {
    const interactionEnabled = entry.embedData?.web?.interactionEmbedCode;

    return (
      <Row>
        <VideoColumn
          xs={12}
          xl={interactionEnabled ? 8 : 12}
          xxl={interactionEnabled ? 9 : 12}
          dangerouslySetInnerHTML={{
            __html: entry.embedData?.web?.videoEmbedCode ?? '',
          }}
        />
        {interactionEnabled && (
          <ChatColumn
            xs={12}
            xl={4}
            xxl={3}
            style={{ height: '100$%' }}
            dangerouslySetInnerHTML={{ __html: entry.embedData?.web?.interactionEmbedCode ?? '' }}
          />
        )}
      </Row>
    );
  });
