import { observable } from 'mobx';

export type LocalizedValueJson = {
  lang: string;
  value: string;
};

export class LocalizedValue {
  @observable
  lang: string = 'de';
  @observable
  value: string = '';

  constructor(json?: Partial<LocalizedValue>) {
    if (json) {
      this.lang = json.lang ?? 'de';
      this.value = json.value ?? '';
    }
  }

  toJS(): LocalizedValueJson {
    return {
      lang: this.lang,
      value: this.value,
    };
  }
}
