/**
 * Created by katarinababic on 29.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { FormLabel } from './Form/FormLabel';
import Select, { SingleValue } from 'react-select';
import { useTrans } from '../Store/System/LocalizationStore';
import { useState } from 'react';
import { Theme } from '../Theme/Theme';

export interface Option {
  label: string;
  value: string;
}

export type DropdownProps = {
  options: Option[];
  onChangeOption?: (val?: string) => void;
};

export const Dropdown: React.FC<DropdownProps> = observer(({ options, onChangeOption }) => {
  const { t } = useTrans();

  const [selectedGroup, setSelectedGroup] = useState<Option | null>();

  const handleChangeOption = React.useCallback(
    (option: SingleValue<Option>) => {
      onChangeOption && onChangeOption(option?.value);
      setSelectedGroup(option);
    },
    [onChangeOption],
  );

  return (
    <React.Fragment>
      <FormLabel>
        {t('registrationScreen.stepTwo.departmentLabel', { defaultValue: 'Which department do you work for?' })}
      </FormLabel>
      <Select<Option>
        value={selectedGroup}
        options={options}
        onChange={handleChangeOption}
        isSearchable={true}
        isClearable={true}
        isMulti={false}
        placeholder={t('registrationScreen.stepTwo.departmentPlaceholder', { defaultValue: 'Department' })}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: Theme.Colors.warmGrey,
            primary: selectedGroup ? Theme.Colors.warmGrey : Theme.Colors.error,
          },
        })}
      />
    </React.Fragment>
  );
});
