/**
 * Created by katarinababic on 25.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { AlertModalProps } from './AlertModal';
import { ErrorAlert } from './ErrorAlert';

export type ContactHrAlertProps = Pick<AlertModalProps, 'toggle' | 'isOpen'> & {
  onDismiss?: () => void;
};

export const ContactHrAlert: React.FC<ContactHrAlertProps> = observer(({ toggle, isOpen, onDismiss }) => {
  const { t } = useTrans();

  return (
    <ErrorAlert
      title={t('registrationScreen.contactHrAlert.title', {
        defaultValue: 'Please contact your HR or WHP responsible for support.',
      })}
      centered={true}
      toggle={toggle}
      isOpen={isOpen}
      onDismiss={onDismiss}
    />
  );
});
