/**
 * Created by neo on 23.03.21.
 */
import { DurationBefore, durationOrder, NotificationInterval, NotificationIntervalJson } from './NotificationInterval';
import { computed, observable, onBecomeObserved, runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { Pageable } from '../../Interfaces/Pageable';
import { ExploreEntryBuilder } from '../../Explore/ExploreEntryBuilder';
import { EMPTY_ARRAY } from '../../../Utils/Constants';
import { SubscribableEntry } from '../../Explore/SubscribableEntry';

export type EventEntrySearchParams = Pageable & {
  athleteId?: string;
  objectType?: string;
  objectId?: string;
  sourceType?: string;
  sourceId?: string;
};

export type SubscribedEventEntryJson = {
  id: string;
  athleteId: string;
  objectType?: string;
  objectId?: string;
  sourceType?: string;
  sourceId?: string;
  notificationIntervals: NotificationIntervalJson[];
  notificationEnabled: boolean;
  groupId?: string;
  duration: string;
  dates: string[];
};

export class SubscribedEventEntry {
  @observable
  id = UUID();
  @observable
  athleteId: string = '';
  @observable
  objectType?: string;
  @observable
  objectId?: string;
  @observable
  duration: string = 'PT1H';
  @observable
  dates: Date[] = [];
  @observable
  sourceType?: string;
  @observable
  sourceId?: string;
  @observable
  notificationEnabled = true;
  @observable
  notificationIntervals: NotificationInterval[] = [
    new NotificationInterval({
      durationBefore: DurationBefore.AT_EVENT,
    }),
  ];
  @observable
  groupId?: string;
  @observable
  object?: SubscribableEntry;

  constructor(
    json?: Partial<
      Omit<SubscribedEventEntryJson, 'notificationIntervals'> & {
        notificationIntervals: Partial<NotificationIntervalJson>[];
      }
    >,
  ) {
    if (json) {
      this.id = json.id ?? UUID();
      this.athleteId = json.athleteId ?? '';
      this.objectType = json.objectType;
      this.objectId = json.objectId;
      this.sourceType = json.sourceType;
      this.sourceId = json.sourceId;
      this.notificationEnabled = json.notificationEnabled ?? true;
      this.notificationIntervals = (json.notificationIntervals ?? []).map((i) => new NotificationInterval(i));
      this.groupId = json.groupId;
      this.duration = json.duration ?? 'PT1H';
      this.dates = (json.dates ?? EMPTY_ARRAY).map((d) => new Date(d));
    }

    onBecomeObserved(this, 'object', this.fetchObject);
  }

  fetchObject = () => {
    if (!this.object && this.objectType && this.objectId) {
      return ExploreEntryBuilder.findOne(this.objectId).then((res) => {
        if (res instanceof SubscribableEntry) {
          runInAction(() => (this.object = res));
          return res;
        }
        return undefined;
      });
    }
    return Promise.resolve(this.object);
  };

  toJS(): SubscribedEventEntryJson {
    return {
      id: this.id,
      athleteId: this.athleteId,
      objectType: this.objectType,
      objectId: this.objectId,
      sourceType: this.sourceType,
      sourceId: this.sourceId,
      notificationEnabled: this.notificationEnabled,
      notificationIntervals: this.notificationIntervals.map((n) => n.toJS()),
      groupId: this.groupId,
      duration: this.duration,
      dates: this.dates.map((d) => d.toISOString()),
    };
  }

  withoutRecurring(): SubscribedEventEntry {
    return new SubscribedEventEntry(
      Object.assign(this.toJS(), {
        recurringPattern: undefined,
      }),
    );
  }

  save(): Promise<SubscribedEventEntry> {
    return HttpBackend.post('/coach/schedule/event/subscription', this.toJS()).then(
      (result) => new SubscribedEventEntry(result),
    );
  }

  delete(): Promise<SubscribedEventEntry> {
    return HttpBackend.delete(`/coach/schedule/event/subscription/${this.id}`).then(() => this);
  }

  /**
   * notification title
   */
  @computed
  get title(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals[0].title;
    }
    return undefined;
  }

  /**
   * notification message
   */
  @computed
  get message(): string | undefined {
    if (this.notificationIntervals.length > 0) {
      return this.notificationIntervals[0].message;
    }
    return undefined;
  }

  getName(language: string = 'de'): string | undefined {
    return this.object?.getName(language);
  }

  get image(): string | undefined {
    return this.object?.media?.mediumOrSmallest;
  }

  @computed
  get link(): string | undefined {
    if (this.object) {
      return `https://coach.kinastic.com/explore/${this.object.type}/${this.object.id}?eventId=${this.id}`;
    }
    return undefined;
  }

  @computed
  get earliestInterval(): NotificationInterval | undefined {
    return this.notificationIntervals
      .slice()
      .sort((a, b) => durationOrder.indexOf(b.durationBefore) - durationOrder.indexOf(a.durationBefore))[0];
  }

  @computed
  get latestInterval(): NotificationInterval | undefined {
    return this.notificationIntervals
      .slice()
      .sort((a, b) => durationOrder.indexOf(a.durationBefore) - durationOrder.indexOf(b.durationBefore))[0];
  }

  static find(params: EventEntrySearchParams): Promise<SubscribedEventEntry[]> {
    return HttpBackend.get('/coach/schedule/event/subscription', params).then((res) =>
      res.map((r) => new SubscribedEventEntry(r)),
    );
  }

  static findExisting(params: EventEntrySearchParams): Promise<SubscribedEventEntry | undefined> {
    return HttpBackend.get('/coach/schedule/event/subscription', Object.assign(params, { size: 1 })).then((res) =>
      res[0] ? new SubscribedEventEntry(res[0]) : undefined,
    );
  }

  static findOne(id: string): Promise<SubscribedEventEntry> {
    return HttpBackend.get(`/coach/schedule/event/subscription/${id}`).then((res) => new SubscribedEventEntry(res));
  }
}
