import { computed, observable } from 'mobx';
import { FeedActivity } from './FeedActivity';
import { GetStreamFeed } from './GetStreamFeed';
import dayjs from '../../../Utils/dayjs';

export class NotificationFeedResult {
  @observable.shallow
  activities: FeedActivity[] = [];
  activity_count: number = 0;
  actor_count: number = 0;
  group: string = '';
  id: string = '';
  @observable
  is_read: boolean = false;
  @observable
  is_seen: boolean = false;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  verb: string = '';

  constructor(readonly feed?: GetStreamFeed, json?: any) {
    if (json) {
      this.id = json.id ?? '';
      this.activities = (json.activities || []).map((a: any) => new FeedActivity(feed, a));
      this.activity_count = json.activity_count || 0;
      this.actor_count = json.actor_count || 0;
      this.created_at = json.created_at ? new Date(json.created_at) : new Date();
      this.updated_at = json.updated_at ? new Date(json.updated_at) : new Date();
      this.group = json.group || '';
      this.is_read = json.is_read ?? false;
      this.is_seen = json.is_seen ?? false;
      this.verb = json.verb ?? '';
    }
  }

  @computed
  get createdAtMoment(): dayjs.Dayjs {
    return dayjs(this.created_at);
  }

  @computed
  get updatedAt(): dayjs.Dayjs {
    return dayjs(this.updated_at);
  }
}
