import { observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { HealthDataPointType } from './HealthDataPointType';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { HealthDataPointSource, HealthDataPointSourceJson } from './HealthDataPointSource';

export type HealthDataPointJson = {
  id: string;
  pointType: HealthDataPointType;
  startDate: string;
  endDate: string;
  source?: HealthDataPointSourceJson;
};

export class HealthDataPoint {
  @observable
  id: string = UUID();
  @observable
  pointType: HealthDataPointType = HealthDataPointType.quantity;
  @observable
  startDate: Date = new Date();
  @observable
  endDate: Date = new Date();
  @observable
  source?: HealthDataPointSource;

  constructor(json?: Partial<HealthDataPointJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.pointType = json.pointType ?? HealthDataPointType.quantity;
      this.startDate = json.startDate ? new Date(json.startDate) : new Date();
      this.endDate = json.endDate ? new Date(json.endDate) : this.startDate;
      this.source = json.source ? new HealthDataPointSource(json.source) : undefined;
    }
  }

  toJS(): HealthDataPointJson {
    return {
      id: this.id,
      pointType: this.pointType,
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),
      source: this.source?.toJS(),
    };
  }

  save(): Promise<HealthDataPoint> {
    return HttpBackend.post(`/athlete/health-data`, this.toJS())
      .then((result) => {
        this.id = result.id || this.id;
      })
      .then(() => this);
  }

  update() {
    return HttpBackend.put(`/athlete/health-data/${this.id}`, this.toJS());
  }
}
