export function sleep<T>(time: number, value?: T): Promise<T | undefined> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), time);
  });
}

export function sleepRandom<T>(min: number, max: number, value?: T): Promise<T | undefined> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(value), Math.floor(Math.random() * (max - min + 1) + min));
  });
}
