/**
 * Created by neo on 07.03.22.
 */
import { observable, toJS } from 'mobx';
import { LocalizedEntity, LocalizedEntityJson } from '../LocalizedEntity';
import { Media, MediaJson } from '../Media/Media';

export type ExploreCategoryLinkJson = LocalizedEntityJson & {
  link: string;
  params: Record<string, string>;
  image?: MediaJson;
};

export class ExploreCategoryLink extends LocalizedEntity {
  @observable
  link: string = '';
  @observable
  params: Record<string, string> = {};
  @observable
  image?: Media;

  constructor(json?: Partial<ExploreCategoryLinkJson>) {
    super(json);
    if (json) {
      this.link = json.link ?? '';
      this.params = json.params ?? {};
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(): ExploreCategoryLinkJson {
    return Object.assign(super.toJS(), {
      link: this.link,
      params: toJS(this.params),
      image: this.image?.toJS(),
    });
  }
}
