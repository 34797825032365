/**
 * Created by neo on 03.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Instructor } from '../../Model/Explore/Instructor';
import styled from '@emotion/styled';

const Tag = styled.div`
  background-color: #f6f6f7;
  color: black;
  font-size: 12px;
  border-radius: 16px;
  padding: 2px 16px 2px 6px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  @media (min-width: 1280px) {
    font-size: 12px;
  }
`;

const Profile = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
`;

export type InstructorTagProps = {
  instructor: Instructor;
};

export const InstructorTag: React.FC<InstructorTagProps> = observer(({ instructor }) => {
  const image = instructor.image?.smallest;

  return (
    <Tag>
      {image && <Profile src={image} />}
      {`@${instructor.name}`}
    </Tag>
  );
});
