/**
 *
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';

export type NotFoundScreenProps = {};

export const NotFoundScreen: React.FC<NotFoundScreenProps> = observer(() => {
  return (
    <React.Fragment>
      <Container>
        <h1>Page not found</h1>
        {/*<SingleColRow>*/}
        {/*  <Empty description="Page Not Found" />*/}
        {/*</SingleColRow>*/}
      </Container>
    </React.Fragment>
  );
});
