import { observable } from 'mobx';
import { FeedActivityReaction } from './FeedActivityReaction';

export class FeedActivityReactions {
  @observable.shallow
  like: FeedActivityReaction[] = [];
  @observable.shallow
  comment: FeedActivityReaction[] = [];

  constructor(json?: any) {
    if (json) {
      Object.assign(
        this,
        { like: [], comment: [] },
        Object.entries(json).reduce((data: any, [key, value]: any[]) => {
          data[key] = (value || []).map((v: any) => new FeedActivityReaction(v));
          return data;
        }, {}),
      );
    }
  }
}
