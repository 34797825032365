/**
 * Created by joser on 10.05.2016.
 */

import AppAuthToken from './AppAuthToken';
import AppStore from '../AppStore';
import { HttpBackend } from '../Http/HttpBackend';
import { UserToken } from './UserToken';
import { Athlete } from '../../Model/Athlete/Athlete';
import { Retry } from '../../Utils/Retry';
import { logger } from '../../Utils/logger';
import { AuthBackend } from '../Http/AuthBackend';
import AppBackend from '../Http/AppBackend';
import { AppleAuthenticationRequest } from '../../Store/SignupStore/AppleAuthenticationRequest';

export class AuthenticationService {
  async login(email: string, password: string): Promise<Athlete> {
    const res = await new AuthBackend().postForm('/uaa/oauth/token', {
      grant_type: 'password',
      username: email,
      password,
    });

    if (res) {
      const userToken = new UserToken({ created: Date.now(), ...res });
      AppAuthToken.storeAuthData(userToken);
      logger('logged in!');
      return this.getUserData();
    } else {
      throw new Error('not admin');
    }
  }

  async getUserData(): Promise<Athlete> {
    const res = await HttpBackend.get('/athlete/me');
    AppStore.store('athlete', res);
    return new Athlete(res);
  }

  private checkAthleteData(): Promise<Athlete> {
    return Athlete.me()
      .then((athlete) => {
        return athlete;
      })
      .catch(() => {
        throw new Error('no_athlete');
      });
  }

  otpLogin(authenticationCode: string): Promise<Athlete> {
    return Retry.tryTimes(
      () => AppBackend.post(`/uaa/auth/otp/authenticate`, { authenticationCode }).then((data) => new UserToken(data)),
      5,
    )
      .then((token) => AppAuthToken.storeAuthData(token))
      .then(() => this.checkAthleteData());
  }

  appleLogin(appleAuthData: AppleAuthenticationRequest): Promise<Athlete> {
    return Retry.tryTimes(
      () => AppBackend.post(`/uaa/auth/apple/login`, appleAuthData).then((data) => new UserToken(data)),
      5,
    )
      .then((token) => AppAuthToken.storeAuthData(token))
      .then(() => this.checkAthleteData());
  }

  async checkToken(): Promise<Athlete> {
    const token = await AppAuthToken.getAuthData();

    if (token && token.access_token) {
      const response = await new AuthBackend().postForm('/uaa/oauth/check_token', { token: token.access_token });
      if (!response) {
        AppAuthToken.deleteAuthData();
        AppStore.truncate();
        throw new Error('no auth data available');
      }
      return this.getUserData();
    }
    AppAuthToken.deleteAuthData();
    AppStore.truncate();
    throw new Error('no auth data available');
  }

  logout() {
    AppAuthToken.deleteAuthData();
    AppStore.truncate();
  }
}

const instance = new AuthenticationService();
export default instance;
