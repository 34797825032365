/**
 * Created by neo on 20.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useRootStore } from '../../Store/useRootStore';
import { logger } from '../../Utils/logger';

const Container = styled.select`
  position: relative;
  border: 1px solid black;
  border-radius: 8px;
  //padding: 8px 0 8px 24px;
  padding: 8px 16px 8px 16px;
  background: white;
  //background-image: url('https://s17.postimg.cc/kc1y6xwlb/download.png');
  //background-repeat: no-repeat;
  //background-position: 8px;
`;

const Option = styled.option`
  background: white;
`;

export type FooterLanguageSelectProps = {};

export const FooterLanguageSelect: React.FC<FooterLanguageSelectProps> = observer((props) => {
  const { language } = useRootStore();

  const handleChangeLanguage = React.useCallback(({ target: { value } }) => language.changeLanguage(value), [language]);

  return (
    <Container value={language.language} onChange={handleChangeLanguage}>
      <Option value="de">Deutsch</Option>
      <Option value="en">English</Option>
      <Option value="fr">Français</Option>
      <Option value="it">Italiano</Option>
    </Container>
  );
});
