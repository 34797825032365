/**
 * Created by neo on 16.08.23
 */
import { observable } from 'mobx';

export type OnlineMeetingEntryEmbedDataEntryJson = {
  videoEmbedCode?: string;
  interactionEmbedCode?: string;
};

export class OnlineMeetingEntryEmbedDataEntry {
  @observable
  videoEmbedCode?: string;
  @observable
  interactionEmbedCode?: string;

  constructor(json?: Partial<OnlineMeetingEntryEmbedDataEntryJson>) {
    if (json) {
      this.videoEmbedCode = json.videoEmbedCode;
      this.interactionEmbedCode = json.interactionEmbedCode;
    }
  }

  toJS(): OnlineMeetingEntryEmbedDataEntryJson {
    return {
      videoEmbedCode: this.videoEmbedCode,
      interactionEmbedCode: this.interactionEmbedCode,
    };
  }
}
