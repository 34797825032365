import * as Sentry from '@sentry/react';
import { Config } from '../Config/Config';
import { Athlete } from '../Model/Athlete/Athlete';
import { CaptureContext } from '@sentry/types';
import { BrowserTracing } from "@sentry/tracing"
import __DEV__ from '../Utils/Dev';

export enum Severity {
  /** JSDoc */
  Fatal = 'fatal',
  /** JSDoc */
  Error = 'error',
  /** JSDoc */
  Warning = 'warning',
  /** JSDoc */
  Log = 'log',
  /** JSDoc */
  Info = 'info',
  /** JSDoc */
  Debug = 'debug',
}

export type SentryBreadcrumbType =
  | 'default'
  | 'debug'
  | 'error'
  | 'navigation'
  | 'http'
  | 'info'
  | 'query'
  | 'ui'
  | 'user';

export class SentryServiceClass {
  constructor() {
    if (!__DEV__) {
      Sentry.init({
        dsn: Config.sentry.dsn,
        integrations: [
          new BrowserTracing({
            tracePropagationTargets: ['api.kinastic.com', /^\//],
          }),
        ],
        tracesSampleRate: 0.2,
      });
    }
  }

  setUser(athlete: Athlete) {
    if (athlete.id && !__DEV__) {
      Sentry.setUser({ id: athlete.id });
    }
  }

  clearUser() {
    if (!__DEV__) {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }

  captureException(error: Error, context?: CaptureContext) {
    if (!__DEV__) {
      Sentry.captureException(error, context);
    }
  }

  leaveBreadcrumb(message: string, params?: any, type?: SentryBreadcrumbType, level?: Severity) {
    return this.addBreadcrumb(message, params, type, level);
  }

  addBreadcrumb(message: string, params?: any, type?: SentryBreadcrumbType, level?: Severity) {
    if (!__DEV__) {
      Sentry.addBreadcrumb({ message, data: params, type, level, timestamp: Date.now() });
    }
  }
}

const SentryService = new SentryServiceClass();
export default SentryService;
