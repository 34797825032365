import { Config } from '../../Config/Config';
import { FeedToken } from '../../Model/Feed/FeedToken';
import { GetStreamFeed } from '../../Model/Feed/GetStream/GetStreamFeed';
import { AuthenticationStore } from '../AuthenticationStore';
import { reaction } from 'mobx';
import { DisposableStore } from '../DisposableStore';
import { StreamClient, connect } from 'getstream';
import { logger } from '../../Utils/logger';

export class GetStreamStore extends DisposableStore {
  client?: StreamClient;
  feeds: GetStreamFeed[] = [];
  readonly feedPromises: Map<string, Promise<GetStreamFeed>> = new Map();

  constructor(readonly authentication: AuthenticationStore) {
    super();

    this.disposers.push(
      reaction(
        () => this.authentication.athleteId,
        (athleteId) => this.connectOrDisconnect(athleteId),
        { name: 'GetStream connection' },
      ),
    );
  }

  async feed(slug: string, id?: string): Promise<GetStreamFeed> {
    const {
      authentication: { athlete },
    } = this;
    if (!athlete?.id) {
      throw new Error('not authenticated');
    }
    const feedId = id || athlete.id;
    const promiseKey = `${slug}:${feedId}`;
    const existing = this.feeds.find((feed) => feed.slug === slug && feed.feedId === feedId);
    const existingPromise = this.feedPromises.get(promiseKey);
    if (existing) {
      return existing;
    } else if (existingPromise) {
      return existingPromise;
    }
    const promise = this.createFeed(athlete.id, athlete.nickname ?? '', slug, feedId);
    this.feedPromises.set(promiseKey, promise);
    promise
      .then((res) => {
        this.feedPromises.delete(promiseKey);
        return res;
      })
      .catch((err) => {
        this.feedPromises.delete(promiseKey);
        return err;
      });
    return promise;
  }

  private async createFeed(athleteId: string, alias: string, slug: string, feedId: string) {
    const token = await FeedToken.get(slug, feedId);

    const client = connect(Config.getstream.apiKey, token.token, Config.getstream.appId, {
      location: 'dublin',
    });

    const feed = new GetStreamFeed(client, slug, feedId, athleteId, token);
    this.feeds.push(feed);
    return feed;
  }

  private toggleSubscription(active: boolean) {
    if (active) {
      this.feeds.forEach((f: GetStreamFeed) => {
        f.fetchTillNewest().then(() => f.subscribe());
      });
    } else {
      this.feeds.forEach((f) => f.unsubscribe());
    }
  }

  private async connectOrDisconnect(athleteId?: string) {
    if (athleteId) {
      // const token = await FeedToken.get();
      // if (!token.token) {
      //   throw new Error('unable to getstream fetch token');
      // }
      // this.client = stream.connect(Config.getstream.apiKey, token.token, Config.getstream.appId, {
      //   location: 'dublin',
      // });
    } else {
      logger('disconnecting from getstream.io');
      this.feeds.forEach((feed: GetStreamFeed) => feed.unsubscribe());
    }
  }
}
