/**
 * Created by neo on 29.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../Store/useRootStore';
import styled from '@emotion/styled';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Button } from '../../Components/Button';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Input } from '../../Components/Input';
import { Athlete } from '../../Model/Athlete/Athlete';
import { runInAction } from 'mobx';
import { useState } from 'react';

const Container = styled.div`
  background: #f6f6f7;
  border-radius: 8px;
  padding: 16px 0;
`;

const LeftColContent = styled.div`
  border-left: 4px solid #3886fa;
  display: flex;
`;

const ProfileIcon = styled.i`
  margin: 0 16px 0 8px;
`;

const DesktopButtonCol = styled(Col)`
  button {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    button {
      display: block;
      margin-left: auto;
    }
  }
`;

const MobileButtonCol = styled(Col)`
  button {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    button {
      display: none;
    }
  }
`;

const FormRow = styled(Row)`
  padding: 16px 16px 0 16px;
`;

export type AccountDetailsSectionProps = {};

export const AccountDetailsSection: React.FC<AccountDetailsSectionProps> = observer((props) => {
  const { authentication, language } = useRootStore();

  const { t } = useTrans();

  const [athlete] = useState(new Athlete(authentication.athlete?.toJS()));
  const [selectedLanguage, setSelectedLanguage] = useState(language.language);
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState(() => authentication.athlete?.user.email ?? '');

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      setProcessing(true);
      const requests: Promise<any>[] = [athlete.save(), language.changeLanguage(selectedLanguage)];

      const shouldHandleEmailChange = email !== authentication.athlete?.user.email;

      if (shouldHandleEmailChange) {
        requests.push(athlete.user.changeEmail(email));
      }

      Promise.all(requests)
        .finally(() => setProcessing(false))
        .then(() => {
          runInAction(() => (authentication.athlete = athlete));

          if (shouldHandleEmailChange) {
            window.alert(
              t('accountScreen.details.emailChangeSuccessMessage', {
                defaultValue:
                  'You have successfully requested to change your email address. Open your email and click on the link you have received.',
              }),
            );
          }
        })
        .catch((e) => {
          console.error('Error updating profile:', e);
          window.alert(
            t('accountScreen.details.errorMessage', { defaultValue: 'An error occurred, please try again later.' }),
          );
        });
    },
    [athlete, language, selectedLanguage, authentication, email],
  );

  const handleChangeName = React.useCallback(
    ({ target: { value } }) => runInAction(() => (athlete.firstname = value)),
    [athlete],
  );

  const handleChangeEmail = React.useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);

  const handleChangeLanguage = React.useCallback(({ target: { value } }) => setSelectedLanguage(value), []);

  const handleChangeBirthYear = React.useCallback(
    ({ target: { value } }) => runInAction(() => (athlete.birthYear = Number(value))),
    [athlete],
  );

  const handleChangeGender = React.useCallback(
    ({ target: { value } }) => runInAction(() => (athlete.gender = value)),
    [athlete],
  );

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <LeftColContent>
              <ProfileIcon className="bi bi-person"></ProfileIcon>
              {t('accountScreen.details.accountTitle', { defaultValue: 'Profile' })}
            </LeftColContent>
          </Col>
          <DesktopButtonCol>
            <Button type="submit" size="sm">
              {t('accountScreen.details.saveChangesButton', { defaultValue: 'Save Changes' })}
            </Button>
          </DesktopButtonCol>
        </Row>
        <FormRow>
          <Col xs={12} md={6}>
            <FormGroup>
              <Label for="name">{t('accountScreen.details.nameLabel', { defaultValue: 'Name Displayed' })}</Label>
              <Input
                id="name"
                name="name"
                value={athlete.firstname}
                onChange={handleChangeName}
                disabled={processing}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">{t('accountScreen.details.emailLabel', { defaultValue: 'Email' })}</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={handleChangeEmail}
                disabled={processing}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Label for="language">{t('accountScreen.details.languageLabel', { defaultValue: 'Language' })}</Label>
              <Input
                id="language"
                name="language"
                type="select"
                value={selectedLanguage}
                onChange={handleChangeLanguage}
                disabled={processing}
              >
                <option value="de">Deutsch</option>
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="it">Italiano</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="birthYear">{t('accountScreen.details.birthYearLabel', { defaultValue: 'Birth year' })}</Label>
              <Input
                id="birthYear"
                name="birthYear"
                type="number"
                value={athlete.birthYear}
                onChange={handleChangeBirthYear}
                disabled={processing}
              />
            </FormGroup>
            <FormGroup>
              <Label for="gender">{t('accountScreen.details.genderLabel', { defaultValue: 'Gender' })}</Label>
              <Input
                id="gender"
                name="gender"
                type="select"
                value={athlete.gender}
                onChange={handleChangeGender}
                disabled={processing}
              >
                <option value="FEMALE">{t('accountScreen.details.gender.female', { defaultValue: 'Female' })}</option>
                <option value="MALE">{t('accountScreen.details.gender.male', { defaultValue: 'Male' })}</option>
                <option value="UNKNOWN">{t('accountScreen.details.gender.other', { defaultValue: 'Other' })}</option>
              </Input>
            </FormGroup>
          </Col>
          <MobileButtonCol>
            <Button type="submit" size="sm">
              {t('accountScreen.details.saveChangesButton', { defaultValue: 'Save Changes' })}
            </Button>
          </MobileButtonCol>
        </FormRow>
      </Form>
    </Container>
  );
});
