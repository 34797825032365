import { computed, observable } from 'mobx';
import { Media, MediaJson } from '../Media/Media';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { BaseTrackingKey, TrackingKeysList } from '../ProgramPortfolio/TrackingKeys';
import { LocalizedEntity, LocalizedEntityJson } from '../LocalizedEntity';
import { Pageable } from '../Interfaces/Pageable';
import CacheService from '../../Services/CacheService';
import { HOURS_24 } from '../../Utils/Constants';

export type ActivityRequest = Pageable & {
  query?: string;
  tags?: string[];
  trackingKeys?: string[];
};

export type ActivityJson = {
  identifier: string;
  tags: string[];
  medias: MediaJson[];
  trackingKeys: BaseTrackingKey[];
} & LocalizedEntityJson;

export class Activity extends LocalizedEntity {
  @observable identifier: string = '';
  @observable tags: string[] = [];
  @observable medias: Media[] = [];
  @observable trackingKeys: BaseTrackingKey[] = [];

  constructor(json?: Partial<ActivityJson>) {
    super(json);
    if (json) {
      this.id = json.id || '';
      this.identifier = json.identifier || '';
      this.tags = json.tags || [];
      this.trackingKeys = json.trackingKeys ?? [];
      this.medias = (json.medias ?? []).map((m) => new Media(m));
    }
  }

  @computed
  get firstSmallestMediaUrl(): string | undefined {
    const { medias } = this;
    if (medias && medias.length > 0) {
      return medias[0].smallest;
    }
    return undefined;
  }

  @computed
  get image(): { uri: string } | undefined {
    return this.firstSmallestMediaUrl ? { uri: this.firstSmallestMediaUrl } : undefined;
  }

  @computed
  get media(): Media | undefined {
    if (this.medias && this.medias.length > 0) {
      return this.medias[0];
    }
    return undefined;
  }

  @computed
  get trackingKeysSorted(): BaseTrackingKey[] {
    return this.trackingKeys.slice().sort((a, b) => {
      return TrackingKeysList.indexOf(a) - TrackingKeysList.indexOf(b);
    });
  }

  @computed
  get durationInMinutes(): number {
    return 0;
  }

  static find(params?: Partial<ActivityRequest>): Promise<Activity[]> {
    return HttpBackend.get('/activity/activities', params).then((result) => result.map((e) => new Activity(e)));
  }

  static get(id: string): Promise<Activity | undefined> {
    // return CacheService.cache(
    //   `activity:${id}`,
    //   () => HttpBackend.get(`/activity/activities/${id}`),
    //   (res) => new Activity(res),
    //   HOURS_24,
    // ).then((result) => (result ? new Activity(result) : undefined));

    return HttpBackend.get(`/activity/activities/${id}`).then((result) => (result ? new Activity(result) : undefined));
  }
}
