/**
 * Created by katarinababic on 11.4.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from 'reactstrap';
import styled from '@emotion/styled';

const StyledModal = styled(Modal)`
  max-width: 80%;
  margin: auto;

  .modal-content {
    border-radius: 2rem;
  }

  @media (min-width: 768px) {
    max-width: 40%;
  }

  @media (min-width: 990px) {
    max-width: 30%;
  }

  @media (min-width: 1286px) {
    max-width: 25%;
  }

  @media (min-width: 1600px) {
    max-width: 20%;
  }

  @media (min-width: 2000px) {
    max-width: 15%;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  background-color: #3c3c3c;
  border-width: 0;
  color: white;
  padding: 0.5rem;
`;

const StyledModalBody = styled(ModalBody)`
  background-color: #3c3c3c;
  border-width: 0;
  padding: 0 0.5rem 0 0.5rem;
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: #3c3c3c;
  border-width: 0;
  justify-content: space-between;
`;

export type AlertModalProps = ModalProps & {
  AlertHeader?: JSX.Element;
  AlertBody?: JSX.Element;
  AlertFooter?: JSX.Element;
};

export const AlertModal: React.FC<AlertModalProps> = observer(
  ({ AlertHeader, AlertBody, AlertFooter, ...moreProps }) => {
    return (
      <StyledModal {...moreProps}>
        <StyledModalHeader>{AlertHeader}</StyledModalHeader>
        <StyledModalBody>{AlertBody}</StyledModalBody>
        <StyledModalFooter>{AlertFooter}</StyledModalFooter>
      </StyledModal>
    );
  },
);
