/**
 * Created by katarinababic on 27.6.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { WebinarEntry } from '../../../Model/Explore/WebinarEntry';
import { SubscribedEventEntry } from '../../../Model/Coach/Schedule/SubscribedEventEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { DurationBefore, NotificationInterval } from '../../../Model/Coach/Schedule/NotificationInterval';
import { runInAction } from 'mobx';
import { Col, Row } from 'reactstrap';
import { Button } from '../../Button';
import { Input } from '../../Input';

export type ExploreContentViewWebinarSubscribeButtonsProps = {
  entry: WebinarEntry;
  onSubscribed?: (event: SubscribedEventEntry) => void;
  canJoinMeeting?: boolean;
};

export const ExploreContentViewWebinarSubscribeButtons: React.FC<ExploreContentViewWebinarSubscribeButtonsProps> =
  observer(({ entry, onSubscribed, canJoinMeeting }) => {
    const { notification, analytics } = useRootStore();
    const { t } = useTrans();

    const [processing, setProcessing] = useState(false);
    const [event, setEvent] = useState(new SubscribedEventEntry());
    const earliestReminder = event.earliestInterval;

    useEffect(() => {
      setEvent(
        new SubscribedEventEntry(
          Object.assign({}, entry.event.toJS(), {
            notificationIntervals: [
              {
                durationBefore: DurationBefore.MIN_15_BEFORE,
              },
            ],
          }),
        ),
      );
    }, [entry]);

    const handleChangeReminder = React.useCallback(
      ({ target: { value } }) => {
        runInAction(() => {
          if (value) {
            event.notificationIntervals = [new NotificationInterval({ durationBefore: value })];
          } else {
            event.notificationIntervals = [];
          }
        });
      },
      [event],
    );

    const handleRegister = React.useCallback(() => {
      setProcessing(true);
      event
        .save()
        .then(() => {
          onSubscribed && onSubscribed(event);
          return notification.requestPermission();
        })
        .finally(() => setProcessing(false));
      analytics.logEvent('webinar_registered', { objectId: entry.id });
    }, [analytics, entry, event, notification, onSubscribed]);

    return (
      <Row>
        <Col xs={12} style={{ marginBottom: 16 }}>
          <Input type="select" value={earliestReminder?.durationBefore} onChange={handleChangeReminder}>
            <option value={DurationBefore.ONE_HOUR_BEFORE}>
              {t('exploreEntryViewScreen.reminder.pt1h', {
                defaultValue: '1 hour before',
              })}
            </option>
            <option value={DurationBefore.HALF_HOUR_BEFORE}>
              {t('exploreEntryViewScreen.reminder.pt30m', {
                defaultValue: '30 minutes before',
              })}
            </option>
            <option value={DurationBefore.MIN_15_BEFORE}>
              {t('exploreEntryViewScreen.reminder.pt15m', {
                defaultValue: '15 minutes before',
              })}
            </option>
            <option value={DurationBefore.AT_EVENT}>
              {t('exploreEntryViewScreen.reminder.pt0s', {
                defaultValue: 'When it starts',
              })}
            </option>
            <option value="">
              {t('exploreEntryViewScreen.reminder.noReminder', {
                defaultValue: 'Do not remind me',
              })}
            </option>
          </Input>
        </Col>
        <Col xs={12}>
          <Button block={true} onClick={handleRegister} disabled={processing}>
            {t('exploreEntryViewScreen.reminder.register', {
              defaultValue: 'Register',
            })}
          </Button>
        </Col>
      </Row>
    );
  });
