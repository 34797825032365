import { computed } from 'mobx';
import { CoachWorkoutPhase } from './CoachWorkoutPhase';
import { CoachIntervalRound } from './CoachIntervalRound';
import { CoachExerciseBlock } from './CoachExerciseBlock';

export class CoachIntervalPhase extends CoachWorkoutPhase {
  @computed
  get rounds(): CoachIntervalRound[] {
    const rounds: CoachIntervalRound[] = [];
    if (this.exerciseBlocks.length > 0) {
      let currentRound: CoachIntervalRound | undefined;
      let roundIndex = -1;
      this.exerciseBlocks.forEach((block: CoachExerciseBlock) => {
        if (block.roundInfo || !currentRound) {
          currentRound = new CoachIntervalRound(++roundIndex, [], block.roundInfo, currentRound);
          rounds.push(currentRound);
        }
        currentRound.blocks.push(block);
      });
    }
    return rounds;
  }

  @computed
  get roundsCount(): number {
    return this.rounds.length;
  }

  @computed
  get lastRound(): CoachIntervalRound | undefined {
    const { rounds, roundsCount } = this;
    return roundsCount > 0 ? rounds[roundsCount - 1] : undefined;
  }
}
