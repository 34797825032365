/**
 * Created by neo on 01.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { Translation } from '../../../Components/Translation';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ScheduleItem } from '../../../Components/Schedule/ScheduleItem';
import { TimeNavigationBar } from '../../../Components/TimeNavigationBar';
import { CurrentWeekEmptyScheduleCard } from '../../../Components/Schedule/CurrentWeekEmptyScheduleCard';
import { FutureEmptyScheduleCard } from '../../../Components/Schedule/FutureEmptyScheduleCard';
import { SubscribableEntry } from '../../../Model/Explore/SubscribableEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';

const Header = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
`;

const Description = styled.p`
  margin-top: 16px;
`;

export type AcademyScheduleScreenProps = {};

export const AcademyScheduleScreen: React.FC<AcademyScheduleScreenProps> = observer((props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [fetching, setFetching] = useState(false);
  const [upcoming, setUpcoming] = useState<SubscribableEntry[]>([]);

  const defaultValue = React.useMemo(() => dayjs().startOf('month'), []);
  const monthValue = searchParams.get('time') || defaultValue.format('YYYY-MM');
  const monthParsed = React.useMemo(() => dayjs(monthValue, 'YYYY-MM').startOf('month'), [monthValue]);
  const month = monthParsed.isValid() && !monthParsed.isBefore(defaultValue) ? monthParsed : defaultValue;
  const monthBefore = month.subtract(1, 'month').startOf('month');
  const maxMonth = defaultValue.add(3, 'month').startOf('month');
  const hasPrevious = !monthBefore.isBefore(dayjs().startOf('month'));
  const isCurrentMonth = month.startOf('month').diff(dayjs().startOf('month')) === 0;
  const futureDisabled = month.startOf('month').diff(maxMonth.startOf('month')) === 0;

  useEffect(() => {
    setFetching(true);
    setUpcoming([]);
    Promise.all([
      ExploreEntryBuilder.upcoming({
        size: 20,
        categories: ['academy_live'],
        startDateTime: month.startOf('month').toDate(),
        endDateTime: month.endOf('month').toDate(),
      }),
      ExploreEntryBuilder.upcoming({
        size: 1,
        categories: ['academy_live'],
        startDateTime: month.add(1, 'month').startOf('month').toDate(),
        endDateTime: month.add(1, 'month').endOf('month').toDate(),
      }),
    ])
      .then(([res, nextMonth]) => {
        setUpcoming(res);
      })
      .finally(() => setFetching(false));
  }, [month]);

  const handlePrevious = React.useCallback(() => {
    if (hasPrevious) {
      searchParams.set('time', monthBefore.format('YYYY-MM'));
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [hasPrevious, location, monthBefore, navigate, searchParams]);

  const handleNext = React.useCallback(() => {
    searchParams.set('time', month.add(1, 'month').format('YYYY-MM'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [location, month, navigate, searchParams]);

  const handleClickEntry = React.useCallback(
    (entry) => {
      navigate(`/academy/${entry.id}`);
    },
    [navigate],
  );

  const handleFirst = React.useCallback(() => {
    searchParams.set('time', dayjs().format('YYYY-MM'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [searchParams, navigate, location]);

  const handleLast = React.useCallback(() => {
    searchParams.set('time', maxMonth.format('YYYY-MM'));
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [searchParams, maxMonth, navigate, location]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Header>
            <Translation
              i18nKey="academySchedule.title"
              defaultValue={`Academy\n<span style="color:#24dca4">Schedule</span>`}
            />
          </Header>
        </Col>
        <Col md={7}>
          <Description>
            <Translation
              i18nKey="academySchedule.description"
              defaultValue={`Learn more about health! In our monthly webinars, KINASTIC experts share their knowledge with you. You can ask them your questions and get practical health tips for your everyday life.`}
            />
          </Description>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TimeNavigationBar
            displayTime={month.format('MMM YYYY')}
            hasNext={!futureDisabled}
            hasPrevious={hasPrevious}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onFirst={handleFirst}
            onLast={handleLast}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!fetching && upcoming.length === 0 && isCurrentMonth && (
            <CurrentWeekEmptyScheduleCard linkPrefix={'/academy/'} />
          )}
          {!fetching && upcoming.length === 0 && !isCurrentMonth && <FutureEmptyScheduleCard />}
          {upcoming.map((entry) => (
            <ScheduleItem
              key={entry.id}
              entry={entry}
              onClick={handleClickEntry}
              currentStartDate={month}
              linkPrefix="/academy/"
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
});
