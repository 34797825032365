/**
 * Created by katarinababic on 31.5.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { useTrans } from '../../Store/System/LocalizationStore';
import { useRootStore } from '../../Store/useRootStore';

const Container = styled.div`
  background-color: #f6f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  padding: 16px;
  border-radius: 16px;
  height: 280px;
  min-width: 296px;
`;

const Image = styled.img`
  width: 280px;
  height: 240px;
`;

const Title = styled.div`
  position: relative;
  font-weight: 700;
  text-align: center;
  bottom: 8rem;
  left: 0;
  right: 0;
  margin-bottom: 0.3rem;
`;

const Description = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 0.6rem;
  text-align: center;
  bottom: 8rem;
  width: 200px;
  margin-bottom: 0.3rem;
`;

const Subtitle = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  bottom: 8rem;
  width: 160px;
`;

export type LanguageDisclaimerProps = {
  availableLanguages?: string[];
};

export const LanguageDisclaimer: React.FC<LanguageDisclaimerProps> = observer(({ availableLanguages }) => {
  const { t } = useTrans();
  const { language } = useRootStore();

  if (!availableLanguages || availableLanguages.length < 1 || availableLanguages?.includes(language.language)) {
    return null;
  }
  return (
    <Container>
      <Image src={require('../../Assets/Images/language_disclaimer.png')} alt="lng-disclaimer" />
      <Title>{t('languageDisclaimer.title', { defaultValue: 'Oh snap!' })}</Title>
      <Description>
        {t('languageDisclaimer.description', {
          defaultValue:
            'We are sorry to inform you that at the moment this video is not available in your preferred language. We are working on it!',
        })}
      </Description>
      <Subtitle>
        {t(`languageDisclaimer.availableLanguages`, {
          value: availableLanguages?.map((l) => l.toUpperCase()).join(','),
          defaultValue: 'Available audio: {{value}}',
        })}
      </Subtitle>
    </Container>
  );
});
