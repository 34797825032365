/**
 * Created by andreaskarantzas on 06.01.19.
 */

import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '../../Theme/Theme';

const TextComponent = styled.p``;

export type LatoTextVariant = keyof typeof Theme.Text;

export type LatoTextProps = HTMLAttributes<HTMLSpanElement> & {
  text?: string | null;
  variant?: LatoTextVariant;
  isSensitive?: boolean;
};

export const LatoText: React.FC<PropsWithChildren<LatoTextProps>> = observer(
  ({ text, style, isSensitive = false, variant = 'body1', children, ...moreProps }) => {
    const textStyle = Object.assign({}, Theme.Text[variant], style);

    return (
      <TextComponent style={textStyle} {...moreProps}>
        {text ?? children}
      </TextComponent>
    );
  },
);
