import * as qs from 'qs';

const internalUrls = ['https://coach.kinastic.com'];

export const kinasticSpotifyProfileUrl = 'https://open.spotify.com/user/e4ffs3rrweb7u1j9tjplmo7z3?si=06ad7e7a2fce4bbc';
export const twitterShareUrl = 'https://twitter.com/intent/tweet?url=';
export const twitterTextParam = '&text=';
export const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
export const facebookTextParam = '&quote=';

export class LinkUtils {
  static parseLink(link: string): string {
    if (this.isDeprecatedLink(link)) {
      const params = qs.parse(link.substring(link.indexOf('?') + 1));
      if (params.id) {
        if (link.includes('recipe')) {
          return `https://coach.kinastic.com/explore/recipe/${params.id}`;
        } else {
          return `https://coach.kinastic.com/explore/${params.id}`;
        }
      }
    }
    return link;
  }

  static isInternalUrl(link?: string): boolean {
    const trimmedLink = link?.trim().toLowerCase();
    if (trimmedLink) {
      return internalUrls.some((url) => trimmedLink.startsWith(url));
    }

    return false;
  }

  static isExternalWebUrl(link?: string): boolean {
    const trimmedLink = link?.trim().toLowerCase();
    if (trimmedLink) {
      return trimmedLink.startsWith('https://') || trimmedLink.startsWith('http://');
    }

    return false;
  }

  /**
   * the "download" link is used to download the app in the first place
   * or in conjunction with onboarding_tokens
   * @param link
   */
  static isDownloadLink(link: string): boolean {
    return link.includes('https://www.kinastic.com/download');
  }

  /**
   * a check for older versions (<1.15) where users scheduled with
   * https://www.kinastic.com/domain?params=
   * @param link
   */
  private static isDeprecatedLink(link: string): boolean {
    return link.includes('https://www.kinastic.com/');
  }
}
