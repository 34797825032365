/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { AppleDownloadButton } from '../Auth/Buttons/AppleDownloadButton';
import { GoogleDownloadButton } from '../Auth/Buttons/GoogleDownloadButton';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export type AppDownloadButtonsProps = {};

export const AppDownloadButtons: React.FC<AppDownloadButtonsProps> = observer((props) => {
  return (
    <Container>
      <AppleDownloadButton />
      <GoogleDownloadButton />
    </Container>
  );
});
