import { action, computed, observable } from 'mobx';

export type PageResultJson<T> = {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
};

export class PageResult<T> {
  @observable
  content: Array<T> = [];
  @observable
  first: boolean = true;
  @observable
  last: boolean = false;
  @observable
  number: number = 0;
  @observable
  numberOfElements: number = 0;
  @observable
  size: number = 0;
  @observable
  totalElements: number = 0;
  @observable
  totalPages: number = 0;

  constructor(json?: Partial<PageResultJson<T>>) {
    if (json) {
      this.first = json.first ?? true;
      this.last = json.last ?? true;
      this.number = json.number ?? 0;
      this.numberOfElements = json.numberOfElements ?? 0;
      this.size = json.size ?? 0;
      this.totalElements = json.totalElements ?? 0;
      this.totalPages = json.totalPages ?? 0;
      this.content = json.content ?? [];
    }
  }

  toJS(): PageResultJson<T> {
    return {
      first: this.first,
      last: this.last,
      number: this.number,
      numberOfElements: this.numberOfElements,
      size: this.size,
      totalElements: this.totalElements,
      totalPages: this.totalPages,
      content: this.content,
    };
  }

  map(callback: (elem: T, index: number) => any) {
    return this.content.map(callback);
  }

  @action
  merge(result: PageResult<T>): PageResult<T> {
    if (this.number !== result.number || this.empty) {
      this.first = result.first;
      this.last = result.last;
      this.number = result.number;
      return new PageResult<T>({
        number: result.number,
        content: this.content.concat(result.content),
        first: result.first,
        last: result.last,
      });
    }
    return this;
  }

  @computed
  get empty(): boolean {
    return this.content.length === 0;
  }

  static execute<T>(
    response: Promise<T[]>,
    counter: Promise<number>,
    page: number = 0,
    size: number = 10,
  ): Promise<PageResult<T>> {
    return Promise.all([response, counter]).then(([result, count]) => {
      const last = result.length < size;
      const totalPages = Math.ceil(count / size);
      return new PageResult({
        content: result,
        first: page === 0,
        last,
        totalPages,
        number: page,
        size,
        totalElements: count,
      });
    });
  }
}
