/**
 * Created by neo on 11.01.21.
 */
import { ExploreEntry, ExploreEntryJson } from './ExploreEntry';
import { IReactionDisposer, observable, onBecomeObserved, onBecomeUnobserved, reaction, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { PipelineContext, PipelineContextJson } from '../Coach/PipelineContext';
import { WorkoutTemplate } from '../Coach/SuperMacro/WorkoutTemplate/WorkoutTemplate';
import { CoachWorkout } from '../Coach/Program/Workout/CoachWorkout';

export type WorkoutTemplateEntryJson = ExploreEntryJson & {
  workoutTemplateId: string;
  context: PipelineContextJson;
  durationInMinutes: number;
};

export class WorkoutTemplateEntry extends ExploreEntry {
  @observable
  workoutTemplateId: string = '';
  @observable
  context: PipelineContext = new PipelineContext();
  @observable
  durationInMinutes = 0;
  @observable
  workoutTemplate?: WorkoutTemplate;
  fetchDisposer?: IReactionDisposer;

  constructor(json?: Partial<WorkoutTemplateEntryJson>) {
    super(Object.assign({ type: 'workoutTemplate' }, json ?? {}));
    if (json) {
      this.workoutTemplateId = json.workoutTemplateId ?? '';
      this.context = new PipelineContext(json.context);
      this.durationInMinutes = json.durationInMinutes ?? 0;
    }

    onBecomeObserved(this, 'workoutTemplate', this.startFetchParent);
    onBecomeUnobserved(this, 'workoutTemplate', this.stopFetchParent);
  }

  startFetchParent = () => {
    this.fetchDisposer = reaction(
      () => this.workoutTemplateId,
      (workoutTemplateId) => {
        if (workoutTemplateId) {
          WorkoutTemplate.get(workoutTemplateId).then((result) => runInAction(() => (this.workoutTemplate = result)));
        } else {
          runInAction(() => (this.workoutTemplate = undefined));
        }
      },
      { fireImmediately: true },
    );
  };

  stopFetchParent = () => {
    this.fetchDisposer && this.fetchDisposer();
    this.fetchDisposer = undefined;
  };

  generate(context: PipelineContext = new PipelineContext()): Promise<CoachWorkout> {
    return CoachWorkout.generate(this.workoutTemplateId, this.context.merge(context));
  }

  static async find(request: Omit<ExploreEntryQueryRequest, 'type'>): Promise<WorkoutTemplateEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request), { type: 'workoutTemplate' })).then((res) =>
      (res ?? []).map((r) => new WorkoutTemplateEntry(r)),
    );
  }

  static async findOne(id: string): Promise<WorkoutTemplateEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new WorkoutTemplateEntry(res));
  }
}
