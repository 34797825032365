/**
 * Created by andreaskarantzas on 15.05.18.
 */
import { autorun, reaction } from 'mobx';
import { AuthenticationStore } from '../AuthenticationStore';
import { AuthenticationMethodsType, OnboardingSectionType, ShareContentType } from './AnalyticsStoreTypes';
import { Athlete } from '../../Model/Athlete/Athlete';
import SentryService from '../../Services/SentryService';
import { LocalizationStore } from '../System/LocalizationStore';
import { logger } from '../../Utils/logger';
import __DEV__ from '../../Utils/Dev';
import { FirebaseAnalytics, FirebaseApp } from './firebase';
import {
  logEvent,
  setConsent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
  setDefaultEventParameters,
} from 'firebase/analytics';

export class AnalyticsStore {
  readonly analytics = FirebaseAnalytics;

  constructor(readonly authentication: AuthenticationStore, readonly language: LocalizationStore) {
    reaction(
      () => this.authentication.athlete,
      (athlete) => {
        if (athlete) {
          setAnalyticsCollectionEnabled(this.analytics, this.shouldLog(athlete));
          setUserId(this.analytics, athlete.id);
          setDefaultEventParameters({
            athleteId: athlete.id,
          });
        }
      },
      { name: 'Firebase set UserId' },
    );
    // autorun(() => this.logScreen(this.navigator.currentScene), { name: 'logScreen' });

    reaction(
      () => this.authentication.athlete,
      (athlete) => athlete && this.setAthleteUserProperties(athlete),
      { fireImmediately: true, name: 'Log athlete information' },
    );

    // reaction(
    //   () => this.permission.notification,
    //   (valid) => {
    //     if (valid) {
    //       analytics()
    //         .setUserProperty('notification_permission', valid)
    //         .catch(() => {});
    //     }
    //   },
    //   { fireImmediately: true, name: 'Log notification permissions' },
    // );
  }

  setUserProperty(property: string, value?: string) {
    if (value) {
      setUserProperties(this.analytics, { [property]: value });
    }
  }

  setAthleteUserProperties(athlete: Athlete) {
    // app user related
    this.setUserProperty('language', this.language.language);
    this.setUserProperty('athlete_age', athlete.ageGroup);
    this.setUserProperty('athlete_gender', athlete.gender);
    /**
     * fitness related
     * ('goal', 'activity', 'fitness_frequency', 'fitness_duration' & 'fitness_equipment'
     * are reported during the onboarding)
     */
    this.setUserProperty('fitness_level', athlete.getTagValue('level'));
    /**
     * mentalStrength related - due to a limitation in the number of chars
     * the name of the user property can have (24chars), we will for the next
     * 2-3 versions check also if the previous identifier was set
     */
    const serenityScore =
      athlete.getTagValue('mentalStrength_stressManagement_score') || athlete.getTagValue('mental_serenity_score');
    const focusScore = athlete.getTagValue('mentalStrength_focus_score') || athlete.getTagValue('mental_focus_score');
    const sleepScore = athlete.getTagValue('mentalStrength_sleep_score') || athlete.getTagValue('mental_sleep_score');
    const mentalTotalScore =
      athlete.getTagValue('mentalStrength_total_score') || athlete.getTagValue('mental_total_score');

    this.setUserProperty('mental_serenity_score', serenityScore);
    this.setUserProperty('mental_focus_score', focusScore);
    this.setUserProperty('mental_sleep_score', sleepScore);
    this.setUserProperty('mental_total_score', mentalTotalScore);
    // nutrition related
    this.setUserProperty('sleep_duration', athlete.getTagValue('sleep_duration'));
    this.setUserProperty('diet', athlete.getTagValue('diet') ?? 'all');
    this.setUserProperty('energy_level', athlete.getTagValue('energy_level'));
    // athlete.getTagValues('diet').map((diet) => this.setUserProperty(`diet_${diet}`, 'true'));
  }

  logScreen(screenName: string) {
    logger('AnalyticsStore::logScreen', { screenName });
    if (screenName && !__DEV__) {
      logEvent(this.analytics, 'screen_view', {
        firebase_screen: screenName,
        firebase_screen_class: screenName,
      });
    }
  }

  logLogin(method: AuthenticationMethodsType) {
    logger('AnalyticsStore::logLogin', { method });
    if (!__DEV__) {
      logEvent(this.analytics, 'login', { method });
    }
  }

  logSignup(method: AuthenticationMethodsType) {
    logger('AnalyticsStore::logSignup', { method });
    if (!__DEV__) {
      logEvent(this.analytics, 'sign_up', { method });
    }
  }

  logSectionCompleted(section: OnboardingSectionType) {
    this.logEvent('onboardingSection_completed', { section_type: section, timestamp: Date.now() });
  }

  logSearch(searchTerm: string) {
    logger('AnalyticsStore::logSearch', { searchTerm });
    if (!__DEV__ && searchTerm.length > 0) {
      logEvent(this.analytics, 'search', { search_term: searchTerm });
    }
  }

  logShare(contentType: string, id: string, method?: string) {
    logger('AnalyticsStore::logShare', { contentType, id, method });
    if (!__DEV__) {
      logEvent(this.analytics, 'share', { content_type: contentType, item_id: id, method: method || 'unknown' });
    }
  }

  logLike(contentType: ShareContentType, id: string, foreign_id?: string) {
    logger('AnalyticsStore::logLike', { contentType, id, foreign_id });
    if (!__DEV__) {
      logEvent(this.analytics, 'like_content', { content_type: contentType, item_id: id, foreign_id });
    }
  }

  logEvent(eventName: string, params?: Record<string, string | number | boolean | undefined>) {
    logger('AnalyticsStore::logEvent', { eventName, params });
    try {
      if (!__DEV__) {
        const data = Object.assign({}, params ?? {}, { athleteId: this.authentication.athleteId });
        SentryService.addBreadcrumb(`Event_${eventName}`, params);
        logEvent(this.analytics, eventName.length > 40 ? eventName.substr(0, 40) : eventName, data);
      }
    } catch (err) {}
  }

  logEvents(events: string[], params?: any): Promise<void[]> {
    return Promise.all(events.map((event) => this.logEvent(event, params)));
  }

  logSelectContent(content_type: string, item_id: string) {
    const allData = { content_type: content_type?.trim(), item_id: item_id?.trim() };
    logger('AnalyticsStore::logSelectContent', allData);
    if (!__DEV__ && content_type?.trim() && item_id?.trim()) {
      logEvent(this.analytics, 'select_content', allData);
    }
  }

  async logError(process: string, error_code: string): Promise<void> {
    const allData = { process: process?.trim(), error_code: error_code?.trim() };
    logger('AnalyticsStore::logError', allData);
    if (!__DEV__ && process?.trim() && error_code?.trim()) {
      try {
        await logEvent(this.analytics, 'request_error', { process: process, error_code: error_code });
      } catch (e) {
        console.log('logEvent error', e);
      }
    }
    return Promise.resolve();
  }

  /**
   * Analytic events and properties should not be logged when in DEV
   * or when it is one of the kinastic team or test env, in order not to skew the results
   */
  private shouldLog(athlete: Athlete) {
    return true;
    // return !__DEV__ && !athlete.user.email.endsWith('@kinastic.com');
  }
}
