/**
 * Created by katarinababic on 26.4.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LinkComponent } from '../../LinkComponent';
import { useTrans } from '../../../Store/System/LocalizationStore';

const SignupTextContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
`;

const signupSpanStyle: React.CSSProperties = {
  color: '#ffffff',
};

const signupLinkStyle: React.CSSProperties = {
  color: '#E5842B',
};

export type RegisterBottomButtonProps = {};

export const RegisterBottomButton: React.FC<RegisterBottomButtonProps> = observer((props) => {
  const { t } = useTrans();

  return (
    <SignupTextContainer>
      <span style={signupSpanStyle}>
        {t('registerBottomButton.noAccountYet', { defaultValue: 'No account yet?' })}&nbsp;
      </span>
      <LinkComponent to="/registration" style={signupLinkStyle}>
        {t('registerBottomButton.register', { defaultValue: 'Register' })}
      </LinkComponent>
    </SignupTextContainer>
  );
});
