/**
 * Created by neo on 03.01.17.
 */

import { action, computed, observable, toJS, onBecomeObserved, ObservableMap, runInAction } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Media, MediaJson } from '../Media/Media';
import { User, UserJson } from '../User';
import { HealthDataPointQuantity } from '../HealthData/HealthDataPointQuantity';
import { HealthDataPointQuantityType } from '../HealthData/HealthDataPointQuantityType';
import { HealthDataPointObjectType } from '../HealthData/HealthDataPointObjectType';
import { HealthDataPointQuery } from '../HealthData/HealthDataPointQuery';
import { HealthDataPointCategory } from '../HealthData/HealthDataPointCategory';
import { HealthDataPointCorrelation } from '../HealthData/HealthDataPointCorrelation';
import { HealthDataPointObject } from '../HealthData/HealthDataPointObject';
import { EMPTY_ARRAY } from '../../Utils/Constants';
import { Audited, AuditedJson } from '../Audited';
import { GenderType } from './GenderType';
import { notUndefined } from '../../Utils/notUndefined';

export type AthleteJson = AuditedJson & {
  id: string;
  user: UserJson;
  firstname?: string;
  lastname?: string;
  birthdate?: string;
  birthYear?: number;
  gender: GenderType;
  profilePicture?: MediaJson;
  nickname?: string;
  tags: string[];
  allowsDataSharing: boolean;
  acceptsMarketing: boolean;
};

export class Athlete extends Audited {
  @observable
  user: User = new User();
  @observable
  firstname?: string;
  @observable
  lastname?: string;
  @observable
  birthYear?: number;
  @observable
  gender: GenderType = 'UNKNOWN';
  @observable
  profilePicture?: Media = undefined;
  @observable
  bodyValues?: ObservableMap<HealthDataPointObjectType, HealthDataPointObject>;
  @observable
  nickname?: string;
  @observable
  tags: string[] = [];
  @observable
  allowsDataSharing = false;
  @observable
  acceptsMarketing = false;

  constructor(json?: Partial<AthleteJson>) {
    super(json);
    if (json) {
      this.firstname = json.firstname;
      this.lastname = json.lastname;
      this.birthYear = json.birthYear;
      this.gender = json.gender || 'UNKNOWN';
      this.profilePicture = json.profilePicture ? new Media(json.profilePicture) : undefined;
      this.user = new User(json.user);
      this.nickname = json.nickname;
      this.tags = json.tags ?? [];
      this.allowsDataSharing = json.allowsDataSharing ?? false;
      this.acceptsMarketing = json.acceptsMarketing ?? false;
    }

    onBecomeObserved(this, 'bodyValues', this.fetchHealthData);
  }

  fetchHealthData = () => {
    if (!this.bodyValues) {
      this.bodyValues = this.bodyValues ?? observable.map({});
      return HealthDataPointQuery.recent()
        .then((result) => {
          runInAction(() => {
            result.forEach((point) => {
              if (
                point instanceof HealthDataPointQuantity ||
                point instanceof HealthDataPointCategory ||
                point instanceof HealthDataPointCorrelation
              ) {
                this.bodyValues?.set(point.type, point);
              }
            });
          });
        })
        .then(() => this.bodyValues);
    }
    return Promise.resolve(this.bodyValues);
  };

  toJS(): AthleteJson {
    return Object.assign(super.toJS(), {
      user: this.user.toJS(),
      firstname: this.firstname,
      lastname: this.lastname,
      birthYear: this.birthYear,
      gender: this.gender,
      profilePicture: this.profilePicture ? this.profilePicture.toJS() : undefined,
      nickname: this.nickname,
      tags: toJS(this.tags),
      allowsDataSharing: this.allowsDataSharing,
      acceptsMarketing: this.acceptsMarketing,
    });
  }

  @action
  setBodyValue(key: HealthDataPointObjectType, value: HealthDataPointObject) {
    this.bodyValues = this.bodyValues ?? observable.map({});
    this.bodyValues.set(key, value);
  }

  @action
  changeNickname(val: string) {
    this.nickname = val;
  }

  hasTagCategory(tagCategory: string) {
    const search = `${tagCategory}:`;
    return this.tags.findIndex((tag) => tag.startsWith(search)) !== -1;
  }

  getTagValue(tagCategory: string): string | undefined {
    const search = tagCategory.endsWith(':') ? tagCategory : `${tagCategory}:`;
    const index = this.tags.findIndex((tag) => tag.startsWith(search));
    if (index !== -1) {
      return this.tags[index].split(':').pop();
    }
    return undefined;
  }

  getTag(tagCategory: string): string | undefined {
    const search = tagCategory.endsWith(':') ? tagCategory : `${tagCategory}:`;
    return this.tags.find((tag) => tag.startsWith(search));
  }

  getTagValues(tagCategory: string): string[] {
    const search = (tagCategory.startsWith(':') ? tagCategory : `${tagCategory}:`).toLowerCase();
    return this.tags
      .filter((tag) => tag.toLowerCase().startsWith(search))
      .map((tag) => tag.split(':').pop())
      .filter(notUndefined);
  }

  @action
  setTagCategory(tagCategory: string, value: string) {
    const search = tagCategory.endsWith(':') ? tagCategory : `${tagCategory}:`;
    const index = this.tags.findIndex((tag) => tag.startsWith(search));
    if (index !== -1) {
      this.tags.splice(index, 1);
    }
    this.tags.push(`${search}${value}`);
  }

  @action
  pushTag(tagCategory: string, value: string) {
    const search = `${(tagCategory.endsWith(':') ? tagCategory : `${tagCategory}:`).toLowerCase()}${value}`;
    const index = this.tags.findIndex((tag) => tag === search);
    if (index === -1) {
      this.tags.push(search);
    }
  }

  @action
  setTagCategoryValues(tagCategory: string, values: string[]) {
    this.deleteTagCategory(tagCategory);
    this.pushTagCategoryValues(tagCategory, values);
  }

  @action
  pushTagCategoryValues(tagCategory: string, values: string[]) {
    values.forEach((value) => this.pushTag(tagCategory, value));
  }

  @action
  deleteTagCategory(tagCategory: string) {
    const search = (tagCategory.endsWith(':') ? tagCategory : `${tagCategory}:`).toLowerCase();
    this.tags = this.tags.filter((tag) => !tag.toLowerCase().startsWith(search));
  }

  @computed
  get height(): HealthDataPointQuantity | undefined {
    return this.bodyValues?.get(HealthDataPointQuantityType.height) as HealthDataPointQuantity;
  }

  @computed
  get weight(): HealthDataPointQuantity | undefined {
    return this.bodyValues?.get(HealthDataPointQuantityType.bodyMass) as HealthDataPointQuantity;
  }

  @computed
  get BMI(): number {
    return (this.bodyValues?.get(HealthDataPointQuantityType.bodyMassIndex) as HealthDataPointQuantity)?.value ?? 0;
  }

  @computed
  get bmr(): number {
    return (this.bodyValues?.get(HealthDataPointQuantityType.bmr) as HealthDataPointQuantity)?.value ?? 1689.22;
  }

  @computed
  get allTags(): string[] {
    return [...new Set(this.tags.concat([this.ageTag, this.gender === 'FEMALE' ? 'gender:female' : 'gender:male']))];
  }

  @computed
  get ageTag(): string {
    const { age } = this;
    if (age <= 20) {
      return 'age:junior';
    } else if (age <= 45) {
      return 'age:adult';
    } else if (age <= 65) {
      return 'age:senior';
    }
    return 'age:elder';
  }

  @computed
  get initials(): string {
    return (this.firstname?.trim().split(' ') ?? EMPTY_ARRAY)
      .map((str) => str.charAt(0))
      .join('')
      .toUpperCase();
  }

  @computed
  get fullName(): string {
    return this.firstname?.trim() ?? '';
  }

  @computed
  get fullNameUpper(): string {
    return this.fullName.toUpperCase();
  }

  @computed
  get age(): number {
    if (this.birthYear) {
      return new Date().getFullYear() - this.birthYear;
    }
    return 0;
  }

  /**
   * standard age groups used as predefined values from
   * google Analytics
   */
  @computed
  get ageGroup(): string | undefined {
    if (this.age) {
      switch (true) {
        case this.age >= 13 && this.age <= 17:
          return '13-17';
        case this.age >= 18 && this.age <= 24:
          return '18-24';
        case this.age >= 25 && this.age <= 34:
          return '25-34';
        case this.age >= 35 && this.age <= 44:
          return '35-44';
        case this.age >= 45 && this.age <= 54:
          return '45-54';
        case this.age >= 55 && this.age <= 64:
          return '55-64';
        case this.age >= 65:
          return '65+';
        default:
          return undefined;
      }
    }
    return undefined;
  }

  delete() {
    return HttpBackend.delete('/athlete');
  }

  save(): Promise<Athlete> {
    return HttpBackend.post('/athlete', this.toJS()).then(() => this);
  }

  static findByNickname(nickname: string): Promise<Athlete | undefined> {
    return HttpBackend.get('/athlete/findByNickname', { nickname }).then((res) => (res ? new Athlete(res) : undefined));
  }

  static checkNickname(nickname: string): Promise<Athlete | undefined> {
    return HttpBackend.get('/athlete/checkNickname', { nickname }, undefined);
  }

  static me(): Promise<Athlete> {
    return HttpBackend.get('/athlete/me').then((res) => new Athlete(res));
  }
}
