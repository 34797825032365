/**
 * Created by katarinababic on 15.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';
import { PlaceholderScreen } from '../../Components/PlaceholderScreen/PlaceholderScreen';
import ReactMarkdown from 'react-markdown';

const defaultContent = `
## HEALTH BLOG

**This content is currently only available in our app**.

Stay up-to-date on health news and extend your knowledge with our health blog. We cover the topics sleep, nutrition, work-life-balance and many more!

**Download now**

Information: your KINASTIC login is also valid for the KINASTIC app.
`;

export type BlogIndexScreenProps = {};

export const BlogIndexScreen: React.FC<BlogIndexScreenProps> = observer((props) => {
  const { t } = useTrans();

  return (
    <PlaceholderScreen headerImage={require('../../Assets/Images/blog_placeholder_content.png')}>
      <ReactMarkdown>{t('blogPlaceholderScreen.content', { defaultValue: defaultContent })}</ReactMarkdown>
    </PlaceholderScreen>
  );
});
