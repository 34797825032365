/**
 * Created by neo on 03.05.21.
 */
import { WorkoutTemplateEntry, WorkoutTemplateEntryJson } from './WorkoutTemplateEntry';
import { ExploreEntryQueryRequest } from './ExploreEntryQueryRequest';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { toJS } from 'mobx';

export type WorkplaceWorkoutEntryJson = WorkoutTemplateEntryJson & {};

export class WorkplaceWorkoutEntry extends WorkoutTemplateEntry {
  constructor(json?: Partial<WorkplaceWorkoutEntryJson>) {
    super(Object.assign(json ?? {}, { type: 'workplace' }));
    if (json) {
      this.type = 'workplace';
    }
  }

  static async find(request?: Omit<ExploreEntryQueryRequest, 'type'>): Promise<WorkplaceWorkoutEntry[]> {
    return HttpBackend.get(`/coach/explore`, Object.assign(toJS(request ?? {}), { type: 'workplace' })).then((res) =>
      (res ?? []).map((r) => new WorkplaceWorkoutEntry(r)),
    );
  }

  static async findOne(id: string): Promise<WorkplaceWorkoutEntry> {
    return HttpBackend.get(`/coach/explore/${id}`).then((res) => new WorkplaceWorkoutEntry(res));
  }
}
