/**
 * Created by neo on 15.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import { ExploreContentViewOnlineMeetingTeaser } from './ExploreContentViewOnlineMeetingTeaser';
import { ExploreContentViewOnlineMeetingEmbeddedVideo } from './ExploreContentViewOnlineMeetingEmbeddedVideo';
import { Container, Row } from 'reactstrap';

export type ExploreContentViewOnlineMeetingMediaContainerProps = {
  entry: OnlineMeetingEntry;
};

export const ExploreContentViewOnlineMeetingMediaContainer: React.FC<ExploreContentViewOnlineMeetingMediaContainerProps> =
  observer(({ entry }) => {

    if (!entry.isNotVimeoLink && entry.canJoinMeeting && entry.embedData?.web?.videoEmbedCode) {
      return (
        <Container>
          <ExploreContentViewOnlineMeetingEmbeddedVideo entry={entry} />
        </Container>
      );
    }

    return (
      <Container>
        <ExploreContentViewOnlineMeetingTeaser entry={entry} />
      </Container>
    );
  });
