import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';

const LinkTag = styled(Link)`
  color: #3886fa;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
`;

export const LinkComponent: React.FC<LinkProps> = observer(({ ...moreProps }) => {
  return <LinkTag {...moreProps}></LinkTag>;
});
