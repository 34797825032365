/**
 * Created by neo on 30.11.16.
 */
import { observable, action, computed, runInAction } from 'mobx';
import AuthenticationService from '../Services/Security/AuthenticationService';
import { Athlete } from '../Model/Athlete/Athlete';
import { User } from '../Model/User';
import SentryService from '../Services/SentryService';
import AppAuthToken from '../Services/Security/AppAuthToken';
import AppBackend from '../Services/Http/AppBackend';
import __DEV__, { __BETA__ } from '../Utils/Dev';
import { AppleAuthenticationRequest } from './SignupStore/AppleAuthenticationRequest';

export class AuthenticationStore {
  @observable isAuthenticating = false;
  @observable.ref
  athlete?: Athlete;
  @observable
  authTokenCheckInProgress = true;
  @observable
  authenticated = false;

  /* actions */
  @action
  async checkAuthData() {
    this.authTokenCheckInProgress = true;
    AppAuthToken.loadAuthData();
    if (!this.athlete) {
      try {
        const user = await AuthenticationService.checkToken();
        return this.setUserData(user);
      } catch (err) {
        this.authTokenCheckInProgress = false;
        throw err;
      }
    }
    await AppAuthToken.getAuthData();
    return this.athlete;
  }

  @action
  logout() {
    this.athlete = undefined;
    AuthenticationService.logout();
  }

  @action
  setAthleteData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    SentryService.setUser(athlete);
    return athlete;
  }

  @action
  setUserData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    this.authenticated = true;
    this.isAuthenticating = false;
    this.authTokenCheckInProgress = false;
    return this.athlete;
  }

  @action
  authenticate(email: string, password: string): Promise<Athlete> {
    if (email.trim().toLowerCase().length > 0 && password.trim().length > 0) {
      this.isAuthenticating = true;
      return AuthenticationService.login(email.trim().toLowerCase(), password.trim())
        .then((athlete) => this.setAthleteData(athlete))
        .finally(() => runInAction(() => (this.isAuthenticating = false)));
    }

    return Promise.reject(new Error('authentication error'));
  }

  @action
  appleLogin(request: AppleAuthenticationRequest): Promise<Athlete> {
    this.isAuthenticating = true;
    return AuthenticationService.appleLogin(request)
      .then((athleteData) => this.setAthleteData(athleteData))
      .finally(() => runInAction(() => (this.isAuthenticating = false)));
  }

  otpLogin(authenticationCode: string): Promise<Athlete> {
    this.isAuthenticating = true;
    return AuthenticationService.otpLogin(authenticationCode)
      .then((athleteData) => this.setAthleteData(athleteData))
      .finally(() => runInAction(() => (this.isAuthenticating = false)));
  }

  requestMagicLink(email: string): Promise<void> {
    return AppBackend.post(`/coach/authentication/magic-link`, {
      email: email,
      callbackUrl: this.callBackUrl(),
      numeric: true, // request a numeric code
    });
  }

  private callBackUrl(): string {
    if (__DEV__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fauth&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fauth&afl=https%3A%2F%2Fdev.coach.kinastic.com%3A3000%2Fauth&efr=1&isi=1465156373';
    } else if (__BETA__) {
      return 'https://open.kinastic.com/?link=https%3A%2F%2Fbeta.coach.kinastic.com%2Fauth&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fbeta.coach.kinastic.com%2Fauth&afl=https%3A%2F%2Fbeta.coach.kinastic.com%2Fauth&efr=1&isi=1465156373';
    }

    return 'https://open.kinastic.com/?link=https%3A%2F%2Fcoach.kinastic.com%2Fauth&apn=app.flinq.athlete&ibi=app.flinq.athlete&ifl=https%3A%2F%2Fcoach.kinastic.com%2Fauth&afl=https%3A%2F%2Fcoach.kinastic.com%2Fauth&efr=1&isi=1465156373';
  }

  /* computed */
  @computed
  get athleteId(): string | undefined {
    return this.athlete?.id;
  }

  @computed
  get user(): User | undefined {
    return this.athlete?.user;
  }

  @computed
  get userId(): string | undefined {
    return this.user?.id;
  }
}
