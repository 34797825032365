/**
 * Created by neo on 10.06.21.
 */
import { TagCondition, TagConditionJson } from '../Coach/SuperMacro/TagCondition';
import { LocalizedValue, LocalizedValueJson } from '../LocalizedValue';
import { observable } from 'mobx';

export type NotificationMessageJson = {
  tagCondition: TagConditionJson;
  title: LocalizedValueJson[];
  message: LocalizedValueJson[];
};

export class NotificationMessage {
  @observable
  tagCondition = new TagCondition();
  @observable
  title: LocalizedValue[] = [];
  @observable
  message: LocalizedValue[] = [];

  constructor(json?: Partial<NotificationMessageJson>) {
    if (json) {
      this.tagCondition = new TagCondition(json.tagCondition);
      this.title = (json.title ?? []).map((m) => new LocalizedValue(m));
      this.message = (json.message ?? []).map((m) => new LocalizedValue(m));
    }
  }

  toJS(): NotificationMessageJson {
    return {
      tagCondition: this.tagCondition.toJS(),
      title: this.title.map((t) => t.toJS()),
      message: this.message.map((t) => t.toJS()),
    };
  }
}
