/**
 * Created by neo on 30.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../../Model/Explore/ExploreCategory';
import { useRootStore } from '../../../../Store/useRootStore';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { Row } from 'reactstrap';
import { ExploreCardEntryRenderer } from '../../../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { SUPPORTED_TYPES } from '../../../../Utils/Constants';
import {ExploreFilterTagsSection} from "../../ExploreFilterTagsSection";
import styled from "@emotion/styled";

const FiltersTagsContainer = styled.div`
  padding-bottom: 32px;
`;

export type ExploreContentViewCategoryRendererProps = {
  category: ExploreCategory;
  linkPrefix?: string;
};

export const ExploreContentViewCategoryRenderer: React.FC<ExploreContentViewCategoryRendererProps> = observer(
  ({ category, linkPrefix }) => {
    const { language } = useRootStore();

    const [entries, setEntries] = useState<ExploreEntry[]>([]);
      const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const title = category.getName(language.language);
    const description = category.getDescription(language.language);

    useEffect(() => {
      ExploreEntryBuilder.find(
          Object.assign(
              selectedTags.length > 0
                  ? {
                      rootCategories: category.identifier ? [category.identifier] : [],
                  }
                  : {
                      categories: category.identifier ? [category.identifier] : [],
                  },
              {
                  type: SUPPORTED_TYPES,
                  size: 200,
                  filterTags: selectedTags,
              },
          )
      ).then((res) => setEntries(res));
    }, [category, selectedTags]);

    const handleSelectFilterTag = React.useCallback((tags: string[]) => {
        setSelectedTags(tags)
    }, []);

    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 32 }}>{title}</h1>
        <p>{description}</p>
        <Row>
            <FiltersTagsContainer>
                <ExploreFilterTagsSection category={category.identifier} selectedTags={selectedTags} onFilter={handleSelectFilterTag} />
            </FiltersTagsContainer>
          {entries.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={linkPrefix} />
          ))}
        </Row>
      </React.Fragment>
    );
  },
);
