/**
 * Created by neo on 23.02.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { PropsWithChildren, useEffect } from 'react';
import styled from '@emotion/styled';
import { Col, ColProps } from 'reactstrap';

const Wrapper = styled(Col)`
  padding: 8px;
  height: 400px;
  overflow-y: hidden;
`;

export type ExploreCardWrapperProps = {};

export const ExploreCardWrapper: React.FC<PropsWithChildren<ExploreCardWrapperProps>> = observer(({ children }) => {
  return (
    <Wrapper xs={10} sm={5} xl={3}>
      {children}
    </Wrapper>
  );
});
