import styled from '@emotion/styled';
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'reactstrap';
import { observer } from 'mobx-react';

const StyledButton = styled(BootstrapButton)`
  border-radius: 200px;
  border-width: 2px;
  font-weight: 600;
  line-height: 14px;
  color: #fff;
  font-size: ${({ size }) => (size === 'sm' ? '0.8em' : '1em')};
  padding: ${({ size }) => (size === 'sm' ? '10px 16px' : '20px 32px')};
  ${({ shadowed }) => (shadowed ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);' : '')}

  &:hover {
    // ${({ disabled }) => (disabled ? '' : 'cursor: pointer')};
  }

  &.btn-primary {
    background-color: #000;
    border-color: #000;

    &:active,
    &:hover {
      background-color: #000;
      border-color: #000;
    }
  }

  &.btn-primary.disabled,
  &:active.disabled,
  &:hover.disabled {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    pointer-events: inherit;
    cursor: inherit;
  }

  &.btn-danger {
    background-color: #dc3545;

    &:active {
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }

  &.btn-secondary {
    background-color: white;
    color: black;
    border-color: black;

    &:hover,
    &:active {
      background-color: black;
      border-color: white;
      color: white;
    }
  }

  &.btn-link {
    color: black;
    border-width: 0;
  }

  &.btn-warning {
    background-color: #f0a44b;
    color: black;
  }

  @media (min-width: 600px) {
    font-size: 1em;
    padding: 20px 32px;
  }

  &.btn-success {
    background-color: #24dca4;
    border-color: #24dca4;
    color: black;
  }

  &.btn-success.disabled {
    background-color: rgba(0, 0, 0, 0.5);
    border-width: 0;
    color: white;
    pointer-events: inherit;
    cursor: inherit;
  }
`;

export type ButtonProps = Omit<BootstrapButtonProps, 'value'> & {
  block?: boolean;
  value?: string | null;
  size?: 'sm';
  shadowed?: boolean;
};

export const Button: React.FC<PropsWithChildren<ButtonProps>> = observer(
  ({ value, color = 'primary', children, ...moreProps }) => {
    return (
      <StyledButton {...moreProps} color={color}>
        {children ?? String(value).toUpperCase()}
      </StyledButton>
    );
  },
);
